export const ENVIRONMENT = {
  LOCAL: "local",
  DEV: "dev",
  PROD: "prod",
  UAT: "uat",
};

const DEFAULT = {
  env: ENVIRONMENT.DEV,
  apiBaseUrl: "https://not.a.real.url",
};

const LOCAL = {
  env: ENVIRONMENT.LOCAL,
  // apiBaseUrl: "http://localhost:8000", // The FastAPI server
  apiBaseUrl: "https://api-dev.gfyag.com",
};

const DEV = {
  env: ENVIRONMENT.DEV,
  apiBaseUrl: "https://api-dev.gfyag.com",
};

const UAT = {
  env: ENVIRONMENT.UAT,
  apiBaseUrl: "https://api-uat.gfyag.com",
};

const PROD = {
  env: ENVIRONMENT.PROD,
  apiBaseUrl: "https://api.gfyag.com",
};


const config = {
  HUBSPOT_URL_DEV: 'https://zvg30f6wk8.execute-api.us-east-2.amazonaws.com/Prod/Hubspot',
  HUBSPOT_URL_UAT: 'https://ck7zbb9rsh.execute-api.us-east-2.amazonaws.com/Prod/Hubspot',
  HUBSPOT_URL_PRODUCTION: 'https://4byr4d1uq9.execute-api.us-east-2.amazonaws.com/Prod/Hubspot',


  HUBSPOT_PORTAL_ID_DEV : "24479443",
  HUBSPOT_PORTAL_ID_UAT : "24479443",
  HUBSPOT_PORTAL_ID_PRODUCTION : "23710839",
  HUBSPOT_FORM_ID_DEV : "9fc2f0c1-507d-4931-917d-d460235c970c",
  HUBSPOT_FORM_ID_UAT : "9fc2f0c1-507d-4931-917d-d460235c970c",
  HUBSPOT_FORM_ID_PRODUCTION : "8e6bcd81-5046-4d5e-a7ad-65b66d83cf58",

  GFYBACKEND_URL_DEV: 'https://ucojy4x0z7.execute-api.us-east-2.amazonaws.com/Prod/GFYBackend',  
  GFYBACKEND_URL_UAT: 'https://tn9s78co45.execute-api.us-east-2.amazonaws.com/Prod/GFYBackend',  
  GFYBACKEND_URL_PRODUCTION: 'https://45oqhtscqk.execute-api.us-east-2.amazonaws.com/Prod/GFYBackend',      

};
export default config;

export function getConfig() {
  const hostName = window.location.hostname.toLowerCase();

  if (["www.gfy.ag", "gfy.ag"].includes(hostName)) {
    return Object.freeze({
      ...DEFAULT,
      ...PROD,
    });
  }

  if (hostName.includes("uat.")) {
    return Object.freeze({
      ...DEFAULT,
      ...UAT,
    });
  }

  if (hostName.includes("dev.")) {
    return Object.freeze({
      ...DEFAULT,
      ...DEV,
    });
  }

  return Object.freeze({
    ...DEFAULT,
    ...LOCAL,
  });
}
