import { useState, useEffect, useContext } from "react";
import { Avatar, Chip, Box, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Link } from "react-router-dom";
import { AuthContext } from "../context";

const useStyles = makeStyles((theme) => ({
  token: {
    marginRight: "1rem",
    "&.MuiChip-root": {
      backgroundColor: "#fd8f25",
      color: "#fd8f25",
    },
    "&.MuiChip-avatar": {
      backgroundColor: "#fd8f25",
      color: "#fd8f25",
    },
  },
  avatar: {
    backgroundColor: "#fd8f25!important",
    color: "rgba(0, 0, 0, 0.87)!important",
  },
  tokenText: {
    color: "#7F0000",
    lineHeight: "24px",
    fontSize: "13px",
    marginRight: "7px",
  },
  label: {
    paddingRight: "2px",
  },
  icon: {
    color: "#611A15",
  },
  tooltip: {
    width: "86px",
    textAlign: "center",
  },
  link: {
    color: "#FF8F00",
  },
}));

const TokenChip = () => {
  const styles = useStyles();
  const [tokenCount, setTokenCount] = useState(0);

  const { tokens } = useContext(AuthContext);

  useEffect(() => {
    if (tokens) {
      setTokenCount(tokens);
    }
  }, [tokens]);

  return (
    <Chip
      avatar={<Avatar className={styles.avatar}>{tokenCount}</Avatar>}
      className={styles.token}
      classes={{ label: styles.label }}
      style={{ backgroundColor: "white" }}
      label={
        <Box display="flex" className={styles.label}>
          <Box className={styles.tokenText}>Drill Tokens</Box>{" "}
          <Tooltip
            arrow
            disableFocusListener
            enterTouchDelay={0}
            interactive
            placement="top"
            classes={{ tooltip: styles.tooltip }}
            title={
              <>
                1 drill = 1 token You may purchase more tokens in{" "}
                <Link className={styles.link} to={"/account"}>
                  your account.
                </Link>
              </>
            }>
            <InfoIcon className={styles.icon} />
          </Tooltip>
        </Box>
      }
      variant="outlined"
    />
  );
};

export default TokenChip;
