import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Stripe } from "../icons/stripe";
import amexIcon from "../../assets/amex.png";
import discoverIcon from "../../assets/discover.png";
import jcbIcon from "../../assets/jcb.png";
import masterCardIcon from "../../assets/master-card.png";
import unionPayIcon from "../../assets/union-pay.png";
import visaIcon from "../../assets/visa.png";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    // height: 438,
    // width: '376px',
    maxWidth: "376px",
    borderRadius: "4px",
    marginTop: 0,
    // boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
    marginRight: "18px",
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "Roboto",
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  cardBody: {
    padding: "30px 26px",
  },
  bankList: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "Roboto",
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
    listStyle: "none",
    margin: "10px 0px 10px -13px",
  },
  cardIcon: {
    width: 47,
    height: 34,
    margin: "0px 3px",
  },
  allPayments: {
    display: "flex",
    alignItems: "inherit",
    marginTop: 8,
  },
}));

const CardsAcceptdBox = ({ status, type, startDate, endDate, isError }) => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Box display="flex" justifyContent="flex-start" flexDirection="column" className={styles.cardBody}>
        {/* <Box>
          <b>Note:</b> All payments, prices and transactions are conducted in US Dollars (USD).
          <Box>
            <br />
            We accept payments through these major credit cards:
          </Box>
        </Box>

        <Box>
          <ul className={styles.bankList}>
            <li>• &nbsp; American Express</li>
            <li>• &nbsp; China UnionPay (CUP)</li>
            <li>• &nbsp; Discover & Diners</li>
            <li>• &nbsp; Japan Credit Bureau (JCB)</li>
            <li>• &nbsp; Mastercard</li>
            <li>• &nbsp; Visa</li>
          </ul>
        </Box> */}
        {/* <Box>
          <img className={styles.cardIcon} src={amexIcon} alt="Amex" />
          <img className={styles.cardIcon} src={discoverIcon} alt="Discover" />
          <img className={styles.cardIcon} src={jcbIcon} alt="JCB" />
          <img className={styles.cardIcon} src={masterCardIcon} alt="Master Card" />
          <img className={styles.cardIcon} src={unionPayIcon} alt="Union Pay" />
          <img className={styles.cardIcon} src={visaIcon} alt="Visa" />
        </Box> */}
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight="500" className={styles.allPayments}>
            <Box>All payments are</Box> &nbsp; &nbsp; <Stripe />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CardsAcceptdBox;
