import { useContext } from "react";
import { Dialog, DialogContent, Typography, DialogTitle, Button, Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Warning from "@mui/icons-material/WarningOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context";
import { deleteUser } from "../../data/queries";
import pupper from "../../assets/pupper.jpg";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#FFB74D",
    height: "80px",
    padding: "0 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      height: "50px",
    },
  },
  container: {
    width: "691px",
    height: "443px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
    },
  },
  titleText: {
    marginLeft: "20px",
    marginRight: "auto",
    verticalAlign: "middle",
    display: "flex",
    fontSize: "1.5rem",
    alignItems: "center",
  },
  text: {
    color: "rgba(0,0,0,0.87)",
    textAlign: "left",
    marginBottom: "40px",
    marginTop: "20px",
    letterSpacing: "0.15px",
    lineHeight: "28px",
    fontSize: "1rem",
    [theme.breakpoints.down(600)]: {
      fontSize: "1.2rem",
    },
  },
  content: {
    textAlign: "center",
    padding: 0,
    maxHeight: "362px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
  closeButton: {
    right: theme.spacing(1),
    color: "black",
    marginLeft: "auto",
  },
  deleteButton: {
    width: "300px",
    backgroundColor: "#1E4620",
    color: "white",
    fontSize: "15px",
    marginBottom: "14px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  cancelButton: {
    width: "300px",
    fontSize: "15px",
    color: "#1E4620",
    "&.MuiButton-outlined": {
      border: "1px solid #1E4620",
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  contentContainer: {
    padding: "2.7rem",
    [theme.breakpoints.down(600)]: {
      padding: "2rem",
    },
  },
  image: {
    height: "362px",
  },
}));

const DeleteDialog = (props) => {
  const { deleteOpen, setDeleteOpen } = props;

  const styles = useStyles();
  const history = useHistory();
  const { setIsLoggedIn } = useContext(AuthContext);

  const userDelete = async () => {
    const user = await Auth.currentAuthenticatedUser();
    await API.graphql(graphqlOperation(deleteUser, { uuid: user.username }));
    user.deleteUser((error, result) => {
      if (error) {
        console.log(error);
      }
      Auth.signOut();
      setIsLoggedIn(false);
      history.push("/login");
    });
  };

  return (
    <Dialog
      onClose={() => setDeleteOpen(false)}
      aria-labelledby="delete-dialog"
      open={deleteOpen}
      maxWidth={"lg"}
      fullWidth={true}
      classes={{ paper: styles.container }}>
      <DialogTitle className={styles.dialog} id="delete-dialog" onClose={() => setDeleteOpen(false)}>
        <Typography className={styles.titleText}>
          <Warning className={styles.icon} /> Delete My Account
        </Typography>
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={() => setDeleteOpen(false)}
          sx={{ display: { xs: "none", md: "flex" } }}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Box className={styles.contentContainer}>
          <Typography gutterBottom className={styles.text}>
            You are about to delete your account with Go Farm Yourself. Are you sure?
            <br />
            <br />
            Please don't go!
          </Typography>
          <Button variant="contained" size="large" className={styles.deleteButton} onClick={() => userDelete()}>
            Yes, Delete My Account
          </Button>
          <Button variant="outlined" size="large" className={styles.cancelButton} onClick={() => setDeleteOpen(false)}>
            Cancel
          </Button>
        </Box>
        <Box className={styles.image} sx={{ display: { xs: "none", md: "flex" } }}>
          <img src={pupper} alt="" />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteDialog;
