import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Paper, Box, Typography, Tabs, Tab, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MomoSummaryStatsTable from "../../components/CropDetails/MomoSummaryStatsTable";
import { CROP_TYPES } from "../../utils/constants";
import soybean from "../../assets/soybean.png";
import wheat from "../../assets/wheat.png";
import corn from "../../assets/corn.png";
import soybeanSelected from "../../assets/soybean_grey.png";
import wheatSelected from "../../assets/wheat_grey.png";
import cornSelected from "../../assets/corn_grey.png";
import notifications from "../../assets/notifications.png";

const cropIcon = [corn, wheat, soybean];
const cropIconSelected = [cornSelected, wheatSelected, soybeanSelected];

const useStyles = makeStyles((theme) => ({
  spotlight: {
    color: "#C56000",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "32px",
    textTransform: "uppercase",
    padding: "40px 0",
  },
  offerHeader: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    marginBottom: "20px",
  },
  offerText: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "20px",
    textAlign: "center",
  },
  notifiyimg: {
    height: "30.06px",
    width: "33.15px",
    margin: "0 20px",
  },
  offerbutton: {
    borderRadius: 4,
    height: 42,
    width: 225,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #1E4620",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: 500,
    backgroundColor: "transparent",
    alignSelf: "center",
    color: "#1E4620",
    margin: "30px 0",
    textTransform: "uppercase",
    "&:hover": {},
  },
  offerimage: {
    width: "100%",
  },
  tabRoot: {
    width: "100%",
    display: "flex",
  },
  tabLabel: {
    fontWeight: 500,
    paddingLeft: "60px",
    paddingRight: "60px",
    color: "#e0e0e0",
    fontSize: "11px",
    "&.Mui-selected": {
      color: "#000",
      paddingLeft: "60px",
      paddingRight: "60px",
    },
  },
  tabIcon: {
    color: "#e0e0e0",
    height: "16px",
    "&.Mui-selected": {
      color: "#000",
    },
  },
  tableofferbutton: {
    margin: "30px auto !important",
  },
}));

const Offer = () => {
  const [cropType, setCropType] = useState(0);
  const styles = useStyles();
  const history = useHistory();

  return (
    <Box>
      <Typography variant="h1" className={styles.spotlight}>
        What we are offering you?
      </Typography>
      <Grid container spacing={5} mb={5}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" className={styles.offerHeader}>
            1. GFY Suggested Action
          </Typography>
          <Typography variant="p" className={styles.offerText}>
            Our Hedging Stoplight gives very clear and simple advice to you, our farmers. Think of it like a...well
            stoplight! When its red, stop what you are doing and consider selling some crop! Pay attention and be
            prepared to take action if yellow. Finally if green, sit back and chill. We give you simple to understand
            suggestions based on our underlying models. The Stoplight is powered by Machine Learning and Statistics and
            will change according to the value of our proprietary indicator.
          </Typography>
          <Box display="flex" mt={3}>
            <img src={notifications} alt="notifications icon" className={styles.notifiyimg} />
            <Typography variant="p" className={styles.offerText}>
              + we will send you daily text alerts!
            </Typography>
          </Box>
          <Box className={styles.offerbutton} onClick={() => history.push("/login")}>
            Sign Up To View More
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src="./assets/predictions.png" alt="predictions" className={styles.offerimage} />
        </Grid>
      </Grid>
      <Grid container spacing={5} mb={5}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" className={styles.offerHeader}>
            2. GFY Proprietary Indicator
          </Typography>
          <Typography variant="p" className={styles.offerText}>
            Our proprietary indicator powers our Stoplight. This handy chart shows how it changes over the crop year and
            allows you to see its value relative to itself over time. Those color bands match up with our Stoplight.
            When the indicator is in the red, our Stoplight is red, when the indicator is in the yellow, our Stoplight
            is yellow...you get the idea.
          </Typography>
          <Box className={styles.offerbutton} onClick={() => history.push("/login")}>
            Sign Up To View More
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src="./assets/indicator.png" alt="indicator" className={styles.offerimage} />
        </Grid>
      </Grid>
      <Grid container spacing={5} mb={5}>
        <Grid item xs={12} md={12}>
          <Typography variant="h1" className={styles.offerHeader}>
            3. Historic Performance Comparison With GFY And Without
          </Typography>
          <Typography variant="p" className={styles.offerText}>
            Not convinced? We ran the Stoplight on the past 8 years of historical data for all our listed commodities
            and aggregated it for you in this handy table. Every year we add in how we did, comparing to the market
            average. Got a year in mind when you think you did well marketing? Look it up in this table and see how much
            better you would have done with GFY. (this example table shows December corn.)
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={4}>
            <Tabs centered className={styles.tabRoot} value={cropType} onChange={(e, value) => setCropType(value)}>
              {CROP_TYPES.map((crop, idx) => (
                <Tab
                  key={crop}
                  label={crop}
                  icon={
                    <img
                      src={cropType === idx ? cropIcon[idx] : cropIconSelected[idx]}
                      alt=""
                      className={styles.tabIcon}
                    />
                  }
                  classes={{ root: styles.tabLabel }}
                />
              ))}
            </Tabs>
            <MomoSummaryStatsTable cropType={CROP_TYPES[cropType]} />
          </Paper>
          <Box className={`${styles.tableofferbutton} ${styles.offerbutton}`} onClick={() => history.push("/login")}>
            Sign Up To View More
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={5} mb={5}>
        <Grid item xs={12} md={6}>
          <Typography variant="h1" className={styles.offerHeader}>
            4. Historic Crop Price And GFY Sells Chart
          </Typography>
          <Typography variant="p" className={styles.offerText}>
            Finally we provide market price data for our commodities going back 8 years in chart form. We also print out
            on the chart when our Stoplight has given sell suggestions - those are the red arrows. The green dashed line
            is the average of all our sell suggestions shown visually. The blue dotted line is the market average. We
            are transparent about our technology and want you to have access to the data - so here it is. Go play around
            and see how we did and how our suggestions might help your marketing strategy. Go Farm Yourself!
          </Typography>
          <Box className={styles.offerbutton} onClick={() => history.push("/login")}>
            Sign Up To View More
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src="./assets/tradechart.png" alt="tradechart" className={styles.offerimage} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Offer;
