import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Theme from "../../../Theme/Theme";

// icons
import closeIcon from '../../../assets/svg/close-circle.svg'
import { H3, H4 } from "../../../components/Text";

const CartItem = ({productName, productPrice, onRemove}) => {

  const styles = useStyles();

  return (
    <>
      <div className={styles.container}>
          <div className={styles.titleContainer}>
            <H4 className={styles.NoMargin}>{productName}</H4>
          </div>
          <div className={styles.productPrice}>
            <H3 className={styles.NoMargin}>{productPrice}</H3>
          </div>
          {/* <div className={styles.cross}>
            <button className={styles.crossButton} onClick={onRemove}>
              <img src={closeIcon} className={styles.closeIcon} />
            </button>
          </div> */}
        </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 25,
    paddingBottom: 25, 
    marginBottom: 20,
    border: `1px solid ${Theme.colors.cartBorderGrey}`,
  },
  titleContainer : {
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    flex: 1 
  },
  NoMargin: {
    margin: 0
  },
  crossButton: {
    padding: 0,
    margin: 0,
    border: 'none',
    background: 'transparent'
  },
  activeButton: {
    paddingTop: 5,
    marginLeft: 31,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 12,
    width: 97,
    height: 37,
    backgroundColor: Theme.colors.activeButtonColor,
    border: "none",
  },
  bottomsection: {
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    height: 50,
  },
  prodPrice: {
    fontFamily: Theme.fontfamily.Roboto,
    fontSize: Theme.fonts.mediumLarge,
    fontWeight: Theme.fontWeight.base,
    lineHeight: Theme.fonts.lineHeightsTwo,
    marginTop: 28,
    width: 230,
    height: 34,
    marginLeft: 14,
  },
  prodName: {
    fontFamily: Theme.fontfamily.Roboto,
    fontSize: Theme.fonts.mediumFull,
    fontWeight: Theme.fontWeight.small,
    lineHeight: Theme.fonts.lineHeightsTwo,
    marginLeft: 30,
    width: 109,
    height: 29,
  },
  productPrice: {
    flex: 0.75,
  },
  middleNameTitlePriceButtonContainer: {
    height: 152,
    width: 648,
    backgroundColor: Theme.colors.inputBackgroundColor,
    marginTop: 16,
    marginBottom: 16,
    border: "0.735849px solid #D3D3D3",
  },
  nameTitlePriceButtonContainer: {
    height: 152,
    width: 648,
    backgroundColor: Theme.colors.inputBackgroundColor,
    border: "0.735849px solid #D3D3D3",
  },
  closeIcon: {
    width: 32,
    height: 32,
    marginTop: 5,
  }
}));

export default CartItem;
