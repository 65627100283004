import { useState, useEffect } from "react";
import { Box, TextField, InputAdornment, IconButton, Collapse, Alert } from "@mui/material";
import { Clear, CheckCircleOutline, Close, Visibility, VisibilityOff } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";
import PasswordHelper from "./PasswordHelper";
import { Button } from "../.";
import { passwordValidate } from "../../utils/passwordValidation";

const useStyles = makeStyles((theme) => ({
  box: {
    borderTopLeftRadius: 0,
  },
  container: {
    marginBottom: "137px",
    marginTop: "33px",
    height: "400px",
    width: "380px",
  },
  innerContainer: {
    backgroundColor: "#fff",
    padding: "25px 40px",
  },
  formContainer: {
    marginTop: "42px",
  },
  centerBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
  },
  floatingLabelFocusStyle: {
    color: "#000",
  },
  button: {
    fontWeight: 500,
  },
  buttonContainer: {
    marginTop: "39px",
  },
  header: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginBottom: "24px",
  },
  input: {
    marginBottom: "22.9px",
  },
  successIcon: {
    color: "#497248",
    height: "45px",
    width: "45px",
    marginBottom: "32px",
    marginTop: "20px",
  },
  successText: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.15px",
    marginBottom: "48px",
  },
  successButtonContainer: {
    width: "300px",
  },
}));

const ResetPassword = ({ type, setResetOpen, padding }) => {
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordHelper, setShowPasswordHelper] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [errors, setErrors] = useState({
    code: false,
    oldPassword: false,
    confirmPassword: false,
    password: false,
    username: false,
  });
  const [resetSuccess, setResetSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  const passwordInputType = showPassword ? "text" : "password";
  const passwordConfirmInputType = showPasswordConfirm ? "text" : "password";

  const styles = useStyles();

  const handleReset = async () => {
    if (!oldPassword) {
      setErrors((prevState) => ({
        ...prevState,
        oldPassword: true,
      }));
      return;
    }
    if (!password) {
      setErrors((prevState) => ({
        ...prevState,
        password: true,
      }));
      return;
    }
    if (!confirmPassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: true,
      }));
      return;
    }
    if (password !== confirmPassword) {
      alert("Please confirm passwords match!");
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, password);
      setPassword("");
      setConfirmPassword("");
      setResetSuccess(true);
    } catch (e) {
      setApiError(e.message);
      setShowApiError(true);
    }
  };

  useEffect(() => {
    if (showPasswordHelper) {
      currentId === "newPassword" ? passwordValidate(password) : passwordValidate(confirmPassword);
    }
  }, [showPasswordHelper]);

  const ResetSuccess = () => (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      <Box>
        <CheckCircleOutline className={styles.successIcon} />
      </Box>
      <Box className={styles.successText}>
        Yeehaw! 🤠 <br /> You have successfully created a New Password!
      </Box>
      <Box>
        <Button
          onClick={() => setResetOpen(false)}
          fullWidth
          label="Ok, Done"
          className={styles.successButtonContainer}
          classes={{ label: styles.button }}
        />
      </Box>
    </Box>
  );

  return (
    <Box style={{ padding: padding }}>
      {type === "internal" && (
        <Box display="flex" justifyContent="space-between">
          <Box className={styles.header}>Create a new password:</Box>
          {type === "internal" && (
            <Box>
              <Clear className={styles.closeIcon} onClick={() => setResetOpen(false)} />
            </Box>
          )}
        </Box>
      )}
      {!resetSuccess && (
        <Box>
          <Collapse in={showApiError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowApiError(false);
                  }}>
                  <Close fontSize="inherit" />
                </IconButton>
              }>
              {apiError}
            </Alert>
          </Collapse>
          <form>
            {type === "internal" && (
              <TextField
                id="oldPassword"
                label="Old Password"
                type="password"
                fullWidth
                className={styles.input}
                value={oldPassword}
                error={errors.oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                helperText={errors.oldPassword ? "Please enter your old password." : ""}
              />
            )}

            <TextField
              id="newPassword"
              label="New Password"
              type={passwordInputType}
              fullWidth
              className={styles.input}
              value={password}
              error={errors.password}
              onFocus={(e) => {
                setShowPasswordHelper(true);
                setCurrentId(e.target.id);
              }}
              onBlur={() => setShowPasswordHelper(false)}
              onChange={(e) => {
                setPassword(e.target.value);
                passwordValidate(e.target.value);
              }}
              helperText={errors.password ? "Please enter a password." : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword()}
                      onMouseDown={() => handleMouseDownPassword()}
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="confirmPassword"
              label="Confirm Password"
              type={passwordConfirmInputType}
              fullWidth
              floatinglabelstyle={{ color: "#000" }}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                passwordValidate(e.target.value);
              }}
              value={confirmPassword}
              className={styles.form}
              onFocus={(e) => {
                setShowPasswordHelper(true);
                setCurrentId(e.target.id);
              }}
              onBlur={() => setShowPasswordHelper(false)}
              error={errors.confirmPassword}
              helperText={errors.confirmPassword ? "Please enter a password." : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPasswordConfirm()}
                      onMouseDown={() => handleMouseDownPasswordConfirm()}
                      size="large">
                      {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <PasswordHelper showPasswordHelper={showPasswordHelper} />

            <Box className={styles.buttonContainer}>
              <Button
                onClick={() => handleReset()}
                fullWidth
                label="Reset Password"
                classes={{ label: styles.button }}
              />
            </Box>
          </form>
        </Box>
      )}
      {resetSuccess && <ResetSuccess />}
    </Box>
  );
};

export default ResetPassword;
