export const CROPS = {
  CORN: {
    cropType: 1,
    frCropType: 1,
    displayName: "Corn",
  },
  SOYBEANS: {
    cropType: 2,
    frCropType: 2,
    displayName: "Soybeans",
  },
  KC_WHEAT: {
    cropType: 3,
    frCropType: 3, // ZW CHI Wheat
    displayName: "KC Wheat",
  },
};
