import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// Icons
import Green from '../../../assets/greenCircle.svg';
import Yellow from '../../../assets/yellowCircle.svg';
import Red from '../../../assets/redCircle.svg';
import { LabelRegular } from '../../../components/Text';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center',
    marginTop: '5%',
    marginBottom: '5%'
  },
  icon: {
    width: 56,
    height: 56,
    marginRight: '3%'
  },
  label: {
    color: '#858585'
  },
  divider: {
    height: 1,
    backgroundColor: '#858585',
    opacity: 0.5,
  }
}))

const TrafficLight = ({ type = 'green', label = '', hasBottomBorder = false }) => {

  const styles = useStyles();

  return (
    <>
      <div className={styles.container}>
        <img src={type == "green" ? Green : type == "yellow" ? Yellow : Red} className={styles.icon} />
        <LabelRegular className={styles.label}>{label}</LabelRegular>
      </div>
      {hasBottomBorder && <div className={styles.divider} />}
    </>
  );
}

export default TrafficLight;