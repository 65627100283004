import {
  Card,
  CardActions,
  CardContent,
  Button,
  Box,
  Checkbox,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EastIcon from "@mui/icons-material/East";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: "380px",
    borderRadius: "1px",
    background: "#016938",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  cardContent: {
    padding: "24px",
  },
  cardTitle: {
    fontSize: "25px",
    fontFamily: "Coolvetica",
    fontWeight: "400",
    fontSize: "25px",
    color: "#EBA83A",
  },
  description: {
    padding: "16px 0 24px",
    fontSize: "18px",
    fontWeight: "500",
    color: "#fff",
    fontFamily: "Inter",
    lineHeight: "119.5%",
    letterSpacing: "-0.03em",
    maxWidth: "310px",
  },
  contentRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  contentUp: {
    fontFamily: "Inter !important",
    fontWeight: "400",
    fontSize: "18px",
    // lineHeight: "389.5%",
    // letterSpacing: "-0.03em",
    color: "#FFF",
    [theme.breakpoints.down("md")]: {
      lineHeight: "inherit",
    },
  },
  contentDown: {
    fontFamily: "Inter !important",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "389.5%",
    letterSpacing: "-0.03em",
    color: "#FFF",
    [theme.breakpoints.down("md")]: {
      lineHeight: "inherit",
      paddingTop: 10,
    },
  },
  labelRoot: {
    margin: 0,
    paddingBottom: 6,
    //disabled line dividing princing card content
    // borderBottom: "1px solid #ffffff20",
    "& .MuiFormControlLabel-label": {
      width: "100%",
    },
    "& .MuiCheckbox-root": {
      display: "none",
    },
    "&:last-child": {
      border: "none",
    },
  },
  checkboxRoot: {
    display: "flex",
    paddingLeft: 15,
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      width: 5,
      height: 5,
      backgroundColor: "white",
      borderRadius: "50%",
      position: "absolute",
      left: 0,
      top: "46%",
    },
  },
  number: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 16,
    width: 40,
    height: 40,
    color: "white",
    fontWeight: "500",
    backgroundColor: "#FF8F00",
  },

  price: {
    padding: "16px 0",
    fontSize: "40px",
    fontWeight: "400",
    color: "#EBA83A",
    fontFamily: "Coolvetica",
    lineHeight: "106%",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
  },
  cardActionRoot: {
    justifyContent: "start",
    padding: "0 24px 24px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    "& button": {
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "22px",
      color: "#fff",
      background: "transparent",
      borderRadius: "1px",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingRight: "20px",
      textAlign: "center",
      borderColor: "#EBA83A",
      border: "1px solid",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingRight: "20px",
      paddingLeft: "20px",
      textTransform: 'initial',
      "&:hover": {
        background: "#EBA83A",
        color: "#13302B",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        width: "100%",
      },
      [theme.breakpoints.down(375)]: {
        fontSize: "11px",
      },
    },
  },
}));

const PricingCard = ({ title, description, content, price, actionButtonText, onActionClick, hideActionButtons }) => {
  const pricestyles = useStyles();

  return (
    <Card className={pricestyles.cardRoot}>
      <CardContent className={pricestyles.cardContent}>
        <Typography variant="h4" className={pricestyles.cardTitle}>
          {title}
        </Typography>
        <Typography variant="body1" className={pricestyles.description}>
          {description}
        </Typography>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked disabled />}
              labelPlacement="start"
              className={pricestyles.labelRoot}
              label={
                <div className={pricestyles.checkboxRoot}>
                  {/* <Typography variant="body1" className={styles.number}>
                    1
                  </Typography> */}
                  <div className={pricestyles.contentRoot}>
                    <Typography className={pricestyles.contentUp}>
                      {title === "Just One" ? "Single Commodity" : content}
                    </Typography>
                    {/* {title === "Just One" && (
                      <Typography variant="p" className={styles.contentDown}>
                        any of your choice
                      </Typography>

                    )} */}
                  </div>
                </div>
              }
            />

            <FormControlLabel
              control={<Checkbox defaultChecked disabled />}
              labelPlacement="start"
              className={pricestyles.labelRoot}
              label={
                <div className={pricestyles.checkboxRoot}>
                  {/* <Typography variant="body1" className={styles.number}>
                    2
                  </Typography> */}
                  <div className={pricestyles.contentRoot}>
                    <Typography className={pricestyles.contentDown}>Trade Alerts / Market Pivots</Typography>
                    
                    {/* <Typography variant="p" className={styles.contentDown}>
                      daily trade signals
                    </Typography> */}
                  </div>
                </div>
              }
            />
          </FormGroup>
        </Box>
        <Typography variant="h3" className={pricestyles.price}>
          $ {price}/YR
        </Typography>
      </CardContent>
      <CardActions className={pricestyles.cardActionRoot}>
        {!hideActionButtons && (
          <Button onClick={onActionClick} endIcon={<EastIcon />}>
            {actionButtonText}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default PricingCard;
