import { Box, Button, Grid, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../context";
import makeStyles from "@mui/styles/makeStyles";
import EastIcon from "@mui/icons-material/East";

const useStyles = makeStyles((theme) => ({
    h2: {
        color: "#031716",
        fontFamily: "'Coolvetica'",
        fontSize: "50px",
        fontWeight: "400",
        lineHeight: "96%",
        marginInline: "auto",
        marginBottom: 35,
        textAlign: "center",
        [theme.breakpoints.down(1536)]: {
          fontSize: "60px",
        },
        [theme.breakpoints.down(992)]: {
          fontSize: "50px",
        },
        [theme.breakpoints.down(768)]: {
          fontSize: "40px",
          textAlign: "center",
        },
      },
      container: {
        // marginTop: "400px",
        // [theme.breakpoints.down(1765)]: {
        //   marginTop: "600px",
        // },
        // [theme.breakpoints.down(1300)]: {

        // },
        // [theme.breakpoints.down(1180)]: {

        // },
        // [theme.breakpoints.down(768)]: {
        //   marginTop: "800px",
        // },
        // [theme.breakpoints.down(375)]: {

        // },
      },
      para: {
        letterSpacing: "-0.03em",
        color: "#031716",
        fontFamily: "Inter",
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "167%",
        marginBottom: "20px",
        textAlign: "center",
        paddingLeft: "40px",
        paddingRight: "40px",
        [theme.breakpoints.down(1536)]: {
          marginTop: "20px",
        },
        [theme.breakpoints.down(768)]: {
          marginInline: "auto",
          textAlign: "center",
          fontSize: "18px",
          paddingLeft: "25px",
          paddingRight: "25px",
          marginBottom: "2px",
          letterSpacing: "1px",          
        },
        [theme.breakpoints.down(375)]: {
          marginInline: "auto",
          textAlign: "center",
          fontSize: "18px",
          paddingLeft: "25px",
          paddingRight: "25px",
          paddingTop: "40px",
          marginBottom: "2px",
          letterSpacing: "1px",          
        },
      },
      button: {
        display: "inline-block",
        width: "190px",
        height: "50px",
        border: "2px solid",
        borderRadius: "4px",
        backgroundColor: "#32CD32",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "13px",
        cursor: "pointer",
        animation: "pulsate 1.5s infinite",
        // transition: "transform 0.3s ease",
        textAlign: "center",
        marginRight: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRight: "32px",
        paddingLeft: "32px",
        [theme.breakpoints.down(768)]: {
          width: "70%",
          fontSize: "16px",
          marginRight: "15px",
          marginLeft: "15px",
        },
        [theme.breakpoints.down(375)]: {
          fontSize: "11px",
          width: "70%",
          marginRight: "15px",
          marginLeft: "15px",
        },
        "&:hover": {
          backgroundColor: "#fff",
          borderColor: "#000",
          transform: "scale(1.5)",
          // animation: "none",
        },
      },
      button2: {
        width: "190px",
        height: "50px",
        fontFamily: "Inter",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "22px",
        color: "#13302B",
        background: "#fff",
        borderRadius: "1px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRight: "20px",
        textAlign: "center",
        borderColor: "#13302B",
        boxShadow: "none",
        border: "1px solid",
        textTransform: "initial",
        "&:hover": {
          color: "#eba83a"
        },
        [theme.breakpoints.down("sm")]: {
          width: "70%",
          fontSize: "16px",
          marginRight: "15px",
          marginLeft: "15px",
        },
        [theme.breakpoints.down(375)]: {
          fontSize: "11px",
        },
      },
      mobileButtons: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
      },
      bold: {
        color: "#016938",
        fontSize:"50px",
        [theme.breakpoints.down('sm')]: {
          fontSize: "18px",
          lineHeight: '40px',
        },
      },
      strong: {
        fontSize:"50px",
        [theme.breakpoints.down('sm')]: {
          fontSize: "18px",
          lineHeight: '40px',
          fontWeight: 700,
        },
      }, 
  }));

const ResultTextBlock = ({ profit }) => {
    
    const styles = useStyles();
    const { setShowContact } = useContext(AppContext);
   
 return (
    <Box container spacing={1} alignItems="center" id="results" className={styles.container}>
      <Box item xs={12} lg={12}>
        <Typography className={styles.para}>
          Executing on all of GFY sell alerts in 2022 allowed a <strong>1000-acre</strong> corn and soybean operation to
          realize an <br /><strong className={styles.strong}>additional profit of</strong>{" "}
          <b className={styles.bold}>{profit.toLocaleString("en-US", { style: "currency", currency: "USD" })}</b>{" "}
          versus the Spring Insurance Price.
        </Typography>
      </Box>
      <Box item xs={12}>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="center" alignItems="center" spacing={2} pt={5}>
          <Button variant="contained" className={styles.button} onClick={() => {
            setShowContact(true);
          }}>
            CLAIM FREE TRIAL
          </Button>
          <Button variant="contained" endIcon={<EastIcon />} className={styles.button2} href='/results'>
            Learn More
          </Button>
        </Stack>
      </Box>
    </Box>
 );
}
export default ResultTextBlock;