import LoadingButton from "@mui/lab/LoadingButton";

const GFYButton = (props) => {
  const { label, className, onClick, loading } = props;

  return (
    <LoadingButton
      {...props}
      variant="contained"
      loading={loading}
      color="secondary"
      size="large"
      className={className}
      onClick={onClick}>
      {label}
    </LoadingButton>
  );
};

export default GFYButton;
