import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "14px",
    fontWeight: "normal",
    color: "#C56000",
    padding: "6px 9px",
    backgroundColor: "#FFE97D",
    width: "160px",
    borderRadius: "20px",
    marginLeft: "15px",
    marginBotton: "0",
  },
}));

const TopHeader = () => {
  const styles = useStyles();

  return (
    <Box my={3}>
      <Box className={styles.text}>
        <b>Hi Hello! Welcome to the</b>
      </Box>
    </Box>
  );
};

export default TopHeader;
