import { useState } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TwoTokens } from "../icons/tokens";
import SocialDialog from "../modals/SocialDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "120px",
    maxWidth: "356px",
    width: "100%",
    borderRadius: "4px",
    marginTop: "36px",
    boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
    marginRight: "18px",
    transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    "&:hover": {
      transform: "scale(1.01, 1.01)",
      transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
      boxShadow: "0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2)",
    },
    [theme.breakpoints.down(600)]: {
      height: "120px",
      width: "auto",
      marginRight: 0,
    },
    "@media (min-width:600px) and (max-width:800px)": {
      width: "300px",
    },
  },
  bottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #1E4620",
    cursor: "pointer",
    color: "#1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  top: {
    height: "80px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "20px",
  },
  topText: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: 500,
    fontSize: "14px",
    marginLeft: "10px",
  },
  amount: {
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
    marginTop: "4px",
    marginLeft: "10px",
    [theme.breakpoints.down(600)]: {
      marginRight: "10px",
    },
  },
}));

const PurchaseTokenBox = (props) => {
  const [showSocialShare, setShowSocialShare] = useState(false);

  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Box color="primary.contrastText" className={styles.top}>
        <TwoTokens />
        <Box display="flex" justifyContent="flex-start" flexDirection="column">
          <Box component="span" className={styles.topText}>
            2 Drill Tokens
          </Box>
          <Typography variant="h6" className={styles.amount}>
            One Social Media Share
          </Typography>
        </Box>
      </Box>
      <Box className={styles.bottom} onClick={() => setShowSocialShare(true)}>
        Brag About GFY!
      </Box>
      <SocialDialog {...{ showSocialShare, setShowSocialShare }} />
    </Box>
  );
};

export default PurchaseTokenBox;
