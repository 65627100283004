import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { TextField, Box, InputAdornment, IconButton, Collapse, Alert } from "@mui/material";
import { Visibility, VisibilityOff, Close } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";
import Button from "../Button";
import AuthContext from "../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: "14px",
  },
  text: {
    textDecoration: "underline",
    fontSize: "12px",
    color: "#1E4620",
    cursor: "pointer",
  },
  button: {
    fontWeight: 500,
  },
  buttonStyle: {
    color: "#001F00",
  },
}));

const Login = ({ setCurrentScreen }) => {
  const styles = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: false, password: false });
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const history = useHistory();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const inputType = showPassword ? "text" : "password";

  const handleSignIn = async () => {
    setLoading(true);
    if (!email) {
      setErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
      return;
    }
    if (!password) {
      setErrors((prevState) => ({
        ...prevState,
        password: true,
      }));
      return;
    }
    try {
      const user = await Auth.signIn(email, password);
      setIsLoggedIn(true);
      setUserProfile(user.attributes);
      setLoading(false);
      history.push("/stoplight");
    } catch (e) {
      //Fix Quota login issue on iOS
      if (e.name === "QuotaExceededError") {
        window.localStorage.clear();
        setApiError("Please try again");
        setShowApiError(true);
        setLoading(false);
        //Test before uncommenting
        //handleSignIn();
      }
      setApiError(e.message);
      setShowApiError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Collapse in={showApiError}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowApiError(false);
              }}>
              <Close fontSize="inherit" />
            </IconButton>
          }>
          {apiError}
        </Alert>
      </Collapse>
      <TextField
        id="email"
        label="Your E-mail"
        type="email"
        fullWidth
        required
        value={email}
        error={errors.email}
        helperText={errors.email ? "Please enter an email address." : ""}
        className={styles.form}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        id="password"
        label="Password"
        type={inputType}
        fullWidth
        required
        error={errors.password}
        helperText={errors.password ? "Please enter a password." : ""}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className={styles.form}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword()}
                onMouseDown={() => handleMouseDownPassword()}
                size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box mt={2} className={styles.text} onClick={() => setCurrentScreen("send")}>
        forgot password
      </Box>
      <Box mt={8} style={{ position: "relative" }}>
        <Button
          onClick={() => handleSignIn()}
          fullWidth
          classes={{ label: styles.button }}
          label="Sign In"
          disabled={loading}
          loading={loading}
          className={styles.buttonStyle}
        />
      </Box>
    </>
  );
};

export default Login;
