import { Box, Button, Grid, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "react-responsive-modal";
import { useState, useContext } from "react";
import { AppContext } from "../../context";


const modalHeight = 400;

const useStyles = makeStyles((theme) => ({
  section: {
    maxWidth: "1310px",
    margin: "auto",
  },
  h2: {
    color: "#031716",
    fontFamily: "'Coolvetica'",
    fontSize: "70px",
    fontWeight: "400",
    lineHeight: "96%",
    textAlign: "center",
    marginInline: "auto",
    marginBottom: 35,
    [theme.breakpoints.down("lg")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  buttonWrapper: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      width: "70%",
      fontSize: "16px",
    },
  },
  button: {
    display: "inline-block",
    width: "190px",
    height: "50px",
    border: "2px solid",
    borderRadius: "4px",
    backgroundColor: "#32CD32",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "13px",
    cursor: "pointer",
    animation: "pulsate 1.5s infinite",
    // transition: "transform 0.3s ease",
    textAlign: "center",
    marginTop: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "32px",
    paddingLeft: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      fontSize: "16px",
      marginRight: "15px",
      marginLeft: "15px",
    },
    [theme.breakpoints.down(375)]: {
      fontSize: "11px",
      width: "70%",
      marginRight: "15px",
      marginLeft: "15px",
    },
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#000",
      transform: "scale(1.5)",
      // animation: "none",
    },
  },
  para: {
    letterSpacing: "-0.03em",
    color: "#858585",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "167%",
    maxWidth: "1000px",
    [theme.breakpoints.down("sm")]: {
      marginInline: "auto",
      textAlign: "center",
    },
  },
  img: {
    maxWidth: "100%",
    marginInline: "auto",
    display: "block",
    marginBottom: 40,
  },
  modalWrapper: {
    "& .frameeee": {
      width: "800px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },
}));
const modalStyles = {
  modal: {
    padding: 0,
    height: modalHeight,
    overflow: "hidden",
  },
};
const HeroVideo = () => {
  const heroVideoStyles = useStyles();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const { setShowContact } = useContext(AppContext);

  return (
    <Grid container className={heroVideoStyles.section} id="about">
      <Grid item xs={12}>
        <Typography variant="h2" className={heroVideoStyles.h2}>
          Who is Go Farm Yourself?
        </Typography>
        <Typography className={heroVideoStyles.para} align="center" mb="40px" mx="auto">
          GFY was created by Co-Founder and CEO Rupert Williams, who is a former CBOT floor trader, and broker, and
          Jefferson Duggan, a computer scientist. Rupert recognized that the big guys in Chicago and Wall Street have
          data platforms and computer algorithms that can quickly trade and make decisions much faster than a human can.
        </Typography>
        <img
          src="../assets/hero-video-image.png"
          alt="Who is Go Farm Yourself?"
          className={heroVideoStyles.img}
          onClick={onOpenModal}
        />
        <Box className={heroVideoStyles.modalWrapper}>
          <Modal open={open} onClose={onCloseModal} center showCloseIcon={false} styles={modalStyles}>
            <iframe
              style={{ width: "calc(100vw - 40px)", maxWidth: "800px" }}
              height={modalHeight}
              src="https://www.youtube.com/embed/T_od5Ug7DDs"
              title="Go Farm Yourself - Who is Go Farm Yourself?"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="frameeee"
            />
          </Modal>
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Typography className={heroVideoStyles.para} gutterBottom>
          So to level the playing field for farmers, he set out with his co-founder Jefferson to create a
          machine-learning data platform that will spit out buy/sell alerts for farmers on when to market their grain
          (corn, soybeans, wheat) via a text message alert.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={4} className={heroVideoStyles.buttonWrapper}>
        <Button variant="contained" className={heroVideoStyles.button} onClick={() => {
            setShowContact(true);
          }}>
          CLAIM FREE TRIAL
        </Button>
      </Grid>
    </Grid>
  );
};

export default HeroVideo;
