import dayjs from "dayjs";

export class Column {
  constructor({ key, label, align, formatType, ...rest }) {
    this.key = key;
    this.label = label || key;
    this.align = align || "center";
    this.formatType = formatType || "text";

    // optional
    this.minWidth = rest.minWidth || undefined;
    this.formatter = rest.formatter || undefined;
  }

  format(val, row) {
    if (this.formatter) return this.formatter(val, row);

    switch (this.formatType) {
      case "currency":
        return val
          ? Number(val).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 4,
            })
          : "";
      case "date":
        return val ? dayjs(val).format("MMM DD YYYY") : "";
      case "longOrShort":
        if (!val) return "";

        if (this.key.toLowerCase().includes("long")) {
          return "+" + val;
        } else {
          return "-" + val;
        }
      default:
        return val;
    }
  }
}
