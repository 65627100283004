let d = new Date(),
  y = d.getFullYear(),
  m = d.getMonth(),
  quarter = [
    { start: `${y}-01-01`, end: `${y}-03-31` },
    { start: `${y}-04-01`, end: `${y}-06-30` },
    { start: `${y}-07-01`, end: `${y}-09-30` },
    { start: `${y}-10-01`, end: `${y}-12-31` },
  ],
  options = [];

for (let pastYear = y - 2; pastYear < y; pastYear++) {
  const pastQuarter = [
    { start: `${pastYear}-01-01`, end: `${pastYear}-03-31` },
    { start: `${pastYear}-04-01`, end: `${pastYear}-06-30` },
    { start: `${pastYear}-07-01`, end: `${pastYear}-09-30` },
    { start: `${pastYear}-10-01`, end: `${pastYear}-12-31` },
  ];

  pastQuarter.forEach((q, i) =>
    options.push({
      end: q.end,
      key: `Q${i + 1} ${pastYear}`,
      start: q.start,
      year: pastYear,
    })
  );
}
quarter.slice(0, parseInt(m / 3 + 1)).forEach((q, i) =>
  options.push({
    end: q.end,
    key: `Q${i + 1} ${y}`,
    start: q.start,
    year: y,
  })
);
export const quarters = options;

let newYears = [2017, 2018, 2019, 2020, 2021, 2022, 2023];
const sortYears = newYears.sort((a, b) => new Date(a) - new Date(b));

export const years = sortYears;
