export const passwordValidate = (pass) => {
  if (pass) {
    const letter = document.getElementById("letter");
    const capital = document.getElementById("capital");
    const number = document.getElementById("number");
    const length = document.getElementById("length");
    const character = document.getElementById("character");
    if (!letter || !capital || !number || !length || !character) return;
    //Check for lowercase
    if (pass.match(/[a-z]/g)) {
      letter.classList.remove("invalid");
      letter.classList.add("valid");
    } else {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
    }
    //Validating length
    if (pass.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");
    } else {
      length.classList.remove("valid");
      length.classList.add("invalid");
    }
    //check for uppercase
    if (pass.match(/[A-Z]/g)) {
      capital.classList.remove("invalid");
      capital.classList.add("valid");
    } else {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
    }
    //check for number
    if (pass.match(/\d+/g)) {
      number.classList.remove("invalid");
      number.classList.add("valid");
    } else {
      number.classList.remove("valid");
      number.classList.add("invalid");
    }
    //check for special character
    if (pass.match(/[#?!@$%^&*\-_\\\/]/g)) {
      character.classList.remove("invalid");
      character.classList.add("valid");
    } else {
      character.classList.remove("valid");
      character.classList.add("invalid");
    }
  }
};
