import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Theme from "../../../../../Theme/Theme";
import ArrowIcon from "../../../../../assets/svg/arrow.svg";
import { LabelRegular, Subtitles } from "../../../../../components/Text";

export const AccordianDescriptionContianerTwo = () => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.descriptionContainer}>
        <span className={styles.circilarSideButton}>
          <Subtitles>Corn</Subtitles>
        </span>
        <img src={ArrowIcon} className={styles.arrowStyling} />
        <span className={styles.circilarSideButton}>
          <Subtitles>Soybean</Subtitles>
        </span>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  arrowStyling: {
    width: 49.93,
    height: 13,
    marginLeft: 30,
    marginRight: 30,
  },
  circilarSideButton: {
    width: 142,
    height: 39,
    background: Theme.colors.tickGrayBackground,
    borderRadius: 100,
    marginRight: 24,
    marginLeft: 24,
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
