export const getDrillModelResults = /* GraphQL */ `
  query GetDrillModelResults($id: ID!) {
    getDrillModelResults(id: $id) {
      id
      spoof_value_filter
      spoof_value_actual
      model_prediction_val
    }
  }
`;

export const GetAllWebParams = /* GraphQL */ `
  query GetAllWebParams {
    listWebAppParams {
      items {
        key
        value
      }
    }
  }
`;

export const GetWebAppParamsData = /* GraphQL */ `
  query GetWebAppParamsData($uuid: String) {
    getWebAppParamsData(uuid: $uuid)
  }
`;

export const getUserSubscriptionData = /* GraphQL */ `
  query getUserSubscriptionData($uuid: String) {
    getUserSubscriptionData(uuid: $uuid)
  }
`;

export const getUserTokenBalance = /* GraphQL */ `
  query GetUser($uuid: String!) {
    getUser(uuid: $uuid) {
      id
      uuid
      tokenCount
      subscriptions {
        items {
          id
          customerId
          subscriptionId
          totalAmount
          priceId
          productName
          paymentStatus
          startDate
          endDate
        }
      }
    }
  }
`;

export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($uuid: String!) {
    deleteUser(uuid: $uuid)
  }
`;

export const getBasicModelResults = /* GraphQL */ `
  query GetBasicModelResults($id: ID!) {
    getBasicModelResults(id: $id) {
      id
      model_name
      filter_year_run
      model_key
      executed_at
      effective_date
      curr_active
      model_prediction_val
      input_last_price_val
      prediction_deviation
    }
  }
`;

export const drillModel = /* GraphQL */ `
  query DrillModel($id: ID, $uuid: String) {
    drillModel(id: $id, uuid: $uuid)
  }
`;

export const listGFYSellsResults = /* GraphQL */ `
  query listGFYSellsResults($filter: ModelGFYSellsResultsFilterInput, $limit: Int, $nextToken: String) {
    listGFYSellsResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        gfy_sell_val
        avg_sale_with_gfy
        avg_sale_without_gfy
        close
        crop
        date
        symbol
        filter_year_run
        id
      }
      nextToken
    }
  }
`;

export const createUserContacts = /* GraphQL */ `
  mutation createUserContacts($id: ID!, $uuid: String!, $timestamp: AWSDateTime!, $info: AWSJSON!) {
    createUser(input: { id: $id, uuid: $uuid, contacts: { info: $info, created: $timestamp } }) {
      id
      uuid
      contacts {
        info
        created
      }
    }
  }
`;

export const updateUserContact = `
  mutation updateUserContacts($uuid: String!, $timestamp: AWSDateTime!, $info: AWSJSON!) {
    updateUser(input: {contacts: {info: $info, created: $timestamp}, uuid: $uuid}) {
      contacts {
        info
      }
    }
  }
`;

export const listMomoResults = /* GraphQL */ `
  query ListMomoResults($filter: ModelMomoResultsFilterInput, $limit: Int, $nextToken: String) {
    listMomoResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        green_threshold
        filter_year_run
        momo
        momo_p
        yellow_threshold
        crop
      }
      nextToken
      startedAt
    }
  }
`;
