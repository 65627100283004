


export const getContentful = async ({ query, variables }) => {
  
  const contentfulConfig = {
    'space': process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    'accessToken': process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
  }
  
  // console.log(process.env)

  let headers = {
    "Content-Type": "application/json",
    // Authenticate the request
    Authorization: `Bearer ${contentfulConfig.accessToken}`,
  }

  let body = JSON.stringify({
    query,
    variables,
  })

  console.log([contentfulConfig, headers, body])
  return window
    .fetch(contentfulConfig['space'], {
      method: "POST",
      headers: headers,
      // send the GraphQL query
      body: body,
    })
    .then((response) => response.json())
    .catch((error) => console.error([error, contentfulConfig]));
};
