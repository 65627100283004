import { Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const OnboardingOne = (props) => {
  const { setCurrentCard } = props;

  const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: "34px",
      color: "#B71C1C",
      lineHeight: "42px",
      marginTop: "10px",
      [theme.breakpoints.down(600)]: {
        fontSize: "22px",
        lineHeight: "30px",
      },
      [theme.breakpoints.down(800)]: {
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    middle: {
      color: "rgba(0,0,0,0.87)",
      fontSize: "24px",
      marginTop: "32px",
      [theme.breakpoints.down(600)]: {
        fontSize: "18px",
        marginTop: "18px",
      },
      [theme.breakpoints.down(800)]: {
        fontSize: "20px",
      },
    },
    bottom: {
      color: "rgba(0,0,0,0.87)",
      fontSize: "24px",
      marginTop: "32px",
      fontWeight: 500,
      [theme.breakpoints.down(600)]: {
        fontSize: "18px",
      },
      [theme.breakpoints.down(800)]: {
        fontSize: "20px",
      },
    },
    button: {
      width: "300px",
      backgroundColor: "#1E4620",
      color: "white",
      fontSize: "15px",
      marginBottom: "101px",
      [theme.breakpoints.down(600)]: {
        marginBottom: "18px",
      },
      [theme.breakpoints.down(800)]: {
        marginBottom: "24px",
      },
    },
  }));

  const styles = useStyles();

  return (
    <Box>
      <Box mt={4}>
        <Box mb={2} className={styles.heading}>
          oh Hai, Me Again,
          <br />
          Welcome To The <b>Grain Hedging Stoplight</b> by Go Farm Yourself!
        </Box>

        <Box className={styles.middle}>
          We studied the maths and the markets.
          <br />
          We built a crop hedging indicator.
        </Box>
        <Box className={styles.bottom}>
          Now we made our model and sell recommendations available to you. <br />
          Subscribe to the crop of your choice to get access to our GFY Grain Hedging Stoplight.
        </Box>
        <br />
        <Button
          variant="contained"
          size="large"
          className={styles.button}
          onClick={() => setCurrentCard("OnboardingTwo")}>
          Let's Get Started!
        </Button>
      </Box>
    </Box>
  );
};

export default OnboardingOne;
