import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "1.25rem",
    fontWeight: 500,
    marginTop: "24px",
  },
  text: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "24px",
  },
}));

const QA = () => {
  const styles = useStyles();

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box className={styles.subHeader}>Who is Go Farm Yourself?</Box>
        <Typography className={styles.text}>
          Go Farm Yourself Inc-GFY.AG is the extensive collaboration with farmers and Rupert Williams. Rupert is, a
          graduate of Baylor University with an Economics degree and a former CBOT/CME (floor and electronic) grain and
          cattle option market maker. When the trading pits in Chicago closed he traveled rural America and learning
          about the challenges producers faced. One of the common challenges was ‘marketing’. Realizing a gap in
          self-service marketing products for farms, Rupert teamed up with the Data Scientist Jefferson Duggan.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box className={styles.subHeader}>How can GFY Help You?</Box>
        <Typography className={styles.text}>
          Together, Rupert, Jefferson, and team set out to create a ‘value prediction’ on forward commodity prices. The
          result is a tool to provide farmers an unbiased, data driven, machine learning algorithm to help make
          decisions based on data and not emotion. As the nature of markets changed away from ‘the pits’ to more
          computer based algorithmic trading, these tools are more important than ever. This is a tool for the local
          producer to maintain competitiveness against commercial traders.
        </Typography>
      </Box>
      {/* <Box display="flex" flexDirection="column">
      <Box className={styles.subHeader}>What is the Drill?</Box>
      <Typography className={styles.text}>
        The Drill allows anyone to access our predictive models. In addition to having a daily predicted closing
        price for the next closing day, the drill allows our users to 'fake' a closing price for that next
        closing day, and in return get the following trading days predicted closing price. Think of it as a way
        to get a prediction about market behavior based on your own input of where the market will close
        currently. If its Monday before market close, just use input to the drill your assumption of a closing
        price, the drill will respond with a prediction for closing on Tuesday if the market does indeed close
        Monday at your suggested input.
      </Typography>
    </Box>
    <Box display="flex" flexDirection="column">
      <Box className={styles.subHeader}>How Does this work?</Box>
      <Typography className={styles.text}>
        The drill allows you to get a closing price prediction for the next trading day, based on your proposed
        closing price of the current trading day. Load your account with tokens, submit your assumption of
        closing price for today and receive the predicted closing price for tomorrow! Each drill submission cost
        1 token. We will start you off with some tokens but you can purchase more or there are ways to earn
        them! The drill shuts down every day between 1:30pm and 3pm CST for the model to get updated.
      </Typography>
    </Box> */}
    </>
  );
};

export default QA;
