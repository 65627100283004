import { useState, useEffect, useContext } from "react";
import { Auth, API } from "aws-amplify";
import { Box, TextField, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import styles from "./account.styles";
import AuthContext from "../../context/AuthContext";
import EditComponent from "../../components/Edit";
import SaveComponent from "../../components/Save";
import axios from "axios";
import config from '../../config';
import { set } from "lodash";
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles((theme) => ({
  // ... other styles
  verifyButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    marginTop: '5px',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
}));

const NameSetting = withStyles(styles)(({ classes, setApiError, setShowApiError }) => {
  const { userProfile } = useContext(AuthContext);

  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");  
  const [promo, setPromo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nameDisabled, setNameDisabled] = useState(true);
  const [emailDisabled, setEmailDisabled] = useState(true);  
  const [promoDisabled, setPromoDisabled] = useState(true);
  const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(true);
  const [isPhoneVerified, setIsPhoneVerified] = useState(true);
  const [nameEdit, setNameEdit] = useState("edit");
  const [errors, setErrors] = useState({ name: false, firstName: false , lastName: false, role: false, phoneNumber: false });
  const styles = useStyles();

  const beginPhoneVerification = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const phone_number = user.attributes.phone_number;
    await Auth.verifyCurrentUserAttribute("phone_number");
    alert("Verification code sent to " + phone_number);
  };

  const checkPhoneVerification = async () => {
    
    const user = await Auth.currentAuthenticatedUser();
    const phone_number = user.attributes.phone_number;
    const phone_verified = user.attributes.phone_number_verified;

    if (phone_number && phone_verified) {
      setIsPhoneVerified(true);
    } else {
      setIsPhoneVerified(false);
    }
    
  };  

  const getContactDetails = async () => {


      
    try {
      const user = await Auth.currentAuthenticatedUser();

      const email = user.attributes.email;

      // get idtoken
      const token = user.signInUserSession.idToken.jwtToken;
      const env_var = process.env.REACT_APP_NODE_ENV;

      const queryStringParameters = {
        env: env_var,
        email: email,
      };

      // console.log("Hubspot payload", payload);
      const headers = {
        Authorization: token,
      };

      const hubspot_url_key = "HUBSPOT_URL_" + env_var.toUpperCase();
      const hubspot_url = config[hubspot_url_key];

      const response = await axios.get(hubspot_url + "/GetContact", {
        params: queryStringParameters,
        headers: headers,
      });

      const data = response.data;

      // console.log("hubspot response", data);
      
      const first_name =  data.firstname
      const phone_number = data.phone
      const last_name = data.lastname
      // if data.promocode exists, then set it to promo
      
      const promo_code = data.promo_code;
      // console.log("promo_code", promo_code)
      if (promo_code) {
        setPromo(promo_code);
      }
      else {
        setPromo('');
      }

      const hubspot_contact_id = data.contact_id;
      
      // if first_name is empty, then return
      if (first_name) {
        setFirstName(first_name);
        setLastName(last_name);
        setName(first_name + ' ' + last_name);
      }
        setPhoneNumber(phone_number)
        

    } catch (error) {
      console.log("error: ", error);
    }
  }

  useEffect(() => {
    setEmail(userProfile.email);

    getContactDetails();
    // Uncomment when toll free number is available
    // checkPhoneVerification();
  }, [userProfile]);

  const handleNameEdit = () => {
    setNameDisabled(false);
    setPromoDisabled(false);
    setPhoneNumberDisabled(false);
    setNameEdit("save");
  };

  const saveNameInfo = async () => {

    if (!firstName) {
      alert("Please enter your first name.");
      setErrors((prevState) => ({
        ...prevState,
        firstName: true,
      }));
      return;
    }
    if (!lastName) {
      alert("Please enter your last name.");
      setErrors((prevState) => ({
        ...prevState,
        lastName: true,
      }));
      return;
    }

    if (firstName && lastName) {
      setName(firstName + ' ' + lastName);
    }
    if (!name) {
      alert("Please enter your name.");
      setErrors((prevState) => ({
        ...prevState,
        name: true,
      }));
      return;
    }
    if (!phoneNumber) {
      alert("Please enter your phone number.");
      return;
    }
    if (phoneNumber.length < 10) {
      alert("Please enter valid phone number!");
      return;
    }
    const cognitophoneNumber = '+' + phoneNumber.replace(/-/g, "");

    const data = {
      name,
      "custom:phoneNumber": phoneNumber,
      phone_number : cognitophoneNumber,
    };
    data["custom:promocode"] = promo ?? "";
    try {
      const user = await Auth.currentAuthenticatedUser();

            // get idtoken
            const token = user.signInUserSession.idToken.jwtToken;

      // console.log("data", data);
      await Auth.updateUserAttributes(user, { ...data });
      setNameEdit("edit");
      setNameDisabled(true);
      
      setPromoDisabled(true);
      setPhoneNumberDisabled(true);

      const env_var = process.env.REACT_APP_NODE_ENV;

      

      try {

        const payload = {
          email: userProfile.email,
          name: name,
          firstname: firstName,
          lastname: lastName,
          phone: phoneNumber,
          promocode: promo,
          env: env_var,
        };

        // console.log("hubspot add details payload", payload);

        const hubspot_url_key = 'HUBSPOT_URL_' + env_var.toUpperCase();
const hubspot_url = config[hubspot_url_key];           

        const headers = {
          Authorization: token
        };
        
        const response = await axios.post(hubspot_url + '/UpdateContact', payload, { headers });
        

        // console.log("hubspot response", response);
      } catch (error) {
        console.log("error: ", error);
      }      

      // update user details in mailchimp
      const apiName = "restApiProd";
      const path = "/subscribeMailChimp";
      const myInit = {
        body: {
          email: userProfile.email,
          data: {
            FNAME: name,
            MMERGE7: name,
            PHONE: phoneNumber,
            MMERGE8: phoneNumber,
            MMERGE9: promo,
          },
        },
      };
      await API.put(apiName, path, myInit);
      // clear errors
      setErrors((prevState) => ({
        ...prevState,
        name: false,
        firstName: false,
        lastName: false,
        phoneNumber: false,
      }));
      
    } catch (e) {
      setApiError(e.message);
      setShowApiError(true);
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.subHeader}>Who I am</Box>
        {nameEdit === "edit" ? (
          <EditComponent onClick={() => handleNameEdit()} />
        ) : (
          <SaveComponent onClick={() => saveNameInfo()} />
        )}
      </Box>
      <TextField
        id="firstName"
        label="First Name"
        type="text"
        disabled={nameDisabled}
        required
        error={errors.firstName}
        InputLabelProps={{ shrink: true }}
        className={classes.form}
        margin="normal"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        helperText={errors.firstName ? "Please enter your first name." : ""}
      />
            <Box mt={1} />

      <TextField
        id="lastName"
        label="Last Name"
        type="text"
        disabled={nameDisabled}
        required
        error={errors.lastName}
        InputLabelProps={{ shrink: true }}
        className={classes.form}
        margin="normal"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        helperText={errors.lastName ? "Please enter your last name." : ""}
      />

      <Box mt={1} />
      <Box display="flex" alignItems="center">
        <PhoneInput
          value={phoneNumber}
          className={`${classes.form} formBorder`}
          onChange={(e) => setPhoneNumber(e)}
          inputStyle={{ width: "auto", border: "none" }}
          id="phoneNumber"
          placeholder="Enter phone number"
          enableSearch
          disabled={phoneNumberDisabled}
          masks={{ us: "...-...-....", ca: "...-...-...." }}
          country="us"
          preferredCountries={["us", "ca"]}
        />
        {!isPhoneVerified && (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => beginPhoneVerification()}
            className={styles.verifyButton}
          >
            Verify
          </Button>
        )}
      </Box>
      <Box mt={1} />
      <TextField
        id="promo"
        InputLabelProps={{ shrink: true }}
        label="Promo Code (optional)"
        type="text"
        value={promo}
        className={classes.form}
        disabled={promoDisabled}
        onChange={(e) => setPromo(e.target.value)}
        margin="normal"
      />      
    </Box>
  );  
});

export default NameSetting;
