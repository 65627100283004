import { Dialog, DialogContent, DialogTitle, IconButton, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { SubscriptionBox } from "..";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "absolute",
    top: "10%",
    zIndex: "500",
    height: "80%",
  },
  dialogContent: {
    marginLeft: "20px",
    marginRight: "20px",
    textAlign: "center",
  },
}));

const ModifySubscriptionDialog = (props) => {
  const { showMofifySubDialog, setShowMofifySubDialog, userProfile, userSubscription } = props;
  const styles = useStyles();
  return (
    <Dialog
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={showMofifySubDialog}
      classes={{ paper: styles.dialog }}>
      <DialogTitle>
        <Grid container justifyContent="space-between" justify="space-between" alignItems="space-between">
          <Typography variant="h2">Subscription Plan</Typography>
          <IconButton onClick={() => setShowMofifySubDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <SubscriptionBox {...{ userProfile, userSubscription }} />
      </DialogContent>
    </Dialog>
  );
};

export default ModifySubscriptionDialog;
