import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Column } from "../table/column";
import { orderBy } from "lodash";

const COLUMNS = [
  new Column({ key: "tradeDate", label: "Trade Date", formatType: "date" }),
  new Column({ key: "longQuantity", label: "Long", formatType: "longOrShort" }),
  new Column({ key: "shortQuantity", label: "Short", formatType: "longOrShort" }),
  new Column({ key: "tradePrice", label: "Trade Price", formatType: "currency" }),
  new Column({ key: "netQuantity", label: "Cumulative Total" }),
  new Column({ key: "gainLoss", label: "Cumulative PnL", formatType: "currency" }),
];

const TradeLedgerTableV2 = ({ trades }) => {
  trades = trades || [];

  const tradesGroupedBySymbol = trades.reduce((acc, trade) => {
    const symbol = trade.frSymbol;
    if (!acc[symbol]) {
      acc[symbol] = [];
    }
    acc[symbol].push(trade);
    return acc;
  }, {});

  const tradeGroupings = Object.entries(tradesGroupedBySymbol).map(([key, vals]) => {
    const orderedTrades = orderBy(vals, (x) => x.tradeDate, "asc");

    let runningNetQuantity = 0;
    let runningLongQuantity = 0;
    let runningShortQuantity = 0;
    let runningGainLoss = 0;
    const orderedTradesWithRunningTotals = orderedTrades.map((trade, idx) => {
      const prevNetQuantity = runningNetQuantity;
      const prevGainLoss = runningGainLoss;
      const netQuantity = Number(trade.longQuantity) - Number(trade.shortQuantity);
      const totalValue = netQuantity * Number(trade.tradePrice) * 5000;

      runningLongQuantity += Number(trade.longQuantity);
      runningShortQuantity += Number(trade.shortQuantity);
      runningNetQuantity += netQuantity;
      runningGainLoss += totalValue;

      trade.netQuantity = runningNetQuantity;
      trade.totalValue = totalValue;
      // only track this when we have exits
      //if (prevNetQuantity !== 0 && runningLongQuantity !== 0 && runningShortQuantity !== 0) {
      if (prevNetQuantity !== 0 && runningNetQuantity === 0) {
        const prevAvgPrice = prevGainLoss / prevNetQuantity / 5000;
        const offsetValue = netQuantity * prevAvgPrice * 5000;
        const currentGainLoss = totalValue - offsetValue;
        trade.gainLoss = currentGainLoss * -1; // reverse the sign so that "shorts" are positive
      } else {
        trade.gainLoss = 0;
      }

      // reset when flat
      if (netQuantity === 0) {
        runningLongQuantity = 0;
        runningShortQuantity = 0;
      }

      return trade;
    });

    return {
      frSymbol: key,
      trades: orderedTradesWithRunningTotals,
    };
  });

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        {trades.length > 0 ? (
          <Table stickyHeader aria-label="sticky table" sx={{ borderBottomColor: "transparent" }}>
            <TableHead>
              <TableRow>
                {COLUMNS.map((column) => (
                  <TableCell {...column} style={{ fontWeight: 900, textTransform: "uppercase" }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tradeGroupings.map((tradeGroup, idx) => {
                return (
                  <>
                    {tradeGroup.trades.map((row, idx2) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={`row-${idx2}`}>
                          {COLUMNS.map((column) => (
                            <TableCell {...column}>{column.format(row[column.key], row)}</TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Typography mb={4} variant="h1" textAlign="center">
            No Trades!
          </Typography>
        )}
      </TableContainer>
    </Paper>
  );
};

export default TradeLedgerTableV2;
