import { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Amplify, Auth, API } from "aws-amplify";
import "@stripe/stripe-js";
import { Routes } from "./routes";
import { AuthContext, AppContext } from "./context";
import { GetAllWebParams } from "./data/queries";
import awsconfig from "./aws-exports";
import { SubscriptionProvider } from "./providers/subscription-provider";
import { CROP_TYPES } from "./utils/constants";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./styles/App.scss";

const domainToRedirects = {
  "localhost": awsconfig.oauth.redirectSignIn.split(",")[0],
  "dev.d3q1zlnk5c0ho3.amplifyapp.com": awsconfig.oauth.redirectSignIn.split(",")[1],
  "uat.d3q1zlnk5c0ho3.amplifyapp.com": awsconfig.oauth.redirectSignIn.split(",")[3],
  "production.d3q1zlnk5c0ho3.amplifyapp.com": awsconfig.oauth.redirectSignIn.split(",")[2],
  "www.gfy.ag": awsconfig.oauth.redirectSignIn.split(",")[4],
};

const domainToSignOuts = {
  "localhost": awsconfig.oauth.redirectSignOut.split(",")[0],
  "dev.d3q1zlnk5c0ho3.amplifyapp.com": awsconfig.oauth.redirectSignOut.split(",")[1],
  "uat.d3q1zlnk5c0ho3.amplifyapp.com": awsconfig.oauth.redirectSignOut.split(",")[3],
  "production.d3q1zlnk5c0ho3.amplifyapp.com": awsconfig.oauth.redirectSignOut.split(",")[2],
  "www.gfy.ag": awsconfig.oauth.redirectSignOut.split(",")[4],
};

const currentDomain = window.location.hostname;

const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: domainToRedirects[currentDomain] || awsconfig.oauth.redirectSignIn,
    redirectSignOut: domainToSignOuts[currentDomain] || awsconfig.oauth.redirectSignOut,
  },
};

// Amplify.configure(awsconfig);
Amplify.configure(updatedAwsConfig);

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    h1: {
      fontSize: "1.5rem",
      "@media (max-width:599px)": {
        fontSize: "2.4rem",
      },
    },
    h2: {
      fontSize: "1.5rem",
      "@media (max-width:599px)": {
        fontSize: "1.3rem",
      },
    },
    h3: {
      fontSize: "1.25rem",
      "@media (max-width:599px)": {
        fontSize: "1.2rem",
        textAlign: "center",
      },
    },
    h4: {
      fontSize: "1.125rem",
    },
    h5: {
      fontSize: "1rem",
      "@media (max-width:599px)": {
        fontSize: "0.8rem",
      },
    },
    h6: {
      fontSize: "0.875rem",
      "@media (max-width:599px)": {
        fontSize: "0.75rem",
      },
    },
  },
  palette: {
    background: {
      default: "rgb(242,248,242)",
      white: "#fff",
    },
    primary: {
      main: "#081e03",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#fd8f25",
      contrastText: "#bdbdbd",
    },
    text: {
      secondary: "#000",
    },
    success: {
      main: "#1E4620",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [tokens, setTokens] = useState(0);
  const [showDisabled, setShowDisabled] = useState(false);
  const [params, setParams] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);

  const [showContact, setShowContact] = useState(false);
  const [graphData, setGraphData] = useState([]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        staleTime: 1000 * 20,
        cacheTime: 1000 * 60 * 5,
      },
    },
  });

  const getWebParams = async () => {
    try {
      const data = await API.graphql({ query: GetAllWebParams, authMode: "API_KEY" });
      if (data) {
        setParams(data.data.listWebAppParams.items);
      }
    } catch (e) {
      console.error("getWebParams failed: ", e);
    }
  };

  const checkAuthState = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setIsLoggedIn(true);
        setUserProfile(user.attributes);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const AuthValue = {
    isLoggedIn,
    setIsLoggedIn,
    userProfile,
    setUserProfile,
    tokens,
    setTokens,
    checkAuthState,
  };

  const AppValue = {
    params,
    setParams,
    showDisabled,
    setShowDisabled,
    showContact,
    setShowContact,
    graphData,
    setGraphData,
    setSelectedTab,
    selectedTab,
    cropType: CROP_TYPES,
  };

  useEffect(() => {
    getWebParams();
  }, []);

  useEffect(() => {
    checkAuthState();
    const interval = setInterval(() => {
      checkAuthState();
    }, 300000);
    return () => clearInterval(interval);
  }, [isLoggedIn]);

  return (
    <div className="app">
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <AppContext.Provider value={AppValue}>
                <AuthContext.Provider value={AuthValue}>
                  <SubscriptionProvider>
                    <Routes />
                  </SubscriptionProvider>
                </AuthContext.Provider>
              </AppContext.Provider>
            </QueryClientProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
