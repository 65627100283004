import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50%",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px",
    zIndex: 1,
    backdropFilter: "blur(20px)",
  },
  titleText: {
    backgroundColor: "#FFB74D",
    color: "red",
    fontSize: "24px",
    padding: "8px",
  },
  text: {
    padding: "16px",
    backgroundColor: "white",
    fontSize: "1.25rem",
    fontWeight: 500,
    color: "rgba(0,0,0,0.6)",
    textAlign: "center",
    letterSpacing: "0.15px",
    lineHeight: "32px",
  },
}));

const CropErrorModal = ({ open, message }) => {
  const styles = useStyles();
  return (
    <>
      {open && (
        <div className={styles.root}>
          <Box display="flex" alignItems="center" justifyContent="center" className={styles.titleText}>
            Error
          </Box>
          <Box className={styles.text}>{message}</Box>
        </div>
      )}
    </>
  );
};

export default CropErrorModal;
