import { useState, useContext } from "react";
import { AuthContext } from "../../context";
import { SubscriptionContext } from "../../context/subscription-context";
import { Box, Grow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UpdateSubBox from "./UpdateSubBox";
import PricingCard from "../PricingCard";
import ModifySubscriptionDialog from "../modals/ModifySubscriptionDialog";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    marginTop: 25,
    marginBottom: 12,
  },
  subHeader: {
    fontSize: "16px",
    color: "rgba(0,0,0,0.87)",
    marginTop: "21px",
    letterSpacing: "0.15px",
  },
  left: {
    width: "100%",
    maxWidth: "657px",
    [theme.breakpoints.down(800)]: {
      width: "auto",
    },
  },
  right: {
    display: "flex",
    gap: 8,
    paddingLeft: "25px",
    [theme.breakpoints.down(1200)]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down(1024)]: {
      flexDirection: "row",
      paddingLeft: 0,
      paddingTop: 8,
    },
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
    },
  },
  payments: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "14px",
    fontWeight: 500,
    marginRight: "10px",
  },
  paymentsContainer: {
    marginTop: "39px",
  },
  socialContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(1024)]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    marginBottom: "50px",
    marginTop: 0,
  },
}));

const UpdateSubscription = (props) => {
  const styles = useStyles();
  const { userProfile } = useContext(AuthContext);
  const { userSubscription } = useContext(SubscriptionContext);
  const [showMofifySubDialog, setShowMofifySubDialog] = useState(false);
  const history = useHistory();

  return (
    <Box>
      <Grow in={true} timeout={1000}>
        <div>
          <Typography mb={4} variant="h3" className={styles.header}>
            My Subscriptions
          </Typography>
          <Typography className={styles.subHeader}>
            You may select, manage or cancel your subscription here! Below are price breakdowns of the subscriptions,
            you may learn more <a href=".">here</a>.
          </Typography>
          <Box className={styles.container} mt={4}>
            <Box className={styles.left}>
              <UpdateSubBox {...props} />
            </Box>
            <Box className={styles.right}>
              <PricingCard
                title="Just One"
                description="Subscribe to one crop with real-time text alerts."
                price={999.99}
                actionButtonText="Select"
                onActionClick={() => {
                  history.push("/planscheckout");
                }}
              />
              <PricingCard
                title="All Grain"
                description="Subscribe to all three crops with real-time text alerts."
                content="Corn, Soybeans, HRW, MPLS, Cotton Soybean Meal"
                price={1999.99}
                actionButtonText="Select"
                onActionClick={() => {
                  history.push("/planscheckout");
                }}
              />
              <ModifySubscriptionDialog
                {...{ userProfile, userSubscription, showMofifySubDialog, setShowMofifySubDialog }}
              />
            </Box>
          </Box>
        </div>
      </Grow>
    </Box>
  );
};

export default UpdateSubscription;
