import { Dialog, DialogContent, DialogTitle, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#FFB74D",
  },
  titleText: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "24px",
  },
  text: {
    fontSize: "1.25rem",
    fontWeight: 500,
    color: "rgba(0,0,0,0.6)",
    textAlign: "left",
    marginBottom: "16px",
    marginTop: "20px",
    letterSpacing: "0.15px",
    lineHeight: "32px",
  },
  content: {
    marginLeft: "20px",
    marginRight: "20px",
    textAlign: "center",
  },
  icon: {
    marginRight: "10px",
  },
}));

export const SubscriptionBlockDialog = ({ showBlockDialog, message }) => {
  const styles = useStyles();
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={showBlockDialog}
      BackdropProps={{
        style: { position: "absolute", backdropFilter: "blur(20px)", backgroundColor: "rgba(255, 255, 255, 1)" },
      }}
      style={{ position: "absolute" }}
      container={() => document.getElementById("drill")}>
      <DialogTitle className={styles.dialog} id="customized-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="center" className={styles.titleText}>
          Error
        </Box>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Box className={styles.text}>{message}</Box>
      </DialogContent>
    </Dialog>
  );
};
