import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Theme from "../../../../Theme/Theme";
//
export const AccordianPlusButton = ({ setOpen, open }) => {
  const styles = useStyles();

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.outerCircle} onClick={handleOpen} aria-label="expand" size="small">
        <div className={styles.innerCircle}>
          <div className={styles.plusSign}>+</div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  outerCircle: {
    width: 37,
    height: 37,
    borderRadius: "50%",
    backgroundColor: Theme.colors.black,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  innerCircle: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    border: "2px solid",
    borderColor: Theme.colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Theme.colors.black,
  },

  plusSign: {
    fontSize: Theme.fonts.mediumMiddle,
    fontWeight: "bold",
    color: Theme.colors.white,
  },
}));
