import { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { TextField, Box, InputAdornment, IconButton, Checkbox, Collapse, Alert } from "@mui/material";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth, API } from "aws-amplify";
import PasswordHelper from "./PasswordHelper";
import { Button } from "../.";
import { passwordValidate } from "../../utils/passwordValidation";
import AuthContext from "../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: "14px",
  },
  formContainer: {
    marginTop: "42px",
  },
  centerBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
  },
  floatingLabelFocusStyle: {
    color: "#000",
  },
  button: {
    fontWeight: 500,
  },
  agreeText: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.4px",
  },
  agreeContainer: {
    marginTop: "8px",
  },
  link: {
    color: "#2196F3",
  },
  buttonStyle: {
    color: "#001F00",
  },
}));

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordHelper, setShowPasswordHelper] = useState(false);
  const [checked, setChecked] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const inputType = showPassword ? "text" : "password";

  const styles = useStyles();
  const history = useHistory();

  const handleSignUp = async () => {
    setLoading(true);
    if (!email) {
      setEmailError(true);
      setLoading(false);
      return;
    }
    if (!password) {
      setPasswordError(true);
      setLoading(false);
      return;
    }
    if (!checked) {
      setApiError("Please agree to terms and conditions before signing up!");
      setShowApiError(true);
      setLoading(false);
      return;
    }
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email: email,
          "custom:onboardingComplete": "0",
        },
      });
      const user = await Auth.signIn(email, password);
      setIsLoggedIn(true);
      setUserProfile(user.attributes);
      history.push("/stoplight");


    // console.log('Adding user to Mailchimp...', email)
      // add user to mailchimp
      const apiName = "restApiProd";
      const path = "/subscribeMailChimp";
      const myInit = {
        body: { email },
      };
      await API.post(apiName, path, myInit);
    } catch (e) {
      setApiError(e.message);
      setShowApiError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePassword = (value) => {
    setPassword(value);
    passwordValidate(value);
  };

  useEffect(() => {
    if (showPasswordHelper) {
      passwordValidate(password);
    }
  }, [showPasswordHelper]);

  useEffect(() => {
    setTimeout(() => {
      setEmail("");
      setPassword("");
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setEmail("");
      setPassword("");
    });
  }, []);

  return (
    <form className={styles.formContainer} id="form">
      <Collapse in={showApiError}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowApiError(false);
              }}>
              <Close fontSize="inherit" />
            </IconButton>
          }>
          {apiError}
        </Alert>
      </Collapse>
      <TextField
        id="email"
        label="Your E-mail"
        type="email"
        fullWidth
        required
        className={styles.form}
        value={email}
        autoComplete="nope"
        error={emailError}
        onChange={(e) => setEmail(e.target.value)}
        helperText={emailError ? "Please enter an email address." : ""}
      />

      <TextField
        id="password"
        label="Password"
        type={inputType}
        fullWidth
        floatinglabelstyle={{ color: "#000" }}
        required
        onChange={(e) => handlePassword(e.target.value)}
        autoComplete="new-password"
        value={password}
        onFocus={() => setShowPasswordHelper(true)}
        onBlur={() => setShowPasswordHelper(false)}
        className={styles.form}
        error={passwordError}
        helperText={passwordError ? "Please enter a password." : ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword()}
                onMouseDown={() => handleMouseDownPassword()}
                size="large">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <PasswordHelper showPasswordHelper={showPasswordHelper} />

      <Box display="flex" justifyContent="center" alignItems="center" className={styles.agreeContainer}>
        <Box>
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Box>
        <Box className={styles.agreeText}>
          By creating this account you confirm that you agree with our{" "}
          {/*  <Link className={styles.link} to={"/terms"}>
            Terms and Conditions
          </Link>{" "}
          and{" "} */}
          <Link className={styles.link} to={"/privacy"}>
            Privacy Policy
          </Link>
          .
        </Box>
      </Box>

      <Box mt={3}>
        <Button
          onClick={() => handleSignUp()}
          loading={loading}
          fullWidth
          label="Sign Up"
          classes={{ label: styles.button }}
          className={styles.buttonStyle}
        />
      </Box>
    </form>
  );
};

export default SignUp;
