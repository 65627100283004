import { useContext, useCallback } from "react";
import { Container, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GFYTabs, CropDetails } from "../index";
// import GFYTabs from "../gfy-tabs";
// import CropDetails from "../CropDetails";
import { AppContext } from "../../context";
import { SUGGESTED_ACTION, CROP_TYPES } from "../../utils/constants";
import soybean from "../../assets/soybean_green.png";
import wheat from "../../assets/wheat_green.png";
import corn from "../../assets/corn_green.png";
import soybeanSelected from "../../assets/soybean_red.png";
import wheatSelected from "../../assets/wheat_red.png";
import cornSelected from "../../assets/corn_red.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "-159px",
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: "-150px",
    }
  }
}));

function createData(empty, alltimeHighPrice, maxOfAth, avbOfAth, avgPriceCbot, avgPriceWithgfy, increaseBushelWithGfy) {
  return { empty, alltimeHighPrice, maxOfAth, avbOfAth, avgPriceCbot, avgPriceWithgfy, increaseBushelWithGfy };
}

const rows = {
  zc: [
    createData("2021", "$636.50", "96.12%", "88.40%", "$490.35", "$562.66", "$0.72"),
    createData("2020", "$423.00", "98.82%", "89.82%", "$373.86", "$379.96", "$0.06"),
    createData("2019", "$468.50", "99.95%", "91.62%", "$399.62", "$429.23", "$0.30"),
    createData("2018", "$426.50", "86.29%", "85.97%", "$387.09", "$366.67", "-$0.20"),
    createData("2017", "$414.75", "99.60%", "99.12%", "$379.56", "$411.10", "$0.32"),
  ],
  zs: [
    createData("2021", "$1,459.50", "99.10%", "96.77%", "$1,186.56", "$1,412.42", "$2.26"),
    createData("2020", "$1,065.50", "100.32%", "94.57%", "$924.35", "$1,007.67", "$0.83"),
    createData("2019", "$966.00", "97.16%", "96.53%", "$914.99", "$932.48", "$0.17"),
    createData("2018", "$1,053.50", "84.48%", "83.49%", "$965.17", "$879.62", "-$0.86"),
    createData("2017", "$1,043.25", "99.36%", "96.36%", "$975.40", "$1,005.28", "$0.30"),
  ],
  ke: [
    createData("2021", "$736.75", "99.60%", "85.27%", "$560.94", "$628.23", "$0.67"),
    createData("2020", "$547.25", "92.98%", "90.58%", "$475.74", "$495.72", "$0.20"),
    createData("2019", "$633.25", "69.06%", "66.68%", "$528.47", "$422.24", "-$1.06"),
    createData("2018", "$619.00", "90.92%", "87.51%", "$507.07", "$541.68", "$0.35"),
    createData("2017", "$568.50", "81.85%", "79.82%", "$469.60", "$453.79", "-$0.16"),
  ],
};

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
const cropIcon = [corn, wheat, soybean];
const cropIconSelected = [cornSelected, wheatSelected, soybeanSelected];

function DrillTabView({ item, i }) {
  // TODO! rename it later and check if the commented lines are needed in the future.

  // const [minDrillValue, setMinDrillValue] = useState(0);
  // const [maxDrillValue, setMaxDrillValue] = useState(0);
  // const [predictions, setPredictions] = useState({
  //   previousPrediction: 0.0,
  //   previousClose: 0.0,
  //   tomorrow: 0.0,
  //   lastUpdatedDate: todaysDate,
  // });

  const { params, selectedTab } = useContext(AppContext);

  // useEffect(() => {
  //   if (params.length) {
  //     const results = params.find((x) => x.key === "drillSlider");
  //     if (results) {
  //       const data = JSON.parse(results.value);
  //       for (const [key, value1] of Object.entries(data)) {
  //         if (item.toLowerCase() === key.toLowerCase()) {
  //           // setMinDrillValue(value1.minBound);
  //           // setMaxDrillValue(value1.maxBound);
  //           // setInputValue(value1.defaultVal);
  //         }
  //       }
  //     }
  //     if (item === "CORN") {
  //       const cornParams = params.find((x) => x.key === "cornParams");
  //       if (cornParams) {
  //         const cornValues = JSON.parse(cornParams.value);
  //         // setPredictions({
  //         //   previousClose: cornValues.lastUpdatedInputVal,
  //         //   previousPrediction: cornValues.lastUpdatedPredVal,
  //         //   lastUpdatedDate: cornValues.lastUpdatedDate,

  //         //   nextUpdatedDate: cornValues.nextUpdatedDate,
  //         //   nextPrediction: cornValues.nextUpdatedPredVal,
  //         // });
  //       }
  //     } else if (item === "SOYBEAN") {
  //       const soybeansParams = params.find((x) => x.key === "soybeansParams");
  //       if (soybeansParams) {
  //         const soybeansValues = JSON.parse(soybeansParams.value);
  //         // setPredictions({
  //         //   previousClose: soybeansValues.lastUpdatedInputVal,
  //         //   previousPrediction: soybeansValues.lastUpdatedPredVal,
  //         //   lastUpdatedDate: soybeansValues.lastUpdatedDate,

  //         //   nextUpdatedDate: soybeansValues.nextUpdatedDate,
  //         //   nextPrediction: soybeansValues.nextUpdatedPredVal,
  //         // });
  //       }
  //     } else if (item === "WHEAT") {
  //       const wheatParams = params.find((x) => x.key === "wheatParams");
  //       if (wheatParams) {
  //         const wheatValues = JSON.parse(wheatParams.value);
  //         // setPredictions({
  //         //   previousClose: wheatValues.lastUpdatedInputVal,
  //         //   previousPrediction: wheatValues.lastUpdatedPredVal,
  //         //   lastUpdatedDate: wheatValues.lastUpdatedDate,

  //         //   nextUpdatedDate: wheatValues.nextUpdatedDate,
  //         //   nextPrediction: wheatValues.nextUpdatedPredVal,
  //         // });
  //       }
  //     }
  //   }
  // }, [item, params]);
  const styles = useStyles();

  const getTabStatus = useCallback(
    (tab) => {
      return tab.includes(`${CROP_TYPES[selectedTab].toLowerCase()}`);
    },
    [selectedTab]
  );

  return (
    <Container label={item} key={i} className={styles.wrapper} >
      <GFYTabs
        type="stoplight"
        tabUnderlineColor="#016938"
        variant={useMediaQuery("(max-width:599px)") ? "fullWidth" : null}>
        <div label="Corn" icon={getTabStatus("corn") ? cropIcon[0] : cropIconSelected[0]}>
          <CropDetails rows={rows.zc} listData={SUGGESTED_ACTION} cropType={item} />
        </div>
        <div label="Wheat" icon={getTabStatus("wheat") ? cropIcon[1] : cropIconSelected[1]}>
          <CropDetails rows={rows.ke} listData={SUGGESTED_ACTION} cropType={item} />
        </div>
        <div label="Soybean" icon={getTabStatus("soybean") ? cropIcon[2] : cropIconSelected[2]}>
          <CropDetails rows={rows.zs} listData={SUGGESTED_ACTION} cropType={item} />
        </div>
      </GFYTabs>
    </Container>
  );
}

export default DrillTabView;
