import { useContext, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  AppBar,
  Button,
  Toolbar,
  Box,
  Container,
  Link,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import { LogoIcon } from "./icons/logo";
import { AuthContext, AppContext } from "../context";
import { CROP_TYPES } from "../utils/constants";
import soybean from "../assets/soybean_grey.png";
import wheat from "../assets/wheat_grey.png";
import corn from "../assets/corn_grey.png";
import soybeanSelected from "../assets/soybean_yellow.png";
import wheatSelected from "../assets/wheat_yellow.png";
import cornSelected from "../assets/corn_yellow.png";
import gfyLogo from "../assets/logo-item.png";
import LoginIcon from "@mui/icons-material/Login";
import { Grid } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    color: "white",
  },
  chip: {
    backgroundColor: "#FFF",
  },
  headerMenu: {
    marginRight: "30px",
    color: "#fff",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    textTransform: "uppercase",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:last-child": {
      marginRight: "5px",
    },
  },
  headerTabs: {
    color: "#e0e0e0",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.3px",
    textTransform: "uppercase",
    cursor: "pointer",
    maxWidth: "480px",
    textAlign: "right",
    "&.Mui-selected": {
      color: "#rgba(0,0,0,0.87)",
    },
  },
  tabText: {
    color: "#e0e0e0",
    fontFamily: "Roboto",
    fontSize: "7px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "12px",
    textAlign: "center",
    "&.Mui-selected": {
      color: "#rgba(0,0,0,0.87)",
    },
  },
  icon: {
    color: "#e0e0e0",
    height: "14px",
    "&.Mui-selected": {
      color: "#000",
    },
  },
  customHeight: {
    maxHeight: "64px",
    paddingLeft: 0,
    paddingRight: 0,
    display: "inline-flex",
    justifyContent: "center",
    width: "100%",
  },
  logo: {
    cursor: "pointer",
  },
  customHeightwrap: {
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      paddingInline: 0,
    },
  },
  buttonOutlined: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "16px",
    color: "#fff",
    background: "transparent",
    borderRadius: "1px",
    textAlign: "center",
    borderColor: "#EBA83A",
    border: "1px solid",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "32px",
    paddingLeft: "32px",
    textTransform: "none",
    [theme.breakpoints.down('sm')]: {
      display:"none",
    },
    "&:hover": {
      background: "#EBA83A",
      color: "#13302B",
    }
  },
  homeLinks: {
    flex: "1 1 auto",
    textAlign: "right",
    "& a": {
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "18px",
      marginRight: "4%",
      "&.active": {
        color: "#eba83a",
        borderBottom: "2px solid",
        paddingBottom: "10px",
      },
    },
  },
  logoName: {
    color: "white", 
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  loggedoutbtns: {
    [theme.breakpoints.down(768)]: {
      display: "none",
    }
  }
}));

const cropIcon = [corn, wheat, soybean];
const cropIconSelected = [cornSelected, wheatSelected, soybeanSelected];

const loggedInMenu = [
  {
    href: "/stoplight",
    title: "Go to Stoplight",
  },
  {
    href: "/account",
    title: "My Account",
  },
  {
    href: "/login",
    title: "Log out",
  },
];

const loggedOutMenu = [
  {
    href: "",
    title: "CLAIM FREE TRIAL",
    className: "free-trial-button"
  },
  {
    href: "/login",
    title: "Login",
    className: "buttonOutlined"
  },
];

const homeLinks = [
  { title: "Home", href: "#" },
  { title: "About", href: "#about" },
  { title: "Result", href: "#results" },
  { title: "Pricing", href: "#pricing" },
  { title: "Blog", href: "blog" },  
  { title: "Contact", href: "#contact" },
];

const Header = (props) => {
  const { headerIsTransparent } = props;
  // console.log("headerIsTransparent", headerIsTransparent)
 
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { isLoggedIn, setUserProfile, setIsLoggedIn } = useContext(AuthContext);
  const { setShowContact, selectedTab, setSelectedTab } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);

  // console.log("isLoggedIn", isLoggedIn);

  const [isDrawer, setIsDrawer] = useState(false);

  const logout = useCallback(async () => {
    setUserProfile("");
    await Auth.signOut();
    setIsLoggedIn(false);
    history.push("/");
    // window.location.reload();  // TODO! check if it is needed or not
  }, [history, setIsLoggedIn, setUserProfile]);

  const getTabStatus = useCallback(
    (tab) => {
      return tab === CROP_TYPES[selectedTab];
    },
    [selectedTab]
  );

  const LoggedIn = useCallback(
    () => (
      <Box sx={{ display: { md: pathname != "/" ? 'flex' : "none", sm: "none", xs: "none" }, justifyContent: "center", alignItems: "center" }}>
        {loggedInMenu.map((menu) => (
          <Link
            key={menu.title}
            component="button"
            underline="none"
            onClick={() => (menu.href === "/login" ? logout() : history.push(menu.href))}
            className={styles.headerMenu}
            style={{
              color: pathname === menu.href ? theme.palette.secondary.main : "white",
            }}>
            {menu.title}
          </Link>
        ))}
      </Box>
    ),
    [history, logout, pathname, styles.headerMenu, theme.palette.secondary.main, headerIsTransparent]
  );

  const LoggedOut = useCallback(
    
    () => (
  
      <Box flexGrow={0}>
        {!isLoggedIn && (
          <Grid container justifyContent="flex-end" alignItems="center" spacing={1} >
            {loggedOutMenu.map((menu) => (
              <Grid item key={menu.title}>
                <Button
                  className={menu.className}
                  color="inherit"
                  onClick={() => {
                    if (menu.href) {
                      history.push(menu.href);
                    } else {
                      setShowContact(true);
                    }
                  }}
                  endIcon={menu.title === "Login" && <LoginIcon />}
                >
                  {menu.title}
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    ),
    [history, setShowContact]
  );
  

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setIsDrawer(open);
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {homeLinks.map((menu) => (
          <ListItem key={menu.title} divider={true} disablePadding>
            <ListItemButton>
              <ListItemText>
                <Link href={menu.href} underline="none" sx={{ display: "block" }}>
                  {menu.title}
                </Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
        {loggedOutMenu.map((menu) => (
          <ListItem key={menu.title} divider={true} disablePadding>
            <ListItemButton>
              <ListItemText
                primary={menu.title}
                onClick={() => {
                  handleClose();
                  if (!menu.href) {
                    setShowContact(true);
                  } else {
                    history.push(menu.href);
                  }
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const checkPathname = (pathname) => {
    const paths = ["/stoplight", "/account", "/dashboard", "/planscheckout", "/blog"];
    return paths.some(path => pathname.includes(path));
  };
  
  return (
    <AppBar
      position="fixed"
      className={styles.customHeight}
      sx={{ backgroundColor: '#000000' }}>
      <Container>
        <Toolbar className={styles.customHeightwrap}>
          <Box
            display="flex"
            className={styles.logo}
            onClick={() => history.push("/")}
            sx={{ display: { sm: "flex" } }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src={gfyLogo} alt="gfyLogo" />
            </Box>
            {/* add h2 */}
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h2" className={styles.logoName}>
                Go Farm Yourself
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: "1 1 auto",
              justifyContent: "flex-end",
            }}>
            <Box sx={{ display: { md: "flex", xs: "none" }, flex: { md: "1 1 auto" }, justifyContent: "flex-end" }}>
              {checkPathname(pathname) && (
                <Box>
                  <LoggedIn />
                  {/**
                   * REMOVE CROPS TABS ON CLIENT'S REQUEST 7th Mar 2023.
                   * TODO: Remove it completely after testing new changes.
                   */}

                  {/* <Tabs
                    centered
                    className={styles.headerTabs}
                    value={selectedTab}
                    onChange={(e, value) => {
                      setSelectedTab(value);
                    }}
                    onClick={() => history.push("/stoplight")}
                    variant="fullWidth"
                    TabIndicatorProps={{ style: { background: "#ff8f00" } }}>
                    {CROP_TYPES.map((child, index) => {
                      return (
                        <Tab
                          className={styles.tabText}
                          style={{ color: "#fff" }}
                          key={child}
                          icon={
                            child ? (
                              <img
                                src={getTabStatus(child) ? cropIconSelected[index] : cropIcon[index]}
                                alt={child}
                                className={styles.icon}
                              />
                            ) : null
                          }
                          label={child}
                        />
                      );
                    })}
                  </Tabs> */}
                </Box>
              )}
              {pathname === "/" && (
                <div className={styles.homeLinks}>
                  <Link href="#" className={pathname === "/" ? "active" : ""} underline="none" sx={{ color: "white" }}>
                    Home
                  </Link>
                  <Link href="#about" underline="none" sx={{ color: "white" }}>
                    About
                  </Link>
                  <Link href="#results" underline="none" sx={{ color: "white" }}>
                    Results
                  </Link>
                  <Link href="#pricing" underline="none" sx={{ color: "white" }}>
                    Pricing
                  </Link>
                  <Link href="blog" underline="none" sx={{ color: "white" }}>
                    Blog
                  </Link>                  
                  {/* <Link href="#contact" underline="none" sx={{ color: "white" }}>
                    Contact
                  </Link> */}
                  <Link href="/faqs" underline="none" sx={{ color: "white" }}>
                    FAQs
                  </Link>
                  {/* <Link
                  className="free-trial-button"
                    underline="none"
                    href="#"
                    sx={{ color: "white" }}
                    onClick={() => {
                      setShowContact(true);
                    }}>
                    Take a 30-Day Free Trial
                  </Link> */}

                  {/* <Link href="/terms" underline="none" sx={{ color: headerIsTransparent ? "black" : "white" }}>
                    Terms & Conditions
                  </Link> */}
                  {isLoggedIn && (
                    <>
                      {/* <Link href="/blog" underline="none" sx={{ color: "white" }}>
                        Blog
                      </Link> */}
                      <Link href="/stoplight" underline="none" sx={{ color: "white" }}>
                        Dashboard
                      </Link>
                    </>
                  )}
                </div>
              )}
            </Box>

            <Box sx={{ display: { md: "none", xs: "block" } }} flexGrow={1} className={styles.headerTabs}>
              {isLoggedIn ? <MenuIcon onClick={handleClick} /> : <MenuIcon onClick={toggleDrawer(true)} />}
              <Menu id="mobile-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {isLoggedIn ? (
                  <div>
                    {loggedInMenu.map((menu) => (
                      <MenuItem
                        key={menu.title}
                        onClick={() => {
                          handleClose();
                          if (menu.href === "/login") {
                            logout();
                          } else {
                            history.push(menu.href);
                          }
                        }}>
                        {menu.title}
                      </MenuItem>
                    ))}
                  </div>
                ) : (
                  <Drawer anchor="left" open={isDrawer} onClose={toggleDrawer(false)}>
                    {list()}
                  </Drawer>
                )}
              </Menu>
            </Box>

            {/* make compatible with mobile */}
            <Box flexGrow={0} className={styles.loggedoutbtns}>
              {!isLoggedIn && <LoggedOut />}
            </Box>
            
            <Box flexGrow={0}>
              {!isLoggedIn && (
                <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                  <Grid item>
                    {/* Place your first button component here */}
                  </Grid>
                  <Grid item>
                    {/* Place your second button component here */}
                  </Grid>
                </Grid>
              )}
            </Box>

            {/* <Box flexGrow={0}>{isLoggedIn ? (
              <div className={styles.homeLinks}>
                <Link href="/account" underline="none" sx={{ color: "white" }}>
                  Dashboard
                </Link>
              </div>
            ) : null}</Box> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;