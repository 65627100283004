import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import squggle from "../../assets/squggle@2x.png";

const useStyles = makeStyles((theme) => ({
  drillText: {
    padding: "0 20px",
    marginTop: "20px",
    background: "rgb(242,248,242)",
    display: "flex",
    [theme.breakpoints.down(1100)]: {
      marginLeft: "15px",
    },
  },
  arrow: {
    flex: 1,
    height: "180px",
    maxWidth: "90px",
    marginTop: "25px",
    marginRight: "15px",
    [theme.breakpoints.down(400)]: {
      display: "none",
    },
  },
  textContainer: {
    flex: 1,
  },
  heading: {
    fontSize: "50px",
    color: "#1E4620",
    letterSpacing: "2px",
  },
  drillHeader: {
    color: "#1E4620",
    fontFamily: "Roboto",
    fontSize: "60px",
    fontWeight: "bold",
    letterSpacing: "-0.5px",
    lineHeight: "72px",
    [theme.breakpoints.down(400)]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
    tabContainer: {
      padding: "45px 0",
    },
  },
  paragraphText: {
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "28px",
    fontFamily: "Roboto",
    margin: "25px 0 55px 0",
  },
  bottom: {
    borderRadius: 4,
    height: 42,
    width: 225,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #1E4620",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: "#1E4620",
    alignSelf: "center",
    color: "white",
    margin: "10px 0",
    "&:hover": {},
  },
  m0: {
    margin: 0,
  },
}));

const PageHeader = ({ showSignup }) => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <div className={styles.drillText}>
      <img id="arrow" alt="arrow" src={squggle} className={styles.arrow} />
      <div className={styles.textContainer}>
        <span className={(styles.heading, styles.drillHeader)}>Grain Hedging Stoplight</span>
        <div className={styles.paragraphText}>
          <p className={styles.m0}>Use our Stoplight indicator to know when the best times to sell your grain are.</p>
          <p className="drilLines drilLinesGreen" style={{ marginLeft: "60px" }}>
            Green means things are good and wait.
          </p>
          <p className="drilLines drilLineYellow"> Yellow, the market is changing so pay attention.</p>
          <p className="drilLines drilLineRed"> Red, stop what you're doing - now is a good time to sell.</p>
          <p className={styles.m0}>
            You decide how much if any to sell - <b>Go Farm Yourself!</b>
          </p>
          {showSignup && (
            <Box className={styles.bottom} onClick={() => history.push("/login")}>
              SIGN UP NOW
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
