// MUI components------------------------------------------------->
import makeStyles from "@mui/styles/makeStyles";

// Layout Components----------------------------------------------->
import Theme from "../../../Theme/Theme";
import { H2, Label } from "../../../components/Text";

// Assests--------------------------------------------------------->
import ArrowIcon from "../../../assets/svg/arrow.svg";
import greenTick from "../../../assets/svg/greenTick.svg";

// Sub Componenets-------------------------------------------------->
import { GFYButton } from "../../../components/ButtonGfy/GFYButton";

export const Popup = ({
  title,
  description,
  primaryButtonLabel,
  secondaryButtonLabel,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onClosePopup,
}) => {
  // variables using react componenets----------------->
  const styles = useStyles();

  return (
    <div>
      <div className={styles.popupContainer}>
        <div className={styles.popupOverlay} onClick={onClosePopup}></div>
        <div className={styles.popup}>
          <div className={styles.displayAndAlign}>
            <div className={styles.outerCircle}>
              <img src={greenTick} style={{ width: 78, height: 78 }} />
            </div>
          </div>
          <H2>{title}</H2>
          <Label>{description}</Label>
          <div className={styles.buttonContainer}>
            {secondaryButtonLabel && (
              <GFYButton
                label={secondaryButtonLabel}
                isPrimary={false}
                styleProps={{}}
                onClick={onSecondaryButtonClick}
              />
            )}
            {primaryButtonLabel && (
              <GFYButton
                label={primaryButtonLabel}
                icon={ArrowIcon}
                iconWidth={33}
                iconHeigt={13}
                // isPrimary={false}
                styleProps={{}}
                onClick={onPrimaryButtonClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/* Popup Component Styling---------------------------------------------------->
 * Contains Styling for the component Popup
 * Uses MUIs makestyles method to create styling */
const useStyles = makeStyles((theme) => ({
  displayAndAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  outerCircle: {
    width: 140,
    height: 140,
    borderRadius: 1000,
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: Theme.colors.tickGrayBackground,
  },

  innerCircle: {
    width: 78,
    height: 78,
    borderRadius: "50%",
    backgroundColor: Theme.colors.greenCardColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  tick: {
    fontSize: Theme.fonts.big,
    color: Theme.colors.inputBackgroundColor,
    fontWeight: Theme.fontWeight.small,
  },

  popupDesc: {
    width: 393,
    height: 22,
    fontFamily: Theme.fontfamily,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.medium,
    fontSize: Theme.fonts.smallplus,
    lineHeight: Theme.fonts.lineHeightsOne,
    color: Theme.colors.black,
    marginLeft: 44,
  },
  popupHeading: {
    fontFamily: Theme.fontfamily,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.small,
    fontSize: Theme.fonts.big,
    lineHeight: Theme.fonts.lineHeightsTwo,
    color: Theme.colors.headingColor,
  },
  popupContainer: {
    position: "fixed",
    top: "0",
    left: "0",
    marginTop: "37px",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: "999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0px",
  },

  popup: {
    position: "relative",
    // paddingLeft: 72,
    // paddingRight: 72,
    // paddingTop: 66,
    // paddingBottom: 66,
    padding: 60,
    backgroundColor: Theme.colors.inputBackgroundColor,
    borderRadius: 5,
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
    maxWidth: 520,
    width: "100%",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },

  buttonClose: {
    backgroundColor: Theme.colors.whiteButtonColor,
    color: Theme.colors.black,
    padding: "10px 20px",
    cursor: "pointer",
    width: 141,
    height: 60,
    border: "1px solid",
    borderColor: "gray",
  },

  buttonShowSub: {
    backgroundColor: Theme.colors.YellowButtonColor,
    color: Theme.colors.black,
    padding: "10px 20px",
    border: "none",
    cursor: "pointer",
    width: 341,
    height: 60,
    marginLeft: 12,
  },

  popupOverlay: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    filter: "blur(5px)",
  },
}));
