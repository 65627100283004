import Theme from "./Theme";

const baseStyles = (fontSize, fontWeight, lineHeight) => ({
  fontSize,
  fontWeight,
  lineHeight,
  fontFamily: Theme.fontfamily.Roboto,
});

export const H1 = {
  ...baseStyles("4.375rem", "400", "1.75rem"),
  fontFamily: Theme.fontfamily.Coolvetica,
};

export const H2 = {
  ...baseStyles("2.5rem", "400", "2.5rem"),
  fontFamily: Theme.fontfamily.Coolvetica,
};

export const H3 = {
  ...baseStyles("2.1875rem", "400", "2.5rem"),
  fontFamily: Theme.fontfamily.Coolvetica,
};

export const H4 = {
  ...baseStyles("1.875rem", "400", "2.5rem"),
  fontFamily: Theme.fontfamily.Coolvetica,
};

export const Label = {
  ...baseStyles("1.125rem", Theme.fontWeight.base, "1.375rem"),
  fontFamily: Theme.fontfamily.Inter,
};

export const LabelR = {
  ...baseStyles("1.125rem", Theme.fontWeight.small, "1.125rem"),
  fontFamily: Theme.fontfamily.Inter,
};

export const Caption = {
  ...baseStyles("1rem", Theme.fontWeight.medium, "1.125rem"),
  fontFamily: Theme.fontfamily.Inter,
};

export const Subtitles = {
  ...baseStyles("0.875rem", Theme.fontWeight.base, "1.125rem"),
  fontFamily: Theme.fontfamily.Inter,
};

export const SubtitlesR = {
  ...baseStyles("0.875rem", Theme.fontWeight.small, "1.125rem"),
  fontFamily: Theme.fontfamily.Inter,
};
