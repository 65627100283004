// react Components-------------------------->
import React from "react";

// Layout Components-------------------------->
import { H4 } from "../../../../../components/Text";
import { H3 } from "../../../../../components/Text";

export const SubscriptionInfoList = ({
  subidretrieved,
  customerID,
  collectionmethode,
  latestInvoice,
  currency,
  currentPeriodEnd,
}) => {
  return (
    <>
      <ul>
        <li>
          <H4 className="detail_list">Renewal Date: {currentPeriodEnd}</H4>
        </li>
      </ul>
    </>
  );
};
