import { useHistory } from "react-router-dom";
import { Paper, Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AuthLayout } from "../layouts";
import { SignIn2, LoginSubscription } from "../components";
import { LogoIcon } from "../components/icons/logo";

const useStyles = makeStyles((theme) => ({
  box: {
    borderTopLeftRadius: 0,
  },
  container: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0 0px",
    [theme.breakpoints.down(600)]: {
      margin: "20px 0 20px",
    },
  },
  secondGrid: {
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    padding: "30px 10px 0px",
    alignItems: "center",
    display: "flex",
    backgroundColor: "#e8ece8",
    [theme.breakpoints.down(768)]: {
      padding: "30px 15px",
    },
    [theme.breakpoints.down(600)]: {
      height: "auto",
    },
  },
  firstGrid: {
    [theme.breakpoints.down(600)]: {
      height: "auto",
    },
  },
  footerMenu: {
    color: "#fff",
    fontSize: "13px",
    cursor: "pointer",
    flex: "0 0 auto",
    marginRight: "13px",
    marginBottom: "10px",
  },
  mainGrid: {
    height: "100vh",
  },
  loginMenu: {
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  loginCopyright: {
    marginTop: "8px",
    marginBottom: "16px",
    color: "#fff",
    fontSize: "14px",
    alignItems: "center",
  },
  mainLoginTab: {
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  paper: {
    padding: "30px",
  },
}));

const Login = () => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <Grid container className={styles.mainGrid}>
      <Grid item xs={12} sm={6} className={styles.firstGrid}>
        <AuthLayout>
          <Box display="flex" className={styles.container}>
            <Box maxWidth={380} className={styles.mainLoginTab}>
              <Paper elevation={4} className={styles.paper}>
                <SignIn2 />
              </Paper>
              <Box mt={10}>
                <Box sx={{ display: { xs: "none", sm: "flex" } }} className={styles.loginMenu}>
                  <Box onClick={() => history.push("/about")} className={styles.footerMenu}>
                    About Us
                  </Box>
                  <Box onClick={() => history.push("/faqs")} className={styles.footerMenu}>
                    FAQs
                  </Box>
                  <Box onClick={() => history.push("/privacy")} className={styles.footerMenu}>
                    Privacy Policy
                  </Box>
                  <Box onClick={() => history.push("/terms")} className={styles.footerMenu}>
                    Terms and Conditions
                  </Box>
                </Box>
                <Box className={styles.loginCopyright} sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Box mr={1}>
                    <LogoIcon height="20" primary="#AC0303" secondary="#FFFFFF" />
                  </Box>
                  All Rights Reserved. Property of GFY 2021 (c)
                </Box>
              </Box>
            </Box>
          </Box>
        </AuthLayout>
      </Grid>
      <Grid container item xs={12} sm={6} className={styles.secondGrid}>
        <LoginSubscription />
      </Grid>
    </Grid>
  );
};

export default Login;
