export const Stripe = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    height="27px"
    width="120px"
    viewBox="0 0 923.1 201.7"
    xmlSpace="preserve">
    <path
      fill="rgba(0,0,0,0.6)"
      d="M876.5,201.7h-830C20.8,201.7,0,180.8,0,155.1V46.5C0,20.8,20.8,0,46.5,0h830c25.7,0,46.5,20.8,46.5,46.5v108.6
	C923.1,180.8,902.2,201.7,876.5,201.7z M915.3,46.5c0-21.4-17.4-38.8-38.8-38.8h-830C25.1,7.8,7.8,25.1,7.8,46.5v108.6
	c0,21.4,17.4,38.8,38.8,38.8h830c21.4,0,38.8-17.4,38.8-38.8V46.5z"
    />
    <path
      fill="rgba(0,0,0,0.6)"
      d="M470.8,143h-10.1l7.8-19.3L453,84.3h10.7l9.8,26.8l9.9-26.8H494L470.8,143z M432,126.1c-3.5,0-7.1-1.3-10.4-3.8
	v2.9h-10.4V66.4h10.4v20.7c3.3-2.5,6.9-3.8,10.4-3.8c10.9,0,18.4,8.8,18.4,21.4C450.4,117.3,442.9,126.1,432,126.1L432,126.1z
	 M429.8,92.3c-2.9,0-5.7,1.2-8.2,3.7v17.5c2.4,2.4,5.3,3.7,8.2,3.7c5.9,0,10-5.1,10-12.4C439.7,97.4,435.7,92.3,429.8,92.3
	L429.8,92.3z M368.9,122.3c-3.2,2.5-6.8,3.8-10.4,3.8c-10.9,0-18.4-8.8-18.4-21.4c0-12.6,7.6-21.4,18.4-21.4c3.6,0,7.2,1.3,10.4,3.8
	V66.4h10.5v58.7h-10.5V122.3z M368.9,96c-2.4-2.5-5.2-3.7-8.1-3.7c-6,0-10,5.1-10,12.4s4.1,12.4,10,12.4c2.9,0,5.7-1.2,8.1-3.7V96z
	 M306.7,107.6c0.7,6.2,5.6,10.4,12.4,10.4c3.8,0,7.9-1.4,12.2-3.8v8.7c-4.6,2.1-9.3,3.2-13.9,3.2c-12.3,0-21-9-21-21.7
	c0-12.3,8.5-21.1,20.2-21.1c10.7,0,18,8.4,18,20.4c0,1.1,0,2.5-0.2,3.8H306.7z M316.1,91.4c-5.1,0-9,3.8-9.5,9.4h17.8
	C324.1,95.3,320.8,91.4,316.1,91.4L316.1,91.4z M279.2,97.9v27.3h-10.4V84.3h10.4v4.1c2.9-3.3,6.5-5.1,10-5.1c1.1,0,2.3,0.1,3.4,0.4
	v9.3c-1.1-0.3-2.4-0.5-3.7-0.5C285.5,92.6,281.8,94.4,279.2,97.9z M232.5,107.6c0.7,6.2,5.5,10.4,12.4,10.4c3.8,0,7.9-1.4,12.2-3.8
	v8.7c-4.7,2.1-9.3,3.2-13.9,3.2c-12.3,0-21-9-21-21.7c0-12.3,8.5-21.1,20.2-21.1c10.7,0,18,8.4,18,20.4c0,1.1,0,2.5-0.2,3.8H232.5z
	 M242,91.4c-5.1,0-9,3.8-9.5,9.4h17.8C250,95.3,246.7,91.4,242,91.4z M196,125.1l-8.3-27.8l-8.2,27.8h-9.4l-14-40.8h10.4l8.2,27.8
	l8.2-27.8h9.5l8.2,27.8l8.2-27.8h10.4l-14,40.8H196z M132.2,126.1c-12.3,0-21.1-8.9-21.1-21.4c0-12.6,8.7-21.4,21.1-21.4
	s21,8.8,21,21.4C153.2,117.2,144.5,126.1,132.2,126.1z M132.2,92.1c-6.1,0-10.4,5.1-10.4,12.7c0,7.5,4.2,12.7,10.4,12.7
	c6,0,10.3-5.1,10.3-12.7C142.5,97.2,138.2,92.1,132.2,92.1z M86.3,105h-9.4v20.2H66.5V69h19.8c11.4,0,19.6,7.4,19.6,18
	S97.8,105,86.3,105L86.3,105z M84.9,77.5h-7.9v19h7.9c6,0,10.3-3.8,10.3-9.5C95.1,81.3,90.9,77.5,84.9,77.5z M861.9,109h-43.1
	c1,10.3,8.5,13.4,17.1,13.4c8.7,0,15.6-1.8,21.6-4.9v17.7c-6,3.3-13.9,5.7-24.4,5.7c-21.5,0-36.5-13.4-36.5-40
	c0-22.4,12.8-40.2,33.7-40.2c20.9,0,31.8,17.8,31.8,40.4C862.2,103.2,862,107.8,861.9,109z M830.2,78.7c-5.5,0-11.6,4.2-11.6,14.1
	h22.8C841.4,82.8,835.6,78.7,830.2,78.7z M761,140.9c-7.7,0-12.4-3.3-15.6-5.6l0,24.9l-22,4.7l0-102.8h19.4l1.1,5.4
	c3-2.8,8.6-6.9,17.3-6.9c15.5,0,30,13.9,30,39.6C791.2,128.3,776.8,140.9,761,140.9L761,140.9z M755.9,80.2c-5,0-8.2,1.8-10.5,4.4
	l0.1,32.7c2.1,2.3,5.2,4.2,10.4,4.2c8.1,0,13.6-8.9,13.6-20.7C769.5,89.2,763.9,80.2,755.9,80.2L755.9,80.2z M691.5,62.2h22.1v77.2
	h-22.1V62.2z M691.5,37.5l22.1-4.7v17.9l-22.1,4.7V37.5z M668,87v52.3h-22V62.2h19l1.4,6.5c5.2-9.5,15.5-7.6,18.4-6.5v20.2
	C681.9,81.5,673.2,80.2,668,87L668,87z M620.7,112.3c0,13,13.9,8.9,16.7,7.8V138c-2.9,1.6-8.3,2.9-15.5,2.9
	c-13.1,0-22.9-9.6-22.9-22.7l0.1-70.7l21.5-4.6l0,19.1h16.7v18.8h-16.7L620.7,112.3z M593.3,116c0,15.9-12.6,24.9-30.9,24.9
	c-7.6,0-15.9-1.5-24.1-5v-21c7.4,4,16.8,7,24.1,7c4.9,0,8.4-1.3,8.4-5.4c0-10.5-33.5-6.6-33.5-30.9c0-15.6,11.9-24.9,29.8-24.9
	c7.3,0,14.6,1.1,21.9,4v20.7c-6.7-3.6-15.2-5.7-21.9-5.7c-4.6,0-7.5,1.3-7.5,4.8C559.6,94.5,593.3,89.8,593.3,116z"
    />
  </svg>
);
