export default {
  // fontfamily: "Roboto",
  fontfamily: {
    Roboto: "Roboto",
    Coolvetica: "Coolvetica",
    Inter: "Inter",
  },
  fontStyle: "normal",

  fontWeight: {
    small: 400,
    medium: 500,
    base: 600,
    heavy: 800,
  },

  fonts: {
    // smaller fonts
    smallest: 9,
    small: 12,
    smaller: 15,
    smallplus: 18, // TODO: Need to refactor this one

    // base fonts
    base: 14,

    // medium fonts
    medium: 16,
    mediumMiddle: 20,
    mediumplus: 22,
    mediumSecond: 25,
    mediumFull: 30,
    mediumLarge: 35,
    mediumXL: 40,

    // bigger fonts
    big: 50,

    // larger fonts

    // heading fonts
    heading: 70,

    // fonts line heights
    lineHeightsOne: 22,
    lineHeightsTwo: "96%",
  },

  colors: {
    // General colors
    brown: "brown",
    orange: "orange",
    white: "white",
    black: "black",
    gray: "gray",

    // Heading colors
    headingColor: "#031716",

    // buttons and badge colors
    YellowButtonColor: "#EBA83A",
    whiteButtonColor: "#F5F5F5",
    greenCardColor: "#016938",
    grayCardColor: "#F5F5F5",
    recommendBadgeColor: "#016938",
    activeButtonColor: "#65DCA4",
    tickGrayBackground: "#F9F0E1",
    yourSubscriptionsCardColor: "#D3D3D3",
    cancelButtonTextColor: "#13302B",

    // inputfields Background colors
    inputBackgroundColor: "#FFFFFF",

    // Heading colors
    bigHeadingColor: "#031716",
    lightHeadingColor: "#777777",

    // Border Colors
    cancelButtonBorderColor: "#BB371A",

    subtitleGrey: "#BABABA",
    cartBorderGrey: "#D3D3D3",
  },
  letterSpacings: {
    letterSpacingOne: "-0.045em",
  },
};
