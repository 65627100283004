import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import { H3, H4, Subtitles, SubtitlesRegular } from "../../../components/Text";
import Theme from "../../../Theme/Theme";

// icons
import closeIcon from "../../../assets/svg/close-circle.svg";
import arrowIcon from "../../../assets/svg/arrow.svg";

// convert to arrow
export default function AddToCartCard({
  cardtitle,
  className,
  theFontColor,
  showBadge,
  isSelected = false,
  onClick,
  price,
}) {
  const [value, setValue] = useState("");
  const styles = useStyles();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <div
        className={styles.cardLayout}
        style={{ backgroundColor: isSelected ? Theme.colors.greenCardColor : Theme.colors.grayCardColor }}
        onClick={onClick}>
        <div className={styles.cardContainer} style={{ color: isSelected ? Theme.colors.white : Theme.colors.black }}>
          {showBadge && (
            <span className={styles.badge}>
              <SubtitlesRegular>Recommended</SubtitlesRegular>
            </span>
          )}
          <H4 className={styles.productName}>{cardtitle}</H4>
          <SubtitlesRegular className={styles.productStatus}>Non Active</SubtitlesRegular>
        </div>
        <div className={styles.priceContainer}>
          <div className={styles.priceDisplay}>
            <H3 className={isSelected ? styles.pricing : styles.pricingBlack}>{price}</H3>
          </div>
          <div
            className={styles.btnContainer}
            style={{
              backgroundColor: isSelected ? Theme.colors.white : "transparent",
              border: isSelected ? 0 : `1px solid ${Theme.colors.subtitleGrey}`,
            }}>
            <Subtitles className={styles.btnLabel}>{`${isSelected ? "Selected" : "Add To Cart"}`}</Subtitles>
            <img src={`${isSelected ? closeIcon : arrowIcon}`} className={styles.closeIcon} />
          </div>
        </div>
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  badge: {
    position: "absolute",
    top: -33,
    right: 5,
    backgroundColor: Theme.colors.recommendBadgeColor,
    color: Theme.colors.white,
    padding: "7px 14px",
    cursor: "pointer",
    borderRadius: 16,
    fontSize: Theme.fonts.smallest,
    fontFamily: Theme.fontfamily.Inter,
    [theme.breakpoints.down("sm")]: {
      fontSize: "7px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "5px",
    },
  },
  contentBlack: {
    height: "50%",
    width: "100%",
    color: Theme.colors.black,
  },
  cardContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    color: Theme.colors.white,
  },
  pricingBlack: {
    fontFamily: Theme.fontfamily.Roboto,
    fontSize: Theme.fonts.mediumplus,
    color: Theme.colors.black,
    fontWeight: "lighter",
    fontWeight: Theme.fontWeight.heavy,
    margin: 0,
  },
  grayCardCard: {
    height: 381,
    width: 288,
    backgroundColor: Theme.colors.grayCardColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardLayout: {
    height: 357,
    width: 280,
    backgroundColor: Theme.colors.greenCardColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 19,
  },
  Dropdowner: {
    height: "25%",
    width: "100%",
    justifyContent: "center",
  },
  btnContainer: {
    height: 44,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btnLabel: {
    marginLeft: 12,
  },
  dropdownbtn: {
    backgroundColor: Theme.colors.white,
    color: Theme.colors.black,
    border: "none",
    cursor: "pointer",
    width: "100%",
    height: 44,
  },
  pricing: {
    fontFamily: Theme.fontfamily.Roboto,
    fontSize: Theme.fonts.mediumplus,
    color: Theme.colors.white,
    fontWeight: "lighter",
    fontWeight: Theme.fontWeight.heavy,
    margin: 0,
  },
  space: {
    height: "15%",
    width: "100%",
    margin: 5,
  },
  priceDisplay: {
    height: "40%",
    width: "100%",
    marginBottom: 10,
  },
  Dropdowner: {
    height: "25%",
    width: "100%",
  },
  priceContainer: {
    height: "60%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: 10,
  },
  productStatus: {
    color: Theme.colors.subtitleGrey,
  },
  productName: {
    fontFamily: Theme.fontfamily.Roboto,
    fontSize: Theme.fonts.mediumMiddle,
    marginBottom: 6,
    marginTop: 25,
  },

  closeIcon: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
}));
