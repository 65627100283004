import dayjs from "dayjs";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const columns = [
  { id: "tradeType", label: "Trade Type", align: "center" },
  { id: "tradePrice", label: "Trade Price", align: "center" },
  { id: "cropContractDate", label: "Crop Contract Date", align: "center" },
  // { id: "cropContractMonth", label: "Crop Contract Month", align: "center" },
  { id: "datetime", label: "Datetime", align: "center" },
];

const formatColumn = (data, type) => {
  switch (type) {
    case "tradePrice":
      return data[type].toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    case "datetime":
      return dayjs(new Date(data[type])).format("MM-DD-YYYY");
    case "cropContractDate":
      return `${data.cropContractMonth}, ${data.cropContractYear}`;
    default:
      return data[type];
  }
};

const TradeLedgerTable = ({ ledger }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        {ledger.length > 0 ? (
          <Table stickyHeader aria-label="sticky table" sx={{ borderBottomColor: "transparent" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ledger.map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {formatColumn(row, column.id)}
                        {/* {column.id === "datetime"
                          ? dayjs(new Date(row[column.id])).format("MM-DD-YYYY")
                          : row[column.id]} */}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Typography mb={4} variant="h1" textAlign="center">
            No data
          </Typography>
        )}
      </TableContainer>
    </Paper>
  );
};

export default TradeLedgerTable;
