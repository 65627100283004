import { DialogContent, Typography, DialogTitle, Button, Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Warning from "@mui/icons-material/WarningOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useContext } from "react";
import { API } from "aws-amplify";
import pupper from "../../../assets/pupper.jpg";
import { SubscriptionContext } from "../../../context/subscription-context";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#FFB74D",
    height: "80px",
    padding: "0 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      height: "50px",
    },
  },
  container: {
    width: "691px",
    height: "443px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
    },
  },
  titleText: {
    marginLeft: "20px",
    marginRight: "auto",
    verticalAlign: "center",
    display: "flex",
  },
  text: {
    color: "rgba(0,0,0,0.87)",
    textAlign: "left",
    marginBottom: "40px",
    marginTop: "20px",
    letterSpacing: "0.15px",
    lineHeight: "28px",
    fontSize: "1rem",
    [theme.breakpoints.down(600)]: {
      fontSize: "1.2rem",
    },
  },
  content: {
    textAlign: "center",
    padding: 0,
    maxHeight: "362px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
  closeButton: {
    color: "black",
  },
  buttonContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "8px",
  },
  deleteButton: {
    backgroundColor: "#1E4620",
    color: "white",
    fontSize: "15px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  cancelButton: {
    fontSize: "15px",
    color: "#1E4620",
    "&.MuiButton-outlined": {
      border: "1px solid #1E4620",
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "2.7rem",
  },
  image: {
    height: "362px",
  },
}));

const CancelSubscriptionDialog = ({ subscription, setOpen }) => {
  const styles = useStyles();
  const { email } = useContext(SubscriptionContext);

  const cancelSubscription = async () => {
    const apiName = "restApiProd";
    const path = "/cancelSubscription";
    const myInit = {
      body: { id: subscription.id, email: email },
    };
    const result = await API.post(apiName, path, myInit);
    if (result) {
      console.log("cancelSubscription => result => ", result);
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <DialogTitle className={styles.dialog} id="delete-dialog" onClose={() => setOpen(false)}>
        <Typography variant="h2" className={styles.titleText}>
          <Warning className={styles.icon}></Warning>
          Cancel Subscription
        </Typography>
        <IconButton aria-label="close" className={styles.closeButton} onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Box className={styles.contentContainer}>
          <Typography gutterBottom className={styles.text}>
            Are you sure, You want to cancel your subscription
          </Typography>
          <Box className={styles.buttonContainer}>
            <LoadingButton
              variant="contained"
              size="large"
              className={styles.deleteButton}
              onClick={() => cancelSubscription()}>
              Yes, Cancel It
            </LoadingButton>
            <Button variant="outlined" size="large" className={styles.cancelButton} onClick={() => setOpen(false)}>
              Close
            </Button>
          </Box>
        </Box>
        <Box className={styles.image} sx={{ display: { xs: "none", md: "flex" } }}>
          <img src={pupper} alt="" />
        </Box>
      </DialogContent>
    </React.Fragment>
  );
};

export default CancelSubscriptionDialog;
