import { Box, Divider, Grow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PurchaseTokenBox from "./PurchaseTokenBox";
import SocialTokenBox from "./SocialTokenBox";
import { Stripe } from "../icons/stripe";

const useStyles = makeStyles((theme) => ({
  header: {
    color: "#1E4620",
  },
  subHeader: {
    fontSize: "16px",
    color: "rgba(0,0,0,0.87)",
    marginTop: "21px",
    letterSpacing: "0.15px",
  },
  text: {
    fontSize: "14px",
    color: "rgba(0,0,0,0.6)",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  left: {
    width: "100%",
    maxWidth: "657px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
    },
    "@media (min-width:600px) and (max-width:800px)": {
      width: "auto",
    },
  },
  divider: {
    background: "rgba(0,0,0,0.12)",
  },
  right: {
    maxWidth: "515px",
    width: "100%",
    paddingLeft: "30px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      paddingLeft: 0,
      marginTop: "20px",
    },
    "@media (min-width:600px) and (max-width:1024px)": {
      width: "auto",
      paddingLeft: 0,
      marginTop: "30px",
    },
  },
  payments: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "14px",
    fontWeight: 500,
    marginRight: "10px",
  },
  paymentsContainer: {
    marginTop: "39px",
  },
  socialContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(1024)]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    marginBottom: "50px",
  },
}));

const tokens = [
  {
    text: "20 Drill Tokens",
    image: "TwentyTokens",
    amount: "$35",
    key: 0,
    product: "token20",
  },
  {
    text: "50 Drill Tokens",
    image: "FiftyTokens",
    amount: "$75",
    key: 1,
    product: "token50",
  },
  {
    text: "100 Drill Tokens",
    image: "OneHundredTokens",
    amount: "$125",
    key: 2,
    product: "token100",
  },
];

const TokenBox = (props) => {
  const { padding } = props;

  const styles = useStyles();

  return (
    <Box style={{ padding: padding }}>
      <Grow in={true} timeout={1000}>
        <div>
          <Typography mb={4} variant="h3" className={styles.header}>
            Get More Drill Tokens
          </Typography>
          <Typography className={styles.subHeader}>
            Looking to get more tokens? Please select an option best fitting to your needs below:
          </Typography>
          <Box className={styles.container} mt={4}>
            <Box className={styles.left}>
              <Typography className={styles.text}>
                You may <b>purchase</b> drill token bundles with any major credit card within your Account. The token
                balance will immideatly apply to your account. There is no limit as to how many bundles you may
                purchase.
              </Typography>
              <Box className={styles.socialContainer}>
                {tokens.map((token) => (
                  <PurchaseTokenBox
                    text={token.text}
                    image={token.image}
                    amount={token.amount}
                    key={token.key}
                    product={token.product}
                  />
                ))}
              </Box>
              <Box
                className={styles.paymentsContainer}
                display="flex"
                alignItems="center"
                sx={{ display: { sm: "none", xs: "flex" } }}>
                <Box className={styles.payments}>All payments are</Box> <Stripe />
              </Box>
            </Box>
            <Divider orientation="vertical" classes={{ root: styles.divider }} flexItem />
            <Box className={styles.right}>
              <Typography className={styles.text}>
                You may <b>earn</b> drill tokens by sharing about GFY on Social Media. The token credit balance will
                immediately apply to your account. Each share = 2 drill tokens. Limited to once per day.
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <SocialTokenBox />
              </Box>
            </Box>
          </Box>
          <Box
            className={styles.paymentsContainer}
            display="flex"
            alignItems="center"
            sx={{ display: { xs: "none", sm: "flex" } }}>
            <Box className={styles.payments}>All payments are</Box> <Stripe />
          </Box>
        </div>
      </Grow>
    </Box>
  );
};

export default TokenBox;
