import { Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AuthLayout } from "../layouts";
import { ResetPasswordComponent } from "../components";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "137px",
    marginTop: "33px",
    height: "400px",
    width: "380px",
  },
}));

const ResetPassword = () => {
  const styles = useStyles();

  return (
    <AuthLayout>
      <Paper elevation={4} className={styles.container}>
        <ResetPasswordComponent type="external" padding="25px 40px" />
      </Paper>
    </AuthLayout>
  );
};

export default ResetPassword;
