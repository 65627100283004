const styles = (theme) => ({
  header: {
    fontSize: "1.25rem",
    fontWeight: 500,
    marginBottom: "16px",
  },
  subHeader: {
    fontSize: "1.25rem",
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
  },
  form: {
    marginTop: "1rem",
    width: "273px",
  },
  deleteButton: {
    color: "#FF8F00",
    "&.MuiButton-outlined": {
      border: "1px solid #FF8F00",
    },
  },
  container: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down(600)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  saveButton: {
    color: "#1E4620",
    "&.MuiButton-outlined": {
      border: "1px solid #1E4620",
    },
    width: "100px",
    fontWeight: 600,
  },
  rightBox: {
    width: "450px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginTop: "10px",
    },
  },
  actionIcons: {
    cursor: "pointer",
  },
  resetContainer: {
    height: "auto",
    width: "380px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
    },
  },
  settingContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
    },
  },
});

export default styles;
