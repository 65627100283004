import { useContext, useState, useEffect, useCallback, forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  IconButton,
  Divider,
  TextField,
  Collapse,
  useMediaQuery,
  Snackbar,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/lab/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { LogoIcon } from "../icons/logo";
import { AppContext, AuthContext } from "../../context";
import { createUserContacts, updateUserContact } from "../../data/queries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import HubspotContactForm from "./modalComponenets/HubspotContactForm";
import { Form } from "./modalComponenets/Form";

const MuiAlert = forwardRef(function MuiAlert(props, ref) {
  return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "auto",
    width: "700px",
  },
  titleText: {
    marginLeft: "20px",
  },
  text: {
    fontSize: "1.25rem",
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
    textAlign: "left",
    marginBottom: "40px",
    marginTop: "20px",
  },
  content: {
    marginLeft: "20px",
    marginRight: "20px",
    textAlign: "center",
    padding: "0 30px",
    [theme.breakpoints.down(600)]: {
      padding: "0 10px",
    },
  },
  icon: {
    marginRight: "10px",
  },
  divider: {
    background: "#1E4620",
  },
  header: {
    color: "#B71C1C",
    fontSize: "34px",
    letterSpacing: "0.25px",
    lineHeight: "42px",
    textAlign: "center",
    marginTop: "34px",
    [theme.breakpoints.down(600)]: {
      fontSize: "24px",
      lineHeight: "32px",
      marginTop: "24px",
    },
  },
  subHeader: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0,0,0,0.87)",
    marginTop: "24px",
    textAlign: "left",
    [theme.breakpoints.down(600)]: {
      marginTop: "18px",
    },
  },
  formContainer: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0,0,0,0.87)",
    marginTop: "44px",
    textAlign: "left",
    [theme.breakpoints.down(600)]: {
      marginTop: "18px",
    },
  },
  form: {
    width: "300px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  phoneRoot: {
    marginBottom: theme.spacing(1),
    textAlign: "left",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  mt3: {
    marginTop: theme.spacing(3),
  },
  errorMessage: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
    margin: "3px 0 0",
  },
  // button: {
  //   fontWeight: 500,
  //   height: "42px",
  //   width: "300px",
  //   fontSize: "15px",
  //   color: "#fff",
  //   letterSpacing: "0.43px",
  //   lineHeight: "26px",
  //   marginTop: "24px",
  //   marginBottom: "30px",
  // },
  button: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#13302B",
    background: "#EBA83A",
    borderRadius: "1px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    textAlign: "center",
    boxShadow: "none",
    borderColor: "transparent",
    border: "1px solid",
    marginTop: "24px",
    marginBottom: "30px",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#000",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "100%",
    },
    [theme.breakpoints.down(375)]: {
      fontSize: "11px",
    },
  },
  buttonContainer: {
    width: "300px",
    marginBottom: "20px",
  },
  sendButton: {
    color: "#fff",
  },
  closeButton: {
    color: "rgba(0,0,0,0.87)",
  },
}));

const errorOptions = {
  name: false,
  email: false,
  phone: false,
};

const ContactDialog = () => {
  const styles = useStyles();
  const { showContact, setShowContact } = useContext(AppContext);
  const { userProfile } = useContext(AuthContext);

  const logoHeight = useMediaQuery("(max-width:599px)") ? "34px" : "68px";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState(errorOptions);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccessMsg(false);
  };

  const handleChangeValue = useCallback((e, field) => {
    setErrors((prevState) => ({
      ...prevState,
      [field]: false,
    }));
    if (field === "name") {
      setName(e.target.value);
    } else if (field === "email") {
      setEmail(e.target.value);
    } else if (field === "phone") {
      setPhoneNumber(e);
    }
  }, []);

  useEffect(() => {
    if (userProfile) {
      setName(userProfile.name);
      setEmail(userProfile.email);
    }
  }, [userProfile]);

  const sendMessage = async () => {
    setLoading(true);
    if (!name) {
      setErrors((prevState) => ({
        ...prevState,
        name: true,
      }));
      setLoading(false);
      return;
    }
    if (!email) {
      setErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
      setLoading(false);
      return;
    }
    if (!phone) {
      setErrors((prevState) => ({
        ...prevState,
        phone: true,
      }));
      setLoading(false);
      return;
    }

    try {
      if (userProfile.email) {
        const data = {
          uuid: email,
          info: JSON.stringify({ phone }),
          timestamp: new Date().toISOString(),
        };
        await API.graphql({
          query: updateUserContact,
          variables: data,
          authMode: "API_KEY", // AWS_IAM API_KEY
        });
      } else {
        const data = {
          id: uuidv4(),
          uuid: email,
          info: JSON.stringify({ phone }),
          timestamp: new Date().toISOString(),
        };
        await API.graphql({
          query: createUserContacts,
          variables: data,
          authMode: "API_KEY", // AWS_IAM API_KEY
        });
      }
      setShowSuccessMsg(true);
      setErrors(errorOptions);
      setPhoneNumber("");
      setName("");
      setEmail("");
      setShowContact(false);
    } catch (e) {
      // setApiError(e.message || e.errors[0].message || e);
      setApiError(`oh uh something went wrong!`);
      setShowApiError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={showContact}
        classes={{ paper: styles.dialog }}>
        <DialogContent className={styles.content}>
          <Box mt={2}>
            {/* <Form /> */}
            <Box
              style={{ marginTop: 15, marginBottom: 15 }}
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Box style={{ marginRight: "auto", width: "10px" }} />
              <Box style={{ marginLeft: "auto", marginRight: "auto" }}>
                <LogoIcon height={logoHeight} primary="#AC0303" secondary="#FFFFFF" />
              </Box>
              <Box style={{ marginLeft: "auto" }}>
                <IconButton aria-label="close" className={styles.closeButton} onClick={() => setShowContact(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Divider classes={{ root: styles.divider }} />

            {/* <script>
              {
                hbspt.forms.create({
                  region: "na1",
                  portalId: "23710839",
                  formId: "8e6bcd81-5046-4d5e-a7ad-65b66d83cf58",
                  target: "demo",
                })
              }
            </script>
            <Box id="demo"></Box> */}

            {/* this is where i placed the hubspot form that i used in example app but for some reason not showin gup in app */}

            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <Box className={styles.header}>Request a Free Trial</Box>
              <Box className={styles.subHeader}>
                We are always happy to hear your thoughts and answer all and any of your questions!{" "}
                <b>Shoot your shot</b>:
                <HubspotContactForm />
              </Box>
              <Box className={styles.formContainer}>
                <HubspotContactForm />
              </Box>

              <Collapse in={showApiError}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setShowApiError(false);
                      }}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  {apiError}
                </Alert>
              </Collapse>

              {/* <TextField
                id="name"
                label="Your Name"
                type="text"
                InputLabelProps={{ shrink: name ? true : false }}
                className={styles.form}
                margin="normal"
                value={name}
                error={errors.name}
                onChange={(e) => handleChangeValue(e, "name")}
                helperText={errors.name ? "Please enter your name." : ""}
              />
              <Box mt={1} />
              <TextField
                id="email"
                InputLabelProps={{ shrink: email ? true : false }}
                label="Email"
                type="email"
                value={email}
                className={styles.form}
                margin="normal"
                error={errors.email}
                onChange={(e) => handleChangeValue(e, "email")}
                helperText={errors.email ? "Please enter your email." : ""}
              />
              <Box mt={1} />
              <div className={styles.phoneRoot}>
                <PhoneInput
                  value={phone}
                  className={`${styles.form} ${styles.mt3} formBorder`}
                  onChange={(e) => handleChangeValue(e, "phone")}
                  inputStyle={{ width: "auto", border: "none" }}
                  id="phoneNumber"
                  placeholder="Enter phone number"
                  enableSearch
                  masks={{ us: "...-...-....", ca: "...-...-...." }}
                  country="us"
                  preferredCountries={["us", "ca"]}
                />
                {errors.phone && <label className={styles.errorMessage}>Please enter your phone number.</label>}
              </div>

              <LoadingButton
                // TODO: could be changed to Button (GFYButton)
                className={styles.button}
                onClick={sendMessage}
                variant="contained"
                color="primary"
                size="large"
                loadingIndicator="Sending..."
                loading={loading}
                classes={{ label: styles.sendButton }}>
                Request a Demo
              </LoadingButton> */}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showSuccessMsg}
        autoHideDuration={6000}
        onClose={handleClose}>
        <MuiAlert onClose={handleClose} severity="success">
          Successfully Submitted
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default ContactDialog;
