// React Componenets--------------------------------------------------->
import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import axios from "axios";
import config from "../../config";
// MUI Componenets----------------------------------------------------->
import makeStyles from "@mui/styles/makeStyles";
import { Box, Container, Grid } from "@mui/material";

// Layout Componenets-------------------------------------------------->
import Theme from "../../Theme/Theme";
import { H1 } from "../../components/Text";
import { GeneralLayout } from "../../layouts";

// Stripe Components--------------------------------------------------->
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Aws Amplify Components---------------------------------------------->
import { API } from "@aws-amplify/api";

// Context ------------------------------------------------------------>
import { SubscriptionContext } from "../../context/subscription-context";

// SubComponents------------------------------------------------------->
import CartItem from "./components/CartItem";
import { Views } from "../SubAndPayComps/Views";
import { Popup } from "./Card Details componenets/Popup";
import { GFYButton } from "../../components/ButtonGfy/GFYButton";
import { PaymentView } from "./Card Details componenets/PaymentView";

// Icons----------------------------------------------------------------->
import ArrowIcon from "../../assets/svg/arrow.svg";

// Data Arrays------------------------------------------------------------>
import { ProductsInfoSandbox, ProductsInfoLive } from "../../data/products";

// Lodash---------------------------------------------------------------->
import _, { get, set } from "lodash";

/* StripeKey Configuration/Selection-------------------------------------->
 * Contains Control Flow to set Stripe Key According to prefered Enviroment
 * Contains test key for testing Stripe responses
 * Contains live key to test live Stripe operations */
let stripeKey = process.env.REACT_APP_STRIPE_API_KEY

const stripePromise = loadStripe(stripeKey);

export const EnterCardDetail = ({ onSwitchView, cardItems }) => {
  // variables using react componenets----------------->
  const styles = useStyles();
  const history = useHistory();
  const submitRef = useRef();
  const { pathname } = useLocation();

  // useState variables--------------------------------->
  const [ClientSecret, setClientSecret] = useState(null);
  const [AddToCartPriceList, setAddToCartPriceList] = useState(cardItems);
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [products, setProducts] = useState(
    process.env.REACT_APP_NODE_ENV === "dev" || process.env.REACT_APP_NODE_ENV === "uat"
      ? ProductsInfoSandbox 
      : ProductsInfoLive
  );
  

  /* context variables destrucherd to get values from context------------------------->
   * getting values subscription, customerId, email, promocode from SubscriptionContext
   * getting values selectProd, setSelectProduct from SubscriptionContexts selectedProd */
  const { data, selectedProd } = useContext(SubscriptionContext);
  const { customerId, promocode, email, subscription } = data;
  const { selectProd, setSelectProduct } = selectedProd;

  const [finalTotal, setFinalTotal] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  /*  Componenet UseEffect #1------------------------------------>
   * the effect when fires runs the fetchData method
   * Upon running the methode calls the createSubscription method
   * the create subscription methode create a subscrpion */
  useEffect(() => {
    const fetchData = async () => {
      const recentSub = await createSubscription();
      
      let finalTotal = recentSub.latest_invoice.total;

      finalTotal = finalTotal / 100;
      // format as currency
      finalTotal = finalTotal.toLocaleString("en-US", { style: "currency", currency: "USD" });
      setFinalTotal(finalTotal);

      // if total dicount amounts array is not empty
      if (recentSub.latest_invoice.total_discount_amounts.length > 0) {
        // get the total discount amount
        let totalDiscount = recentSub.latest_invoice.total_discount_amounts[0].amount;
        totalDiscount = totalDiscount / 100;
        // format as currency
        totalDiscount = totalDiscount.toLocaleString("en-US", { style: "currency", currency: "USD" });
        // set the total discount amount to state variable
        setTotalDiscount(totalDiscount);
      }

    };

    fetchData();

  }, []);

  /* Filter Mechanism for the selected product--------------->
   * selectionToFilter variable contains the selected price id
   * the filterProducts contains the selected product filtered out
   * from the data array set by the enviroment*/
  const selectionToFilter = cardItems[0];
  const filterProducts = products.filter((selection) => selection.priceId === selectionToFilter);

  /* Componenet Methods-------------------------->
   * createSubscription---------------------->
   * method creates subscription for the users
   * Contains Api call and requires client secret for the POST API
   * Uses the "/stripe-custom-checkout" API end point
   * sets the value from response client secret to statevariabe setClientSecret */
  const createSubscription = async () => {
    const { clientSecret, subscription, status, error } = await API.post("restApiProd", "/stripe-custom-checkout", {
      body: {
        prices: AddToCartPriceList,
        email: email,
        coupon: promocode,
        customerId: customerId,
        payment_method: "",
      },
    });

    if (error && error.raw && error.raw.message) {
      setClientSecret(error.raw.message);
      return subscription;
    } else if (error) {
      setClientSecret(error);
      return subscription;
    }

    setClientSecret(clientSecret);
    return subscription;
  };

  /* RemoveProduct----------------------------------------->
   * method remove the selected item from list on the right side
   * of the enter card details right section */
  const RemoveProduct = (index) => {
    const items = [...products];
    items.splice(index, 1);
    setProducts(items);
  };

  /* ClosePopup-------------------------------------------->
   * method closes the popup when clicked on the black background */
  const ClosePopup = () => {
    setShowDialogBox(false);
  };

  /* backToCardDetails------------------------------------->
     method navigates user back to Your Subscriptions page */
  const goToSubscriptions = () => {
    history.push("/YourSubscriptions");
  };

  /* closeDialogBox---------------------------------------->
     method closes dialog box when clicked on cancel button */
  const closeDialogBox = () => {
    setShowDialogBox(false);
  };

  /* goBackToSelection------------------------------------->
     method navigates on button click back to the Add to Cart page using pathname */
  const goBackToSelection = () => {
    if (pathname === "/payment") {
      history.push("/subscriptions");
    } else {
      onSwitchView(Views.FIRST);
    }
  };

  /* openConfirmPopup--------------------------------------->
     After creating subscription the method opens a dialog box confirming the subscription is created
     it also allows user to navigate to yourSubscription page to see all the uers subscriptions */
  const openConfirmPopup = (value) => {
    setShowDialogBox(value);
  };

  return (
    <GeneralLayout>
      <Container className={styles.container}>
        <Box sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <H1 className={styles.heading}>Check Out</H1>
        </Box>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6} className={styles.grid}>
            <Elements stripe={stripePromise}>
              <PaymentView clientSecret={ClientSecret} openConfirmPopup={openConfirmPopup} submitRef={submitRef} />
            </Elements>
            {showDialogBox && (
              <Popup
                title={"Subscription Created"}
                description={"You have successfully subscribed to product"}
                primaryButtonLabel={"Show my Subscriptions"}
                secondaryButtonLabel={""}
                onSecondaryButtonClick={closeDialogBox}
                onPrimaryButtonClick={goToSubscriptions}
                onClosePopup={ClosePopup}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className={styles.grid}>
            {filterProducts?.map((item, index) => {
              return (
                <CartItem
                  key={index}
                  productName={item.text}
                  productPrice={item.amount}
                  // onRemove={() => RemoveProduct(index)}
                />
              );
            })}

            {/* add promo code discount if there is a discount */}
            {promocode && (
              <CartItem
                productName={"PROMO"}
                productPrice={"-" + totalDiscount}
                // onRemove={() => RemoveProduct(index)}
              />
            )}

            <CartItem
              productName={"TOTAL"}
              productPrice={finalTotal}
              // onRemove={() => RemoveProduct(index)}
            />
          </Grid>
        </Grid>
        <Box
          sx={{ display: "flex", flexDirection: "row", marginTop: 8, justifyContent: "center", alignItems: "center" }}>
          <GFYButton label={"Back"} isPrimary={false} styleProps={{ marginRight: 10 }} onClick={goBackToSelection} />
          <GFYButton
            label={"Confirm Payment"}
            icon={ArrowIcon}
            iconWidth={33}
            iconHeigt={13}
            onClick={() => submitRef.current.click()}
            // onClick={handleConfirmPayment}
          />
        </Box>
      </Container>
    </GeneralLayout>
  );
};

/* EnterCardDetail Component Styling---------------------------------------------------->
 * Contains Styling for the component EnterCardDetail
 * Uses MUIs makestyles method to create styling */
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1320,
    paddingBottom: 81,
    paddingLeft: 0,
    paddingRight: 0,
  },
  heading: {
    textAlign: "center",
    marginTop: 81,
    marginBottom: 69,
  },
  backButton: {
    fontFamily: Theme.fontfamily.Coolvetica,
    backgroundColor: Theme.colors.white,
    border: "0.735849px solid",
    borderColor: Theme.colors.subtitleGrey,
    color: Theme.colors.black,
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: Theme.fonts.small,
    margin: "4px 2px",
    cursor: "pointer",
    width: 156,
  },

  proceedButton: {
    fontFamily: Theme.fontfamily.Roboto,
    backgroundColor: Theme.colors.YellowButtonColor,
    border: "none",
    color: Theme.colors.black,
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: Theme.fonts.small,
    margin: "4px 2px",
    cursor: "pointer",
    width: 341,
  },
  enterCardDetailSectionHeading: {
    fontFamily: Theme.fontfamily.Roboto,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.small,
    fontSize: Theme.fonts.heading,
    lineHeight: "10%",
    color: Theme.colors.bigHeadingColor,
  },
  containerCarts: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 150,
    marginTop: 0,
  },

  enterCardDetailSection: {
    width: "55%",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },

  formAndOptionsContainer: {
    width: "100%",
    marginBottom: 10,
    display: "flex",
  },

  leftSide: {
    backgroundColor: "#DCDCDC",
    width: 648,
    height: 496,
    marginRight: 18,
  },
  rightSide: {
    width: 648,
    height: 496,
    display: "flex",
    flexDirection: "column",
  },

  submitionButton: {
    width: "50%",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));
