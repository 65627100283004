import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Column } from "../../components/table/column";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    marginBottom: "30px",
    [theme.breakpoints.down(1174)]: {
      margin: "20px",
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "5px",
      marginBottom: "10px",
      marginLeft: "auto",
      marginRight: "auto",
    }
  },
  type: {
    paddingBottom: 20,
    fontSize: "30px",
    fontFamily: "'Coolvetica'",
    [theme.breakpoints.down("md")]: {
      paddingBottom: 20,
    },
    [theme.breakpoints.down(600)]: {
      textAlign: "center",
      fontSize: "24px",
      alignItems: "center", 
      paddingBottom: "10px",
    },
    [theme.breakpoints.down(430)]: {
      textAlign: "center",
      fontSize: "24px",
      alignItems: "center", 
      paddingBottom: "10px",
    },
  },
  header: {
    height: "20vh",
    width: "290px",
    padding: "30px",
    backgroundColor: "#000000",
    color: "white",
    [theme.breakpoints.down(1508)]: {
      height: "20vh",
      width: "210px",
      padding: "30px",
    },
    [theme.breakpoints.down('sm')]:{
      height: "15vh",
      padding: "15px ",
      width: '55vw',
    },
    "& .header-inner": {
      display:"flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      columnGap: "10px",
      alignItems: "center",
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        textAlign: "center",
      },
      "& h6": {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 300,
        fontStyle: "italic",
        [theme.breakpoints.down('sm')]: {
          fontSize: "14px",
          paddingTop: "5px",
        },
      },
      "& h3": {
        fontFamily: "'Coolvetica'",
        fontSize: "90px",
        fontWeight: "400",
        lineHeight: "80%",
        paddingBottom: "20px",
        [theme.breakpoints.down("lg")]: {
          fontSize: "60px",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "50px",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "30px",
          paddingBottom: "7px",
        },
      },
    },
  },
  body: {
    display:"flex",
    alignItems:"center",
    height: "20vh",
    width: "270px",
    fontFamily: "Inter",
    fontSize: "18px",
    backgroundColor: "#ffffff", 
    border: "10px solid #000000",
    padding: "30px",
    color: "#031716",
    lineHeight: "200%",
    fontWeight: 500,
    "& ul": {
      paddingLeft: "20px",
      margin: 0,
      listStyleType: "none",
    },
    [theme.breakpoints.down(1508)]: {
      fontSize: "14px",
      padding: "15px",
      height: "20vh",
      width: '220px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "10px",
      height: "15vh",
      width: '52.5vw',
    },
  },
  footer: {
    display:"flex",
    alignItems:"center",
    height: "20vh",
    width: "280px",
    fontFamily: "Inter",
    fontSize: "18px",
    backgroundColor: "#016938",
    padding: "30px 35px",
    color: "#ffffff",
    lineHeight: "200%",
    fontWeight: 500,
    [theme.breakpoints.down(1508)]: {
      fontSize: "14px",
      padding: "15px 15px",
      height: "20vh",
      width: '240px',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      padding: "15px 15px",
      height: "15vh",
      width: '55vw',
    },
    "& ul": {
      paddingLeft: "25px",
      margin: 0,
      listStyleType: "none",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "15px",
      },
    }, 
    "& li":{
      marginTop: "10px",
      [theme.breakpoints.down("sm")]: {
        
      },
    },
  },
  number: {
    fontWeight: 1500,
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
}));

const Result = ({ data }) => {
  const { profitPerBushelGfy, type, highestSellAlert, avgPrice, cbotAvg, numberOfAlerts } = data;
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Typography className={styles.type}>{type}</Typography>
        <div className="header-inner">
          <Typography variant="h3">${highestSellAlert}</Typography>
          <Typography variant="h6">
            GFY Highest Sell Alert
          </Typography>
        </div>
      </div>
      <div className={styles.body}>
        <ul>
          <li>
            GFY Avg. Sell Alert: <br></br><strong className={styles.number}>${avgPrice}</strong>
          </li>
          <li>
            Spring Insurance Price: <br></br><strong className={styles.number}>${cbotAvg}</strong>
          </li>
        </ul>    
      </div>
      <div className={styles.footer}>
        <ul>
          <li>
            Additional Revenue: <br></br><strong className={styles.number}>{profitPerBushelGfy}</strong>
          </li>
          <li>
            Number Of Alerts: <strong>{numberOfAlerts}</strong>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Result;
