import { useState, useEffect } from "react";
import { Box, Typography, Paper, Tabs, Tab, Container, Grid, useMediaQuery, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth, API } from "aws-amplify";
import moment from "moment";
import TrafficLight from "./TrafficLight";
import MomoResultsGraph from "./MomoResultsGraph";
import MomoSummaryStatsTable from "./MomoSummaryStatsTable";
import GFYSellsGraph from "./GFYSellsGraph";
import TradeLedgerTable from "./TradeLedgerTable";
import CropErrorModal from "../modals/CropErrorMoal";
import { listGFYSellsResults, listMomoResults } from "../../data/queries";
import { years } from "../../utils/quarters";
import { PRODUCT_TPYE } from "../../utils/constants";
import { ReactComponent as LoadingIcon } from "../../assets/spinner_grey.svg";
import { getContentful, newTradeLedgerCollection, notificationsCollection } from "../../contentful";
import FeaturedPosts from "../blog/FeaturedPosts";
import CurrenPositions from "./CurrentPositions";
import { H2, H3 } from "../Text";
import { useTheme } from "@mui/styles";
import { CROPS } from "../../constants";
import { useOpenPositions, usePositionSummaries, useTrades } from "../../pages/Dashboard";
import CropTradesSummary from "./CropTradesSummary";

const useStyles = makeStyles((theme) => ({
  actionGraphContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(1100)]: {
      // let's add this value to mui breakpoints if needed
      flexDirection: "column",
    },
  },
  opacityHalf: {
    opacity: 0.5,
  },
  header: {
    fontWeight: 500,
    color: "#000",
    [theme.breakpoints.down(600)]: {
      // TODO! check if this can be 'sm'
      textAlign: "left",
    },
  },
  contentTwo: {
    width: "100%",
    marginTop: "70px",
    marginBottom: "70px",
  },
  boxTwoPara: {
    fontSize: "12px",
    color: "grey",
    fontWeight: "600",
    letterSpacing: "0.5px",
  },
  tabRoot: {
    marginBottom: "30px",
  },
  notificationRoot: {
    marginBottom: 32,
    padding: 8,
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    background: "pink",
  },
  historicalAvg: {
    textAlign: 'center',
  },
  heading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem !important',
      marginTop: '5rem',
    },
  }
}));

const CropDetails = ({ listData, cropType }) => {
  // TODO! check if this index file should be moved to pages folder?
  const styles = useStyles();
  const [momoTrafficLight, setMomoTrafficLight] = useState(0);
  const [selectedCropType, setSelectedCropType] = useState();
  const [selectedQuarter, setSelectedQuarter] = useState(years.slice(-1)[0]);
  const [nextToken, setNextToken] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [momoGraphData, setMomoGraphData] = useState([]);
  const [latestMomoRecord, setLatestMomoRecord] = useState();
  const [loadingGFY, setLoadingGFY] = useState(false);
  const [loadingMomo, setLoadingMomo] = useState(false);
  const [gfyErrorMessage, setGFYErrorMessage] = useState("");
  const [momoErrorMessage, setMomoErrorMessage] = useState("");
  const [ledger, setLedger] = useState([]);
  const [tableType, setTableType] = useState(0);
  const [notification, setNotification] = useState("");
  const cropTypeMap = {
    WHEAT: 'KC_WHEAT',
    SOYBEAN: 'SOYBEANS',
  };
  const cropTypeKey = cropTypeMap[cropType] || cropType;
  const selectedCrop = CROPS[cropTypeKey];
  const frCropType = selectedCrop.frCropType;
  const currentYear = new Date().getFullYear();
  const [productionCycle, setProductionCycle] = useState(currentYear);
  const productionCycleList = [currentYear - 2, currentYear - 1, currentYear];
  const tradesQry = useTrades(frCropType, productionCycle);
  const positionSummariesQry = usePositionSummaries(frCropType, productionCycle);
  const openPositionsQry = useOpenPositions(frCropType, productionCycle);

  const trades = tradesQry.data || [];
  const openPositions = openPositionsQry.data || [];
  const positionSummaries = positionSummariesQry.data || [];


  // detect small screens
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getGFYSellsResults = async (years, cropType) => {
    try {
      setSelectedQuarter(years);
      setGFYErrorMessage("");
      setLoadingGFY(true);
      // add custom checking subscriptions
      const graphResultsDEV = await API.graphql({
        query: listGFYSellsResults,
        variables: {
          limit: 1000,
          filter: {
            crop: { eq: PRODUCT_TPYE[cropType] },
            filter_year_run: { eq: years.toString().slice(-2) },
          },
          nextToken: nextToken,
        },
        authMode: "AWS_LAMBDA",
        //authToken: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        authToken: "True",
      });
      setLoadingGFY(false);

      if (graphResultsDEV) {
        setNextToken(graphResultsDEV.data.listGFYSellsResults.nextToken);
        const results = graphResultsDEV.data.listGFYSellsResults.items;
        if (selectedQuarter === years) {
          setGraphData((graphData) => [...graphData, ...results]);
        } else {
          setGraphData([]);
        }
      }
    } catch (e) {
      console.log("err", e);
      setGraphData([]);
      setLineChartData([]);
      setGFYErrorMessage("You don't have valid subscription.");
      setLoadingGFY(false);
    }
  };

  const getMomoResult = async (year, cropType) => {
    const passYear = year.toString().slice(-2);
    let crop;
    if (cropType.toLowerCase() === "soybean") {
      crop = "Beans";
    } else if (cropType.toLowerCase() === "wheat") {
      crop = "Kansan Wheat";
    } else if (cropType.toLowerCase() === "corn") {
      crop = "Corn";
    } else {
      cropType = cropType.toLowerCase();
      crop = cropType.charAt(0).toUpperCase() + cropType.slice(1);
    }
    let momoGraphResults;
    try {
      setLoadingMomo(true);
      setMomoErrorMessage("");
      momoGraphResults = await API.graphql({
        query: listMomoResults,
        variables: {
          limit: 10000,
          filter: { crop: { eq: crop }, filter_year_run: { eq: passYear } },
        },
        authMode: "AWS_LAMBDA",
        //authToken: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        authToken: "True",
      });
      setLoadingMomo(false);
    } catch (e) {
      console.log("err", e);
      setMomoErrorMessage("You don't have valid subscription.");
      setLoadingMomo(false);
    }
    if (momoGraphResults) {
      const results = momoGraphResults.data.listMomoResults.items;
      if (results.length > 0) {
        const newArr = results
          .map((v) => ({ ...v, red_threshold: 3.5, green_bot: -3.5 }))
          .sort((a, b) => new Date(a.date) - new Date(b.date));
        let latestDate = new Date(Math.max(...results.map((e) => new Date(e.date))));
        latestDate = moment(latestDate);
        let latestDateRecord = newArr.find((e) => e.date === latestDate.format("YYYY-MM-DD"));
        latestDateRecord = newArr[newArr.length - 1];
        setLatestMomoRecord(latestDateRecord);
        if (latestDateRecord.momo < latestDateRecord.green_threshold) {
          setMomoTrafficLight(0);
        } else if (latestDateRecord.momo < latestDateRecord.yellow_threshold) {
          setMomoTrafficLight(1);
        } else {
          setMomoTrafficLight(2);
        }
        setMomoGraphData(newArr);
      } else {
        setLatestMomoRecord("");
        setMomoGraphData([]);
        setMomoTrafficLight(0);
      }
    }
  };

  const getTradeLedger = (cropType) => {
    getContentful({
      query: newTradeLedgerCollection,
      variables: { order: "datetime_DESC", where: { cropContractName: cropType } },
    }).then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      setLedger(data.newTradeLedgerCollection.items);
    });
  };

  const getNotifications = () => {
    getContentful({
      query: notificationsCollection,
      variables: { where: { visibility: true } },
    }).then(({ data, errors }) => {
    
      if (errors) {
        console.error(errors);
      }
      if (data && data.notificationsCollection && data.notificationsCollection.items && data.notificationsCollection.items[0]) {
        setNotification(data.notificationsCollection.items[0].content);
      } else {
        console.error('Data structure is not as expected:', data);
      }
    });
  };

  useEffect(() => {
    if (selectedCropType !== cropType) {
      getGFYSellsResults(selectedQuarter, cropType);
      getMomoResult(selectedQuarter, cropType);
      getTradeLedger(cropType);
      getNotifications();
      setSelectedCropType(cropType);
    }
  }, [cropType]);

  useEffect(() => {
    if (nextToken) {
      getGFYSellsResults(selectedQuarter, cropType);
    } else {
      if (graphData.length > 0) {
        setLineChartData(graphData.sort((a, b) => new Date(a.date) - new Date(b.date)));
      } else {
        setLineChartData([]);
      }
    }
  }, [nextToken]);

  return (
    <div>
      {notification && <div className={styles.notificationRoot}>{notification}</div>}
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        {loadingMomo ? (
          <LoadingIcon />
        ) : (
          <>
            <CropErrorModal open={!!momoErrorMessage} message={momoErrorMessage} />
            <Container>
              <Box mb={6}>
                <FormControl fullWidth>
                  <InputLabel id="crop-year-label" htmlFor="productionCycle">
                    Crop Year
                  </InputLabel>
                  <Select
                    labelId="crop-year-label"
                    id="productionCycle"
                    value={productionCycle}
                    label="Production Cycle"
                    onChange={(e) => {
                      setProductionCycle(e.target.value);
                    }}>
                    {productionCycleList.map((item) => (<MenuItem value={item}>{item}</MenuItem>))}
                  </Select>
                </FormControl>
              </Box>
              <div className={`${!!momoErrorMessage ? styles.opacityHalf : ""}`}>
                <Grid container spacing={isSmallScreen ? 0 : 5}>
                  <Grid item sm={12} md={5}>
                    <TrafficLight data={listData[momoTrafficLight]} latestMomoRecord={latestMomoRecord} />
                  </Grid>
                  <Grid item sm={12} md={7} style={isSmallScreen ? { width: "100%" } : {}}>
                    {/* <MomoResultsGraph
                      listData={listData[momoTrafficLight]}
                      momoGraphData={momoGraphData}
                      latestMomoRecord={latestMomoRecord}
                    /> */}
                    <CurrenPositions
                      listData={listData[momoTrafficLight]}
                      momoGraphData={momoGraphData}
                      latestMomoRecord={latestMomoRecord}
                      cropType={cropType}
                      productionCycle={productionCycle}
                      frCropType={frCropType}
                    />
                  </Grid>
                </Grid>
              </div>
            </Container>
            <Box marginTop={5}>
              <Container>
                <Typography variant="h2" marginBottom={1}>
                  Our Past Trades
                </Typography>
                <CropTradesSummary trades={trades} positionSummaries={positionSummaries} openPositions={openPositions} />
              </Container>
            </Box>
            <FeaturedPosts />
          </>
        )}
      </Box>

      {/* <GFYSellsGraph
        cropType={cropType}
        selectedQuarter={selectedQuarter}
        loading={loadingGFY}
        errorMessage={gfyErrorMessage}
        onGetData={getGFYSellsResults}
        lineChartData={lineChartData}
      /> */}

      <Container>
        <div className={styles.contentTwo}>
          {/* <Paper elevation={4}> */}
          {/* <Tabs centered value={tableType} onChange={(e, value) => setTableType(value)} className={styles.tabRoot}>
            <Tab label="Historical" />
            <Tab label="Trade Ledger" />
          </Tabs> */}
          <div>
            {tableType === 0 ? (
              <div className={styles.historicalAvg}>
                {/* <Typography mb={2} variant="h3" className={styles.header}>
                  Historic Performance Comparison With GFY And Without
                </Typography>
                <Typography mb={6} className={styles.boxTwoPara}>
                  Here's a summary of the current year and past years performance. Max percent of ATH is how close a
                  singular GFY sell suggestion came to the commodities ATH. Average percent of ATH is a non weighted
                  average of all sell suggestions closeness to the commodities ATH. Average Price CBOT is the average
                  sale price without GFY suggestions.
                </Typography> */}
                <H2 className={styles.heading}>Historical Averages</H2>
                <MomoSummaryStatsTable cropType={cropType} />
              </div>
            ) : (
              <TradeLedgerTable ledger={ledger} />
            )}
          </div>
          {/* </Paper> */}
        </div>
      </Container>
    </div>
  );
};

export default CropDetails;
