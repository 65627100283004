// React Components----------------------------------------------------->
import * as React from "react";
import { useState, useContext, useEffect, useCallback, useRef } from "react";

// Layout Components----------------------------------------------------->
import { GeneralLayout } from "../../layouts";
import Theme from "../../Theme/Theme";
import { H1 } from "../../components/Text";

// MUI Components--------------------------------------------------------->
import { Container, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";

// Context----------------------------------------------------------------->
import { SubscriptionContext } from "../../context/subscription-context";
import { AppContext } from "../../context";

// Sub Components---------------------------------------------------------->
import { AccordianComp } from "./Subscriptions Accordion/AccordianComp";

// AWS Amplify components-------------------------------------------------->
import axios from "axios";

// Others------------------------------------------------------------------>
import Swal from "sweetalert2";
import _ from "lodash";

export const YourSubscription = React.memo(({ withLayout = true }) => {
  // variables using react componenets--->
  const styles = useStyles();

  // useState variables------------------>
  const [Price, setPrice] = useState([]);
  const [UniqueSubscriptions, setUniqueSubscriptions] = useState([]);
  const [AddToCartPriceList, setAddToCartPriceList] = useState([]);

  /* context variables destrucherd to get values from context------------------------>
   * getting values subscription, customerId, email, promocode from SubscriptionContext
   * getting values selectProd, setSelectProduct from SubscriptionContexts selectedProd */
  const { params } = useContext(AppContext);
  const { data, selectedProd } = useContext(SubscriptionContext);
  const { subscription, email, promocode, customerId } = data;
  const { selectProd, setSelectProduct } = selectedProd;

  /* Componenet UseEffect #1------------------------------------------------------->
   * Sets the value into the use state varibale price by getting params from the
     appContext Api
   * Filters the active subscriptions from the subscriptions from the
     subscription-providers subscrptions
   * The filterSubscription conatins the filtered active subscriptions */
  useEffect(() => {
    setPrice(
      JSON.parse(params?.find((param) => param.key === "stripe")?.value || JSON.stringify({}))?.plans?.map(
        (eachPrice) => {
          return { ...eachPrice, addToCart: false };
        }
      )
    );
    if (subscription !== "undefined") {
      const filterSubscription = subscription.filter((s) => s.paymentStatus === "active");
      setUniqueSubscriptions(_.uniq(_.map(filterSubscription, "subscriptionId")));
    }
  }, []);

  /* Componenet UseEffect #2------------------------------------------------------->
   * gets the price variable and filters out the price of the products
     stores it in filterPrices
   * maps the price list to AddtoCartPricelist state variable s*/
  useEffect(() => {
    const filterPrices = Price.filter((eachPrice) => eachPrice.addToCart == true);
    const pricesList = filterPrices.map((e) => e.priceId);
    setAddToCartPriceList(pricesList);
  }, [Price]);

  return (
    withLayout ? (
      <GeneralLayout>
        <Container className={styles.container}>
          <SubscriptionInfo UniqueSubscriptions={UniqueSubscriptions} subscription={subscription} styles={styles} />
        </Container>
      </GeneralLayout>
    ) : (
      <Container className={styles.container} style={{maxWidth: 1320}}>
        <SubscriptionInfo UniqueSubscriptions={UniqueSubscriptions} subscription={subscription} styles={styles} />
      </Container>
    )
  );
});

const SubscriptionInfo = React.memo(({ UniqueSubscriptions, subscription, styles }) => {
  const [updatingID, setUpdatingID] = useState('')
  const [cancelingID, setCancelingID] = useState('')
  const [subscriptions, setSubscriptions] = useState([])
  const accordianRefs = useRef([]);

  useEffect(() => {
    const filteredSubscriptions = subscription.filter((s) => UniqueSubscriptions.includes(s.subscriptionId));
    const uniqueItems = filteredSubscriptions.reduce((acc, curr) => {
      if (!acc.find((s) => s.subscriptionId === curr.subscriptionId)) {
        acc.push(curr);
      }
      return acc;
    }, []);
    setSubscriptions(uniqueItems);
  }, [UniqueSubscriptions, subscription]);

  const updateAccordian = (index) => {
    accordianRefs.current[index].reloadSubscription();
  };

  const cancelSubscription = useCallback(async function (subscriptionId, index) {
    try {
      setCancelingID(subscriptionId)
      // get env variable
      const env = process.env.REACT_APP_NODE_ENV;

      var url = `https://u5fktfu78b.execute-api.us-east-2.amazonaws.com/Prod/Stripe/SubCancel/${subscriptionId}`;
      await axios.delete(url, { data: { 'env': env } });
      Swal.fire({
        title: 'Subscription Canceled',
        text: "You have canceled the subscription.",
        icon: "info",
      })
      updateAccordian(index)
    } catch (error) {
      console.error(error);
    } finally {
      setCancelingID('')
    }
  }, []);


  const updateSubscription = useCallback(async function (subscription, index) {
    try {
      const env = process.env.REACT_APP_NODE_ENV;
      const { autoRenew, subscriptionId } = subscription;
      const update = { autoRenew, env }; // included env here
      setUpdatingID(subscriptionId);
      const url = `https://u5fktfu78b.execute-api.us-east-2.amazonaws.com/Prod/Stripe/SubUpdate/${subscriptionId}`;
      await axios.post(url, JSON.stringify(update));

      Swal.fire({
        title: 'Subscription updated',
        text: "You have successfully updated the subscription.",
        icon: "success",
      })
      updateAccordian(index)
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingID('')
    }
  }, []);

  if (!UniqueSubscriptions) {
    return "";
  }

  return (
    <>
      <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <H1 className={styles.subscriptionHeading}>Your Subscriptions</H1>
      </Box>
      <Grid container spacing={2} className={styles.stripContainer}>
        {subscriptions &&
          subscriptions.map((card, index) => (
            <Grid item xs={12} key={index}>
              <AccordianComp
                ref={(el) => (accordianRefs.current[index] = el)}
                cardtitle={card.productName}
                cardPrice={card.totalAmount}
                style={{ marginBottom: 12 }}
                subscriptionid={card.subscriptionId}
                confirmCancelSubscription={(subscriptionId) => cancelSubscription(subscriptionId, index)}
                confirmUpdateSubscription={(subscription) => updateSubscription(subscription, index)}
                updatingID={updatingID}
                cancelingID={cancelingID}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
});

/* YourSubscriptions Component Styling------------------>
 * Contains Styling for the component YourSubscriptions
 * Uses MUIs makestyles method to create styling */
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: Theme.colors.whiteButtonColor,
    marginBottom: 80,
  },
  stripContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subscriptionHeading: {
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.small,
    fontSize: Theme.fonts.heading,
    lineHeight: Theme.fonts.lineHeightsTwo,
    color: Theme.colors.headingColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 547,
    height: 67,
  },
}));
