import { useCallback, useContext } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AppContext } from "../../context";
import { SUMMARY_TYPE } from "../../utils/constants";
import Theme from "../../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  tableCategories: {
    backgroundColor: Theme.colors.greenCardColor , //"rgba(255,233,125,0.2)"
    border: "none",
    color: Theme.colors.white,
    fontFamily: Theme.fontfamily.Inter,
    fontWeight: Theme.fontWeight.base,
  },
  tableRow: {
    // border: "1px solid rgba(0,0,0,0.26)",
    border: "none",
    borderBottom: "1px solid rgba(186,186,186,0.4)",
    borderRight: "1px solid rgba(186,186,186,0.4)",
    backgroundColor: "transparent",
    color: "#858585",
    fontFamily: Theme.fontfamily.Inter,
    fontWeight: Theme.fontWeight.small,
  },
  boxTable: {
    maxWidth: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    overflowY: "auto",
    "&tr:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  rowYear: {
    backgroundColor: Theme.colors.greenCardColor,
    border: "1px solid rgba(186,186,186,0.5)",
    color: Theme.colors.white,
    maxWidth: 130
  },
}));

const MomoSummaryStatsTable = ({ cropType }) => {
  const styles = useStyles();
  const { params } = useContext(AppContext);

  const getTableData = useCallback(() => {
    const filterData =
      params?.length > 0
        ? params.filter((item) => {
            return item.key === "summary";
          })
        : [];
    if (!filterData?.length > 0) return;
    let summaryData = JSON.parse(filterData[0].value),
      newArr = [];
    summaryData &&
      summaryData.summary.forEach((element) => {
        const expr = SUMMARY_TYPE[cropType];
        const zcData = element[expr];
        if (zcData) {
          const year = Object.keys(zcData)[0];
          newArr.push({ ...Object.values(zcData)[0], year });
        }
      });
    newArr = newArr.sort((a, b) => b.year.localeCompare(a.year));
    let numberFormate = new Intl.NumberFormat("en-US");
    const formatedData = newArr?.map((item) => ({
      AllTimeHighPrice: item.AllTimeHighPrice ? `$${numberFormate.format(item.AllTimeHighPrice)}` : "-",
      MaxPercOfATH: item.MaxPercOfATH ? `${item.MaxPercOfATH}%` : "-",
      AvgPercOfATH: item.AvgPercOfATH ? `${item.AvgPercOfATH}%` : "-",
      AvgPriceCBOT: item.AvgPriceCBOT ? `$${numberFormate.format(item.AvgPriceCBOT)}` : "-",
      AvgPriceGFY: item.AvgPriceGFY ? `$${numberFormate.format(item.AvgPriceGFY)}` : "-",
      DollAvgIncBushel: item.DollAvgIncBushel ? `$${item.DollAvgIncBushel}` : "-",
      year: item.year ? item.year : "-",
    }));
    return formatedData;
  }, [params, cropType]);

  return (
    <Box className={styles.boxTable}>
      {getTableData()?.length > 0 ? (
        <table aria-label="custom pagination table">
          <thead>
            <tr style={{ color: "#666666" }}>
              <th
                id="tableHeading"
                style={{
                  backgroundColor: "white",
                }}>
                {" "}
              </th>
              <th className={styles.tableCategories}>All Time High Price</th>
              <th className={styles.tableCategories}>Max % Of ATH</th>
              <th className={styles.tableCategories}>AVG % OF ATH</th>
              <th className={styles.tableCategories}>AVG Price CBOT</th>
              <th className={styles.tableCategories}>AVG Price With GFY</th>
              <th className={styles.tableCategories}>$ Increase / Bushel With GFY</th>
            </tr>
          </thead>
          <tbody style={{ justifyContent: "center" }}>
            {getTableData().map((row) => (
              <tr key={row.AvgPriceGFY}>
                <td
                  className={styles.rowYear}>
                  {row.year}
                </td>
                <td className={styles.tableRow}>{row.AllTimeHighPrice}</td>
                <td className={styles.tableRow}>{row.MaxPercOfATH}</td>
                <td className={styles.tableRow}>{row.AvgPercOfATH}</td>
                <td className={styles.tableRow}>{row.AvgPriceCBOT}</td>
                <td className={styles.tableRow}>{row.AvgPriceGFY}</td>
                <td className={styles.tableRow}>{row.DollAvgIncBushel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography mb={4} variant="h1" textAlign="center">
          Data not found!
        </Typography>
      )}
    </Box>
  );
};

export default MomoSummaryStatsTable;
