import { useState, useContext, useEffect } from "react";
import {
  Container,
  Box,
  Paper,
  Divider,
  TextField,
  Alert,
  Button,
  Dialog,
  DialogContent,
  Collapse,
  IconButton,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Close, Edit, Delete } from "@mui/icons-material";
import NotificationSetting from "./NotificationSetting";
import RoleSetting from "./RoleSetting";
import NameSetting from "./NameSetting";
import styles from "./account.styles";
import { GeneralLayout } from "../../layouts";
import { DeleteDialog, ResetPasswordComponent } from "../../components";
import UpdateSubscription from "../../components/dashboard/UpdateSubscription";
// import { SubscriptionLayout } from "../../components/subscription/subscription-layout";
import AuthContext from "../../context/AuthContext";
import { SubscriptionContext } from "../../context/subscription-context";
// import { getUserSubscriptionData } from "../../data/queries";

const Account = withStyles(styles)((props) => {
  const { classes } = props;
  const { userProfile } = useContext(AuthContext);
  const { subscription: userSubscription } = useContext(SubscriptionContext);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const [showMofifySubDialog, setShowMofifySubDialog] = useState(true);
  const [email, setEmail] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    if (userProfile && userProfile.email) {
      setEmail(userProfile.email);
      setIsEmailVerified(userProfile.email_verified);
    }
  }, [userProfile]);

  // const [userSubscription, setUserSubscription] = useState([]);
  // const [IsSubscriptionDialogOpened, setIsSubscriptionDialogOpened] = useState(true);

  // const getUserSubscriptions = async () => {
  //   if (userProfile && userProfile.email) {
  //     const userSubscritions = await API.graphql({
  //       query: getUserSubscriptionData,
  //       variables: { uuid: userProfile.email },
  //     });
  //     if (userSubscritions.data && userSubscritions.data.getUserSubscriptionData) {
  //       const subData = JSON.parse(userSubscritions.data.getUserSubscriptionData);
  //       if (subData.data && subData.data.subscriptions) {
  //         setUserSubscription(subData.data.subscriptions);
  //       }
  //     }
  //   }
  // };

  const ResetPasswordDialog = () => (
    <Dialog open={resetOpen} classes={{ paper: classes.resetContainer }}>
      <DialogContent style={{ padding: 0 }}>
        <ResetPasswordComponent type="internal" padding="25px 40px" {...{ setResetOpen }} />
      </DialogContent>
    </Dialog>
  );

  return (
    <GeneralLayout>
      <Container>
        <Paper elevation={4}>
          <Box p={4} mt={6} mb={6} className={classes.container}>
            <Box mt={2} color="#1E4620" className={classes.header}>
              My Account - {email}
            </Box>
            <Divider />
            <UpdateSubscription {...{ userProfile, userSubscription, showMofifySubDialog, setShowMofifySubDialog }} />
            {/* <SubscriptionLayout
              {...{
                userProfile,
                userSubscription,
                // getUserSubscriptions,
                IsSubscriptionDialogOpened,
                setIsSubscriptionDialogOpened,
              }}
            /> */}
            <Divider />
            <Collapse in={showApiError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowApiError(false);
                    }}>
                    <Close fontSize="inherit" />
                  </IconButton>
                }>
                {apiError}
              </Alert>
            </Collapse>
            <Box px={2} mb={4} mt={5} className={classes.settingContainer}>
              <NameSetting setApiError={setApiError} setShowApiError={setShowApiError} />
              <RoleSetting setApiError={setApiError} setShowApiError={setShowApiError} />
            </Box>
            <Divider />

            <Box px={2} mb={4} mt={5} className={classes.settingContainer}>
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Box className={classes.subHeader}>My Password</Box>
                  <Box className={classes.actionIcons} onClick={() => setResetOpen(true)}>
                    <Edit />
                  </Box>
                </Box>
                <Box mt={1} />
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  className={classes.form}
                  disabled={true}
                  margin="normal"
                  value={"password"}
                />
              </Box>
              <NotificationSetting setApiError={setApiError} setShowApiError={setShowApiError} />
            </Box>

            <Box mt={1} />
            <Box mt={7} display="flex" justifyContent="flex-end" px={2}>
              <Button
                variant="outlined"
                size="large"
                startIcon={<Delete />}
                className={classes.deleteButton}
                onClick={() => setDeleteOpen(true)}>
                Delete My Account
              </Button>
            </Box>
          </Box>
        </Paper>
        <DeleteDialog {...{ deleteOpen, setDeleteOpen }} />
        <ResetPasswordDialog />
      </Container>
    </GeneralLayout>
  );
});

export default Account;
