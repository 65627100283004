import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Save from "@mui/icons-material/Save";

const useStyles = makeStyles((theme) => ({
  actionIcons: {
    cursor: "pointer",
  },
  manageText: {
    color: "#1E4620",
    fontSize: "13px",
    fontWeight: 500,
    textAlign: "center",
  },
  iconActive: {
    width: "20px",
    height: "20px",
    color: "#1E4620",
    marginRight: "5px",
  },
}));

const SaveComponent = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" className={classes.actionIcons} alignItems="center" onClick={onClick}>
      <Save className={classes.iconActive} />
      <Box component="span" className={classes.manageText}>
        SAVE
      </Box>
    </Box>
  );
};

export default SaveComponent;
