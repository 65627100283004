import { Box, Grow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Stripe } from "../../icons/stripe";
import { SubscriptionList } from "./subscription-list";
import { PlanList } from "./plan-list";
import { SubscriptionContext } from "../../../context/subscription-context";
import { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  payments: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    color: "rgba(0,0,0,0.6)",
    fontSize: "14px",
    fontWeight: 500,
  },
  fullContent: {
    width: "100%",
    height: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
}));

export const SubscriptionDialogContent = () => {
  const { subscription } = useContext(SubscriptionContext);
  const styles = useStyles();

  return (
    <Box className={styles.fullContent}>
      <Grow in={true} timeout={1000}>
        <Box className={styles.mainContent}>
          {subscription.length > 0 ? <SubscriptionList /> : "No Subscription Active"}
          <PlanList />
          <Box className={styles.payments}>
            All payments are &nbsp; <Stripe />
          </Box>
        </Box>
      </Grow>
    </Box>
  );
};
