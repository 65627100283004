import { useState, useContext, useEffect } from "react";
import { Button, Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LoadingButton from "@mui/lab/LoadingButton";
import { Auth, API } from "aws-amplify";
import Swal from "sweetalert2";
import { AuthContext } from "../../context";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import axios from "axios";
import config from "../../config";
import { first, last, set } from "lodash";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const roles = ["Farmer", "Trader", "Risk Management", "Other"];

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "300px",
    marginRight: "300px",
    [theme.breakpoints.down(600)]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
    [theme.breakpoints.down(800)]: {
      marginLeft: "40px",
      marginRight: "40px",
    },
  },
  heading: {
    fontSize: "34px",
    color: "#B71C1C",
    lineHeight: "42px",
    [theme.breakpoints.down(600)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  subHeader: {
    fontSize: "1.25rem",
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
    textAlign: "left",
    marginBottom: "18px",
  },
  button: {
    width: "300px",
    backgroundColor: "#1E4620",
    color: "white",
    fontSize: "15px",
    marginBottom: "50px",
    [theme.breakpoints.down(600)]: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  },
  formLeft: {
    marginTop: "2rem",
    marginRight: "14px",
    [theme.breakpoints.down(600)]: {
      marginRight: 0,
    },
  },
  formRight: {
    marginTop: "1rem",
    marginLeft: "14px",
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
    },
  },
  telInput: {
    marginBottom: "8px",
  },
  customTelInput: {
    width: "90% !important",
    border: "none !important",
    paddingTop: "12px !important",
    fontSize: "15px !important",
    [theme.breakpoints.down(600)]: {
      paddingTop: "0px !important",
    },
  },
  nameContainer: {
    fontSize: "1.25rem",
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  roleButtonLeft: {
    height: "56px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    color: "rgba(0,0,0,0.6)",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "32px",
    textTransform: "inherit",
    marginBottom: "14px",
    marginRight: "14px",
    width: "calc(50% - 14px)",
    boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
    [theme.breakpoints.down(600)]: {
      fontSize: "18px",
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
  },
  roleButtonRight: {
    height: "56px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    color: "rgba(0,0,0,0.6)",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "32px",
    textTransform: "inherit",
    marginBottom: "14px",
    marginLeft: "14px",
    width: "calc(50% - 14px)",
    boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
    [theme.breakpoints.down(600)]: {
      fontSize: "18px",
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
}));

// on page load, get user details from cognito and set them in the state

const OnboardingTwo = (props) => {
  const { userProfile, setUserProfile } = useContext(AuthContext);
  const { setCurrentCard } = props;
  const [role, setRole] = useState(userProfile["custom:role"]);
  const [name, setName] = useState(userProfile.name ? userProfile.name : "");
  const [firstName, setFirstName] = useState(first(name));
  const [lastName, setLastName] = useState(last(name));
  const [promo, setPromo] = useState(userProfile["custom:promocode"]);
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(userProfile["custom:phoneNumber"]);
  const [loading, setLoading] = useState(false);
  const [forceUpdatePassword, setForceUpdatePassword] = useState(false);
  const styles = useStyles();

  const loadUserDetails = async () => {
    const user = await Auth.currentAuthenticatedUser();

    // get idtoken
    const token = user.signInUserSession.idToken.jwtToken;

    const email = user.attributes.email;

    const locale = user.attributes.locale;

    if (locale) {
      setForceUpdatePassword(true);
      // console.log("locale: ", locale);
    }

    // ~~~~ Hubspot API ~~~~ **Custom API**
    // console.log("Getting user details from Hubspot...", email);

    const env = process.env.REACT_APP_NODE_ENV;
    // GET CONTACT DETAILS FROM HUBSPOT
    const queryStringParameters = {
      env: env,
      email: email,
    };

    // console.log("Hubspot payload", payload);
    const headers = {
      Authorization: token,
    };

    const hubspot_url_key = "HUBSPOT_URL_" + env.toUpperCase();
    const hubspot_url = config[hubspot_url_key];

    const response = await axios.get(hubspot_url + "/GetContact", {
      params: queryStringParameters,
      headers: headers,
    });

    const responseStatus = response.status;

    // if response includes Contact not found
    if (responseStatus === 204) {
      // console.log("Contact not found in hubspot");
      // create contact in hubspot
      // console.log("Adding user to Hubspot...", email);
      createContactHubspot(email);
    } else {
      const data = response.data;

      // console.log("Hubspot response", data);

      const first_name = data.firstname;
      const phone_number = data.phone;
      const last_name = data.lastname;
      const hubspot_contact_id = data.contact_id;

      // if first_name is empty, then return
      if (first_name) {
        setFirstName(first_name);
      }
      if (last_name) {
        setLastName(last_name);
      }

      let formattedPhoneNumber = "";
      if (phone_number) {
        
        // if phone number does not have a 1 at the beginning, then add it
        if (!phone_number.startsWith("1")) {
          
          formattedPhoneNumber = "1" + phone_number
        }
        else {
          
          formattedPhoneNumber = phone_number
        }
          
        // console.log("formatted phone number: ", formattedPhoneNumber)
      setPhoneNumber(formattedPhoneNumber);
      }
    }
  };

  const createContactHubspot = async (email) => {
    // ~~~~ Hubspot API ~~~~ **Custom API**

    try {
      // console.log("Adding user to Hubspot...", email);

      const env = process.env.REACT_APP_NODE_ENV;

      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;

      const payload = {
        env: env,
        email: email,
      };

      // console.log("Hubspot payload", payload);
      const headers = {
        Authorization: token,
      };

      const hubspot_url_key = "HUBSPOT_URL_" + env.toUpperCase();
      const hubspot_url = config[hubspot_url_key];

      const response = await axios.post(hubspot_url + "/CreateContact", payload, { headers });

      // const response = await axios.post(hubspot_url + "/CreateContact", payload);
      // console.log("Hubspot response", response);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    loadUserDetails();
  }, []);

  const updateUser = async () => {
    setLoading(true);
    if (!firstName) {
      alert("Please enter your First Name!");
      setLoading(false);
      return;
    } else if (!lastName) {
      alert("Please enter your Last Name!");
      setLoading(false);
      return;
    } else if (!phoneNumber) {
      alert("Please enter your phone number!");
      setLoading(false);
      return;
    } else if (!password && forceUpdatePassword) {
      alert("Please enter a password!");
      setLoading(false);
      return;
    } else if (phoneNumber.length < 10) {
      alert("Please enter valid phone number!");
      setLoading(false);
      return;
    } else if (!role) {
      alert("Please choose the role!");
      setLoading(false);
      return;
    }

    setName(firstName + " " + lastName);
    // console.log("phoneNumber: ", phoneNumber);
    // make phone number cognito friendly

    // if phone number does not have a +1, then add it
    let cognitophoneNumber = "";
    cognitophoneNumber = parsePhoneNumberFromString(phoneNumber);

    if (!phoneNumber.includes("+")) {
      cognitophoneNumber = "+" + phoneNumber.replace(/-/g, "");
    } else {
      cognitophoneNumber = phoneNumber.replace(/-/g, "");
    }

    // console.log("cognitophoneNumber: ", cognitophoneNumber);
    const data = {
      name,
      "custom:role": role,
      "custom:phoneNumber": phoneNumber,
      phone_number: cognitophoneNumber,

      locale: "",
    };
    if (promo) data["custom:promocode"] = promo;

    try {
      const user = await Auth.currentAuthenticatedUser();

      // get idtoken
      const token = user.signInUserSession.idToken.jwtToken;

      // console.log("Updating user details in cognito...", data);
      // update user password
      try {
        const cogupdate = await Auth.updateUserAttributes(user, { ...data });
        if (cogupdate) {
          if (forceUpdatePassword) {
            await Auth.changePassword(user, "DummyPassword123!", password);
          }
        }
      } catch (err) {
        console.log("error: ", err);
      }

      // add details to hubspot

      // adding user details to hubspot **Custom API**
      const env_var = process.env.REACT_APP_NODE_ENV;
      let payload = {};
      try {
        if (forceUpdatePassword) {
           payload = {
            email: userProfile.email,
            firstname: firstName,
            lastname: lastName,
            phone: phoneNumber,
            role: role,
            promocode: promo,
            // set free trial start date to today
            free_trial: "true",
            free_trial_start_date: new Date().toISOString().split("T")[0],
            env: env_var,
          };
        } else {
           payload = {
            email: userProfile.email,
            firstname: firstName,
            lastname: lastName,
            phone: phoneNumber,
            role: role,
            promocode: promo,
            env: env_var,
          };
        }
        const hubspot_url_key = "HUBSPOT_URL_" + env_var.toUpperCase();
        const hubspot_url = config[hubspot_url_key];
        const headers = {
          Authorization: token,
        };
        // console.log("Hubspot payload", payload);
        const response = await axios.post(hubspot_url + "/UpdateContact", payload, { headers });
      } catch (error) {
        console.log("error: ", error);
      }

      // update user details in mailchimp
      const apiName = "restApiProd";
      const path = "/subscribeMailChimp";
      const myInit = {
        body: {
          email: userProfile.email,
          data: {
            FNAME: name,
            MMERGE7: name,
            PHONE: phoneNumber,
            MMERGE8: phoneNumber,
            MMERGE9: promo,
            MMERGE10: role,
          },
        },
      };
      // console.log("Updating user details in mailchimp...", myInit);
      await API.put(apiName, path, myInit);

      setUserProfile((prevState) => ({
        ...prevState,
        name: name,
      }));
      setCurrentCard("OnboardingThree");
      setLoading(false);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Oops, please try again later",
        confirmButtonText: "OK",
      });
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    setPhoneNumber(e?.length > 0 ? e : "");
  };

  return (
    <Box mt={4} className={styles.container}>
      <Box mt={1} mb={2} className={styles.heading}>
        First off...
      </Box>
      <Box>
        <Box mt={4} className={styles.nameContainer}>
          Who are you?
        </Box>
        <Box className={styles.nameContainer} mb={1}>
          <TextField
            id="name"
            placeholder="Your First Name"
            type="text"
            value={firstName}
            required
            fullWidth
            onChange={(e) => setFirstName(e.target.value)}
            className={`${styles.formLeft} formInput`}
            margin="normal"
          />
          <TextField
            id="lastName"
            placeholder="Your Last Name"
            type="text"
            value={lastName}
            required
            fullWidth
            onChange={(e) => setLastName(e.target.value)}
            className={`${styles.formLeft} formInput`}
            margin="normal"
          />
          <PhoneInput
            value={phoneNumber}
            className={`${styles.formRight} ${styles.telInput} formInput topupBorder`}
            onChange={(e) => handleChange(e)}
            // inputStyle={{ width: "90%", border: "none", paddingTop: "12px", fontSize: "15px" }}
            inputClass={styles.customTelInput}
            id="phoneNumber"
            placeholder="Enter phone number"
            enableSearch
            masks={{ us: "...-...-....", ca: "...-...-...." }}
            country="us"
            preferredCountries={["us", "ca"]}
          />
        </Box>
        <Box className={styles.nameContainer} mb={1}>
          <TextField
            id="promo"
            value={promo}
            label="Promo Code (optional)"
            type="text"
            onChange={(e) => setPromo(e.target.value)}
            className={`formInput`}
            margin="normal"
            fullWidth
          />

          {forceUpdatePassword && (
            <TextField
              id="password"
              value={password}
              label="Password"
              required
              type="text"
              onChange={(e) => setPassword(e.target.value)}
              className={`formInput`}
              margin="normal"
              fullWidth
            />
          )}
        </Box>
      </Box>
      <Box mb={2}>
        <Box mt={4} className={styles.subHeader}>
          What do you do?
        </Box>
        <Box className={styles.buttonContainer}>
          {roles.map((item, idx) => (
            <Button
              key={idx}
              className={idx % 2 === 0 ? styles.roleButtonLeft : styles.roleButtonRight}
              fullWidth
              style={{
                color: role === item ? "#B71C1C" : "rgba(0,0,0,0.6)",
                border: role === item ? "2px solid #1E4620" : null,
              }}
              onClick={() => setRole(item)}>
              {item}
            </Button>
          ))}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <LoadingButton
          variant="contained"
          size="large"
          fullWidth={true}
          loading={loading}
          className={styles.button}
          onClick={updateUser}>
          Continue
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default OnboardingTwo;
