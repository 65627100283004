import { useState, useEffect, useContext } from "react";
import { Auth, API } from "aws-amplify";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import styles from "./account.styles";
import AuthContext from "../../context/AuthContext";
import EditComponent from "../../components/Edit";
import SaveComponent from "../../components/Save";

const NOTIFICATION_TYPE = {
  TRAFFIC: "traffic",
  GFYSALE: "gfysale",
  ACCOUNT: "account",
};

const NotificationSetting = withStyles(styles)(({ classes, setApiError, setShowApiError }) => {
  const { TRAFFIC, GFYSALE, ACCOUNT } = NOTIFICATION_TYPE;
  const { userProfile } = useContext(AuthContext);

  const [notificationDisabled, setNotificationDisabled] = useState(true);
  const [notificationEdit, setNotificationEdit] = useState("edit");
  const [notification, setNotification] = useState("");

  useEffect(() => {
    setNotification(userProfile?.["custom:notification"] || "");
  }, [userProfile]);

  const handleChangeNotification = (e) => {
    let checks = notification?.split(",") || [];
    if (e.target.checked) {
      checks = checks.filter((val) => val.length > 0);
      checks.push(e.target.value);
    } else {
      const idx = checks.indexOf(e.target.value);
      if (idx > -1) {
        checks.splice(idx, 1);
      }
    }
    setNotification(checks.toString());
  };

  const handleNotificationEdit = () => {
    setNotificationDisabled(false);
    setNotificationEdit("save");
  };

  const saveNotificationInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { "custom:notification": notification });
      setNotificationEdit("edit");
      setNotificationDisabled(true);

      // update user details in mailchimp
      const apiName = "restApiProd";
      const path = "/subscribeMailChimp";
      const myInit = {
        body: {
          email: userProfile.email,
          data: {
            NOTIFYPREF: notification,
          },
        },
      };
      await API.put(apiName, path, myInit);
    } catch (e) {
      setApiError(e.message);
      setShowApiError(true);
    }
  };

  return (
    <Box className={classes.rightBox}>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Box className={classes.subHeader}>Notification Preferences</Box>
        {notificationEdit === "edit" ? (
          <EditComponent onClick={handleNotificationEdit} />
        ) : (
          <SaveComponent onClick={saveNotificationInfo} />
        )}
      </Box>
      <Box>
        <FormControlLabel
          disabled={notificationDisabled}
          value={TRAFFIC}
          checked={notification?.includes(TRAFFIC)}
          control={
            <Checkbox
              inputProps={{
                "aria-label": "Notify me when the traffic light changes",
              }}
            />
          }
          label="Notify me when the traffic light changes"
          onChange={handleChangeNotification}
        />
        <FormControlLabel
          disabled={notificationDisabled}
          value={GFYSALE}
          checked={notification?.includes(GFYSALE)}
          control={<Checkbox inputProps={{ "aria-label": "Notify me when GFY makes a sale" }} />}
          label="Notify me when GFY makes a sale"
          onChange={handleChangeNotification}
        />
        <FormControlLabel
          disabled={notificationDisabled}
          value={ACCOUNT}
          checked={notification?.includes(ACCOUNT)}
          control={
            <Checkbox inputProps={{ "aria-label": "E-mail me with promotions, account changes, and updates" }} />
          }
          label="E-mail me with promotions, account changes, and updates"
          onChange={handleChangeNotification}
        />
      </Box>
    </Box>
  );
});

export default NotificationSetting;
