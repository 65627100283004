import React from "react";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Stack from "@mui/material/Stack";

const Data = [
  {
    url: "../assets/icon-1.png",
    icontitle: "Real-time trade alerts",
  },
  {
    url: "../assets/icon-2.png",
    icontitle: "Predictive analytics",
  },
  {
    url: "../assets/icon-3.png",
    icontitle: "A.I. trading robot (Ginger)",
  },
  {
    url: "../assets/icon-4.png",
    icontitle: "Provides market pivots",
  },
  {
    url: "../assets/icon-5.png",
    icontitle: "Emotionless",
  },
];

const useStyles = makeStyles((theme) => ({
  h2: {
    color: "#031716",
    fontFamily: "Coolvetica",
    fontSize: "70px",
    fontWeight: "400",
    lineHeight: "96%",
    textAlign: "center",
    maxWidth: "912px",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  para: {
    letterspacing: "-0.03em",
    color: "#858585",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "167%",
    textAlign: "center",
    maxWidth: "1000px",
    margin: "auto",
  },
  list: {
    fontFamily: "Inter",
    fontSize: "25px",
    fontWeight: "400",
    lineHeight: "124%",
    color: "#000000",
    textAlign: "center",
    maxWidth: "255px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  icon: {
    objectFit: "contain",
    width: "70px",
    height: "70px",
  },
}));
export default function App() {
  const iconstyle = useStyles();
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" id="icon-card">
      <Grid item xs={12}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} pt={5}>
          <Typography variant="h2" className={iconstyle.h2}>
            What Do I Get?
          </Typography>
          <Typography className={iconstyle.para} pt={4}>
          Go Farm Yourself created an Artificial Intelligence trading robot, named Ginger, using predictive analytics and machine learning.
          Implement Ginger's real-time alerts and market pivots to make fast, accurate, & unbiased decisions in cash grain management. 
          Arm yourself with the trading tools Chicago trading firms have. 
          Take the uncertainty out of your decisions!
          </Typography>
        </Stack>
      </Grid>
      {Data.map((list) => (
        <Grid item xs={12} sm={6} md={4} lg>
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} pt={5}>
            <img src={list.url} className={iconstyle.icon} />
            <Typography className={iconstyle.list}>{list.icontitle}</Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
}
