import { Grid, Box, Button, Container, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}>
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <img
              src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
              alt=""
              width={300}
              height={150}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
              The page you’re looking for doesn’t exist.
            </Typography>
            <Button variant="contained" onClick={() => history.push("/")}>
              Back Home
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NotFound;
