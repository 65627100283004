import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import _ from "lodash";
import { SubscriptionContext } from "../context/subscription-context";
import { getUserSubscriptionData } from "../data/queries";
import { ReactComponent as LoadingIcon } from "../assets/spinner_grey.svg";

export function SubscriptionProvider({ children }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectProd, setSelectProduct] = useState();
  const [userEmail, setUserEmail] = useState(null)
  const history = useHistory();
  const location = useLocation();
  let subscriptionpayload = null;
  const getUserSubscriptions = async (email) => {
    subscriptionpayload = await API.graphql({
      query: getUserSubscriptionData,
      variables: { uuid: email || userEmail },
    });
    const subscriptionJSON = _.get(subscriptionpayload, "data.getUserSubscriptionData", {});
    const subscription = _.get(JSON.parse(subscriptionJSON), "data.subscriptions", {});
    return subscription;
  };
  const getURLpath = useCallback(() => {
    return window.location.pathname;
  }, []);

  const currentURl = getURLpath();

  useEffect(() => {
    const currentURl = getURLpath();
    if (
      currentURl.includes("account") ||
      currentURl.includes("payment") ||
      currentURl.includes("stoplight") ||
      currentURl.toLowerCase().includes("yoursubscriptions") ||
      currentURl.toLowerCase().includes("planscheckout") ||
      currentURl.includes("dashboard")
    ) {
      async function getUserData() {
        return await Auth.currentAuthenticatedUser();
      }
      getUserData()
        .then(async (user) => {
          const email = _.get(user, "attributes.email", "");
          const promocode = _.get(user, "attributes['custom:promocode']", "");
          const onboardingComplete = _.get(user, "attributes['custom:onboardingComplete']", "");
          const phoneNumber = _.get(user, "attributes['custom:phoneNumber']", "");
          const role = _.get(user, "attributes['custom:role']", "");
          const subscription = await getUserSubscriptions(email);
          const subscriptionJSON = _.get(subscriptionpayload, "data.getUserSubscriptionData", {});
          const customerId = _.get(JSON.parse(subscriptionJSON), "data.customerId", {});

          setUserEmail(email)
          setData({ user, subscription, email, promocode, role, phoneNumber, onboardingComplete, customerId });
          setLoading(false);
        })
        .catch((error) => {
          history.push("/login"); // TODO! check if we need "/" instead of "/login"
          setLoading(false);
          console.log(error);
        });
    } else {
      setLoading(false);
    }
  }, [currentURl, history]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      const newURLpath = getURLpath();
      if (newURLpath !== currentURl) {
        setLoading(true);
      }
    });
    return unlisten;
  }, [history, currentURl, getURLpath]);

  return (
    <SubscriptionContext.Provider value={{ data, selectedProd: { selectProd, setSelectProduct }, getUserSubscriptions, setLoading }}>
      {loading ? <LoadingIcon /> : children}
    </SubscriptionContext.Provider>
  );
}
