import { useContext } from "react";
import { Typography, Link, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Phone, Message, Map, MailOutlineOutlined, Email } from "@mui/icons-material";
import { AppContext } from "../../context";
const useStyles = makeStyles((theme) => ({
  icon: {
    color: "#EBA83A",
    fontSize: 40,
    marginBottom: 20,
  },
  text: {
    fontFamily: "Inter",
    fontSize: 18,
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  button: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#EBA83A",
    background: "transparent",
    borderRadius: "1px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    textAlign: "center",
    borderColor: "#EBA83A",
    boxShadow: "none",
    border: "1px solid",
    textTransform: "initial",
    "&:hover": {
      color: "#EBA83A",
      borderColor: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "100%",
    },
    [theme.breakpoints.down(375)]: {
      fontSize: "11px",
    },
  },
}));
const Contact = () => {
  const { setShowContact } = useContext(AppContext);
  const styles = useStyles();
  return (
    <Grid container spacing={5} id="contact">
      <Grid item xs={12} sm={6} md={3}>
        <MailOutlineOutlined className={styles.icon} />
        <Typography className={styles.text} gutterBottom>
          Email Us!
        </Typography>
        <Link href="mailto:contact@gfy.ag" className={[styles.text, styles.link].join(' ')}>
          contact@gfyag.com
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Phone className={styles.icon} />
        <Typography className={styles.text} gutterBottom>
          Call Us!
        </Typography>
        <Link href="tel:15156315554" className={[styles.text, styles.link].join(' ')}>
          +1 (515) 631-5554
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Map className={styles.icon} />
        <Typography className={styles.text} gutterBottom>
          Send Us a Letter!
        </Typography>
        <Typography className={styles.text}>
          Go Farm Yourself, Inc.
          <br />
          505 E Grand Suite 200
          <br />
          Des Moines, IA 50309
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Message className={styles.icon} />
        <Typography className={styles.text} gutterBottom>
          Shoot Us a Message!
        </Typography>
        <Button
          variant="contained"
          className={styles.button}
          onClick={() => {
            setShowContact(true);
          }}
          style={{ marginTop: 5 }}>
          Request a Free Trial
        </Button>
      </Grid>
    </Grid>
  );
};
export default Contact;