import React, { useState, useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { API } from "@aws-amplify/api";
import ModifySubscriptionDialog from "../modals/ModifySubscriptionDialog";
import CancelSubscriptionDialog from "../modals/CancelSubscriptionDialog";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { set } from 'lodash';

const axios = require('axios');


const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "340px",
    width: "100%",
    borderRadius: "4px",
    marginTop: 0,
    boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
    marginRight: "18px",
    alignContent: "center",
  },
  bottom: {
    borderRadius: 4,
    height: 36,
    width: 276,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #1E4620",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    alignSelf: "center",
    color: "white",
    "&:hover": {},
  },
  cancelButton: {
    marginTop: 10,
    borderRadius: 4,
    height: 36,
    width: 276,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    cursor: "pointer",
    textAlign: "center",
    fontSize: "14px",
    alignSelf: "center",
    fontWeight: 500,
    backgroundColor: "#fff",
    "&:hover": {},
    color: "#1E4620",
  },
  disabledButton: {
    color: theme.palette.secondary.contrastText,
    border: "1px solid rgba(0,0,0,0.26)",
  },
  top: {
    alignItems: "center",
    padding: "32px 26px 25px 26px",
  },
  topText: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: 500,
    fontSize: "1.2rem",
    marginLeft: "10px",
  },
  emptyStatus: {
    color: "rgba(0,0,0,0.6)",
    fontWeight: 400,
    marginTop: "53px",
    marginBottom: "52px",
    marginLeft: "10px",
    textAlign: "center",
    fontSize: 16,
    [theme.breakpoints.down(600)]: {
      marginRight: "10px",
    },
  },
  statusWrap: {
    fontFamily: "Roboto",
    fontSize: 16,
    display: "flex",
    flexDirection: "column",
    padding: "14px",
    marginBottom: 26,
  },
  statusRow: {
    display: "flex",
    flexDirection: "row",
    lineHeight: "31px",
  },
  statusName: {
    color: "#1E4620",
    width: 150,
    display: "flex",
    flexDirection: "row",
  },
  statusValue: {
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "bold",
  },
  error: {
    color: "#B71C1C",
  },
}));

const UpdateSubBox = (props) => {
  const { userProfile, userSubscription, getUserSubscriptions } = props;
  const [distinctProductNames, setDistinctProductNames] = useState([]);
  const [userSubsc, setuserSubsc] = useState();
  const [showMofifySubDialog, setShowMofifySubDialog] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const styles = useStyles();
  const history = useHistory();

  const cancelSubscription = async () => {

    setLoading(true);

    //New way of calling API
    // var url = 'https://u5fktfu78b.execute-api.us-east-2.amazonaws.com/Stage/Stripe/SubCancel/' + userSubscription[0].subscriptionId
    // const result = await axios.delete(url);
    // const response = result.data;
    // if (response?.message == 'successfully deleted subscription') {
    //   setCancelOpen(false);
    //   setLoading(false);
    // }
    // else {
    //   console.log('error')
    // }

    //Removed old way of calling API
    const apiName = "restApiProd";
    const path = "/cancelSubscription";
    const myInit = {
      body: { subscriptionId: userSubscription[0].subscriptionId, userEmail: userProfile.email },
    };
    const result = await API.post(apiName, path, myInit);
    const response = JSON.parse(result.setUserSubscriptionStatus);
    if (response?.data?.subscriptions) {
      getUserSubscriptions();
      setCancelOpen(false);
      setLoading(false);
    }
    
  };


  const [user, setUser] = useState(null);

  useEffect(() => {
    const getCognitoUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        setUser(authenticatedUser);

          const token = authenticatedUser.signInUserSession.idToken.jwtToken;
          const userEmail = authenticatedUser.attributes.email;

          const env = process.env.REACT_APP_NODE_ENV;
          const url = 'https://u5fktfu78b.execute-api.us-east-2.amazonaws.com/Prod/Stripe/GetSubscriptionDetails';
          // pass useremail and env as body
          const payload = {
            email: userEmail,
            env: env
          }

          const response = await axios.post(url, payload, {
            headers: {
              'Authorization': token
            }
          });
          // {product_name: 'Basic - Corn', price: '99999', status: 'active', created: 1691424105, next_billing_date: 1723046505}

          // order the data where Goodies is first
          var ordered_data = response.data.sort((a, b) => {
            if (a.product_name === 'All the Goodies') {
              return -1;
            }
            if (b.product_name === 'All the Goodies') {
              return 1;
            }
            return 0;
          });

          var distinct_product_names = [];

          // loop through the array and console.log each item
          ordered_data.forEach(item => {
            
            if (!distinct_product_names.includes(item.product_name)) {
              var clean_product_name = item.product_name.replace('Basic - ', '');
              distinct_product_names.push(clean_product_name);
            }
          });

          // console.log(distinct_product_names);
          setDistinctProductNames(distinct_product_names);


          
          setuserSubsc(ordered_data);

          

          

      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    getCognitoUser();
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  const distinctSubscriptionsList = (
    <Box className={styles.statusRow}>
      <Box className={styles.statusName}>Other Subs:</Box>
      <Box className={styles.statusValue} variant='h6' >
        {distinctProductNames
          .filter(name => name !== 'All the Goodies')
          .join(', ')}
      </Box>
    </Box>
  );
  

  return (

    
    <Box className={styles.container}>
      <Box color="primary.contrastText" className={styles.top}>
        <Box display="flex" justifyContent="flex-start" flexDirection="column">
          <Box component="span" className={styles.topText}>
            Your Subscription Plan: 
          </Box>
          <Box>
            {userSubsc?.length > 0 ? (
              <Box className={styles.statusWrap}>
                <Box className={styles.statusRow}>
                  <Box className={styles.statusName}>Subscription Type:</Box>
                  <Box className={styles.statusValue}>{userSubsc[0].product_name}</Box>
                </Box>

                <Box className={styles.statusRow}>
                  <Box className={styles.statusName} style={{ width: "auto" }}>
                    Status: &nbsp;
                    {userSubsc[0].status === "paid" || userSubsc[0].status === "active" ? (
                      <Box className={styles.statusValue}>Active 😎</Box>
                    ) : (
                      <Box className={[styles.statusValue, styles.error].join(' ')}>
                        Error ⛔️ Go to modify your subscription!
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box className={styles.statusRow}>
                  <Box className={styles.statusName}>Subscribed Since:</Box>
                  <Box className={styles.statusValue}>{userSubsc[0].created}</Box>
                </Box>

                <Box className={styles.statusRow}>
                  <Box className={styles.statusName}>Next Billing Date:</Box>
                  <Box className={styles.statusValue}>{userSubsc[0].next_billing_date}</Box>
                </Box>
                {distinctProductNames?.length > 1 && distinctSubscriptionsList}

              </Box>
            ) : (
              <Typography variant="h6" className={styles.emptyStatus}>
                🤠 Dadgummit!
                <Box>
                  <br />
                  Seems like you did not select your subscription yet!
                </Box>
              </Typography>
            )}
          </Box>

          <Box
            className={styles.bottom}
            onClick={() => {
              history.push("/planscheckout");
            }}>
            MODIFY MY SUBSCRIPTION
          </Box>
          {userSubscription?.length > 0 ? (
            <Box className={[styles.cancelButton, styles.disabledButton].join(' ')} onClick={() => setCancelOpen(true)}>
              CANCEL MY SUBSCRIPTION
            </Box>
          ) : (
            ""
          )}

          <ModifySubscriptionDialog
            {...{ userProfile, userSubscription, showMofifySubDialog, setShowMofifySubDialog }}
          />
        </Box>
      </Box>
      <CancelSubscriptionDialog {...{ cancelOpen, setCancelOpen, cancelSubscription, loading }} />
    </Box>
  );
};

export default UpdateSubBox;
