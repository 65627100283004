// React Components------------------------------------------>
import * as React from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

// AWS amplify Components------------------------------------>
import { API } from "aws-amplify";

// MUI Components-------------------------------------------->
import Divider from "@mui/material/Divider";
import makeStyles from "@mui/styles/makeStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// Layout Components------------------------->
import Theme from "../../../Theme/Theme";

// Sub Components--------------------------------------------------------->
import { Popup } from "../Card Details componenets/Popup";
import { AccordianPlusButton } from "./AccordianSubComponents/AccordianPlusButton";
import { AccordianInfoContianer } from "./AccordianSubComponents/AccordianInfoContainer";
import { AccordianButtonContianer } from "./AccordianSubComponents/AccordianButtonContainer";
import { AccordianRenewalContainer } from "./AccordianSubComponents/AccordianRenewalContainer";
import { LoadingSubscription } from "./AccordianSubComponents/SubscriptionInfo/LoadingSubscription";
import { SubscriptionInfoList } from "./AccordianSubComponents/SubscriptionInfo/SubscriptionInfoList";
import { AccordianDescriptionContainers } from "./AccordianSubComponents/AccordianDescriptionContainers";

export const AccordianComp = forwardRef(({ cardtitle, cardPrice, subscriptionid, confirmCancelSubscription, confirmUpdateSubscription, updatingID, cancelingID }, ref) => {
  // variables using react componenets----------------->
  const styles = useStyles();

  // useState variables--------------------------------->
  const [showDialogBox, setShowDialogBox] = useState(false);
  const [updateDialogBox, setUpdateDialogBox] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [error, setError] = useState();
  const [subidretrieved, setSubIdRetrieved] = useState();
  const [collectionmethode, setCollectionMethode] = useState();
  const [currency, setCurrency] = useState();
  const [customerID, setCustomerID] = useState();
  const [latestInvoice, setLatestInvoice] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState("");
  const [open, setOpen] = useState(false);
  const [autoRenowal, setAutoRenowal] = useState(true)
  const [cancelAtEnd, setCancelAtEnd] = useState(false)
  const [cancelAt, setCancelAt] = useState(null)

  const getSubscription = async (subscriptionid) => {
    setLoading(true);
    return await API.post("restApiProd", "/get-subscription-detail", {
      body: {
        subscriptionId: subscriptionid,
      },
    });
  };

  useImperativeHandle(ref, () => ({
    async reloadSubscription() {
      const response = await getSubscription(subscriptionid)
      const { data, status } = response;
      if (status === 200) {
        setAutoRenowal(!data.cancel_at_period_end)
        setCancelAtEnd(data.cancel_at_period_end)
        setCancelAt(data.cancel_at)
      }
    }
  }))

  useEffect(() => {

    if (subscriptionid) {
      getSubscription(subscriptionid).then((response) => {
        const { data, status } = response;
        if (status === 200) {
          setSubIdRetrieved(data.id);
          setCollectionMethode(data.collection_method);
          setCurrency(data.currency);
          setCustomerID(data.customer);
          setLatestInvoice(data.latest_invoice);
          const currentPeriod = new Date(data.current_period_end * 1000);
          setCurrentPeriodEnd(currentPeriod.toLocaleDateString());
          setAutoRenowal(!data.cancel_at_period_end)
          setCancelAtEnd(data.cancel_at_period_end)
          setCancelAt(data.cancel_at)
        } else {
          setSubscription([]);
          setError({ message: data });
        }
        setLoading(false);
      });
    }
  }, [subscriptionid]);

  /* updateSubscription Function------>
   * Deactivated at the moment */
  const updateSubscription = () => {
    setUpdateDialogBox(true)
    // setShowDialogBox(false);
  };

  /* cancelCancelingSub Function--------------------------------------------->
   * When triggered will close the Dialog box for canceling the subscription */
  const cancelCancelingSub = () => {
    setShowDialogBox(false);
  };

  /* ConfirmSubCancelation Function--------------------------------------------------->
   * When triggered will prompt the user to confirm or cancel the canceling of the selected subscription
   * Contains the confirmCancelSubscription from props that takes in the subidretrieved variable that contains the
   * sub id required to cancel the subscription */
  const ConfirmSubCancelation = () => {
    confirmCancelSubscription(subidretrieved);
    setShowDialogBox(false);
  };

  return (
    <React.Fragment>
      <Accordion ref={ref} className={styles.striptest} expanded={open} style={{ marginBottom: 20 }}  >
        <AccordionSummary
          // expandIcon={<AccordianPlusButton setOpen={setOpen} open={open} />}
          expandIcon={null}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <div className={styles.strip}>
            <AccordianInfoContianer
              cardtitle={cardtitle}
              cardPrice={cardPrice}
              cancelAt={cancelAt}
            />
            <AccordianRenewalContainer
              autoRenowal={autoRenowal}
              onRenowalChange={(value) => { setAutoRenowal(value) }}
              currentPeriodEnd={currentPeriodEnd}
            />
            <AccordianButtonContianer
              cancelLoading={cancelingID === subscriptionid}
              updateLoading={updatingID === subscriptionid}
              onUpdate={updateSubscription}
              onCanceled={() => setShowDialogBox(true)}
              cancelAtEnd={cancelAtEnd}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.monthlyYearlyTable}>
            <Divider className={styles.line} />
            {loading ? (
              <LoadingSubscription />
            ) : (
              <>
                <SubscriptionInfoList
                  subidretrieved={subidretrieved}
                  customerID={customerID}
                  collectionmethode={collectionmethode}
                  latestInvoice={latestInvoice}
                  currency={currency}
                  currentPeriodEnd={currentPeriodEnd}
                />
              </>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      {showDialogBox && (
        <Popup
          title={"Are you sure you want to cancel your subscription?"}
          description={"No refunds are given but removing a product stops renewal"}
          primaryButtonLabel={"Cancel my Subscriptions"}
          secondaryButtonLabel={"Cancel"}
          onSecondaryButtonClick={cancelCancelingSub}
          onPrimaryButtonClick={ConfirmSubCancelation}
        // onClosePopup={handleClosePopup}
        />
      )}
      {updateDialogBox && (
        <Popup
          title={"Are you sure, you want to update subscription?"}
          description={"Your current subscription will be changed"}
          primaryButtonLabel={"Yes, update it!"}
          secondaryButtonLabel={"Cancel"}
          onSecondaryButtonClick={() => { setUpdateDialogBox(false) }}
          onPrimaryButtonClick={() => {
            confirmUpdateSubscription({
              subscriptionId: subidretrieved,
              autoRenew: autoRenowal,
            })
            setUpdateDialogBox(false)
          }}
        // onClosePopup={handleClosePopup}
        />
      )}
    </React.Fragment>
  );
});

/* AccordianComp Component Styling-------------------->
 * Contains Styling for the component AccordianComp
 * Uses MUIs makestyles method to create styling */
const useStyles = makeStyles((theme) => ({
  descriptionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },

  summary: { paddingBottom: 30 },
  accordiansummery: {
    margin: 0,
  },
  Canceltitle: {
    width: 220,
    height: 22,
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: 600,
    fontSize: 18,
    // lineHeight: 22,
    color: Theme.colors.cancelButtonTextColor,
    paddingLeft: 14,
  },

  updateButton: {
    width: 108,
    height: 60,
    border: "1px solid",
    borderColor: Theme.colors.cancelButtonBorderColor,
    // marginLeft: 178,
  },
  line: {
    marginBottom: 25,
  },

  tableRowOne: {
    marginBottom: 18,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: 37,
  },
  tableRowTwo: {
    marginBottom: 18,
  },
  tableRowThree: {
    marginBottom: 18,
  },
  buttontitle: {
    width: 54,
    height: 14,
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: 400,
    fontSize: 15,
    lineHeight: Theme.fonts.lineHeightsTwo,
    letterSpacing: Theme.letterSpacings.letterSpacingOne,
    color: Theme.colors.black,
    paddingLeft: 34,
    marginTop: 10,
  },

  cancelButton: {
    width: 248,
    height: 60,
    border: "1px solid",
    borderColor: Theme.colors.cancelButtonBorderColor,
    marginLeft: 10,
  },

  toggleThreeText: {
    width: 49,
    height: 17,
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: 400,
    fontSize: 18,
    lineHeight: Theme.fonts.lineHeightsTwo,
    letterSpacing: Theme.letterSpacings.letterSpacingOne,
    color: Theme.colors.black,
    marginLeft: 20,
  },

  dateText: {
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.medium,
    fontSize: Theme.fonts.smallplus,
    lineHeight: Theme.fonts.lineHeightsTwo,
    letterSpacing: Theme.letterSpacings.letterSpacingOne,
    color: Theme.colors.black,
  },
  packegeRenewalDate: {
    width: 94,
    height: 17,
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
  },
  titletext: {
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.small,
    fontSize: Theme.fonts.smaller,
    lineHeight: Theme.fonts.lineHeightsTwo,
    letterSpacing: Theme.letterSpacings.letterSpacingOne,
    color: Theme.colors.lightHeadingColor,
  },
  packegeRenewalTitle: {
    width: 150,
    height: 14,
  },

  buttonTitle: {
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.small,
    fontSize: Theme.fonts.smaller,
    lineHeight: Theme.fonts.lineHeightsTwo,
    letterSpacing: Theme.letterSpacings.letterSpacingOne,
    color: Theme.colors.black,
    marginTop: 11,
  },

  plusButtonContainer: {
    marginTop: 14,
    marginLeft: 23,
    alignItems: "center",
    justifyContent: "center",
    width: "7%",
  },

  buttonContainerUpdate: {
    width: "30%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingtop: 111,
  },

  strip: {
    width: "100%",
    backgroundColor: Theme.colors.inputBackgroundColor,
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down(396)]: {
      width: "66%",
    },
    [theme.breakpoints.down(539)]: {
      width: "66%",
    },
  },

  striptest: {
    width: "100%",
    backgroundColor: Theme.colors.inputBackgroundColor,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
}));
