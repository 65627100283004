import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { timeAgo } from "../../utils";
import { Caption, H3, H4, LabelRegular, Subtitles, SubtitlesRegular } from "../Text";

const useStyles = makeStyles((theme) => ({
  // header: {
  //   fontWeight: 500,
  //   color: "#000",
  //   [theme.breakpoints.down(600)]: {
  //     textAlign: "left",
  //   },
  // },
  header: {
    marginTop: 0,
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem !important',
      marginBottom: '1.25rem',
    },
  },
  container: {
    border: "0.735px solid #D3D3D3",
    padding: '37px 45px',
    [theme.breakpoints.down(1100)]: {
      maxWidth: "100%",
      marginBottom: "18px",
      padding: '27px 18px',
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 20,
  },
  boxOneTitle: {
    fontWeight: "bold",
    margin: "19px 0 10px 28px",
    fontSize: "23px",
    letterSpacing: "0.17px",
    lineHeight: "24px",
  },
  boxOneContent: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    // margin: "40px 30px",
  },
  suggestedActionContainer: {
    flex: "1",
    marginRight: "50px",
    maxWidth: "450px",
    [theme.breakpoints.down(1100)]: {
      marginRight: "0",
    },
  },
  boxPara: {
    margin: "20px 25px",
    fontSize: "20px",
  },
  content: {
    color: "#858585",
    lineHeight: '30px !important',
    letterSpacing: "-0.03em"
  },
  boxFooter: {
    textAlign: "right",
    marginTop: 25,
    marginBottom: 0,
    color: "grey",
  },
}));

const TrafficLight = ({ data, latestMomoRecord }) => {
  const styles = useStyles();

  const barData = useMemo(() => {
    if (data?.trafficLightMap === 1) {
      return (
        <>
          <div style={{ width: "80%" }} className="green"></div>
          <div style={{ width: "10%", backgroundColor: "#F9EECC" }} className="yellow"></div>
          <div style={{ width: "10%", backgroundColor: "#E9CFCF" }} className="red"></div>
        </>
      );
    } else if (data?.trafficLightMap === 2) {
      return (
        <>
          <div style={{ width: "10%", backgroundColor: "rgb(155 187 155)" }} className="green"></div>
          <div style={{ width: "80%" }} className="yellow"></div>
          <div style={{ width: "10%", backgroundColor: "rgb(209 177 177 / 46%)" }} className="red"></div>
        </>
      );
    } else {
      return (
        <>
          <div style={{ width: "10%", backgroundColor: "#DBEFDC" }} className="green"></div>
          <div style={{ width: "10%", backgroundColor: "#F9EECC" }} className="yellow"></div>
          <div style={{ width: "80%" }} className="red"></div>
        </>
      );
    }
  }, [data?.trafficLightMap]);

  return (
    <div>
      {/* <Typography mb={4} variant="h3" className={styles.header}>
        GFY Suggested Action
      </Typography> */}
      <H3 className={styles.header}>Our Indicator</H3>
      <Box className={styles.container}>
        <Box>
          {/* <Typography variant="h1">
            {data?.header}
          </Typography> */}
          <H4 className={styles.title}>GFY Suggested Action</H4>
        </Box>
        <Box>
          <div className="barContainer">
            <div className="bar">{barData}</div>
          </div>
        </Box>
        {/* <Box className={(styles.boxPara, styles.boxOneContent)}>
          <p> {data?.text}</p>
        </Box> */}
        <LabelRegular p className={styles.content}>{data?.text}</LabelRegular>
        <SubtitlesRegular p className={styles.boxFooter}>Last updated : {timeAgo(latestMomoRecord?.date)}</SubtitlesRegular>
        {/* <Box className={styles.boxFooter}>Last updated : {timeAgo(latestMomoRecord?.date)}</Box> */}
      </Box>
    </div>
  );
};

export default TrafficLight;
