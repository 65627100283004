import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import BaseModal from "../../modals/BaseModal";
import CancelSubscriptionDialog from "./cancel-subscription-dialog";
const useStyles = makeStyles((theme) => ({
  container: {
    height: "120px",
    width: "100%",
    borderRadius: "4px",
    boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
  },
  bottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #1E4620",
    cursor: "pointer",
    color: "#1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  activeBottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    border: "1px solid #1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  top: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  topText: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: 500,
    fontSize: "14px",
  },
  amount: {
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
    fontSize: "14px",
    marginTop: "4px",
  },
  subscriptionContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
}));

export const Subscription = (props) => {
  const { subscription, index } = props;
  const { productName } = subscription;
  const styles = useStyles();
  const [Open, setOpen] = useState(false);

  return (
    <Box className={styles.container} key={index}>
      <Box color="primary.contrastText" className={styles.top}>
        <Typography variant="div" className={styles.topText}>
          Active Subscription
        </Typography>
        <Typography variant="div" className={styles.amount}>
          {productName}
        </Typography>
      </Box>
      <Box
        className={styles.activeBottom}
        onClick={() => {
          setOpen(true);
        }}>
        Cancel Subscription
      </Box>
      <BaseModal
        onClose={() => {
          setOpen(false);
        }}
        open={Open}>
        <CancelSubscriptionDialog subscription={subscription} setOpen={setOpen} />
      </BaseModal>
    </Box>
  );
};
