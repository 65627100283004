import React, { useContext } from "react";
import { Plan } from "./plan";
import makeStyles from "@mui/styles/makeStyles";
import { v4 } from "uuid";
import { AppContext } from "../../../context";

const useStyles = makeStyles((theme) => ({
  planContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "8px",
  },
}));

export const PlanList = () => {
  const { params } = useContext(AppContext);
  let plans = JSON.parse(params?.find((param) => param.key === "stripe")?.value)?.plans ?? [];

  const styles = useStyles();
  return (
    <div className={styles.planContainer} key={v4()}>
      {plans &&
        plans.map((eachPlan) => (
          <Plan key={v4()} text={eachPlan.text} amount={eachPlan.amount} priceId={eachPlan.priceId} />
        ))}
    </div>
  );
};
