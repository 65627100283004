import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AuthContext } from "../../context";
import CardsAcceptdBox from "../../components/login/CardsAcceptdBox";
import PricingCard from "../../components/PricingCard";

const useStyles = makeStyles((theme) => ({
  h2: {
    color: "#fff",
    fontFamily: "Coolvetica",
    fontSize: "70px",
    fontWeight: "400",
    letterSpacing: "0",
    lineHeight: "97.5%",
    padding: "120px 0 75px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: 0,
      paddingBottom: 10,
      fontSize: "60px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  offerHeader: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "32px",
    marginBottom: "20px",
  },
  offerText: {
    color: "#858585",
    fontFamily: "Inter",
    fontSize: "25px",
    fontWeight: "500",
    letterSpacing: "-0.03em",
    lineHeight: "165.52%",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
  gridRoot: {
    marginTop: "-350px",
    // flexWrap: "nowrap",
    alignItems: "center",
    // justifyContent: "space-around",

    paddingTop: 8,
    [theme.breakpoints.down("lg")]: {
      marginTop: "-650px",
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up(2400)]: {
      marginTop: "-320px",
    },
    [theme.breakpoints.down(1920)]: {
      marginTop: "-380px",
    },
    "& > div": {
      zIndex: 2,
    },
  },
  customGrid: {
    overflowX: "auto",
  },
  table: {
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
    },
  },
  containers: {
    // height: "320px",
    maxHeight: "320px",
    width: "100%",
    borderRadius: "4px",
    marginTop: "73px",
    marginRight: "18px",
    display: "flex",
    flexDirection: "column",
    fontSize: 16,
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  row: {
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    display: "flex",
    flexDirection: "row",
    alignContent: "space-between",
    height: 52,
  },
  col3: {
    width: "25%",
    textAlign: "center",
    marginTop: 16,
  },
  col4: {
    width: "25%",
    textAlign: "center",
    marginTop: 16,
  },
  col5: {
    minWidth: "100px",
    textAlign: "center",
    marginTop: 16,
    padding: "0px 10px",
    width: "150px",
    "&:nth-child(1)": {
      minWidth: "200px",
    },
  },
  subscriptionbtn: {
    borderRadius: 4,
    height: 32,
    width: 105,
    display: "inline-flex",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #1E4620",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: 500,
    backgroundColor: "transparent",
    alignSelf: "center",
    color: "#1E4620",
    textTransform: "uppercase",
    "&:hover": {},
  },

  subscriptionTextDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "30px",
    [theme.breakpoints.down(1536)]: {
      gap: "10px",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  offerWrapper: {
    [theme.breakpoints.down("lg")]: {
      backgroundColor: " #000000bd",
      maxWidth: 500,
      padding: 40,
      minHeight: 220,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: 60,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
}));

const Plan = () => {
  const styles = useStyles();
  const history = useHistory();
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Box id="pricing" pb={5}>
      <Grid className={styles.gridRoot} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} lg={3} xl={4}>
          <Box className={styles.offerWrapper}>
            <Typography variant="h2" className={styles.h2}>
              Ready to get started?
            </Typography>
            <Typography variant="p" className={styles.offerText}>
              Commodities we currently offer: Corn, Soybeans, HRW, MPLS, Cotton Soybean Meal
            </Typography>
          </Box>
        </Grid>
        <Grid className={styles.customGrid} item xs={12} lg={9} xl={8}>
          <Box component="div" className={styles.subscriptionTextDiv}>
            <PricingCard
              title="Just One"
              description="Subscribe to one crop with real-time text alerts."
              price={999.99}
              actionButtonText="Sign up"
              hideActionButtons={isLoggedIn ? true : false}
              onActionClick={() => history.push("/login-new")}
            />
            <PricingCard
              title="All Grain"
              description="Subscribe to all three crops with real-time text alerts."
              content="Corn, Soybeans, HRW, MPLS, Cotton Soybean Meal"
              price={1999.99}
              actionButtonText="Sign up"
              hideActionButtons={isLoggedIn ? true : false}
              onActionClick={() => history.push("/login-new")}

            />
          </Box>
        </Grid>
      </Grid>
      {/* <Grid item>
        <CardsAcceptdBox />
      </Grid> */}
    </Box>
  );
};

export default Plan;
