// React Component-------------------------------->
import React, { useState, useRef } from "react";
import { Auth, API } from "aws-amplify";

// import config which is inside src folder
import config from "../../../config.js";
import axios from "axios";

// MUI Components--------------------------------->
import makeStyles from "@mui/styles/makeStyles";
import { Button } from "@mui/material";

// Stripe Components------------------------------>
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";

// Layout Components------------------------------->
import Theme from "../../../Theme/Theme";
import { H4, Label } from "../../../components/Text";

// others------------------------------------------>
import Swal from "sweetalert2";


export const PaymentView = ({ clientSecret, submitRef, openConfirmPopup }) => {
  // variables using react components--------------->
  const stripe = useStripe();
  const elements = useElements();
  const styles = useStyles();
  const [zipCode, setZipCode] = useState("");


  // useState variables------------------------------>
  const formRef = useRef(null);
  const [paymentIntent, setPaymentIntent] = useState();

  /* Condition to check Stripe.js
   * Stripe.js has not loaded yet. Make sure to disable
   * form submission until Stripe.js has loaded.*/
  if (!stripe || !elements) {
    return "";
  }

  const handleZipCodeChange = (e) => {

    setZipCode(e.target.value);
  };

  const updateHubspotUserDetails = async (zipCode) => {

    const user = await Auth.currentAuthenticatedUser();
    const email = user.attributes.email;
    const token = user.signInUserSession.idToken.jwtToken;

    const env_var = process.env.REACT_APP_NODE_ENV;

    try {
      const payload = {
        email: email,
        zipCode: zipCode,
        env: env_var,
      };
      const hubspot_url_key = "HUBSPOT_URL_" + env_var.toUpperCase();
      const hubspot_url = config[hubspot_url_key];
      const headers = {
        Authorization: token,
      };
      // console.log("Hubspot payload", payload);
      const response = await axios.post(hubspot_url + "/UpdateContact", payload, { headers });
      // console.log("Hubspot response", response);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  /* When the subscribe-form is submitted we do a few things:---------->
   * Tokenize the payment method
   * Create the subscription
   * Handle any next actions like 3D Secure that are required for SCA. */
  const submitPaymentForm = async (e) => {

    e.preventDefault();

    // console.log("zip code", zipCode)

    updateHubspotUserDetails(zipCode);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    
    const cardElement = elements.getElement(CardNumberElement);


    // console.log("clientSecret", clientSecret)

    if (clientSecret === undefined) {
    console.log("clientSecret", clientSecret)
    }
    if (clientSecret === undefined) {
      console.log("clientSecret is undefined")
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Client SDK not initialized correctly issue",
      });
      return;
    }

    if (clientSecret === "") {
      console.log("clientSecret is empty")
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Client SDK not initialized correctly issue",
      });
      return;
    }

    if (clientSecret === undefined || (typeof clientSecret === 'object' && Object.keys(clientSecret).length === 0)) {
      console.log("clientSecret is object and empty")
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Client SDK not initialized correctly issue",
      });
      return;
    }

    if (!clientSecret.startsWith("pi_")) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: clientSecret,
      });
      return;
    }


    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {},
      },
    });

    if (error) {
      // show error and collect new card details.
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
      return;
    }
    setPaymentIntent(paymentIntent);
  };

  /* Payment Intent Control flow------------------------------->
   * payment intent will confirm that payment was successfull or
   * if it is sucessfull props method is set to true */
  if (paymentIntent && paymentIntent.status === "succeeded") {
    // Swal.fire({
    //   title: paymentIntent.description,
    //   text: "You have successfully subscribed to product.",
    //   icon: "success",
    //   showCancelButton: true,
    //   confirmButtonText: "Show my subscription!",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //   }
    // });
    openConfirmPopup(true);
  }

  return (
    <div className={styles.Container}>
      <div className={styles.formTitleContainer}>
        <H4 className={styles.heading}>Add Payment Details</H4>
      </div>

      <form className={styles.theCardForm} ref={formRef} onSubmit={submitPaymentForm}>
        <Label className={styles.label}>Card Number:</Label>
        <CardNumberElement className={styles.cardNumberInput} id="cardNumber" onChange={(event) => {}} />
        <Label className={styles.label}>Valid Thru:</Label>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <CardExpiryElement
            className={styles.cardSecurityInput}
            id="expiry"
            onChange={(event) => {
              console.log("expiry date");
            }}
          />
          <CardCvcElement className={styles.cardSecurityInput} id="cvc" onChange={(event) => {}} />
          
        </div>

        <Label className={styles.label}>Card Holder's Name:</Label>
        <input className={styles.cardHoldersNameInput} placeholder="Name on card" />
        <Label className={styles.label}>Zip Code:</Label>
        <input className={styles.zipInput} placeholder="Zip Code" value={zipCode} onChange={handleZipCodeChange} />
        <Button ref={submitRef} type="submit" style={{ display: "none" }}></Button>
      </form>
    </div>
  );
};

/* PaymentView Component Styling------------------->
 * Contains Styling for the component PaymentView
 * Uses MUIs makestyles method to create styling */
const useStyles = makeStyles((theme) => ({
  Container: {
    backgroundColor: Theme.colors.grayCardColor,
    padding: 40,
  },
  heading: {
    margin: 0,
    marginBottom: 40,
  },
  label: {
    marginBottom: 12,
  },
  cardNumberLabel: {
    height: 22,
    textAlign: "center",
    color: Theme.colors.black,
    display: "flex",
    alignItems: "center",
  },
  cardNumberInput: {
    paddingLeft: 12,
    flex: 1,
    height: 30,
    background: Theme.colors.inputBackgroundColor,
    border: "1px solid rgba(10, 10, 10, 0.12)",
    marginBottom: 30,
    paddingTop: 15,
  },
  cardSecurityInput: {
    paddingLeft: 12,
    width: "25%",
    height: 30,
    background: Theme.colors.inputBackgroundColor,
    border: "1px solid rgba(10, 10, 10, 0.12)",
    marginBottom: 10,
    marginRight: 10,
    paddingTop: 15,
  },
  validThruLabel: {
    width: 88,
    height: 22,
    fontFamily: Theme.fontfamily.Roboto,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.medium,
    fontSize: Theme.fonts.smallplus,
    lineHeight: Theme.fonts.lineHeightsOne,
    textAlign: "center",
    color: Theme.colors.black,
    display: "flex",
    alignItems: "center",
  },
  validThruInput: {
    paddingLeft: 6,
    width: 156,
    height: 60,
    background: Theme.colors.inputBackgroundColor,
    border: "1px solid rgba(10, 10, 10, 0.12)",
    marginRight: 18,
    marginBottom: 20,
  },
  cardHoldersNameLabel: {
    width: 171,
    height: 22,
    fontFamily: Theme.fontfamily.Roboto,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.medium,
    fontSize: Theme.fonts.smallplus,
    lineHeight: Theme.fonts.lineHeightsOne,
    display: "flex",
    alignItems: "center",
    color: Theme.colors.black,
  },
  cardHoldersNameInput: {
    width: 500,
    height: 60,
    background: Theme.colors.inputBackgroundColor,
    border: "1px solid rgba(10, 10, 10, 0.12)",
    marginBottom: 20,
    paddingLeft: 12,
  },

  formTitleContainer: {
    width: "100%",
  },
  formTitle: {
    width: 213,
    height: 28,
    fontFamily: Theme.fontfamily.Roboto,
    fontWeight: Theme.fontWeight.small,
    fontSize: "29.434px",
    lineHeight: Theme.fonts.lineHeightsTwo,
    color: Theme.colors.black,
    marginTop: 15,
  },
  // add style for zip
  zipInput: {
    
    width: 500,
    height: 60,
    background: Theme.colors.inputBackgroundColor,
    border: "1px solid rgba(10, 10, 10, 0.12)",
    marginBottom: 20,
    paddingLeft: 12,
    },

}));
