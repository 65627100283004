import { useState } from "react";
import { TextField, Box, Collapse, IconButton, Alert } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack, Close } from "@mui/icons-material";
import { Auth } from "aws-amplify";
import Button from "../Button";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: "14px",
  },
  button: {
    fontWeight: 500,
  },
  backButton: {
    color: "#1E4620",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "22px",
    lineSpacing: "0.37px",
  },
  backContainer: {
    cursor: "pointer",
  },
  buttonStyle: {
    color: "#001F00",
  },
}));

const SendCode = ({ setCurrentScreen }) => {
  const styles = useStyles();

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);

  const handleSendCode = async () => {
    if (!email) {
      setEmailError(true);
      return;
    }

    try {
      await Auth.forgotPassword(email);
      setCurrentScreen("resetPass");
    } catch (e) {
      setApiError(e.message);
      setShowApiError(true);
    }
  };

  return (
    <>
      <Collapse in={showApiError}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowApiError(false);
              }}>
              <Close fontSize="inherit" />
            </IconButton>
          }>
          {apiError}
        </Alert>
      </Collapse>
      <Box
        display="flex"
        alignItems="center"
        mb={3}
        onClick={() => setCurrentScreen("login")}
        className={styles.backContainer}>
        <ArrowBack /> <Box className={styles.backButton}>BACK</Box>
      </Box>
      <Box mt={1} />
      Enter the email associated with your account and we’ll send a text or email with a confirmation code to reset your
      password. By Entering your email in this box, you agree to receive text messages from GFY. These messages may
      include updates, news, promotions, and more. Standard messaging rates may apply. You can unsubscribe at any time
      by following the instructions provided in our messages. For more information, please review our Privacy Policy.
      {/*              
      Enter the email associated with your account and we’ll send an email with a confirmation code to reset your
      password. By Entering your email in this box, you agree to receive messages from GFY. 
      These messages may include updates, news, promotions, and more. You can unsubscribe at any time by following the instructions provided in our messages. For more information, please review our Privacy Policy.
       */}
      <TextField
        id="email"
        // label="E-mail or mobile number"
        label="E-mail"
        type="text"
        fullWidth
        required
        value={email}
        error={emailError}
        // helperText={emailError ? "Please enter your email address or mobile number." : ""}
        helperText={emailError ? "Please enter your email address." : ""}
        className={styles.form}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Box mt={8}>
        <Button
          onClick={() => handleSendCode()}
          fullWidth
          className={styles.buttonStyle}
          classes={{ label: styles.button }}
          label="Send Me Code"
        />
      </Box>
    </>
  );
};

export default SendCode;
