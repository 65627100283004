import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, Chip, Stack, useMediaQuery, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import { orderBy } from "lodash";
import dayjs from "dayjs";
import { formatCurrency } from "../../utils/formatting";
import TradeLedgerTableV2 from "./TradeLedgerTableV2";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: -120,
      right: -5,
    },
  },
  accordionSummary: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

function CropTradesSummary({ openPositions, positionSummaries, trades }) {
  const classes = useStyles();
  const sortedPositionSummaries = orderBy(positionSummaries, (x) => dayjs(x.expirationDate).toDate(), "desc");
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {sortedPositionSummaries.length === 0 ? (
        <Typography variant="h6" sx={{ textAlign: "center", padding: "16px" }}>
          No trades at this time.
        </Typography>
      ) : (
        sortedPositionSummaries.map((summary) => {
          return (
            <Accordion key={summary.frSymbol} className={isSmallScreen ? classes.accordionSummary : ''}>
              <AccordionSummary expandIcon={<div className={classes.iconWrapper}><ExpandMoreIcon /></div>} >
                <Stack direction={isSmallScreen ? "column" : "row"} spacing={3} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                  <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                    <Chip label={summary.frSymbol} color="primary" sx={{ marginRight: isSmallScreen ? 1 : 3 }} />
                    <Typography sx={{ marginRight: isSmallScreen ? '16px' : 'unset' }}>{summary.instrumentDescription}</Typography>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between", width: isSmallScreen ? "100%" : "auto" }}>
                    <Paper sx={{ padding: "6px", textAlign: "center", minWidth: "120px", maxWidth: "200px", marginRight: 3 }}>
                      <Typography variant="h6">Long</Typography>
                      <Typography variant="h1" sx={isSmallScreen ? { fontSize: '1.5rem !important' } : {}}>{summary.openLongQuantity ? summary.openLongQuantity : "--"}</Typography>
                    </Paper>

                    <Paper sx={{ padding: "6px", textAlign: "center", minWidth: "120px", maxWidth: "200px" }}>
                      <Typography variant="h6">Short</Typography>
                      <Typography variant="h1" sx={isSmallScreen ? { fontSize: '1.5rem !important' } : {}}>{summary.openShortQuantity ? summary.openShortQuantity : "--"}</Typography>
                    </Paper>
                  </Box>

                  <Paper elevation={4} sx={{ padding: "8px", textAlign: "center", minWidth: "120px", maxWidth: isSmallScreen ? "unset" : "200px", width: isSmallScreen ? "calc(100% - 16px)" : "auto" }}>
                    <Typography variant="h6">Profit & Loss</Typography>
                    <Typography variant="h1" sx={isSmallScreen ? { fontSize: '1.5rem !important' } : {}}>{formatCurrency(summary.totalGainLossValue)}</Typography>
                  </Paper>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <TradeLedgerTableV2 trades={trades.filter((x) => x.frSymbol === summary.frSymbol)}></TradeLedgerTableV2>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </Paper>
  );
}

export default CropTradesSummary;
