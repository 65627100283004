import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Label,
  ResponsiveContainer,
} from "recharts";
import CropErrorModal from "../modals/CropErrorMoal";
import { years } from "../../utils/quarters";
import { formatAxis } from "../../utils";
import { ReactComponent as LoadingIcon } from "../../assets/spinner_grey.svg";
import Theme from "../../Theme/Theme";
import { H2 } from "../Text";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 500,
    color: "#000",
    [theme.breakpoints.down(600)]: {
      textAlign: "left",
    },
  },
  chartYears: {
    justifyContent: "space-evenly",
    padding: "25px",
    [theme.breakpoints.down(500)]: {
      justifyContent: "flex-start",
      marginLeft: "0",
    },
  },
  chartYear: {
    [theme.breakpoints.down(500)]: {
      margin: "15px",
      fontSize: "22px",
    },
  },
  historicChartText: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.1px",
    lineHeight: "22px",
  },
  date: {
    color: "#B0BAC9",
    fontSize: "15px",
    cursor: "pointer",
  },
  dateActive: {
    color: "#1976D2",
    fontSize: "15px",
    cursor: "pointer",
  },
  cropGraphContainer: {
    position: "relative",
    display: "flex",
    height: "540px",
    width: "100%",
    margin: "15px",
    [theme.breakpoints.down(1200)]: {
      width: "100%",
      height: "auto",
    },
  },
  ResponsiveGraph: {
    height: "540px !important",
    [theme.breakpoints.down(992)]: {
      height: "400px !important",
    },
    [theme.breakpoints.down(768)]: {
      height: "350px !important",
    },
  },
  container: {
    textAlign:'center',
    paddingTop: "5%",
    paddingBottom: "5%",
    [theme.breakpoints.down('sm')]: {
      marginTop: '4.5rem',
    }
  },
  heading: { 
    color: Theme.colors.white,
    marginBottom: '5%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem !important',
      marginBottom: 'unset',
      marginTop: '5rem',
    },
  }
}));

const GFYSellsGraph = ({ cropType, selectedQuarter, loading, errorMessage, onGetData, lineChartData }) => {
  const styles = useStyles();

  const graphPredictionValues = lineChartData.map((x) => x.close).filter((y) => typeof y === "number");

  let minInitial = Number(Math.min(...graphPredictionValues).toFixed(0)) - 50;
  let minDomain = Math.max(0, minInitial);
  let maxDomain = Number(Math.max(...graphPredictionValues).toFixed(0)) + 50;

  let SaleWithGfy = lineChartData && lineChartData.map((x) => x.avg_sale_with_gfy).filter((y) => typeof y === "number");
  let avgSaleWithGfy = SaleWithGfy.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(3);

  let saleWithoutGfy =
    lineChartData && lineChartData.map((x) => x.avg_sale_without_gfy).filter((y) => typeof y === "number");
  let avgSaleWithoutGfy = saleWithoutGfy.reduce((acc, v, i, a) => acc + v / a.length, 0).toFixed(3);

  return (
    <div style={{ backgroundColor: Theme.colors.greenCardColor }}>
      {/* <Typography mb={4} variant="h3" className={styles.header}>
        Historic {cropType.toLowerCase().charAt(0).toUpperCase() + cropType.toLowerCase().slice(1)} Price and GFY Sells
        Chart
      </Typography>
      <p className={styles.historicChartText}>
        Charted below are historical commodity prices and overlayed are the GFY sell signals from the Stoplight.
        Averaged together these signals make the dashed green line, compared to the dotted blue line for average market
        price.
      </p> */}
      <Container className={styles.container}>
        <H2 className={styles.heading}>Weekly Pivots</H2>
        <Grid container className={styles.chartYears}>
          {years.map((year, index) => (
            <Grid key={`${year}+${index}`} item className={styles.chartYear}>
              <Box
                className={year === selectedQuarter ? styles.dateActive : styles.date}
                onClick={() => onGetData(year, cropType)}>
                {year}
              </Box>
            </Grid>
          ))}
        </Grid>
        <div className={styles.cropGraphContainer}>
          {loading ? (
            <LoadingIcon />
          ) : (
            <>
              <CropErrorModal open={!!errorMessage} message={errorMessage} />
              <ResponsiveContainer className={styles.ResponsiveGraph} width="95%">
                <LineChart data={lineChartData}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    dataKey="date"
                    dy={5}
                    tickFormatter={formatAxis}
                    tickLine={false}
                    angle={-15}
                    textAnchor="end"
                    style={{ fontSize: "10px" }}>
                    <Label value="Date" offset={-35} position="insideBottom" />
                  </XAxis>
                  <YAxis type="number" domain={[minDomain, maxDomain]} tickLine={false}>
                    <Label value="Daily Closing Price" position="insideLeft" angle="-90" />
                  </YAxis>
                  <Tooltip />
                  <ReferenceLine y={avgSaleWithGfy} stroke="#93C47D" strokeWidth={4} strokeDasharray="25 20" />
                  <ReferenceLine y={avgSaleWithoutGfy} stroke="#45818E" strokeWidth={3} strokeDasharray="5 4" />
                  <Line type="monotone" dataKey="close" stroke="#1976D2" dot={0} activeDot={{ r: 6 }} strokeWidth={2} />
                  <Line
                    type="monotone"
                    dataKey="gfy_sell_val"
                    stroke=" #DD2C00"
                    // fill="#DD2C00"
                    activeDot={{ r: 0 }}
                    dot={{ r: 6 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </>
          )}
        </div>
        <Box className="main-box">
          <Box className="box">
            <div className="striped-border"></div>
            <p className="boxone">
              Average sell price with GFY : <b>$ {loading ? "0.000" : avgSaleWithGfy} </b>
            </p>
          </Box>
          <Box className="box">
            <div className="striped-borderTwo"></div>
            <p className="boxone">
              Average sell price without GFY : <b>$ {loading ? "0.000" : avgSaleWithoutGfy}</b>
            </p>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default GFYSellsGraph;
