import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Theme from "../../Theme/Theme";
import { Subtitles } from "../Text";
import { CircularProgress } from "@mui/material";

export const GFYButton = ({ label, icon, iconWidth, iconHeigt, onClick, isPrimary = true, styleProps, loading = false, disabled = false }) => {
  const styles = useStyles();
  return (
    <button
      className={styles.buttonContainer}
      onClick={onClick}
      style={{
        ...styleProps,
        backgroundColor: disabled ? Theme.colors.grayCardColor
          : isPrimary ? Theme.colors.YellowButtonColor : "transparent",
        cursor: disabled ? 'not-allowed': 'inherit',
        border: `0.735849px solid ${Theme.colors.subtitleGrey}`,
        opacity: loading ? 0.5 : 1
      }}
      disabled={disabled || loading}
    >
      <Subtitles className={styles.label}>{label}</Subtitles>
      {icon && <img src={icon} style={{ width: iconWidth, height: iconHeigt }} alt='' />}
      {loading && <CircularProgress style={{ height: 18, width: 18 }} color={'inherit'} />}
    </button>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: 15
  },
  buttonContainer: {
    fontFamily: Theme.fontfamily.Coolvetica,
    backgroundColor: Theme.colors.YellowButtonColor,
    border: "none",
    color: Theme.colors.black,
    padding: "15px 42px",
    textAlign: "center",
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: Theme.fonts.small,
    margin: "4px 2px",
    cursor: "pointer",
    '&:active': {
      transform: "translate(2px, 2px)",  // the button will move slightly down and right when active
    },
    transition: "0.2s all",  // to animate the moving effect
  },
}));




// export default GFYButton;
