import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Container } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '2%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4.5rem'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  card: {
    padding: 25,
    [theme.breakpoints.down('sm')]: {
      padding: 18,
      paddingRight: 0,
    }
  },
  cardImage: {
    width: '100%',
    height: 250,
  },
  btnViewAll: {
    height: 40
  }
}));

const FeaturedPosts = () => {

  const styles = useStyles();
  const history = useHistory();

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // fetch featured posts from DropInBlog
    fetchPosts();
  }, [])

  const fetchPosts = async () => {
    await axios.get('https://api.dropinblog.com/v1/json/?b=a236ac05-cded-4f8b-8055-30bd74a717a7&category=features&limit=3')
      .then((payload) => {
        if (payload?.data.data?.posts) {
          setPosts(payload?.data?.data?.posts);
        }
      })
      .catch((err) => console.log("unable to fetch featured posts", err));
  }

  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 style={{ flex: 1 }}>Our Blog</h2>
          <Button
            variant="outlined"
            size="medium"
            className={styles.btnViewAll}
            onClick={() => {
              const origin = window.location.origin;
              window.location = `${origin}/blog`;
            }}>
            View all
          </Button>
        </div>
        <Grid container spacing={2}>
          {posts && posts?.map((item, index) => {
            var post = item;
            // console.log("Post", posts);
            return (
              <Grid justifyContent={'center'} className={styles.card} sm={12} md={4}>
                <img src={post?.featuredImage} className={styles.cardImage} />
                <p>Soyabean | Nov 20, 2023</p>
                <h2>{post?.title}</h2>
                <p>{post?.summary}</p>
                <a href={`/blog/post/${post?.slug}`}>View Post</a>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </Container>
  );
}

export default FeaturedPosts;