import { memo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { LogoIcon } from "../components/icons/logo";

const useStyles = makeStyles((theme) => ({
  background: {
    background: "radial-gradient(circle at 10% 90%, #ff8f00 20%, #001F00 80%)",
    height: "100%",
    [theme.breakpoints.down(600)]: {
      height: "auto",
    },
  },
  header: {
    fontSize: "24px",
    color: "white",
    lineHeight: "56px",
    [theme.breakpoints.down(768)]: {
      lineHeight: "36px",
      textAlign: "center",
    },
  },
  subHeader: {
    fontSize: "1.25rem",
    lineHeight: "32px",
    color: "rgba(255,255,255,0.7)",
  },
}));

const AuthLayout = (props) => {
  const { children } = props;

  const styles = useStyles();

  return (
    <Box
      className={styles.background}
      display="flex"
      padding="0 15px"
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column">
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={8}>
        <LogoIcon height="68px" primary="#AC0303" secondary="#FFFFFF" />
        <Box mt={2} component="span" className={styles.header}>
          Welcome to Go Farm Yourself!
        </Box>
        {/* <Box component="span" className={styles.subHeader}>
          .beta
        </Box> */}
      </Box>
      {children}
    </Box>
  );
};

export default memo(AuthLayout);
