import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import rupert from "../../assets/rupert-min.jpg";
import olya from "../../assets/olya-min.jpg";
import jefferson from "../../assets/jefferson-min.jpg";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "1.25rem",
    fontWeight: 500,
    marginTop: "24px",
  },
  text: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "24px",
  },
  teamContainer: {
    marginTop: "24px",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  teamHeader: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "1.25rem",
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      marginTop: "24px",
    },
  },
  imageContainer: {
    width: "120px",
    alignSelf: "center",
    backgroundColor: "rgba(0,0,0,0.04)",
  },
  teamContent: {
    marginLeft: "32px",
    margin: "21px"
  },
}));

const Team = () => {
  const styles = useStyles();

  return (
    <>
      <Box className={styles.subHeader}>Meet the team!</Box>
      <Box display="flex" justifyContent="space-between" className={styles.teamContainer}>
        <Box className={styles.imageContainer}>
          <img src={rupert} alt="rupert" />
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" className={styles.teamContent}>
          <Box className={styles.teamHeader}>Snark - CEO, All Things Business</Box>
          <Typography className={styles.text}>
            Rupert is our CEO Affectionately known as ‘Dad to the team. He provides product vision, strategy and trading
            expertise/design to the products. He brings over two decades of experience as an options market maker on all
            three Chicago trading floors, CME, CBOT, CBOE. Going under the trading badge WNR (winner) he has held a CME
            FULL and CBOT FULL simultaneously. Trading options and futures on both grain and livestock commodities in
            tandem. He started at Wolverine Trading LLC where he began his career as an ‘arb clerk’ and worked his way
            up from there. He geeks out on building models to know where ‘one side of the room is’ and has traded
            electronically, been a broker, and now this final iteration building a valuation tool, so simple anyone can
            use. Having watched markets evolve to the HFT world they are today. He has been automated as a floor trader
            and now wants to give the tools the big boys have to the rest of the world. The ultimate GFY to an industry
            that decided they didn’t need him anymore.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" className={styles.teamContainer}>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" className={styles.teamContent}>
          <Box className={styles.teamHeader}>Jefferson - CTO, All Things Data</Box>
          <Typography className={styles.text}>
            Jefferson is our CTO and Head of Data. Jefferson has worked in the fields of Data Science and Machine
            learning for over 10 years and brings with him the experience of building teams and solutions from the
            ground up at small and large scale corporations. Jefferson got his start in predictive analytics at GE where
            he lead the development of Machine Learning in animal health and agriculture. After building several
            successful data teams Jefferson helped take Fetch rewards from a small $75M startup to a $1.5B disruptor as
            head of Data.
          </Typography>
        </Box>
        <Box className={styles.imageContainer}>
          <img src={jefferson} alt="jefferson" />
        </Box>
      </Box>
      {/* <Box display="flex" justifyContent="space-between" className={styles.teamContainer}>
        <Box className={styles.imageContainer}>
          <img src={olya} alt="olya" />
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" className={styles.teamContent}>
          <Box className={styles.teamHeader}>Olya - CDO, All Things Product</Box>
          <Typography className={styles.text}>
            Olya is our Lead Product Designer and CPO. She brings over a decade of experience leading Product Design
            teams across the Globe for large-scale corporations such as: AT&T, CBRE, JCPenney, The Home Depot. Most
            recently she lead the Product Design Delivery of an emergency COVID-19 testing program "NAVICA" at Abbott
            Labs in partnership with The White House Administration. She is currently leading the Design Operations at
            Wayfair.
          </Typography>
        </Box>
      </Box> */}
    </>
  );
};

export default Team;
