import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Theme from "../../../../Theme/Theme";
import moment from "moment";

export const AccordianInfoContianer = ({ cardtitle, cardPrice, cancelAt }) => {
  const styles = useStyles();
  const [cancelDate, setCancelDate] = useState('')

  useEffect(() => {
    if(cancelAt > 0){
      setCancelDate(moment(cancelAt*1000).format('MMMM Do YYYY'))
    }
  }, [cancelAt])
  
  return (
    <>
      {" "}
      <div className={styles.infoContainer}>
        <button className={styles.activeButton}>
          <p className={styles.buttonTitle}>Active</p>
        </button>
        {cancelAt && <p className={styles.cancelAt}>Subscription cancels at {cancelDate}</p> }
        <div className={styles.theTitleContainer}>
          <p className={styles.thetitle}>{cardtitle.slice(7)}</p>
        </div>
        <div className={styles.pricePerYearContainer}>
          <p className={styles.pricePerYear}>${cardPrice}/ year</p>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    width: 218,
    paddingTop: 29,
    paddingBottom: 10,
  },
  activeButton: {
    backgroundColor: Theme.colors.activeButtonColor,
    width: 75,
    height: 37,
    border: "none",
  },
  updateButtontitle: {
    width: 64,
    height: 22,
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: 600,
    fontSize: 18,
    // lineHeight: 22,
    color: Theme.colors.cancelButtonTextColor,
    paddingLeft: 14,
  },
  theTitleContainer: { width: 164, height: 18 },
  thetitle: {
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.small,
    fontSize: Theme.fonts.mediumXL,
    lineHeight: Theme.fonts.lineHeightsTwo,
    color: Theme.colors.black,
    marginTop: 13,
    marginBottom: 0,
  },
  pricePerYearContainer: { width: 161, height: 24 },
  pricePerYear: {
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.small,
    fontSize: Theme.fonts.mediumSecond,
    lineHeight: "157%",
    letterSpacing: Theme.letterSpacings.letterSpacingOne,
    color: Theme.colors.black,
    marginTop: 15,
  },
  cancelAt: {
    color: Theme.colors.brown,
    position: 'absolute',
    left: 100,
    top: 39,
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
    },
  }
}));
