// React Components-------------------------------->
import { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Layout Componenets------------------------------>
import Theme from "../../Theme/Theme";
import { H1 } from "../../components/Text";
import { GeneralLayout } from "../../layouts";
import { Box, Container, Grid } from "@mui/material";
import { GFYButton } from "../../components/ButtonGfy/GFYButton";

// MUI Componenets--------------------------------->
import makeStyles from "@mui/styles/makeStyles";
import AddToCartCard from "./components/AddToCartCard";

// icon--------------------------------------------->
import ArrowIcon from "../../assets/svg/arrow.svg";

// Context------------------------------------------->
import { AppContext } from "../../context";
import { SubscriptionContext } from "../../context/subscription-context";

export const AddtoCart = ({ onSwitchView, saveCardItems, withLayout = true }) => {
  // variables using react componenets----------------->
  const styles = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  // useState variables--------------------------------->
  const [subscriptions, SetSubscriptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [price, setPrice] = useState(0);

  /* context variables destrucherd to get values from context--------------------------->
   * getting values subscription, customerId, email, promocode from SubscriptionContext
   * getting values selectProd, setSelectProduct from SubscriptionContexts selectedProd */
  const { subscription, customerId, email, promocode } = useContext(SubscriptionContext);
  const { selectProd, setSelectProduct } = useContext(SubscriptionContext).selectedProd;
  const { params } = useContext(AppContext);

  /* Componenet UseEffect #1------------------------------------------------------->
   * sets the value for the price useState variable
   * requires params from the AppContext */
  useEffect(() => {
    setPrice(
      params ? JSON.parse(params?.find((param) => param.key === "stripe")?.value || JSON.stringify(params?.find((param) => param.key === "stripe")?.value))?.plans?.map(
        (eachPrice) => {
          return { ...eachPrice, addToCart: false };
        }
      ) : 0
    );
  }, [params]);

  /* Handle ProductSelection Function------------------------------------>
   *  This function is used to handle the card selection mechanism
   *  The function requires productKey parameter*/
  const ProductSelection = (productkey, index) => {
    const filterPrices = price.filter((eachPrice) => eachPrice.priceId == productkey);
    const pricesList = filterPrices.map((e) => e.priceId);
    SetSubscriptions(pricesList);
    setSelectedProduct(productkey);
  };

  /* NavigateToCheckout Function--------------------------------------->
   * Contains the control flow mechanism to handle the subscription creation
   * Requires the subscriptions array
   * contains Prompts on product Selection or no selection */
  const NavigateToCheckout = () => {
    if (subscriptions.length == 0) {
      alert("You have not selected any subscription.");
      return;
    }
    if (subscriptions.length > 1) {
      alert("please select one product...");
      return;
    } else {
      if (pathname === "/subscriptions") {
        setSelectProduct(subscriptions);
        history.push("/payment");
      } else {
        saveCardItems(subscriptions);
      }
    }
  };
  const content = (
    <>
      <Box sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <H1 className={styles.addToCardHeading}>Add to Cart</H1>
      </Box>
      <Grid container direction="row" spacing={3}>
        {params && JSON.parse(params?.find((param) => param.key === "stripe")?.value || JSON.stringify(params?.find((param) => param.key === "stripe")?.value))?.plans?.map(
          (card, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3} className={styles.grid}>
              <AddToCartCard
                cardtitle={card.text}
                className={index === price.length - 1 ? styles.grayCardCard : null}
                // theFontColor={index === ProductsInfo.length - 1 ? styles.grayCardCard : null}
                blackHeading={index === price.length - 1 ? styles.contentBlack : null}
                showBadge={index === price.length - 1}
                isSelected={card.priceId === selectedProduct}
                price={card.amount}
                onClick={() => ProductSelection(card.priceId, index)}
              />
            </Grid>
          )
        )}
      </Grid>
      <Box
        sx={{ display: "flex", flexDirection: "row", marginTop: 8, justifyContent: "center", alignItems: "center" }}>
        <GFYButton
          label="Proceed to checkout"
          icon={ArrowIcon}
          iconWidth={33}
          iconHeigt={13}
          onClick={NavigateToCheckout}
        />
      </Box>
    </>
  )
  return (
    withLayout ? (
      <GeneralLayout>
        <Container className={styles.container}>
          {content}
        </Container>
      </GeneralLayout>
    ) : (
      <Container className={styles.container}>
        {content}
      </Container>
    )
  );
};

/* AddtoCart Component Styling---------------------------------------------------->
 * Contains Styling for the component AddtoCart
 * Uses MUIs makestyles method to create styling */
const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1320,
    paddingBottom: 81,
    paddingLeft: 0,
    paddingRight: 0,
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addToCardHeading: {
    textAlign: "center",
    marginTop: 81,
    marginBottom: 69,
    [theme.breakpoints.down("sm")]: {
      fontSize: Theme.fonts.medium,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: Theme.fonts.small,
    },
  },
  grayCardCard: {
    height: 281,
    width: "100%",
    backgroundColor: "#F0F0F0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Theme.colors.black,
  },
  cardButton: {
    border: "none",
    outline: "0",
    padding: 12,
    color: Theme.colors.white,
    backgroundColor: Theme.colors.black,
    textAlign: "center",
    cursor: "pointer",
    width: "100%",
    fontSize: Theme.fonts.smallplus,
  },
  proceedButton: {
    fontFamily: Theme.fontfamily.Coolvetica,
    backgroundColor: Theme.colors.YellowButtonColor,
    border: "none",
    color: Theme.colors.black,
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: Theme.fonts.small,
    margin: "4px 2px",
    cursor: "pointer",
  },
}));
