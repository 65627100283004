// sandbox product data Array-------------------------------->

export const ProductsInfoSandbox = [
  {
    key: "corn",
    text: "CORN",
    amount: "$999.99 / year",
    priceId: "price_1MOi0hCl8TaA8FOlMg9hlnzc",
  },
  {
    key: "wheat",
    text: "WHEAT",
    amount: "$999.99 / year",
    priceId: "price_1MOi2NCl8TaA8FOlKEbB19lD",
  },
  {
    key: "soyabean",
    text: "SOYBEAN",
    amount: "$999.99 / year",
    priceId: "price_1MOi1aCl8TaA8FOlwDKYOZKd",
  },
  {
    key: "all",
    text: "All the Goodies",
    amount: "$1,999.99 / year",
    priceId: "price_1MOi2uCl8TaA8FOlE5mUh8VW",
  },
];

export const ProductsInfoLive = [
  {
    key: "corn",
    text: "CORN",
    amount: "$999.99 / year",
    priceId: "price_1MOib5Cl8TaA8FOlqWGQeB4T",
  },
  {
    key: "wheat",
    text: "WHEAT",
    amount: "$999.99 / year",
    priceId: "price_1MMB3iCl8TaA8FOlQ4o1gCac",
  },
  {
    key: "soyabean",
    text: "SOYBEAN",
    amount: "$999.99 / year",
    priceId: "price_1MMB4NCl8TaA8FOloYExFk4y",
  },
  {
    key: "all",
    text: "All the Goodies",
    amount: "$1,999.99/year",
    priceId: "price_1MMB35Cl8TaA8FOlVyYV0To9",
  },
];
