import { useState, useEffect } from "react";
import { getContentful, videoPlayerCollection } from "../contentful";

const ContentfulVideo = () => {
  const [videoSrc, setVideoSrc] = useState();
  const [placeholder, setPlaceholder] = useState();

  const getVideo = () => {
    getContentful({
      query: videoPlayerCollection,
      variables: { where: { id: 1 } },
    }).then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      setVideoSrc(data?.videoPlayerCollection.items[0].src.url);
      setPlaceholder(data?.videoPlayerCollection.items[0].placeholder.url);
    });
  };
  useEffect(() => {
    getVideo();
  }, []);

  return (
    <>
      {videoSrc && (
        <>
          <video
            width={320}
            height={240}
            muted
            autoPlay
            preload="none"
            playsInline
            poster={placeholder}
            // poster="https://ottball.com/content/images/2020/06/image-3.png"
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <iframe
            width="320"
            height="240"
            src="https://www.youtube.com/embed/Tn6-PIqc4UM"
            title="React in 100 Seconds"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          /> */}
        </>
      )}
    </>
  );
};

export default ContentfulVideo;
