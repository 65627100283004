import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { TopHeader, Onboarding, DrillTabView, PageHeader } from "../../components";
import { GeneralLayout } from "../../layouts";
import { AuthContext, AppContext } from "../../context";
import WelcomeBanner from "./WelcomeBanner";

const useStyles = makeStyles((theme) => ({
  drillContent: {
    padding: "12px 90px 55px 90px",
    [theme.breakpoints.down(992)]: {
      padding: "12px 20px 55px 20px",
    },
    [theme.breakpoints.down(768)]: {
      padding: "12px 10px 55px 10px",
    },
  },
}));

const Stoplight = () => {
  // TODO! rename it later
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [currentCard, setCurrentCard] = useState("OnboardingOne");
  const [allowOnboardingDisable, setAllowOnboardingDisable] = useState(false);

  const { userProfile } = useContext(AuthContext);
  const { selectedTab, cropType } = useContext(AppContext);

  const styles = useStyles();

  const checkOnboarding = () => {
    const onboardingComplete = userProfile?.["custom:onboardingComplete"];
    if (onboardingComplete !== "1") {
      setCurrentCard("OnboardingOne");
      setShowOnboarding(true);
      setAllowOnboardingDisable(false);
    } else {
      setShowOnboarding(false);
    }
  };

  useEffect(() => {
    checkOnboarding();
  }, [userProfile?.["custom:onboardingComplete"]]);

  return (
    <GeneralLayout>
        {/* {userProfile?.name ? <TopHeader /> : <Box pt={3} />} */}
        <WelcomeBanner />
        <DrillTabView item={cropType[selectedTab]} i={selectedTab} />
        {showOnboarding && (
          <Onboarding {...{ showOnboarding, setShowOnboarding, currentCard, setCurrentCard, allowOnboardingDisable }} />
        )}
    </GeneralLayout>
  );
};

export default Stoplight;
