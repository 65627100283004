import { useContext, useCallback } from "react";
import { Box, Grow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { API } from "@aws-amplify/api";
import SubscriptionPlan from "./SubscriptionPlan";
import { Stripe } from "../icons/stripe";
import CancelSubscriptionDialog from "../modals/CancelSubscriptionDialog";
import cornIcon from "../../assets/corn.png";
import soybeanIcon from "../../assets/soybean.png";
import wheatIcon from "../../assets/wheat.png";
import { AppContext } from "../../context";

const useStyles = makeStyles((theme) => ({
  payments: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "14px",
    fontWeight: 500,
  },
  fullContent: {
    width: "100%",
    height: "100%",
  },
  productNameStyles: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "14px",
  },
  socialContainer: {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: "auto auto",
    alignContent: "center",
    marginBottom: "24px",
    alignItems: "center",
    justifyItems: "center",
    [theme.breakpoints.down(600)]: {
      gridTemplateColumns: "auto",
    },
  },
  cancelContainer: {
    height: "90px",
    width: "100%",
    borderRadius: "4px",
    boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
    transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    "&:hover": {
      transform: "scale(1.01, 1.01)",
      transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
      boxShadow: "0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2)",
    },
  },
  cancelBottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #1E4620",
    cursor: "pointer",
    color: "#1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    /* "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    }, */
  },
  cancelTop: {
    height: "auto",
    padding: "8px",
  },
  cancelTopText: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: 500,
    fontSize: "14px",
    marginLeft: "10px",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
}));

const SubscriptionBox = (props) => {
  const { userProfile, userSubscription, setUserSubscription } = props;
  const { params } = useContext(AppContext);
  const styles = useStyles();

  // const cancelSubscription = async () => {
  //   const apiName = "restApiProd";
  //   const path = "/cancelSubscription";
  //   const myInit = {
  //     body: { subscriptionId: userSubscription[0].subscriptionId, userEmail: userProfile.email },
  //   };
  //   const result = await API.post(apiName, path, myInit);
  //   const response = JSON.parse(result.setUserSubscriptionStatus);
  //   if (response && response.data && response.data.subscriptions) {
  //     getUserSubscriptions();
  //   }
  // };

  const getIconSrc = useCallback((name) => {
    switch (name.toLocaleLowerCase()) {
      case "wheat":
        return wheatIcon;
      case "corn":
        return cornIcon;
      case "soybean":
        return soybeanIcon;
      case "the boss":
        return (
          <div>
            <img src={soybeanIcon} alt="s" />
            <img src={wheatIcon} alt="w" />
            <img src={cornIcon} alt="c" />
          </div>
        );
      default:
        return false;
    }
  }, []);

  return (
    <Box className={styles.fullContent}>
      <Grow in={true} timeout={1000}>
        <Box className={styles.mainContent}>
          {userSubscription?.length > 0 ? (
            <Box className={styles.socialContainer}>
              {userSubscription.map((eachSubscription) => (
                <Box className={styles.cancelContainer}>
                  <Box color="primary.contrastText" className={styles.cancelTop}>
                    <Typography variant="div" className={styles.cancelTopText}>
                      Active Subscription
                    </Typography>
                    <br />
                    <Typography variant="div" className={styles.productNameStyles}>
                      {eachSubscription.productName === "All the Goodies" ? "The Boss" : eachSubscription.productName}
                    </Typography>
                  </Box>
                  <Box className={styles.cancelBottom}>Cancel Subscription</Box>
                </Box>
              ))}
            </Box>
          ) : (
            "No Subscription Active"
          )}
          <Box className={[styles.socialContainer].join(' ')}>
            {JSON.parse(params?.find((param) => param.key === "stripe")?.value || JSON.stringify({}))?.plans?.map(
              (token, key) => (
                <SubscriptionPlan
                  key={key}
                  text={token.text}
                  amount={token.amount}
                  priceId={token.priceId}
                  userSubscription={userSubscription}
                  iconSrc={getIconSrc(token.text)}
                  setUserSubscription={setUserSubscription}
                />
              )
            )}
          </Box>
          <Box>
            <Box className={styles.payments}>All payments are</Box> <Stripe />
          </Box>
        </Box>
      </Grow>
      {/* <CancelSubscriptionDialog {...{ cancelOpen, setCancelOpen, cancelSubscription, loading }} /> */}
    </Box>
  );
};

export default SubscriptionBox;
