import { Container } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import React, { useContext } from 'react';
import { H1, H2, LabelRegular } from '../../components/Text';
import Theme from '../../Theme/Theme';
import Grid from '@mui/material/Grid';
import TrafficLight from './components/TrafficLight';
import { AuthContext } from '../../context';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F5F5F5',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '10.6rem',
    }
  },
  descriptionContainer: {
    paddingTop: '6%'
  },
  labelDesription: {
    color: "#858585",
    maxWidth: 450
  },
  labelTraffic: {
    color: '#858585',
    marginTop: '6%',
    marginBottom: '5%'
  },
  h1: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem !important',
    },
    marginBottom: '5px',
    marginTop: 0,
    lineHeight: 'unset',
    fontSize: '2.5rem',
  },
  welcomeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '2.5em',
    marginBottom: '2em',
  }
}));

const WelcomeBanner = () => {

  const styles = useStyles();
  const { userProfile } = useContext(AuthContext);

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.descriptionContainer}>
          <Grid container spacing={2} justifyContent="center" >
            <Grid item xs={12} sm={7} alignItems="center">
              <div className={styles.welcomeContainer}>
                <H2 className={styles.h1}>Welcome</H2> &nbsp;&nbsp;
                <H2 className={styles.h1}>{userProfile?.name}</H2>
              </div>
              <LabelRegular p className={styles.labelDesription}>Use our Stoplight indicator to know when the best times to sell your grain are.</LabelRegular>
            </Grid>
            <Grid item xs={12} sm={5}>
              <TrafficLight
                type={"green"}
                label="Green means things are good and wait."
                hasBottomBorder={true}
              />
              <TrafficLight
                type={"yellow"}
                label="Yellow, the market is changing so pay attention."
                hasBottomBorder={true}
              />
              <TrafficLight
                type={"red"}
                label="Red, stop what you're doing - now is a good time to sell."
                hasBottomBorder={false}
              />
              <LabelRegular p className={styles.labelTraffic}>You decide how much if any to sell - Go Farm Yourself!</LabelRegular>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default WelcomeBanner;