import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth, API } from "aws-amplify";
import { loadStripe } from "@stripe/stripe-js/pure";
import { TwentyTokens, FiftyTokens, OneHundredTokens } from "../icons/tokens";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "120px",
    width: "156.85px",
    borderRadius: "4px",
    marginTop: "36px",
    boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
    marginRight: "18px",
    [theme.breakpoints.down(600)]: {
      height: "120px",
      width: "200px",
    },
  },
  bottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #1E4620",
    cursor: "pointer",
    color: "#1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  top: {
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buyText: {
    color: "#fff",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
  },
  topText: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: 500,
    fontSize: "14px",
  },
  amount: {
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
    fontSize: "14px",
    marginTop: "4px",
  },
}));

const PurchaseTokenBox = (props) => {
  const { text, image, amount, product } = props;

  const styles = useStyles();

  const tokenImages = {
    TwentyTokens: TwentyTokens,
    FiftyTokens: FiftyTokens,
    OneHundredTokens: OneHundredTokens,
  };

  const handlePayment = async () => {
    const apiName = "restApiProd";
    const path = "/checkout-session";
    const myInit = {
      body: { redirect: window.location.href, purchase_amt: 1, product },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };
    const session = await API.post(apiName, path, myInit);
    const sessionId = session.id;
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    await stripe.redirectToCheckout({ sessionId });
  };

  const TokenImage = tokenImages[image];

  return (
    <Box className={styles.container}>
      <Box color="primary.contrastText" className={styles.top}>
        <TokenImage />
        <Box display="flex" justifyContent="flex-start" flexDirection="column">
          <Box component="span" className={styles.topText}>
            {text}
          </Box>
          <Box component="span" className={styles.amount}>
            {amount}
          </Box>
        </Box>
      </Box>
      <Box className={styles.bottom} onClick={() => handlePayment()}>
        <Box component="span">Purchase token</Box>
      </Box>
    </Box>
  );
};

export default PurchaseTokenBox;
