import { useState, useEffect, useRef, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { RootPortal } from "../portals/root-portal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: "fixed",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    backgroundColor: "rgba(51, 51, 51, 0.3)",
    backdropFilter: "blur(1px)",
    opacity: "0",
    transition: "all 100ms cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDelay: "200ms",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .modal-content": {
      transform: "translateY(100px)",
      transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1)",
      opacity: "0",
    },

    "&.active": {
      transitionDuration: "250ms",
      transitionDelay: "0ms",
      opacity: "1",
      zIndex: "1",

      "& .modal-content": {
        transform: "translateY(0)",
        opacity: "1",
        transitionDelay: "150ms",
        transitionDuration: "350ms",
      },
    },
  },
  content: {
    position: "relative",
    boxSizing: "border-box",
    minHeight: "50px",
    minWidth: "50px",
    maxHeight: "80%",
    maxWidth: "80%",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)",
    backgroundColor: "white",
    borderRadius: "2px",
  },
}));

const BaseModal = (props) => {
  const styles = useStyles();
  const [active, setActive] = useState(false);
  const { open, onClose, locked } = props;
  const backdrop = useRef(null);

  useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const keyHandler = (e) => !locked && [27].indexOf(e.which) >= 0 && onClose();

    const clickHandler = (e) => !locked && e.target === current && onClose();

    if (current) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
      window.addEventListener("keyup", keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector("#root").setAttribute("inert", "true");
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }

      document.querySelector("#root").removeAttribute("inert");
      window.removeEventListener("keyup", keyHandler);
    };
  }, [open, locked, onClose]);

  return (
    <Fragment>
      {(open || active) && (
        <RootPortal className="modal-portal">
          <div ref={backdrop} className={`${styles.backdrop} ${active && open ? "active" : ""}`}>
            <div className={`${styles.content} modal-content`}>{props.children}</div>
          </div>
        </RootPortal>
      )}
    </Fragment>
  );
};

export default BaseModal;
