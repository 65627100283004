import { Dialog, DialogContent, Typography, DialogTitle, Button, Box, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Warning from "@mui/icons-material/WarningOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import pupper from "../../assets/pupper.jpg";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#FFB74D",
    height: "80px",
    padding: "0 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      height: "50px",
    },
  },
  container: {
    width: "691px",
    height: "443px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
    },
  },
  titleText: {
    marginLeft: "20px",
    marginRight: "auto",
    verticalAlign: "middle",
    display: "flex",
    fontSize: "1.5rem",
    alignItems: "center",
  },
  text: {
    color: "rgba(0,0,0,0.87)",
    textAlign: "left",
    marginBottom: "40px",
    marginTop: "20px",
    letterSpacing: "0.15px",
    lineHeight: "28px",
    fontSize: "1rem",
    [theme.breakpoints.down(600)]: {
      fontSize: "1.2rem",
    },
  },
  content: {
    textAlign: "center",
    padding: 0,
    maxHeight: "362px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
  closeButton: {
    right: theme.spacing(1),
    color: "black",
    marginLeft: "auto",
  },
  deleteButton: {
    width: "300px",
    backgroundColor: "#1E4620",
    color: "white",
    fontSize: "15px",
    marginBottom: "14px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  cancelButton: {
    width: "300px",
    fontSize: "15px",
    color: "#1E4620",
    "&.MuiButton-outlined": {
      border: "1px solid #1E4620",
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  },
  contentContainer: {
    padding: "2.7rem",
    [theme.breakpoints.down(600)]: {
      padding: "2rem",
    },
  },
  image: {
    height: "362px",
  },
}));

const CancelSubscriptionDialog = (props) => {
  
  const { cancelOpen, setCancelOpen, cancelSubscription, loading } = props;

  const styles = useStyles();

  return (
    <Dialog
      onClose={() => setCancelOpen(false)}
      aria-labelledby="delete-dialog"
      open={cancelOpen}
      maxWidth={"lg"}
      fullWidth={true}
      classes={{ paper: styles.container }}>
      <DialogTitle className={styles.dialog} id="delete-dialog" onClose={() => setCancelOpen(false)}>
        <Typography className={styles.titleText}>
          <Warning className={styles.icon} /> Cancel Subscription
        </Typography>
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={() => setCancelOpen(false)}
          sx={{ display: { xs: "none", md: "flex" } }}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Box className={styles.contentContainer}>
          <Typography gutterBottom className={styles.text}>
            Are you sure, You want to cancel your subscription
          </Typography>
          <LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            className={styles.deleteButton}
            onClick={() => cancelSubscription()}>
            Yes, Cancel It
          </LoadingButton>
          <Button variant="outlined" size="large" className={styles.cancelButton} onClick={() => setCancelOpen(false)}>
            Close
          </Button>
        </Box>
        <Box className={styles.image} sx={{ display: { xs: "none", md: "flex" } }}>
          <img src={pupper} alt="" />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
