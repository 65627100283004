import { createContext } from "react";

const AuthContext = createContext({
  isLoggedIn: false,
  tokens: 0,
  setIsLoggedIn: () => {},
  userProfile: {
    name: "",
    email: "",
    address: "",
    "custom:onboardingComplete": "0",
    "custom:role": "",
    "custom:phoneNumber": "",
  },
  setUserProfile: () => {},
});

export default AuthContext;
