import { Paper, Box, Typography, Stack } from "@mui/material";
import { formatCurrency } from "../../utils/formatting";

const CropSummary = ({ summary }) => {
  if (!summary) return null;

  return (
    <Box p={4} sx={{ backgroundColor: "#016938" }} display="flex" alignItems={"center"}>
      <Stack direction="row" spacing={2}>
        <Paper
          elevation={4}
          sx={{
            padding: "16px",
            textAlign: "middle",
            verticalAlign: "middle",
            minWidth: "120px",
            maxWidth: "260px",
          }}>
          <Typography variant="h6">Commodity</Typography>
          <Typography variant="h1">{summary.cropName}</Typography>
        </Paper>
        <Paper
          elevation={4}
          sx={{
            padding: "16px",
            textAlign: "center",
            minWidth: "120px",
            maxWidth: "200px",
          }}>
          <Typography variant="h6">Crop Year</Typography>
          <Typography variant="h1">{summary.productionCycle}</Typography>
        </Paper>
        <Paper
          elevation={4}
          sx={{
            padding: "16px",
            textAlign: "end",
            minWidth: "120px",
            maxWidth: "200px",
          }}>
          <Typography variant="h5">Overall Profit/Loss</Typography>
          <Typography variant="h1">{formatCurrency(summary.totalGainLossValue)}</Typography>
        </Paper>
      </Stack>
    </Box>
  );
};

export default CropSummary;
