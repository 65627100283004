import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Button } from "@mui/material";
import Swal from "sweetalert2";
import makeStyles from "@mui/styles/makeStyles";

export const CheckoutCardElement = ({ clientSecret: secret }) => {
  // Get the lookup key for the price from the previous page redirect.
  const [clientSecret] = useState(secret || "");
  const [paymentIntent, setPaymentIntent] = useState();

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();
  const styles = useStyles();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return "";
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    if (clientSecret === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Client SDK not initialized correctly issue",
      });
      return;
    }
    // Use card Element to tokenize payment details
    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {},
      },
    });

    if (error) {
      // show error and collect new card details.
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
      return;
    }
    setPaymentIntent(paymentIntent);
  };

  if (paymentIntent && paymentIntent.status === "succeeded") {
    console.log("paymentIntent => ", paymentIntent);
    Swal.fire({
      title: paymentIntent.description,
      text: "You have successfully subscribed to product.",
      icon: "success",
      showCancelButton: true,
      confirmButtonText: "Show my subscription!",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <CardElement className={styles.paymentcard} />
        <Button type="submit" className={styles.payNowBtn}>
          Pay now
        </Button>
      </form>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  paymentcard: {
    border: "solid 2px #333",
    padding: "8px",
    fontSize: "16px",
    borderRadius: "5px",
  },
  payNowBtn: {
    backgroundColor: "brown",
    marginTop: "8px",
    width: "100%",
    color: "white",
    border: "0",
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "orange",
    },
  },
}));
