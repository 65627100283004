import { useContext } from "react";
import { Dialog, DialogContent, DialogTitle, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AppContext from "../../context/AppContext";

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#FFB74D",
  },
  titleText: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "24px",
  },
  text: {
    fontSize: "1.25rem",
    fontWeight: 500,
    color: "rgba(0,0,0,0.6)",
    textAlign: "left",
    marginBottom: "16px",
    marginTop: "20px",
    letterSpacing: "0.15px",
    lineHeight: "32px",
  },
  content: {
    marginLeft: "20px",
    marginRight: "20px",
    textAlign: "center",
  },
  dialogContainer: {
    width: "482px",
    height: "280px",
    maxHeight: "280px",
  },
  bottomText: {
    color: "#001F00",
    fontSize: "1.25rem",
    textAlign: "left",
  },
}));

const DisabledDialog = () => {
  const { showDisabled } = useContext(AppContext);

  const styles = useStyles();

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={showDisabled}
      BackdropProps={{
        style: { position: "absolute", backdropFilter: "blur(20px)", backgroundColor: "rgba(255, 255, 255, 0.8)" },
      }}
      style={{ position: "absolute" }}
      classes={{ paper: styles.dialogContainer }}
      container={() => document.getElementById("stoplight")}>
      <DialogTitle className={styles.dialog} id="customized-dialog-title">
        <Box display="flex" alignItems="center" justifyContent="center" className={styles.titleText}>
          🤠 The drill is currently unavailable.
        </Box>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Box className={styles.text}>
          We are doing some math 🤓 and refreshing the model with the new closing price.
        </Box>
        <Box className={styles.bottomText}>
          Please come back at <b>3:00 pm CST!</b>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DisabledDialog;
