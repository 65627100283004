import { Box, Container, Paper, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FeaturedPosts from "../../components/blog/FeaturedPosts";
import { GeneralLayout } from "../../layouts";
import { useState, useEffect } from "react";


const useStyles = makeStyles((theme) => ({
}));


const Blog = () => {

  const desiredTitle = "Go Farm Yourself";

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://io.dropinblog.com/embedjs/a236ac05-cded-4f8b-8055-30bd74a717a7.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.title = desiredTitle;
      document.body.removeChild(script);
    };
  }, []);

  const styles = useStyles();
  return (
    <GeneralLayout>
      <Container>
        <Paper elevation={4}>
          <div id="dib-posts"></div>
        </Paper>
      </Container>
    </GeneralLayout>
  );
};

export default Blog;
