import { Dialog, DialogContent, Box, Divider, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LogoIcon } from "../icons/logo";
import OnboardingOne from "./OnboardingOne";
import OnboardingTwo from "./OnboardingTwo";
import OnboardingThree from "./OnboardingThree";
const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "724px",
    [theme.breakpoints.down(600)]: {
      height: "auto",
    },
    "@media (min-width:600px) and (max-width:800px)": {
      height: "auto",
    },
  },
  content: {
    marginLeft: "20px",
    marginRight: "20px",
    textAlign: "center",
    padding: 0,
    [theme.breakpoints.down(600)]: {
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
  divider: {
    background: "#1E4620",
  },
}));

const Onboarding = (props) => {
  const { setShowOnboarding, showOnboarding, currentCard, setCurrentCard, allowOnboardingDisable } = props;
  const styles = useStyles();
  const logoHeight = useMediaQuery("(max-width:599px)") ? "34px" : "68px";
  const logoContainerHeight = useMediaQuery("(max-width:599px)") ? "15px" : "25px";

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      aria-labelledby="customized-dialog-title"
      open={showOnboarding}
      classes={{ paper: styles.dialog }}>
      <DialogContent className={styles.content}>
        <Box mt={2}>
          <Box style={{ margin: logoContainerHeight }}>
            <LogoIcon height={logoHeight} primary="#AC0303" secondary="#FFFFFF" />
          </Box>
          <Divider classes={{ root: styles.divider }} />
        </Box>
        {currentCard === "OnboardingOne" && <OnboardingOne setCurrentCard={setCurrentCard} />}
        {currentCard === "OnboardingTwo" && <OnboardingTwo setCurrentCard={setCurrentCard} />}
        {currentCard === "OnboardingThree" && (
          <OnboardingThree {...{ setCurrentCard, setShowOnboarding, allowOnboardingDisable }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Onboarding;
