import { useState, useEffect, useContext } from "react";
import { Auth, API } from "aws-amplify";
import { Box, FormControlLabel, Radio } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import styles from "./account.styles";
import AuthContext from "../../context/AuthContext";
import EditComponent from "../../components/Edit";
import SaveComponent from "../../components/Save";
import axios from "axios";
import config from '../../config';
const RoleSetting = withStyles(styles)(({ classes, setApiError, setShowApiError }) => {
  const { userProfile } = useContext(AuthContext);

  const [role, setRole] = useState(userProfile?.["custom:role"]);
  const [roleDisabled, setRoleDisabled] = useState(true);
  const [roleEdit, setRoleEdit] = useState("edit");

  useEffect(() => {
    setRole(userProfile?.["custom:role"]);
  }, [userProfile]);

  const handleRoleEdit = () => {
    setRoleDisabled(false);
    setRoleEdit("save");
  };

  const saveRoleInfo = async () => {
    const data = {
      "custom:role": role,
    };
    try {
      const user = await Auth.currentAuthenticatedUser();

      const token = user.signInUserSession.idToken.jwtToken;

      await Auth.updateUserAttributes(user, { ...data });
      setRoleDisabled(true);
      setRoleEdit("edit");

      const env_var = process.env.REACT_APP_NODE_ENV;

      try {
        // **Custom API**
        // console.log("env_var: ", env_var);

        // console.log("email: ", userProfile.email);

        const payload = {
          email: userProfile.email,
          role: role,
          env: env_var,
        };

        // console.log("hubspot add details payload", payload);


        const hubspot_url_key = 'HUBSPOT_URL_' + env_var.toUpperCase();
const hubspot_url = config[hubspot_url_key];        

        const headers = {
          Authorization: token
        };
        
        const response = await axios.post(hubspot_url + '/UpdateContact', payload, { headers });
        

        // console.log("hubspot response", response);
      } catch (error) {
        console.log("error: ", error);
      }            

      // update user details in mailchimp
      const apiName = "restApiProd";
      const path = "/subscribeMailChimp";
      const myInit = {
        body: {
          email: userProfile.email,
          data: {
            MMERGE10: role,
          },
        },
      };
      await API.put(apiName, path, myInit);
    } catch (e) {
      setApiError(e.message);
      setShowApiError(true);
    }
  };

  return (
    <Box className={classes.rightBox}>
      <Box display="flex" justifyContent="space-between">
        <Box className={classes.subHeader}>What I do</Box>
        {roleEdit === "edit" ? (
          <EditComponent onClick={() => handleRoleEdit()} />
        ) : (
          <SaveComponent onClick={() => saveRoleInfo()} />
        )}
      </Box>
      <Box mt={4} px={2} className={classes.settingContainer}>
        <Box>
          <FormControlLabel
            disabled={roleDisabled}
            value="Farmer"
            checked={role === "Farmer"}
            control={<Radio />}
            label="Farmer"
            onChange={(e) => setRole(e.target.value)}
          />
          <FormControlLabel
            disabled={roleDisabled}
            value="Risk Management"
            checked={role === "Risk Management"}
            control={<Radio />}
            label="Risk Management"
            onChange={(e) => setRole(e.target.value)}
          />
        </Box>
        <Box>
          <FormControlLabel
            disabled={roleDisabled}
            value="Trader"
            checked={role === "Trader"}
            control={<Radio />}
            label="Trader"
            onChange={(e) => setRole(e.target.value)}
          />
          <FormControlLabel
            disabled={roleDisabled}
            value="Other"
            checked={role === "Other"}
            control={<Radio />}
            label="Other"
            onChange={(e) => setRole(e.target.value)}
          />
        </Box>
      </Box>
    </Box>
  );
});

export default RoleSetting;
