import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Theme from "../../../../Theme/Theme";
import { GFYButton } from "../../../../components/ButtonGfy/GFYButton";
import ArrowIcon from "../../../../assets/svg/arrow.svg";

export const AccordianButtonContianer = ({ onCanceled, onUpdate, updateLoading, cancelLoading, cancelAtEnd }) => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.buttonContainer}>
        <GFYButton
          label={"Update"}
          onClick={onUpdate}
          styleProps={{ marginBottom: 20, padding: "15px" }}
          isPrimary={false}
          loading={updateLoading}
        />
        <GFYButton
          label={"Cancel Subscription"}
          icon={cancelLoading ? false : ArrowIcon}
          styleProps={{ marginBottom: 20, padding: "15px" }}
          iconWidth={33}
          iconHeigt={13}
          onClick={onCanceled}
          isPrimary={false}
          loading={cancelLoading}
          disabled={cancelAtEnd}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    width: 423,
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
  },
}));
