import { useState, useEffect } from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GeneralLayout } from "../../layouts";
import TradeLedgerTable from "../../components/CropDetails/TradeLedgerTable";
import { getContentful, newTradeLedgerCollection } from "../../contentful";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down(600)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  header: {
    fontSize: "2rem",
    fontWeight: 500,
    marginBottom: "16px",
  },
  body: {
    paddingTop: 32,
  },
}));

const Results = () => {
  const styles = useStyles();

  const [ledger, setLedger] = useState({});

  const getTradeLedger = () => {
    getContentful({
      query: newTradeLedgerCollection,
      variables: { order: ["cropContractName_ASC", "datetime_DESC"] },
    }).then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      const groupByContractName = data.newTradeLedgerCollection?.items?.reduce((group, item) => {
        const { cropContractName } = item;
        group[cropContractName] = group[cropContractName] ?? [];
        group[cropContractName].push(item);
        return group;
      }, {});
      setLedger(groupByContractName);
    });
  };

  useEffect(() => {
    getTradeLedger();
  }, []);

  return (
    <GeneralLayout>
      <Container>
        <Paper elevation={4}>
          <Box p={4} mt={6} mb={6} className={styles.container}>
            <Box mt={2} color="#1E4620" className={styles.header}>
              Results
            </Box>
            <Box>
              {Object.entries(ledger).map(([key, value]) => (
                <div key={key} className={styles.body}>
                  <Typography variant="h3">{key}</Typography>
                  <TradeLedgerTable ledger={value} />
                </div>
              ))}
            </Box>
          </Box>
        </Paper>
      </Container>
    </GeneralLayout>
  );
};

export default Results;
