import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import { Grid, IconButton } from "@mui/material";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Stack from "@mui/material/Stack";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import playerIcon from "../../assets/player-icon.png";
import { useContext } from "react";
import { AppContext } from "../../context";

const data = [
  {
    image: "../assets/client-testimonial.jpg",
    url: "https://www.youtube.com/embed/R6yKoSMMsy8",
  },
];
const modalHeight = 400;
const useStyles = makeStyles((theme) => ({
  buttonimage: {
    color: "transparent",
    padding: "0",
    border: "none",
    width: "100%",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      background: "url('" + playerIcon + "') center / 115px no-repeat",
      [theme.breakpoints.down("sm")]: {
        backgroundSize: "80px",
      },
    },
  },
  button: {
    display: "inline-block",
    width: "190px",
    height: "50px",
    border: "2px solid",
    borderRadius: "4px",
    backgroundColor: "#32CD32",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "13px",
    cursor: "pointer",
    animation: "pulsate 1.5s infinite",
    // transition: "transform 0.3s ease",
    textAlign: "center",
    marginRight: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "32px",
    paddingLeft: "32px",
    [theme.breakpoints.down(768)]: {
      width: "70%",
      fontSize: "16px",
      marginRight: "15px",
      marginLeft: "15px",
    },
    [theme.breakpoints.down(375)]: {
      fontSize: "11px",
      width: "70%",
      marginRight: "15px",
      marginLeft: "15px",
    },
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#000",
      transform: "scale(1.5)",
      // animation: "none",
    },
  },
  button2: {
    width: "190px",
    height: "50px",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#13302B",
    background: "#fff",
    borderRadius: "1px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "20px",
    textAlign: "center",
    borderColor: "#13302B",
    boxShadow: "none",
    border: "1px solid",
    textTransform: "initial",
    "&:hover": {
      color: "#eba83a"
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      fontSize: "16px",
      marginRight: "15px",
      marginLeft: "15px",
    },
    [theme.breakpoints.down(375)]: {
      fontSize: "11px",
    },
  },
  sliderNavButton: {
    color: "#bb371a",
    transform: "scale(1.5)",
    "&.Mui-disabled": {
      color: "#858585",
      transform: "scale(1)",
    },
  },
  sliderLeftButton: {
    marginRight: "10px",
  },
}));
const modalStyles = {
  modal: {
    padding: 0,
    height: modalHeight,
    overflow: "hidden",
  },
};
SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

export default function App() {
  const videostyles = useStyles();
  const [open, setOpen] = useState(false);
  const [isActiveSliderLeftButton, setActiveSliderLeftButton] = useState(false);
  const [isActiveSliderRightButton, setActiveSliderRightButton] = useState(true);

  const swiperRef = useRef();

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const swiperInitHandler = (swiper) => (swiperRef.current = swiper);

  const swiperChangeHandler = (swiper) => {
    if (swiper.isBeginning) setActiveSliderLeftButton(false);
    if (!swiper.isBeginning) setActiveSliderLeftButton(true);
    if (swiper.isEnd) setActiveSliderRightButton(false);
    if (!swiper.isEnd) setActiveSliderRightButton(true);
  };

  const { setShowContact } = useContext(AppContext);
  
  return (
    <div className="video-section">
      <Grid container spacing={2} alignItems="center">
        {/* <Grid item xs={12} md={5} lg={4}> */}
        <Grid item xs={12} md={6} lg={5}>
          <h2>What agents are saying</h2>
          <ul>
            <li>Our Machine Learning Robot</li>
            <li>Peace of Mind</li>
            <li>Unbiased advice</li>
          </ul>
          {data.length > 1 ? (
            <div>
              <IconButton
                className={[videostyles.sliderNavButton, videostyles.sliderLeftButton].join(' ')}
                disabled={!isActiveSliderLeftButton}
                onClick={() => swiperRef.current?.slidePrev()}>
                <WestIcon />
              </IconButton>
              <IconButton
                className={videostyles.sliderNavButton}
                disabled={!isActiveSliderRightButton}
                onClick={() => swiperRef.current?.slideNext()}>
                <EastIcon />
              </IconButton>
            </div>
          ) : null}
        </Grid>
        {/* <Grid item xs={12} md={7} lg={8}> */}
        <Grid item xs={12} md={6} lg={7}>
          <Swiper
            id="main"
            navigation={false}
            pagination={false}
            spaceBetween={25}
            slidesPerView={1}
            onSlideChange={swiperChangeHandler}
            onBeforeInit={swiperInitHandler}>
            {data.map((list, index) => (
              <SwiperSlide key={index}>
                <button onClick={onOpenModal} className={videostyles.buttonimage}>
                  <Stack>
                    <img src={list.image} />
                  </Stack>
                </button>
                <Modal open={open} onClose={onCloseModal} center showCloseIcon={false} styles={modalStyles}>
                  <iframe
                    style={{ width: "calc(100vw - 40px)", maxWidth: "800px" }}
                    height={modalHeight}
                    src={list.url}
                    title="Go Farm Yourself - Agent Review"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Modal>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            // direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            pt={5}>
            <Button variant="contained" className={videostyles.button} onClick={() => {
              setShowContact(true);
            }}>
              CLAIM FREE TRIAL
            </Button>
            <Button variant="contained" endIcon={<EastIcon />} className={videostyles.button2}>
              Learn More
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
