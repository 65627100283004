import { Hub } from 'aws-amplify';
import { useState, useEffect, useRef } from "react";
import { Box, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Offer from "./offer";
import Plan from "./plan";
import Result from "./result";
import Videos from "./videos";
import Testimonial from "./testimonial";
import Hero from "./hero";
import IconCard from "./icon-card";
import useScrollPosition from "../../hooks/useScrollPosition";
import { GeneralLayout } from "../../layouts";
import { TopHeader, PageHeader, PageFooter } from "../../components";
import Team from "../About/team";
import { getContentful, videoPlayerCollection, resultsCollection, additionalProfitCollection, landingPageCollection } from "../../contentful";
import HeroVideo from "./hero-video";
import { useWindowSize } from "../../hooks/useWindowSize";
import Results from "./results";
import ResultTextBlock from './result-text-block';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  drillContent: {},
  paperContainer: {},
  sectionCommon: {
    paddingTop: "100px",
    paddingLeft: "180px",
    paddingRight: "180px",
    [theme.breakpoints.down(1536)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.down(992)]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.down(768)]: {
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "60px",
    },
    [theme.breakpoints.down(375)]: {
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "10px",
    },
  },
  herosection: {
    position: "relative",
    height: "700px",
    backgroundSize: "100%",
    background: "url(../assets/gfy_23_bkgd_web.jpg) center / cover no-repeat",
    backgroundColor: "#000000",
    zIndex:0,
    [theme.breakpoints.down(1536)]: {
      height: "700px",
    },
    [theme.breakpoints.down(992)]: {
      height: "700px",
    },
    [theme.breakpoints.down(768)]: {
      height: "280px",
      // paddingLeft: "30px",
      // paddingRight: "30px",
      // paddingTop: "100px",
    },
  },
  resultsContainer: {
    display: "flex",
    flexDirection:"column",
    height: "auto",
    alignItems:"center",
    background: "#ffffff",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    top: "130%",
    zIndex: 1,
    [theme.breakpoints.down(1765)]: {
      alignItems: "center",
      justifyContent: "center",
      top: "120%",
      padding: "5px",
      maxWidth: "1500px",
    },
    [theme.breakpoints.down(1300)]: {
      top: "140%",
      maxWidth: "1300px",
    },
    [theme.breakpoints.down(1180)]: {
      alignItems: "center",
      justifyContent: "center",
      top: "140%",
      padding: "5px",
      maxWidth: "1300px",
    },
    [theme.breakpoints.down(768)]: {
      alignItems: "center",
      justifyContent: "center",
      top: "150%",
      padding: "5px",
      maxWidth: "1000px",
    },
    [theme.breakpoints.down(375)]: {
      alignItems: "center",
      justifyContent: "center",
      top: "150%",
      maxWidth: "1500px",
      padding: "5px",
      maxWidth: "1000px",
 
    },
  },
  profit: {
    marginTop: "200px",
        [theme.breakpoints.down(1765)]: {
          marginTop: "100px",
        },
        [theme.breakpoints.down(1300)]: {
          marginTop: "100px",
        },
        [theme.breakpoints.down(1180)]: {
          marginTop: "100px",
        },
        [theme.breakpoints.down(768)]: {
          marginTop: "100px",
        },
        [theme.breakpoints.down(375)]: {

        },
  },
  videosection: {
    paddingTop: "200px",
    paddingBottom: "200px",
    paddingRight: "180px",
    paddingLeft: "180px",
    [theme.breakpoints.down(1536)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },

    [theme.breakpoints.down(992)]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.down(768)]: {
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "80px",
      paddingBottom: "80px",
    },
  },
  testimonialsection: {
    paddingLeft: "180px",
    paddingRight: "180px",
    backgroundImage: "url(../assets/soybean-field-stock.jpg)",
    paddingTop: "200px",
    paddingBottom: "850px",
    backgroundPosition: "bottom center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    [theme.breakpoints.down(1536)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    [theme.breakpoints.down(992)]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.down(768)]: {
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "80px",
      paddingBottom: "520px",
    },
    "&:before": {
      content: '""',
      background: "linear-gradient(0.29deg, #011B18 2.44%, rgba(0, 25, 22, 0) 103.67%)",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
      boxSizing: "border-box",
      position: "absolute",
      height: "350px",
      left: "0px",
      right: "0px",
      bottom: "0px",
    },
  },

  videoHolder: {
    padding: "40px 180px",
    backgroundColor: "#004022",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    gap: 8,
    [theme.breakpoints.down(992)]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.down(768)]: {
      flexDirection: "column",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  spotlight: {
    color: "#C56000",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "32px",
    textTransform: "uppercase",
    padding: "40px 0",
  },
  featureSpotlight: {
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "32px",
    textTransform: "uppercase",
    padding: "40px 0",
  },
  spotLightText: {
    height: "96px",
    width: "450px",
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "32px",
  },
  quoteText: {
    fontStyle: "italic",
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "25px",
    fontWeight: "300",
    letterSpacing: "0.35px",
    lineHeight: "38px",
    padding: "0 10% 20px 10%",
  },
  videoTextBullet: {
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "28px",
    fontWeight: "300",
    letterSpacing: "0.15px",
    lineHeight: "46px",
  },
  videoHeaderTitle: {
    color: "#fff",
    fontFamily: "Roboto",
    fontSize: "35px",
    fontWeight: "500",
    letterSpacing: "0.35px",
    lineHeight: "40px",
  },
  offerText: {
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "20px",
    textAlign: "center",
  },
  finalDescription: {
    height: "100%",
    padding: "0 90px",

    [theme.breakpoints.down(992)]: {
      padding: "0 20px",
    },
    [theme.breakpoints.down(768)]: {
      padding: "0 10px",
    },
  },
  tabLabel: {
    fontWeight: 500,
    paddingLeft: "60px",
    paddingRight: "60px",
    color: "#e0e0e0",
    fontSize: "11px",
    "&.Mui-selected": {
      color: "#000",
      paddingLeft: "60px",
      paddingRight: "60px",
    },
  },
  tabIcon: {
    color: "#e0e0e0",
    height: "16px",
    "&.Mui-selected": {
      color: "#000",
    },
  },
  tableWrapper: {
    padding: "16px",
  },
  banner: {
    background: "black",
    marginTop: "0",
    objectFit: "fill",
  },
  resultsBody: {},
  resultBox: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down(992)]: {
      flexDirection: "column",
    },
  },
  cardIcon: {
    width: 47,
    height: 34,
    margin: "0px 3px",
  },
  planSection: {
    paddingLeft: "180px",
    paddingRight: "180px",
    [theme.breakpoints.down(1536)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },

    [theme.breakpoints.down(992)]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.down(768)]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  heroVideoSection: {
    paddingTop: "200px",
    paddingBottom: "60px",
    paddingRight: "180px",
    paddingLeft: "180px",
    [theme.breakpoints.down(1536)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },

    [theme.breakpoints.down(992)]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.down(768)]: {
      paddingLeft: "30px",
      paddingRight: "30px",
      paddingTop: "20px",
    },
  },
  textcontainer: {
    position: "absolute", 
    top: "50px", 
    left: "280px", 
    padding: theme.spacing(2),
    zIndex: 1, 
    [theme.breakpoints.down(768)]: {
      top: "11px",
      left: "18px",
      padding: "9px",
    },
  },
  subtext: {
    display: "flex",
    flexDirection: "row",
  },
  h1: {
    fontFamily: "'Coolvetica'",
    letterSpacing: "5px",
    color: "#016938",
    fontSize: "170px",
    fontWeight: 600,
    paddingTop: "15px",
    [theme.breakpoints.down(768)]: {
      fontSize: "70px",
    },
  },
  h4: {
    fontFamily: "'Coolvetica'",
    letterSpacing: "1px",
    fontSize: "30px",
    fontWeight: 500,
    [theme.breakpoints.down(768)]: {
      fontSize: "16px",
      fontWeight: 600,
    },
  },
  subtexth4: {
    padding: "18px",
    marginTop: "100px",
    fontWeight: 500,
    [theme.breakpoints.down(768)]: {
      fontSize: "10px",
      marginTop: "45px",
      padding: "10px",
    },
  },
  arrow: {
    fontSize: "50px", 
    color: "primary",
  }
}));

const Landing = () => {
  const styles = useStyles();
  const [banners, setBanners] = useState([]);
  const [results, setResults] = useState([]);
  const [landingPageContent, setLandingPageContent] = useState([]);

  const [profitValue, setProfitValue] = useState(0);
  const [headerIsTransparent, setHeaderIsTransparent] = useState(true);
  const scrollPosition = useScrollPosition();
  const { width } = useWindowSize();
  const resultsRef = useRef();
  const videoRef = useRef();

  const getResults = () => {
    getContentful({
      query: resultsCollection,
      variables: { order: "type_ASC" },
    }).then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      setResults(data.resultsCollection.items);
      console.log("result",data.resultsCollection.items)
    });
  };

  const getAdditionalProfit = () => {
    getContentful({
      query: additionalProfitCollection,
    }).then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      setProfitValue(data?.additionalProfitCollection?.items?.[0]?.value);
      console.log("profit value",data?.additionalProfitCollection?.items?.[0]?.value)
    });
  };

  const getBanners = () => {
    getContentful({
      query: videoPlayerCollection,
      variables: { order: "id_ASC" },
    }).then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      setBanners(data.videoPlayerCollection.items);
    });
  };

  const getLandingPageContent = () => {
    getContentful({
      query: landingPageCollection,
    }).then(({ data, errors }) => {
      if (errors) {
        console.error(errors);
      }
      setLandingPageContent(data?.landingPageCollection?.items);
    });
  };

  useEffect(() => {
    getBanners();
    getResults();
    getAdditionalProfit();
    getLandingPageContent()
  }, []);

  useEffect(() => {
    if (scrollPosition <= 0) return;
  }, [scrollPosition]);

  return (
    <GeneralLayout headerIsTransparent={headerIsTransparent}>
      <div className={styles.drillContent}>
        <Box className={styles.herosection}>
          <Box className={styles.textcontainer}>
            <Typography variant="h4" className={styles.h4}>GFY 2023 RESULTS YTD</Typography>
            <Box className={styles.subtext}>
              <Typography variant="h1" className={styles.h1}>+$59,143</Typography>
              <Typography variant='h4' className={styles.subtexth4}>Based on <br />a $100,000 <br />portfolio.</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={styles.resultsContainer}>
          <Results items={results} />
        </Box>
        <Box className={styles.profit}>
          <ResultTextBlock profit={profitValue} />
        </Box>
        <Box className={styles.sectionCommon}>
          <IconCard></IconCard>
        </Box>
        <Box className={styles.videosection}>
          <Videos></Videos>
        </Box>
        <Box className={styles.testimonialsection}>
          <Testimonial></Testimonial>
        </Box>
        <Box className={styles.planSection}>
          <Plan />
        </Box>
        <Box className={styles.heroVideoSection}>
          <HeroVideo />
        </Box>
      </div>
    </GeneralLayout>
  );
};

export default Landing;
