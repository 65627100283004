import React from "react";
import soybeanDefaultImg from "../../assets/soybean.png";
import wheatDefaultImg from "../../assets/wheat.png";
import cornDefaultImg from "../../assets/corn.png";
import soybeanSelectedImg from "../../assets/soybean_grey.png";
import wheatSelectedImg from "../../assets/wheat_grey.png";
import cornSelectedImg from "../../assets/corn_grey.png";

export function CornImg(props = {}) {
  return <img src={props.isSelected ? cornDefaultImg : cornSelectedImg} alt="corn" />;
}

export function WheatImg(props = {}) {
  return <img src={props.isSelected ? wheatDefaultImg : wheatSelectedImg} alt="wheat" />;
}

export function SoybeanImg(props = {}) {
  return <img src={props.isSelected ? soybeanDefaultImg : soybeanSelectedImg} alt="soybean" />;
}
