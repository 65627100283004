import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Theme from "../../../../Theme/Theme";
import Switch from "@mui/material/Switch";
import withStyles from "@mui/styles/withStyles";
import { LabelRegular, Subtitles } from "../../../../components/Text";

export const AccordianRenewalContainer = ({ autoRenowal, onRenowalChange, currentPeriodEnd }) => {
  const styles = useStyles();
  const PurpleSwitch = withStyles({
    switchBase: {
      color: Theme.colors.greenCardColor,
      "&$checked": {
        color: Theme.colors.greenCardColor,
      },
      "&$checked + $track": {
        backgroundColor: Theme.colors.white,
        border: "1px solid gray",
      },
    },
    checked: {},
    track: {},
  })(Switch);
  return (
    <>
      <div className={styles.renewalContainer}>
        <div className={styles.itemsTogglerOne}>
          <LabelRegular p={true} className={styles.toggleOneText}>
            Auto Renewal{" "}
            <PurpleSwitch
              className={styles.toggleStylingOne}
              checked={autoRenowal}
              onChange={() => onRenowalChange(!autoRenowal)}
              name="renewal"
              color="primary"
            />
          </LabelRegular>
          <LabelRegular>{currentPeriodEnd}</LabelRegular>
        </div>
        <div className={styles.itemsTogglerOne} style={{display: 'none'}}>
          <LabelRegular className={styles.toggleTwoText}>
            Monthly{" "}
            <PurpleSwitch
              className={styles.toggleStyling}
              checked={true}
              name="Yearly"
              color="primary"
            />
            Yearly
          </LabelRegular>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  renewalContainer: {
    width: 440,
    paddingTop: 58,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down(498)]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      paddingTop: 12,
    },
    [theme.breakpoints.down(811)]: {
      paddingTop: 12,
    },
  },

  itemsTogglerOne: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  toggleOneText: {
    width: 208,
    height: 17,
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.medium,
    fontSize: Theme.fonts.smallplus,
    lineHeight: Theme.fonts.lineHeightsTwo,
    letterSpacing: Theme.letterSpacings.letterSpacingOne,
    color: Theme.colors.black,
    [theme.breakpoints.down(498)]: {
      height: 47,
    },
    [theme.breakpoints.down(811)]: {
      height: 47,
    },
    marginTop: 0,
  },
  toggleStyling: {
    // marginTop: 100,
  },
  toggleStylingOne: {
    // marginTop: 117,
    // marginRight: 57,
  },

  toggleTwoText: {
    width: 208,
    height: 17,
    fontFamily: Theme.fontfamily.Coolvetica,
    fontStyle: Theme.fontStyle,
    fontWeight: Theme.fontWeight.medium,
    fontSize: Theme.fonts.smallplus,
    lineHeight: Theme.fonts.lineHeightsTwo,
    letterSpacing: Theme.letterSpacings.letterSpacingOne,
    color: Theme.colors.black,
    marginRight: 16,
    [theme.breakpoints.down(498)]: {
      height: 47,
    },
    [theme.breakpoints.down(811)]: {
      height: 47,
    },
  },
}));
