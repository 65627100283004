import { useContext } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { API } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import { loadStripe } from "@stripe/stripe-js/pure";
import Swal from "sweetalert2";
import _ from "lodash";
import AuthContext from "../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "120px",
    width: "100%",
    borderRadius: "4px",
    boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
  },
  bottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #1E4620",
    cursor: "pointer",
    color: "#1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  activeBottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid #1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  top: {
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topText: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: 500,
    fontSize: "14px",
  },
  amount: {
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
    fontSize: "14px",
    marginTop: "4px",
  },
}));

const SubscriptionPlan = (props) => {
  const { text, amount, priceId, userSubscription, iconSrc } = props;
  const { userProfile } = useContext(AuthContext);
  const currentSubscription = userSubscription?.find((subs) => subs.priceId === priceId);
  const styles = useStyles();

  const handlePayment = async () => {
    const customerId = _.get(userSubscription, "[0].customerId", "");
    const { attributes } = await Auth.currentAuthenticatedUser();
    const promocode = attributes["custom:promocode"] ?? null;
    const session = await API.post("restApiProd", "/checkout", {
      body: {
        redirect: window.location.href,
        priceId: priceId,
        userEmail: userProfile.email,
        customerId: customerId,
        coupon: promocode,
      },
    });
    if (session.statusCode === 400) {
      Swal.fire({
        icon: "error",
        title: "There is a problem while doing a payment",
        text: session?.raw?.message,
        confirmButtonText: "I'll try again",
      });
    } else if (session.status === 500) {
      Swal.fire({
        icon: "error",
        title: "There is a problem while doing a payment",
        text: session?.message,
        confirmButtonText: "I'll try again",
      });
    } else {
      const sessionId = session.id;
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
      stripe.redirectToCheckout({ sessionId });
    }
  };

  // const switchPlan = async () => {
  //   /* console.log({ redirect: window.location.href, priceId, userEmail : userProfile.email }) */
  //   const apiName = "restApiProd";
  //   const path = "/checkout";
  //   const objData = { priceId, userEmail: userProfile.email };
  //   if (userSubscription && userSubscription[0] && userSubscription[0].customerId) {
  //     objData.stripeSubscriptionId = userSubscription[0].subscriptionId;
  //   }
  //   const myInit = {
  //     body: objData,
  //   };
  //   const switchPlan = await API.put(apiName, path, myInit);
  //   if (switchPlan && switchPlan.id) {
  //     userSubscription[0].priceId = switchPlan.plan.id;
  //     setUserSubscription(JSON.parse(JSON.stringify(userSubscription)));
  //   }
  // };

  return (
    <Box className={styles.container} key={props.key}>
      <Box color="primary.contrastText" className={styles.top}>
        <Box display="flex" justifyContent="flex-start" flexDirection="column">
          <Box className={styles.icon}>{typeof iconSrc === "object" ? iconSrc : <img src={iconSrc} alt="" />}</Box>
          <Box component="span" className={styles.topText}>
            {text}
          </Box>
          <Box component="span" className={styles.amount}>
            {amount}
          </Box>
        </Box>
      </Box>
      {currentSubscription?.priceId === priceId ? (
        <Box className={styles.activeBottom}>
          <Box component="span">Active</Box>
        </Box>
      ) : (
        <Box className={styles.bottom} onClick={handlePayment}>
          <Box component="span">Subscribe</Box>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionPlan;
