import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth, API } from "aws-amplify";
import AuthContext from "../../context/AuthContext";

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "200px",
    marginRight: "200px",
    [theme.breakpoints.down(600)]: {
      marginLeft: "10px",
      marginRight: "20px",
    },
    [theme.breakpoints.down(800)]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  heading: {
    fontSize: "34px",
    color: "#B71C1C",
    lineHeight: "42px",
    marginBottom: "20px",
    [theme.breakpoints.down(600)]: {
      fontSize: "24px",
      lineHeight: "30px",
      marginBottom: "5px",
    },
  },
  middle: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "24px",
    marginTop: "32px",
    marginBottom: "50px",
    lineHeight: "24px",
    [theme.breakpoints.down(600)]: {
      marginBottom: "10px",
      marginTop: "10px",
    },
  },
  list: {
    fontSize: "16px",
    "& li": {
      paddingLeft: "20px",
      marginTop: "24px",
      textAlign: "left",
      fontWeight: 500,
      lineHeight: "24px",
      [theme.breakpoints.down(600)]: {
        marginTop: "10px",
      },
      "&::marker": {
        content: "",
        fontSize: "10px",
        verticalAlign: "middle",
        lineHeight: "10px",
        marginRight: "10px",
      },
    },
  },
  button: {
    width: "300px",
    backgroundColor: "#1E4620",
    color: "white",
    fontSize: "15px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginTop: "20px",
      marginBottom: "0px",
    },
    [theme.breakpoints.down(800)]: {
      marginBottom: "20px",
    },
  },
  backButton: {
    width: "300px",
    backgroundColor: "#fff",
    color: "#1E4620",
    fontSize: "15px",
    fontWeight: 500,
    border: "1px solid #1E4620",
    marginRight: "23px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginTop: "20px",
      marginBottom: "0px",
    },
    [theme.breakpoints.down(800)]: {
      marginBottom: "20px",
    },
  },
  buttonContainer: {
    marginBottom: "101px",
    [theme.breakpoints.down(600)]: {
      marginBottom: "20px",
    },
    [theme.breakpoints.down(800)]: {
      flexDirection: "column",
    },
  },
}));

const OnboardingThree = (props) => {
  const { setCurrentCard, setShowOnboarding, allowOnboardingDisable } = props;
  const { userProfile, setUserProfile } = useContext(AuthContext);
  const history = useHistory();
  const styles = useStyles();

  const updateOnboarding = async () => {
    setShowOnboarding(false);
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { "custom:onboardingComplete": "1" });
    setUserProfile((prevState) => ({
      ...prevState,
      "custom:onboardingComplete": "1",
    }));
    history.push("/stoplight");

    // update user details in mailchimp
    const apiName = "restApiProd";
    const path = "/subscribeMailChimp";
    const myInit = {
      body: {
        email: userProfile.email,
        data: {
          MMERGE11: 1,
        },
      },
    };
    await API.put(apiName, path, myInit);
  };

  return (
    <Box className={styles.container}>
      <Box mt={4}>
        <Box mt={1} className={styles.heading}>
          Here is how this works:
        </Box>
        <Box display="flex" className={styles.middle}>
          <ul className={styles.list}>
            <li>We built a model to track price and momentum of the market to find the best points to sell crop.</li>
            <li>
              We've conveniently wrapped up our math into a simple GFY Stoplight indicator to tell you the best time to
              sell crop.
            </li>
            <li>It's simple, when it's green, you should sit tight, when yellow - be alert, and when red, SELL!</li>
            <li>We've even included a graph of our indicator for you to see the movement over the year.</li>
            <li>
              Want to know more? Check out our historical profits and GFY sells chart to see where our algorithm traded
              best.
            </li>
          </ul>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" className={styles.buttonContainer}>
          {!allowOnboardingDisable && (
            <Button
              variant="outlined"
              size="large"
              className={styles.backButton}
              onClick={() => setCurrentCard("OnboardingTwo")}>
              Back
            </Button>
          )}
          <Button variant="contained" size="large" className={styles.button} onClick={updateOnboarding}>
            Got It! Let's Go!
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingThree;
