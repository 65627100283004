import React from 'react';
import Theme from '../Theme/Theme';

const Divider = ({
  color = Theme.colors.subtitleGrey, 
  marginTop = 10, 
  marginBottom = 10,
}) => {
  return (
    <div style={{
      height: 1,
      width: '100%',
      backgroundColor: color,
      marginTop,
      marginBottom 
    }} />
  );
}

export default Divider;