import { useState, useEffect } from "react";
import { Box, Container, Paper, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import makeStyles from "@mui/styles/makeStyles";
import { GeneralLayout } from "../layouts";
import { PageFooter } from "../components";
import { faqCollection } from "../contentful/queries";
import { FINAL_DESCRIPTION } from "../utils/constants";
import { DISCLAIMER } from "../utils/constants";


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "68px",
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down(600)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  header: {
    fontSize: "2rem",
    fontWeight: 500,
    marginBottom: "16px",
  },
  // TODO: remove this later
  // subHeader: {
  //   color: "rgba(0,0,0,0.87)",
  //   fontSize: "1.25rem",
  //   fontWeight: 500,
  //   marginTop: "24px",
  // },
  // text: {
  //   color: "rgba(0,0,0,0.87)",
  //   fontSize: "16px",
  //   lineHeight: "24px",
  //   marginTop: "24px",
  // },
  // divider: {
  //   borderTop: "1px solid #0000001f",
  //   paddingBottom: "40px",

  //   "&:last-child": {
  //     paddingBottom: 0,
  //   },
  // },
  lastContent: {
    width: "100%",
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "20px",
    [theme.breakpoints.down(768)]: {
      padding: "30px 15px",
    },
    [theme.breakpoints.down(600)]: {
      position: "static",
      padding: "30px 0 0",
    },
  },
  offerText: {
    paddingBottom: "56px",
    color: "rgba(0,0,0,0.6)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.15px",
    lineHeight: "20px",
  },
}));

const FAQs = () => {
  const [faqs, setFAQs] = useState([]);
  const styles = useStyles();

  const getFAQ = () => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/mc8fkmpn2662/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer 3MNolDAAGvD0VgJc3ggMmov8KtzddPmMGZHgnVIMwsA",
        },
        // send the GraphQL query
        body: JSON.stringify({
          query: faqCollection,
          variables: { order: "id_DESC", where: {} },
        }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }
        setFAQs(data?.faqCollection?.items || []);
      });
  };
  useEffect(() => {
    getFAQ();
  }, []);

  return (
    <GeneralLayout>
      <Container>
        <Paper elevation={4}>
          <Box p={4} mt={6} mb={6} className={styles.container}>
            <Box mt={2} color="#1E4620" className={styles.header}>
              Frequently Asked Questions
            </Box>
            <div>
              {faqs.map((faq) => (
                <Accordion key={faq.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{faq.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
                // TODO: remove this later
                // <Box key={faq.id} display="flex" flexDirection="column" className={styles.divider}>
                //   <Box className={styles.subHeader}>{faq.question}</Box>
                //   <Typography className={styles.text}>{faq.answer}</Typography>
                // </Box>
              ))}
            </div>
            <PageFooter />
          </Box>
        </Paper>
        <Box className={styles.lastContent}>
          <Box className={styles.offerText}>{FINAL_DESCRIPTION}</Box>
          <Box className={styles.offerText}>{DISCLAIMER}</Box>          
        </Box>
      </Container>
    </GeneralLayout>
  );
};

export default FAQs;
