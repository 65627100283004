import React, { useState, useContext } from "react";
import { Box, Container, Paper, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import makeStyles from "@mui/styles/makeStyles";
import { GeneralLayout } from "../../layouts";
import { useQuery } from "@tanstack/react-query";
import { ReactComponent as LoadingIcon } from "../../assets/spinner_grey.svg";
import AuthContext from "../../context/AuthContext";
import { getApiClient } from "../../apiClient";
import { CROPS } from "../../constants";
import CropSummary from "../../components/CropDetails/CropSummary";
import { CornImg, WheatImg, SoybeanImg } from "../../components/icons/crops";
import CropTradesSummary from "../../components/CropDetails/CropTradesSummary";
import CropOpenPositionsSummary from "../../components/CropDetails/CropOpenPositionsSummary";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down(600)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  header: {
    fontSize: "2rem",
    fontWeight: 500,
    marginBottom: "16px",
  },
  body: {
    paddingTop: 32,
  },
}));

export const Dashboard = () => {
  const styles = useStyles();

  const { isLoggedIn } = useContext(AuthContext);

  const [selectedCrop, setSelectedCrop] = useState(CROPS.CORN);
  const [productionCycle, setProductionCycle] = useState(2022);

  const openPositionsQry = useOpenPositions(selectedCrop.frCropType, productionCycle);
  const positionSummariesQry = usePositionSummaries(selectedCrop.frCropType, productionCycle);
  const cropSummaryQry = useCropSummary(selectedCrop.frCropType, productionCycle);
  const tradesQry = useTrades(selectedCrop.frCropType, productionCycle);

  if (
    !isLoggedIn ||
    openPositionsQry.isLoading ||
    positionSummariesQry.isLoading ||
    cropSummaryQry.isLoading ||
    tradesQry.isLoading
  ) {
    return (
      <GeneralLayout>
        <Container>
          <Paper elevation={4}>
            <Box p={4} mt={6} mb={6} className={styles.container}>
              <Box mt={2} color="#1E4620" className={styles.header}>
                <LoadingIcon />
              </Box>
            </Box>
          </Paper>
        </Container>
      </GeneralLayout>
    );
  }

  if (openPositionsQry.isError || positionSummariesQry.isError || cropSummaryQry.isError || tradesQry.isError) {
    return <p>Error: {openPositionsQry.error.message}</p>;
  }

  const openPositions = openPositionsQry.data || [];
  const positionSummaries = positionSummariesQry.data || [];
  const cropSummaries = cropSummaryQry.data || [];
  const trades = tradesQry.data || [];

  const cropSummary = cropSummaries.find((cropSummary) => cropSummary.hedgingCropType === selectedCrop.cropType);

  const handleTabChange = (event, newValue) => {
    const crop = Object.values(CROPS).find((crop) => crop.cropType === newValue);
    setSelectedCrop(crop);
  };

  return (
    <GeneralLayout>
      <Container>
        <Paper elevation={4}>
          <Box p={4} mt={6} mb={6} className={styles.container}>
            <Box mt={2} color="#1E4620" className={styles.header}>
              <Tabs value={selectedCrop?.cropType} onChange={handleTabChange}>
                <Tab
                  label={CROPS.CORN.displayName}
                  value={CROPS.CORN.cropType}
                  icon={<CornImg isSelected={selectedCrop?.cropType === CROPS.CORN.cropType} />}
                />
                <Tab
                  label={CROPS.SOYBEANS.displayName}
                  value={CROPS.SOYBEANS.cropType}
                  icon={<SoybeanImg isSelected={selectedCrop?.cropType === CROPS.SOYBEANS.cropType} />}
                />
                <Tab
                  label={CROPS.KC_WHEAT.displayName}
                  value={CROPS.KC_WHEAT.cropType}
                  icon={<WheatImg isSelected={selectedCrop?.cropType === CROPS.KC_WHEAT.cropType} />}
                />
              </Tabs>
            </Box>
            <Box mt={2}>
              <FormControl fullWidth>
                <InputLabel id="crop-year-label" htmlFor="productionCycle">
                  Crop Year
                </InputLabel>
                <Select
                  labelId="crop-year-label"
                  id="productionCycle"
                  value={productionCycle}
                  label="Production Cycle"
                  onChange={(e) => {
                    setProductionCycle(e.target.value);
                  }}>
                  <MenuItem value={2022}>2022</MenuItem>
                  <MenuItem value={2023}>2023</MenuItem>
                  <MenuItem value={2023}>2024</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mt={6}>
              <CropSummary summary={cropSummary}></CropSummary>
            </Box>
            <Box mt={6}>
              <Typography variant="h2" marginBottom={1}>
                Current Open Positions
              </Typography>
              <CropOpenPositionsSummary
                trades={trades}
                positionSummaries={positionSummaries}
                openPositions={openPositions}
              />
            </Box>
            <Box mt={6}>
              <Typography variant="h2" marginBottom={1}>
                Our Past Trades
              </Typography>
              <CropTradesSummary trades={trades} positionSummaries={positionSummaries} openPositions={openPositions} />
            </Box>
          </Box>
        </Paper>
      </Container>
    </GeneralLayout>
  );
};

export default Dashboard;

export function useTrades(cropType, productionCycle) {
  cropType = cropType || 1; // Corn
  productionCycle = productionCycle || 2022; // 2022

  const fetchTrades = async () => {
    const apiClient = await getApiClient();
    const response = await apiClient.get("/farmers-risk/trades", { params: { cropType, productionCycle } });
    return response.data.results ?? [];
  };

  const query = useQuery({
    queryKey: ["trades", cropType, productionCycle],
    queryFn: fetchTrades,
  });

  return query;
}

export function useOpenPositions(cropType, productionCycle) {
  cropType = cropType || 1; // Corn
  productionCycle = productionCycle || 2022; // 2022

  const fetchOpenPositions = async () => {
    const apiClient = await getApiClient();
    const response = await apiClient.get("/farmers-risk/open-positions", { params: { cropType, productionCycle } });
    return response.data.results ?? [];
  };

  const query = useQuery({
    queryKey: ["open-positions", cropType, productionCycle],
    queryFn: fetchOpenPositions,
  });

  return query;
}

export function usePositionSummaries(cropType, productionCycle) {
  cropType = cropType || 1; // Corn
  productionCycle = productionCycle || 2022; // 2022

  const fetchPositionSummaries = async () => {
    const apiClient = await getApiClient();
    const response = await apiClient.get("/farmers-risk/position-summaries", { params: { cropType, productionCycle } });
    return response.data.results ?? [];
  };

  const query = useQuery({
    queryKey: ["position-summaries", cropType, productionCycle],
    queryFn: fetchPositionSummaries,
  });

  return query;
}

export function useCropSummary(cropType, productionCycle) {
  cropType = cropType || 1; // Corn
  productionCycle = productionCycle || 2022; // 2022

  const fetchCropSummary = async () => {
    const apiClient = await getApiClient();
    const response = await apiClient.get("/farmers-risk/crop-summaries", { params: { cropType, productionCycle } });
    return response.data.results ?? [];
  };

  const query = useQuery({
    queryKey: ["crop-summaries", cropType, productionCycle],
    queryFn: fetchCropSummary,
  });

  return query;
}
