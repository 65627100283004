import makeStyles from "@mui/styles/makeStyles";
import { Paper, Tab, Tabs } from "@mui/material";
import { useContext } from "react";
import AppContext from "../context/AppContext";
import { H3 } from "./Text";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    marginLeft: "36px",
    marginRight: "36px",
    color: "#000000",
    fontSize: "25px",
    display: "flex",
    flexDirection: "row",
    padding: 0,
    "&.Mui-selected": {
      color: "#016938",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      marginLeft: "5px",
      marginRight: "5px",
      fontSize: '1rem'
    },
  },
  icon: {
    color: "#e0e0e0",
    marginRight: "13px",
    height: "25px",
    "&.Mui-selected": {
      color: "#000",
    },
  },
  paper: {
    borderTopLeftRadius: 0,
  },
  container: {
    marginTop: "24px",
    marginBottom: "50px",
    width: "130px",
    display: "flex",
  },
  loginContent: {
    padding: "30px",
  },
  drillContent: {
  },
  tabs: {
    width: "525px",
    display: "flex",
    marginBottom: 70,
    borderBottom: "1px solid #BABABA",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
      width: 'auto',
      marginLeft: "unset",
      padding:'0 10px',
      marginBottom: '80px',
    },
  },
  heading: {
    // width: "200px",
  },
  h3: {
    lineHeight: "1rem !important",
    paddingLeft: "1.5rem",
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1.9rem !important',
    },
  }
}));

const GFYTabs = (props) => {
  const { children, variant, tabUnderlineColor, type, margin } = props;
  const { selectedTab, setSelectedTab } = useContext(AppContext);
  const styles = useStyles();
  return (
    <>
      {/* <Paper elevation={4} className={styles.paper} sx={{ borderTopRightRadius: type === "stoplight" ? "4px" : 0 }}> */}
        <H3 className={styles.h3}>Pick Your Crop</H3>
        <Tabs
          centered
          className={styles.tabs}
          value={selectedTab}
          onChange={(e, value) => setSelectedTab(value)}
          variant={variant}
          TabIndicatorProps={{ style: { background: tabUnderlineColor, height: 3 } }}>
          {children.map((child) => {
            return (
              <Tab
                key={child.props.label}
                icon={child.props.icon ? <img src={child.props.icon} className={styles.icon} alt="" /> : null}
                label={child.props.label}
                classes={{ root: styles.label }}
                className={styles.heading}
              />
            );
          })}
        </Tabs>
        <div className={type === "stoplight" ? styles.drillContent : styles.loginContent} style={{ margin: margin }}>
          {children[selectedTab]}
        </div>
      {/* </Paper> */}
      {/* <Paper elevation={4} className={styles.container}>
        {type === "stoplight" && (
          <div className={value !== 1 ? styles.drillContent : ""}>{value !== 1 ? <TokenBox /> : null}</div>
        )}
      </Paper> */}
    </>
  );
};

export default GFYTabs;
