import React, { isValidElement, useContext, useMemo, useState } from "react";
import { GeneralLayout } from "../layouts";
import {
  Container,
  Box,
  CardActions,
  TextField,
  Paper,
  Button,
  Card,
  Typography,
  CardContent,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import Swal from "sweetalert2";
import makeStyles from "@mui/styles/makeStyles";
import soybean from "../assets/soybean.png";
import wheat from "../assets/wheat.png";
import corn from "../assets/corn.png";
import { API } from "@aws-amplify/api";
import { SubscriptionContext } from "../context/subscription-context";
import { PaymentPlan } from "../components/payments/payment-plan";
import { CheckoutCardElement } from "../components/payments/checkout-card-element";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import _ from "lodash";
import { useCallback } from "react";

let stripeKey = process.env.REACT_APP_STRIPE_API_KEY

const stripePromise = loadStripe(stripeKey);

export const CustomCheckoutPage = React.memo(() => {
  const styles = useStyles();
  const { subscription, customerId, email, promocode } = useContext(SubscriptionContext);
  const [Price, setPrice] = useState([]);
  const [Cards, setCards] = useState([]);
  const [UniqueSubscriptions, setUniqueSubscriptions] = useState([]);
  const [ClientSecret, setClientSecret] = useState(null);
  const [AddToCartPriceList, setAddToCartPriceList] = useState([]);

  React.useEffect(() => {
    let plans = []; // TODO!
    if (process.env.REACT_APP_NODE_ENV === "dev" || process.env.REACT_APP_NODE_ENV === "uat") {
      // plans = [
      //   {
      //     key: "corn",
      //     text: "CORN",
      //     icon: corn,
      //     amount: "$499.99 / year",
      //     priceId: "price_1KhEVfCl8TaA8FOl5T0NlNKY",
      //   },
      //   {
      //     key: "wheat",
      //     text: "WHEAT",
      //     icon: wheat,
      //     amount: "$499.99 / year",
      //     priceId: "price_1KhEUkCl8TaA8FOlx2Zd0YrR",
      //   },
      //   {
      //     key: "soyabean",
      //     text: "SOYABEAN",
      //     icon: soybean,
      //     amount: "$499.99 / year",
      //     priceId: "price_1Kpx4yCl8TaA8FOlSohBpk5p",
      //   },
      //   {
      //     key: "all",
      //     text: "All the Goodies",
      //     amount: "$1,339.99 / year",
      //     priceId: "price_1KhEUBCl8TaA8FOl2kVzpnqj",
      //   },
      // ];
      plans = [
        {
          key: "corn",
          text: "CORN",

          amount: "$499.99 / year",
          priceId: "price_1MOi0hCl8TaA8FOlMg9hlnzc",
        },
        {
          key: "wheat",
          text: "WHEAT",

          amount: "$499.99 / year",
          priceId: "price_1MOi2NCl8TaA8FOlKEbB19lD",
        },
        {
          key: "soyabean",
          text: "SOYBEAN",

          amount: "$499.99 / year",
          priceId: "price_1MOi1aCl8TaA8FOlwDKYOZKd",
        },
        {
          key: "all",
          text: "All the Goodies",
          amount: "$1,339.99 / year",
          priceId: "price_1MOi2uCl8TaA8FOlE5mUh8VW",
        },
      ];
    } else {
      plans = [
        {
          key: "corn",
          // icon: corn,
          text: "CORN",
          amount: "$499.99 / year",
          priceId: "price_1JdGc5Cl8TaA8FOlpo0f9reW",
        },
        {
          key: "wheat",
          // icon: wheat,
          text: "WHEAT",
          amount: "$499.99 / year",
          priceId: "price_1JdGcRCl8TaA8FOl6EExWrqZ",
        },
        {
          key: "soyabean",
          // icon: soybean,
          text: "SOYABEAN",
          amount: "$499.99 / year",
          priceId: "price_1JdGcICl8TaA8FOlHWvwRNap",
        },
        {
          key: "all",
          text: "All the Goodies",
          amount: "$1399.99/year",
          priceId: "price_1JdGd0Cl8TaA8FOlQ8WBXIGw",
        },
      ];
    }
    setPrice(
      plans.map((eachPrice) => {
        return { ...eachPrice, addToCart: false };
      })
    );
    // console.log("the customer id", customerId);
    getCards(customerId).then((result) => {
      setCards(_.get(result, "data.data", []));
    });
    // console.log("the subscriptions", subscription);
    const filterSubscription = subscription.filter((s) => s.paymentStatus == "active");
    // console.log("filtered subscriptions", filterSubscription);
    setUniqueSubscriptions(_.uniq(_.map(filterSubscription, "subscriptionId")));
    // console.log("UniqueSubscriptions", UniqueSubscriptions);
  }, []);

  React.useEffect(() => {
    const filterPrices = Price.filter((eachPrice) => eachPrice.addToCart == true);
    // console.log("filtered Prices", filterPrices);
    const pricesList = filterPrices.map((e) => e.priceId);
    // console.log("pricelist", pricesList);
    setAddToCartPriceList(pricesList);
  }, [Price]);

  const getCards = async (customerId) => {
    return await API.post("restApiProd", "/get-cards", {
      body: {
        customerId: customerId,
      },
    });
  };

  const getSubscription = async (subscriptionId) => {
    return await API.post("restApiProd", "/get-subscription-detail", {
      body: {
        subscriptionId: subscriptionId,
      },
    });
  };

  const createSubscription = async () => {
    // console.log("all values required for creating subscriptions");
    // console.log("AddToCartPriceList", AddToCartPriceList);
    // console.log("email", email);
    // console.log("promocode", promocode);
    // console.log("customerId", customerId);
    // console.log("payment_method", payment_method);
    const { clientSecret, subscription, status } = await API.post("restApiProd", "/stripe-custom-checkout", {
      body: {
        prices: AddToCartPriceList,
        email: email,
        coupon: promocode,
        customerId: customerId,
        payment_method: Cards.length > 0 ? Cards[0].id : "",
      },
    });
    setClientSecret(clientSecret);
  };

  return (
    <GeneralLayout>
      <Container>
        <Paper elevation={4} className={styles.container}>
          <Box className={styles.priceContainer}>
            {Price &&
              Price.map((eachPrice, index) => {
                return (
                  <PaymentPlan
                    price={eachPrice}
                    subscription={subscription}
                    priceId={eachPrice.priceId}
                    setPrice={setPrice}
                    AllPrice={Price}></PaymentPlan>
                );
              })}
          </Box>

          <div className={styles.cardlist}>
            <div>
              <Elements stripe={stripePromise}>
                {ClientSecret && <CheckoutCardElement clientSecret={ClientSecret}></CheckoutCardElement>}
              </Elements>
            </div>
            <Box>
              {Cards.map((eachCard) => {
                return (
                  <Box className={styles.CardDetailItem}>
                    <div>{`**** **** **** ${eachCard.card.last4}`}</div>
                    <div>{eachCard.card.brand}</div>
                    <div
                      className={styles.textAlignRight}>{`${eachCard.card.exp_month}/${eachCard.card.exp_year}`}</div>
                  </Box>
                );
              })}
              {!ClientSecret && (
                <Button
                  disabled={AddToCartPriceList && AddToCartPriceList.length > 0 ? false : true}
                  className={styles.proceedToCheckoutBtn}
                  onClick={createSubscription}>
                  Proceed to Checkout
                </Button>
              )}
            </Box>
          </div>
          <CancelSubscription UniqueSubscriptions={UniqueSubscriptions} subscription={subscription} styles={styles} />
        </Paper>
      </Container>
    </GeneralLayout>
  );
});

const CancelSubscription = React.memo(({ UniqueSubscriptions, subscription, styles }) => {
  const cancelSubscription = useCallback(async function (subscriptionId) {
    return await API.post("restApiProd", "/cancel-subscription", {
      body: {
        subscriptionId: subscriptionId,
      },
    });
  }, []);

  const confirmCancelSubscription = (subscriptionId) => {
    Swal.fire({
      title: "Are you sure, you want to delete subscription?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { status } = await cancelSubscription(subscriptionId);
        window.location.reload();
      }
    });
  };

  if (!UniqueSubscriptions) {
    return "";
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {UniqueSubscriptions.map((eachSub, index) => {
            const filteredSubscription = subscription.filter(
              (s) => s.subscriptionId === eachSub && s.paymentStatus == "active"
            );
            return (
              <BasicTable
                key={index}
                styles={styles}
                rows={[
                  {
                    name: `${index + 1})`,
                    Products: (
                      <>
                        {filteredSubscription &&
                          filteredSubscription.map((eachSub, index) => {
                            return <div key={index}>{`1x ${eachSub.productName}`}</div>;
                          })}
                      </>
                    ),
                    status: <>{filteredSubscription[0]?.paymentStatus ?? "no status found"}</>,
                    Action: (
                      <Button
                        className={styles.proceedToCheckoutBtn}
                        onClick={() => confirmCancelSubscription(eachSub)}>
                        Cancel Subscription
                      </Button>
                    ),
                  },
                ]}
              />
            );
          })}
        </Table>
      </TableContainer>
    </>
  );
});

export default function BasicTable({ rows, styles }) {
  return (
    <TableBody>
      {rows.map((row, index) => (
        <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          {Object.values(row).map((value, key) => {
            return (
              <TableCell component="th" key={key} className={styles.tableContent} scope="row">
                {isValidElement(value) ? <>{value}</> : value}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
}

const useStyles = makeStyles((theme) => ({
  tableContent: {
    textTransform: "uppercase",
  },
  cancelSubscriptionItem: {
    display: "grid",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    gridTemplateColumns: "auto auto auto",
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  cancelSubscriptionButton: {
    backgroundColor: "brown",
    width: "100%",
    color: "white",
    border: "0",
    padding: "8px",
    fontSize: "12px",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "orange",
    },
    "&:disabled": {
      backgroundColor: "black",
      color: "white",
      cursor: "not-allowed !important",
    },
  },
  CardDetailItem: {
    display: "grid",
    justifyContent: "space-between",
    alignItems: "center",
    gridTemplateColumns: "3fr 1fr 1fr",
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: "4px",
    // fontSize: "12px",
    gap: "8px",
    margin: "8px",
    padding: "8px",
  },
  textAlignRight: {
    textAlign: "right",
  },
  priceItem: {
    border: "solid 2px brown",
    padding: "4px",
    borderRadius: "5px",
  },
  selectedPriceItem: {
    border: "solid 4px black",
    borderRadius: "5px",
    padding: "2px",
  },
  addtoCartPriceItem: {
    border: "solid 4px orange",
    borderRadius: "5px",
    padding: "2px",
  },
  priceText: {
    textAlign: "center",
    fontWeight: "bold",
  },
  priceAmount: {
    textAlign: "center",
  },
  container: {
    marginTop: "130px",
    padding: "16px",
  },
  priceContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gap: "8px",
  },
  priceAction: {
    background: "brown",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "orange",
    },
    "&:focus": {
      backgroundColor: "brown",
    },
    "&:active": {
      backgroundColor: "orange",
    },
    "&:disabled": {
      color: "white",
      cursor: "not-allowed !important",
    },
  },
  selectedPriceAction: {
    background: "black",
    color: "white",
    width: "100%",
    "&:hover, &:focus": {
      backgroundColor: "black",
    },
    "&:disabled": {
      color: "white",
      cursor: "not-allowed !important",
    },
  },
  AddToCartPriceAction: {
    background: "orange",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "brown",
    },
    "&:focus": {
      backgroundColor: "orange",
    },
    "&:disabled": {
      color: "black",
      cursor: "not-allowed !important",
    },
  },
  cursorDisabled: {
    cursor: "not-allowed !important",
  },
  cardlist: {
    padding: "16px 0",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
  },
  proceedToCheckoutBtn: {
    backgroundColor: "brown",
    width: "100%",
    color: "white",
    border: "0",
    padding: "8px",
    fontSize: "16px",
    borderRadius: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "orange",
    },
    "&:disabled": {
      backgroundColor: "black",
      color: "white",
      cursor: "not-allowed !important",
    },
  },
}));
