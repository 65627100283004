import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Edit from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
  actionIcons: { cursor: "pointer" },
  manageText: { color: "#1E4620", fontSize: "13px", fontWeight: 500, textAlign: "center" },
  icon: { width: "20px", height: "20px", color: "rgba(0,0,0,0.54)", marginRight: "5px" },
}));

const EditComponent = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" className={classes.actionIcons} alignItems="center" onClick={onClick}>
      <Edit className={classes.icon} />
      <Box component="span" className={classes.manageText}>
        EDIT
      </Box>
    </Box>
  );
};

export default EditComponent;
