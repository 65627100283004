import { useState, useEffect, useContext } from "react";
import { Box, Grow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AppContext } from "../../context";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: "2.125rem",
    color: "rgba(0,0,0,0.6)",
    letterSpacing: "0.25px",
    lineHeight: "42px",
    marginBottom: "32px",
    paddingTop: "40px",
    [theme.breakpoints.down(600)]: {
      fontSize: "1.4rem",
    },
  },
  quality: {
    fontSize: "34px",
    color: "#B71C1C",
    letterSpacing: "0.15px",
  },
  subHeader: {
    fontSize: "16px",
    color: "rgba(0,0,0,0.6)",
    letterSpacing: "0.25px",
    lineHeight: "42px",
    marginBottom: "86px",
    [theme.breakpoints.down(600)]: {
      lineHeight: "26px",
      marginBottom: "40px",
    },
  },
  container: {
    height: "224px",
    padding: "0 100px",
    [theme.breakpoints.down(600)]: {
      padding: "10px 20px",
      height: "auto",
    },
  },
  content: {
    marginTop: "40px",
  },
}));

const QualityBox = () => {
  const styles = useStyles();
  const { params } = useContext(AppContext);

  useEffect(() => {
    if (params.length) {
      const results = params.find((x) => x.key === "cornParams");
      if (results) {
        const data = JSON.parse(results.value);
        //need value from API
      }
    }
  }, [params]);

  return (
    <Box className={styles.container}>
      <Grow in={true} timeout={1000}>
        <div>
          <Typography className={styles.header}>
            Last Prediction Quality:{" "}
            <Box component="span" style={{ color: "#B71C1C" }}>
              -2.16%
            </Box>
          </Typography>
          <Box className={styles.subHeader}>
            As of 4/28 this is the average deviation of our prediction from the market prices. This value is updated
            daily.
          </Box>
        </div>
      </Grow>
    </Box>
  );
};

export default QualityBox;
