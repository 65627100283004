import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Login from "./Login2";
import ForgotPassword from "./ForgotPassword";
import SendCode from "./SendCode";

const useStyles = makeStyles((theme) => ({}));

const SignIn = () => {
  const [currentScreen, setCurrentScreen] = useState("login");

  const styles = useStyles();

  return (
    <form aria-label="Login and Forgot password">
      {currentScreen === "login" && <Login setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "send" && <SendCode setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "resetPass" && <ForgotPassword setCurrentScreen={setCurrentScreen} />}
    </form>
  );
};

export default SignIn;
