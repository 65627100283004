import { Box, Container, Grid, Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import { ContactDialog, PageFooter } from "./index";
import { BigLogoIcon } from "./icons/logo";
import { FINAL_DESCRIPTION } from "../utils/constants";
import { DISCLAIMER } from "../utils/constants";

import amexIcon from "../assets/amex.png";
import discoverIcon from "../assets/discover.png";
import jcbIcon from "../assets/jcb.png";
import masterCardIcon from "../assets/master-card.png";
import unionPayIcon from "../assets/union-pay.png";
import visaIcon from "../assets/visa.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: "100px",
    minHeight: "auto",
    textAlign: "center",
    color: "white",
    background: "#016938",
    [theme.breakpoints.down('sm')]: {
      marginTop: '9.5rem'
    }
  },
  logoWrapper: {
    backgroundColor: "#016938",
    borderRadius: "50%",
    maxWidth: 247,
    height: 247,
    marginTop: "-135px",
    marginInline: "auto",
    padding: "30px 10px 0",
    cursor: "pointer",
  },
  footerMenuRow: {
    marginBottom: 60,
  },
  footerMenu: {
    fontFamily: "Inter",
    fontSize: 18,
    margin: "0 2%",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto 15px",
      display: "block",
      textAlign: "center",
    },
  },
  footerPageFooterRow: {
    marginBottom: 50,
  },
  footerNoteRow: {
    marginBottom: 60,
  },
  footerNote: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#bbb6b6",
  },
  bottomBar: {
    backgroundColor: "#005C31",
    paddingTop: 18,
    paddingBottom: 18,
  },
  bottomBarText: {
    fontFamily: "Inter",
    fontSize: 16,
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  paymentCardWrapper: {
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  cardIcon: {
    width: 47,
    height: 34,
    margin: "0px 3px",
  },
}));

const footerMenu = [
  { href: "/about", title: "About Us" },
  { href: "/faqs", title: "FAQs" },
  { href: "/terms", title: "Terms & Conditions" },
  { href: "/privacy", title: "Privacy Policy" },
];

const Footer = () => {
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { pathname } = useLocation();

  const logoClickHandler = () => {
    pathname === "/" ? window.scrollTo(0, 0) : history.push("/");
  };

  return (
    <Box className={styles.footer}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box onClick={logoClickHandler} className={styles.logoWrapper}>
              <BigLogoIcon />
            </Box>
          </Grid>
          <Grid item xs={12} className={styles.footerMenuRow}>
            {footerMenu.map((menu) => (
              <Link
                key={menu.title}
                component="button"
                underline="none"
                onClick={() => window.location.href = menu.href}
                className={styles.footerMenu}
                style={{ color: pathname === menu.href ? theme.palette.secondary.main : "white" }}>
                {menu.title}
              </Link>
            ))}
          </Grid>
          <Grid item xs={12} className={styles.footerPageFooterRow}>
            <PageFooter />
            <ContactDialog />
          </Grid>
          <Grid item xs={12} className={styles.footerNoteRow}>
            <Typography className={styles.footerNote} mb={4}>
              {FINAL_DESCRIPTION}
            </Typography>
            <Typography className={styles.footerNote} mb={4}>
              {DISCLAIMER}
            </Typography>            
            <Typography className={styles.footerNote}>
              <Typography component="span" color="#eba83a">
                Note:
              </Typography>{" "}
              All payments, prices and transactions are conducted in US Dollars (USD).
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Box className={styles.bottomBar}>
        <Container>
          <Grid container spacing={3} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md={6}>
              <Typography className={styles.bottomBarText}>All Rights Reserved. Property of GFY 2023 (c)</Typography>
            </Grid>
            <Grid className={styles.paymentCardWrapper} item xs={12} md={6}>
              <img className={styles.cardIcon} src={amexIcon} alt="Amex" />
              <img className={styles.cardIcon} src={discoverIcon} alt="Discover" />
              <img className={styles.cardIcon} src={jcbIcon} alt="JCB" />
              <img className={styles.cardIcon} src={masterCardIcon} alt="Master Card" />
              <img className={styles.cardIcon} src={unionPayIcon} alt="Union Pay" />
              <img className={styles.cardIcon} src={visaIcon} alt="Visa" />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
