import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { LogoIcon } from "../../components/icons/logo";
import PricingCard from "../PricingCard";

const useStyles = makeStyles((theme) => ({
  lastContent: {
    bottom: 0,
    width: "100%",
    boxSizing: "border-box",
    padding: "30px 0px 20px",
    color: "rgba(0,0,0,0.6)",
    fontSize: "11px",
    lineHeight: "1.5",
    flex: "0 0 82%",
    alignSelf: "flex-end",
    margin: "0 auto",
    display: "none",
    [theme.breakpoints.down(768)]: {
      padding: "30px 15px 0px",
    },
    [theme.breakpoints.down(600)]: {
      position: "static",
      padding: "30px 0 0",
      display: "block",
    },
    [theme.breakpoints.down(992)]: {
      fontSize: "11px",
      flex: "0 0 100%",
    },
  },
  subscriptionTextDiv: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "10px",
  },
  copyright: {
    marginTop: "8px",
    color: "#3b5e3d",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "15px",
  },
  headerMenu: {
    color: "#3b5e3d",
    fontSize: "15px",
    cursor: "pointer",
  },
}));

const LoginSubscription = (props) => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <>
      <Box component="div" className={styles.subscriptionTextDiv}>
        <PricingCard
          title="Just One"
          description="Subscribe to one crop with real-time text alerts."
          price={999.99}
          hideActionButtons
        />
        <PricingCard
          title="All Grain"
          description="Subscribe to all three crops with real-time text alerts."
          content="Corn, Soybeans, HRW, MPLS, Cotton Soybean Meal"
          price={1999.99}
          hideActionButtons
        />
      </Box>

      <Box className={styles.lastContent}>
        <Box sx={{ display: { xs: "block", sm: "none" } }} mt={3} lineHeight="2">
          <Box onClick={() => history.push("/about")} className={styles.headerMenu}>
            About Us
          </Box>
          <Box onClick={() => history.push("/faqs#contact")} className={styles.headerMenu}>
            FAQs
          </Box>
          <Box onClick={() => history.push("/privacy")} className={styles.headerMenu}>
            Privacy Policy
          </Box>
          <Box onClick={() => history.push("/terms")} className={styles.headerMenu}>
            Terms and Conditions
          </Box>
          <Box onClick={() => history.push("/faqs#refunds")} className={styles.headerMenu}>
            Returns and Refunds
          </Box>
          <Box onClick={() => history.push("/faqs")} className={styles.headerMenu}>
            Frequently Asked Questions
          </Box>
        </Box>
        <Box className={styles.copyright} sx={{ display: { xs: "flex", sm: "none" } }}>
          <Box mr={1}>
            <LogoIcon height="20" primary="#AC0303" secondary="#FFFFFF" />
          </Box>
          All Rights Reserved. Property of GFY 2021 (c)
        </Box>
      </Box>
    </>
  );
};

export default LoginSubscription;
