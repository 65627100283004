export const LogoIcon = (props) => {
  const { height, primary, secondary } = props;

  return (
    <svg
      height={height}
      viewBox="0 0 132 107"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Desktop-HD-Copy" transform="translate(-653.000000, -1324.000000)">
          <g id="Group-3" transform="translate(659.000000, 1330.000000)">
            <g id="Group">
              <g id="Path-12-+-Path-13-+-Path-13-+-Oval-+-Oval-Copy-2-+-Oval-Copy-4-+-Oval-Copy-+-Oval-Copy-3-+-Oval-Copy-5-+-Oval-Copy-6-+-Oval-Copy-7-+-Path-14-+-Path-14-+-Path-15-+-Path-16-+-Path-16-+-Path-17-+-Path-17-Copy-+-Path-17-Copy-3-+-Path-17-Copy-2-Mask">
                <path
                  d="M17.6259607,101 L101.861657,101 C103.785928,101 105.345858,99.4400698 105.345858,97.5157985 L105.345858,62.4826329 C105.345858,61.241225 104.851847,60.0508465 103.972856,59.1742227 L103.104657,58.3083614 C102.769736,57.9743414 102.482679,57.595553 102.251666,57.1827878 L87.9975149,31.7139935 C87.5300933,30.8788218 86.8380999,30.1912598 85.9999425,29.7292134 L62.41946,16.7301292 C60.8650004,15.8732108 58.9777675,15.8818532 57.4312213,16.7529724 L34.3888467,29.7319934 C33.5711469,30.1925772 32.8956523,30.8688953 32.4360652,31.6871558 L18.2910419,56.8713153 C17.9451972,57.4870659 17.4757955,58.0246737 16.9123036,58.45039 L15.9542153,59.1742227 C14.8127205,60.0366184 14.1417592,61.3844473 14.1417592,62.8150893 L14.1417592,97.5157985 C14.1417592,99.4400698 15.7016893,101 17.6259607,101 Z"
                  id="Path-12"
                  fill={primary}></path>
                <path
                  d="M60.0512383,56.0295328 L67.528818,50.6096639 C68.1751795,50.1411708 68.7031331,49.5281312 69.0705767,48.8194324 L82.6600891,22.6089616 C83.1083287,21.7444295 83.7936558,21.025536 84.6357834,20.5365006 L120,0 L120,0"
                  id="Path-13"
                  stroke={primary}
                  strokeWidth="10.3501281"
                  strokeLinecap="round"
                  strokeLinejoin="bevel"
                  transform="translate(90.025619, 28.014766) scale(-1, 1) translate(-90.025619, -28.014766) "></path>
                <path
                  d="M0,56.0295328 L7.47757975,50.6096639 C8.12394122,50.1411708 8.6518948,49.5281312 9.01933848,48.8194324 L22.6088509,22.6089616 C23.0570904,21.7444295 23.7424175,21.025536 24.5845451,20.5365006 L59.9487617,0 L59.9487617,0"
                  id="Path-13"
                  stroke={primary}
                  strokeWidth="10.3501281"
                  strokeLinecap="round"
                  strokeLinejoin="bevel"></path>
                <ellipse
                  id="Oval"
                  fill={secondary}
                  cx="39.2485056"
                  cy="53.6788038"
                  rx="5.43125534"
                  ry="5.41689719"></ellipse>
                <ellipse
                  id="Oval-Copy-3"
                  fill={secondary}
                  cx="59.9487617"
                  cy="44.4802991"
                  rx="5.43125534"
                  ry="5.41689719"></ellipse>
                <ellipse
                  id="Oval-Copy-5"
                  fill={secondary}
                  cx="59.7438087"
                  cy="82.5007851"
                  rx="5.43125534"
                  ry="5.41689719"></ellipse>
                <ellipse
                  id="Oval-Copy-7"
                  fill={secondary}
                  cx="59.7438087"
                  cy="63.4905421"
                  rx="5.43125534"
                  ry="5.41689719"></ellipse>
                <ellipse
                  id="Oval-Copy-8"
                  fill={secondary}
                  cx="82.0836892"
                  cy="63.4905421"
                  rx="5.43125534"
                  ry="5.41689719"></ellipse>
                <ellipse
                  id="Oval-Copy"
                  fill={secondary}
                  cx="39.2485056"
                  cy="72.893458"
                  rx="5.43125534"
                  ry="5.41689719"></ellipse>
              </g>
              <polygon
                id="Path-18"
                stroke={secondary}
                strokeWidth="2.04953032"
                points="39.2485056 54.2846349 60.0512383 44.4802991 82.0836892 63.4905421 59.7438087 82.8329069 39.2485056 72.893458 59.7438087 63.656603"></polygon>
              <line
                x1="59.7438087"
                y1="63.656603"
                x2="82.0836892"
                y2="63.656603"
                id="Path-19"
                stroke={secondary}
                strokeWidth="2.04953032"></line>
            </g>
            <line
              x1="39.2485056"
              y1="54.2846349"
              x2="60"
              y2="82.8329069"
              id="Path-20"
              stroke={secondary}
              strokeWidth="2.04953032"></line>
            <line
              x1="39.2485056"
              y1="45.0861302"
              x2="60"
              y2="73.6344022"
              id="Path-20"
              stroke="FFFFFF"
              strokeWidth="2.04953032"
              transform="translate(49.624253, 59.360266) scale(1, -1) translate(-49.624253, -59.360266) "></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const BigLogoIcon = () => (
  <svg
    width="198px"
    height="170px"
    viewBox="0 0 132 107"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>A0BF1BDD-332B-4B26-868C-BD12ADE20F37@1.5x</title>
    <g id="Welcome" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="credibility-page" transform="translate(-654.000000, -1273.000000)">
        <g id="Group-3" transform="translate(660.000000, 1279.000000)">
          <g id="Group">
            <g id="Path-12-+-Path-13-+-Path-13-+-Oval-+-Oval-Copy-2-+-Oval-Copy-4-+-Oval-Copy-+-Oval-Copy-3-+-Oval-Copy-5-+-Oval-Copy-6-+-Oval-Copy-7-+-Path-14-+-Path-14-+-Path-15-+-Path-16-+-Path-16-+-Path-17-+-Path-17-Copy-+-Path-17-Copy-3-+-Path-17-Copy-2-Mask">
              <path
                d="M17.6259607,101 L101.861657,101 C103.785928,101 105.345858,99.4400698 105.345858,97.5157985 L105.345858,62.4826329 C105.345858,61.241225 104.851847,60.0508465 103.972856,59.1742227 L103.104657,58.3083614 C102.769736,57.9743414 102.482679,57.595553 102.251666,57.1827878 L87.9975149,31.7139935 C87.5300933,30.8788218 86.8380999,30.1912598 85.9999425,29.7292134 L62.41946,16.7301292 C60.8650004,15.8732108 58.9777675,15.8818532 57.4312213,16.7529724 L34.3888467,29.7319934 C33.5711469,30.1925772 32.8956523,30.8688953 32.4360652,31.6871558 L18.2910419,56.8713153 C17.9451972,57.4870659 17.4757955,58.0246737 16.9123036,58.45039 L15.9542153,59.1742227 C14.8127205,60.0366184 14.1417592,61.3844473 14.1417592,62.8150893 L14.1417592,97.5157985 C14.1417592,99.4400698 15.7016893,101 17.6259607,101 Z"
                id="Path-12"
                fill="#FFFFFF"></path>
              <path
                d="M60.0512383,56.0295328 L67.528818,50.6096639 C68.1751795,50.1411708 68.7031331,49.5281312 69.0705767,48.8194324 L82.6600891,22.6089616 C83.1083287,21.7444295 83.7936558,21.025536 84.6357834,20.5365006 L120,0 L120,0"
                id="Path-13"
                stroke="#FFFFFF"
                strokeWidth="10.3501281"
                strokeLinecap="round"
                strokeLinejoin="bevel"
                transform="translate(90.025619, 28.014766) scale(-1, 1) translate(-90.025619, -28.014766) "></path>
              <path
                d="M0,56.0295328 L7.47757975,50.6096639 C8.12394122,50.1411708 8.6518948,49.5281312 9.01933848,48.8194324 L22.6088509,22.6089616 C23.0570904,21.7444295 23.7424175,21.025536 24.5845451,20.5365006 L59.9487617,0 L59.9487617,0"
                id="Path-13"
                stroke="#FFFFFF"
                strokeWidth="10.3501281"
                strokeLinecap="round"
                strokeLinejoin="bevel"></path>
              <ellipse
                id="Oval"
                fill="#AC0303"
                cx="39.2485056"
                cy="53.6788038"
                rx="5.43125534"
                ry="5.41689719"></ellipse>
              <ellipse
                id="Oval-Copy-3"
                fill="#AC0303"
                cx="59.9487617"
                cy="44.4802991"
                rx="5.43125534"
                ry="5.41689719"></ellipse>
              <ellipse
                id="Oval-Copy-5"
                fill="#AC0303"
                cx="59.7438087"
                cy="82.5007851"
                rx="5.43125534"
                ry="5.41689719"></ellipse>
              <ellipse
                id="Oval-Copy-7"
                fill="#AC0303"
                cx="59.7438087"
                cy="63.4905421"
                rx="5.43125534"
                ry="5.41689719"></ellipse>
              <ellipse
                id="Oval-Copy-8"
                fill="#AC0303"
                cx="82.0836892"
                cy="63.4905421"
                rx="5.43125534"
                ry="5.41689719"></ellipse>
              <ellipse
                id="Oval-Copy"
                fill="#AC0303"
                cx="39.2485056"
                cy="72.893458"
                rx="5.43125534"
                ry="5.41689719"></ellipse>
            </g>
            <polygon
              id="Path-18"
              stroke="#AC0303"
              strokeWidth="2.04953032"
              points="39.2485056 54.2846349 60.0512383 44.4802991 82.0836892 63.4905421 59.7438087 82.8329069 39.2485056 72.893458 59.7438087 63.656603"></polygon>
            <line
              x1="59.7438087"
              y1="63.656603"
              x2="82.0836892"
              y2="63.656603"
              id="Path-19"
              stroke="#AC0303"
              strokeWidth="2.04953032"></line>
          </g>
          <line
            x1="39.2485056"
            y1="54.2846349"
            x2="60"
            y2="82.8329069"
            id="Path-20"
            stroke="#AC0303"
            strokeWidth="2.04953032"></line>
          <line
            x1="39.2485056"
            y1="45.0861302"
            x2="60"
            y2="73.6344022"
            id="Path-20"
            stroke="#AC0303"
            strokeWidth="2.04953032"
            transform="translate(49.624253, 59.360266) scale(1, -1) translate(-49.624253, -59.360266) "></line>
        </g>
      </g>
    </g>
  </svg>
);

export const NamedLogoIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="139.1px"
    height="13.6px"
    viewBox="0 0 139.1 13.6">
    <g id="Symbols">
      <g id="beta_x2F_logo-copy">
        <g id="Logo-Text-" transform="translate(37.000000, 2.000000)">
          <path
            id="gofarmyourself.beta"
            className="st0"
            fill="#FFFFFF"
            d="M-33.8,11.6c1,0,1.8-0.3,2.4-0.8s0.9-1.3,0.9-2.3l0,0V1.2H-32L-32.1,2
				c-0.5-0.6-1.1-0.9-2-0.9c-0.9,0-1.6,0.3-2.1,1S-37,3.7-37,4.9c0,1.2,0.3,2.2,0.8,2.9s1.3,1,2.1,1c0.8,0,1.4-0.3,1.9-0.8l0,0v0.5
				c0,0.6-0.2,1-0.5,1.3s-0.8,0.4-1.3,0.4c-0.7,0-1.4-0.3-1.9-0.9l0,0l-0.8,1c0.3,0.4,0.7,0.7,1.2,0.9S-34.4,11.6-33.8,11.6z
				 M-33.6,7.4c-0.5,0-1-0.2-1.3-0.6s-0.5-1-0.5-1.8c0-0.8,0.2-1.5,0.5-1.9s0.7-0.6,1.3-0.6c0.7,0,1.2,0.3,1.5,0.8l0,0v3.3
				C-32.5,7.1-33,7.4-33.6,7.4z M-25.5,8.8c0.7,0,1.3-0.2,1.8-0.5s0.9-0.8,1.2-1.3s0.4-1.2,0.4-2l0,0l0-0.4c-0.1-1.1-0.4-1.9-1-2.6
				s-1.4-1-2.4-1c-0.7,0-1.3,0.2-1.8,0.5s-0.9,0.8-1.2,1.3s-0.4,1.2-0.4,2l0,0V5c0,1.2,0.3,2.1,0.9,2.8S-26.6,8.8-25.5,8.8z
				 M-25.5,7.5c-0.6,0-1-0.2-1.3-0.6s-0.5-1-0.5-1.8c0-0.9,0.2-1.5,0.5-1.9s0.7-0.6,1.3-0.6c0.6,0,1,0.2,1.3,0.7s0.5,1,0.5,1.8
				c0,0.8-0.2,1.5-0.5,1.9S-25,7.5-25.5,7.5z M-14.8,8.6V2.5h1.5V1.2h-1.5V0.6c0-0.8,0.4-1.2,1.2-1.2c0.2,0,0.5,0,0.6,0.1l0,0l0-1.3
				C-13.2-2-13.5-2-13.8-2c-0.8,0-1.5,0.2-1.9,0.7c-0.5,0.4-0.7,1.1-0.7,1.9l0,0v0.7h-1.1v1.2h1.1v6.2H-14.8z M-9.7,8.8
				c0.8,0,1.4-0.3,1.9-0.8c0.1,0.3,0.1,0.5,0.2,0.7l0,0h1.7V8.5C-6,8.1-6.1,7.6-6.1,6.9l0,0V3.6c0-0.8-0.3-1.4-0.8-1.8
				C-7.4,1.3-8.1,1.1-9,1.1c-0.6,0-1.1,0.1-1.5,0.3c-0.5,0.2-0.8,0.5-1.1,0.8C-11.9,2.6-12,3-12,3.4l0,0h1.7c0-0.3,0.1-0.5,0.4-0.7
				c0.2-0.2,0.6-0.3,0.9-0.3c0.4,0,0.8,0.1,1,0.3C-7.9,3-7.8,3.3-7.8,3.6l0,0v0.5h-1c-1.1,0-1.9,0.2-2.5,0.6c-0.6,0.4-0.9,1-0.9,1.8
				c0,0.6,0.2,1.1,0.7,1.6C-11,8.6-10.4,8.8-9.7,8.8z M-9.4,7.4c-0.4,0-0.6-0.1-0.9-0.3s-0.3-0.4-0.3-0.8c0-0.4,0.2-0.7,0.5-0.9
				c0.3-0.2,0.8-0.3,1.4-0.3l0,0h0.9v1.4C-7.9,6.8-8.1,7-8.4,7.2C-8.7,7.4-9,7.4-9.4,7.4z M-2.6,8.6V3.6C-2.4,3-1.8,2.7-1.1,2.7
				c0.2,0,0.5,0,0.7,0.1l0,0l0-1.6C-0.6,1.1-0.8,1.1-1,1.1c-0.7,0-1.3,0.3-1.7,1l0,0l0-0.8h-1.6v7.4H-2.6z M2.4,8.6V3.3
				c0.3-0.5,0.7-0.8,1.4-0.8c0.9,0,1.3,0.4,1.3,1.3l0,0v4.9h1.7l0-5.1C6.9,3.2,7.1,3,7.3,2.8s0.5-0.3,0.9-0.3c0.5,0,0.8,0.1,1,0.3
				s0.3,0.6,0.3,1l0,0v4.8h1.7V3.8c0-0.9-0.2-1.5-0.6-2c-0.4-0.5-1-0.7-1.8-0.7c-1,0-1.7,0.4-2.3,1.1c-0.4-0.8-1-1.1-2-1.1
				C3.6,1.1,2.9,1.4,2.4,2l0,0l0-0.8H0.7v7.4H2.4z M17.2,11.6c1.1,0,1.8-0.6,2.3-1.9l0,0l2.9-8.5h-1.8l-1.5,5l-1.6-5h-1.8l2.6,7.4
				l-0.2,0.6c-0.1,0.4-0.3,0.6-0.5,0.8s-0.5,0.2-0.9,0.2l0,0l-0.3,0v1.3C16.7,11.6,17,11.6,17.2,11.6z M26.6,8.8
				c0.7,0,1.3-0.2,1.8-0.5C28.9,8,29.3,7.5,29.6,7S30,5.7,30,5l0,0l0-0.4c-0.1-1.1-0.4-1.9-1-2.6s-1.4-1-2.4-1
				c-0.7,0-1.3,0.2-1.8,0.5s-0.9,0.8-1.2,1.3s-0.4,1.2-0.4,2l0,0V5c0,1.2,0.3,2.1,0.9,2.8C24.7,8.4,25.5,8.8,26.6,8.8z M26.6,7.5
				c-0.6,0-1-0.2-1.3-0.6s-0.5-1-0.5-1.8c0-0.9,0.2-1.5,0.5-1.9c0.3-0.4,0.7-0.6,1.3-0.6c0.6,0,1,0.2,1.3,0.7s0.5,1,0.5,1.8
				c0,0.8-0.2,1.5-0.5,1.9C27.6,7.2,27.1,7.5,26.6,7.5z M33.9,8.8c0.9,0,1.6-0.3,2.1-0.9l0,0l0,0.7h1.6V1.2h-1.7v5.3
				c-0.3,0.6-0.8,0.9-1.6,0.9c-0.8,0-1.2-0.5-1.2-1.4l0,0V1.2h-1.7V6c0,0.9,0.2,1.6,0.6,2S33.1,8.8,33.9,8.8z M41,8.6V3.6
				c0.3-0.6,0.8-0.9,1.5-0.9c0.2,0,0.5,0,0.7,0.1l0,0l0-1.6c-0.2-0.1-0.4-0.1-0.6-0.1c-0.7,0-1.3,0.3-1.7,1l0,0l0-0.8h-1.6v7.4H41z
				 M47.2,8.8c0.9,0,1.6-0.2,2.2-0.6c0.6-0.4,0.8-1,0.8-1.6c0-0.4-0.1-0.7-0.3-1c-0.2-0.3-0.5-0.5-0.8-0.7c-0.4-0.2-0.9-0.4-1.5-0.5
				c-0.7-0.1-1.1-0.3-1.3-0.4c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.9-0.3c0.4,0,0.7,0.1,0.9,0.3
				c0.2,0.2,0.4,0.5,0.4,0.8l0,0h1.7c0-0.7-0.3-1.3-0.8-1.7c-0.5-0.4-1.3-0.7-2.1-0.7c-0.8,0-1.5,0.2-2.1,0.6
				c-0.5,0.4-0.8,1-0.8,1.6c0,0.8,0.4,1.3,1.2,1.7c0.4,0.2,0.8,0.3,1.4,0.5c0.6,0.1,1,0.3,1.2,0.4c0.2,0.2,0.4,0.4,0.4,0.7
				c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.6,0.2-1,0.2c-0.5,0-0.8-0.1-1.1-0.3s-0.4-0.5-0.4-0.9l0,0h-1.6c0,0.4,0.1,0.9,0.4,1.2
				c0.3,0.4,0.6,0.7,1.1,0.9C46,8.7,46.6,8.8,47.2,8.8z M55,8.8c0.6,0,1.2-0.1,1.7-0.4c0.5-0.2,0.9-0.6,1.2-1l0,0l-0.9-0.9
				c-0.5,0.6-1.1,0.9-1.9,0.9c-0.5,0-1-0.2-1.4-0.5c-0.4-0.4-0.6-0.8-0.6-1.4l0,0h4.8V4.8c0-1.2-0.3-2.1-0.8-2.7
				c-0.5-0.6-1.3-1-2.3-1c-0.6,0-1.2,0.2-1.7,0.5c-0.5,0.3-0.9,0.8-1.2,1.4c-0.3,0.6-0.4,1.2-0.4,2l0,0v0.2c0,1.1,0.3,2,1,2.6
				C53.1,8.4,53.9,8.8,55,8.8z M56.3,4.3h-3.2c0.1-0.6,0.3-1,0.5-1.3s0.6-0.5,1.1-0.5c0.5,0,0.8,0.1,1.1,0.4s0.4,0.7,0.4,1.3l0,0
				V4.3z M61.1,8.6V-1.9h-1.7V8.6H61.1z M65.3,8.6V2.5h1.5V1.2h-1.5V0.6c0-0.8,0.4-1.2,1.2-1.2c0.2,0,0.5,0,0.6,0.1l0,0l0-1.3
				C66.8-2,66.5-2,66.2-2c-0.8,0-1.5,0.2-1.9,0.7s-0.7,1.1-0.7,1.9l0,0v0.7h-1.1v1.2h1.1v6.2H65.3z M69.1,8.7c0.3,0,0.5-0.1,0.7-0.3
				c0.2-0.2,0.3-0.4,0.3-0.6c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
				s0.1,0.5,0.3,0.6C68.6,8.6,68.8,8.7,69.1,8.7z M79.3,8.8c0.9,0,1.6-0.3,2.1-1s0.8-1.6,0.8-2.7l0,0V4.9c0-1.2-0.3-2.1-0.8-2.8
				c-0.5-0.7-1.2-1-2.1-1c-0.8,0-1.5,0.3-1.9,0.8l0,0v-3.8h-1.7V8.6h1.5l0.1-0.8C77.7,8.5,78.4,8.8,79.3,8.8z M78.9,7.4
				c-0.7,0-1.2-0.3-1.5-1l0,0v-3c0.3-0.6,0.8-0.9,1.5-0.9c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.4,1,0.4,1.8l0,0v0.2
				c0,0.8-0.2,1.4-0.4,1.8C79.8,7.2,79.4,7.4,78.9,7.4z M86.9,8.8c0.6,0,1.2-0.1,1.7-0.4c0.5-0.2,0.9-0.6,1.2-1l0,0l-0.9-0.9
				c-0.5,0.6-1.1,0.9-1.9,0.9c-0.5,0-1-0.2-1.4-0.5c-0.4-0.4-0.6-0.8-0.6-1.4l0,0h4.8V4.8c0-1.2-0.3-2.1-0.8-2.7
				c-0.5-0.6-1.3-1-2.3-1c-0.6,0-1.2,0.2-1.7,0.5c-0.5,0.3-0.9,0.8-1.2,1.4c-0.3,0.6-0.4,1.2-0.4,2l0,0v0.2c0,1.1,0.3,2,1,2.6
				C85,8.4,85.9,8.8,86.9,8.8z M88.2,4.3h-3.2c0.1-0.6,0.3-1,0.5-1.3s0.6-0.5,1.1-0.5c0.5,0,0.8,0.1,1.1,0.4s0.4,0.7,0.4,1.3l0,0
				V4.3z M93.6,8.8c0.4,0,0.7-0.1,1.1-0.2l0,0V7.3c-0.2,0-0.4,0.1-0.6,0.1c-0.3,0-0.5-0.1-0.6-0.2s-0.2-0.3-0.2-0.6l0,0V2.5h1.3V1.2
				h-1.3v-1.8h-1.7v1.8h-1.2v1.2h1.2v4.2C91.7,8.1,92.3,8.8,93.6,8.8z M98.3,8.8c0.8,0,1.4-0.3,1.9-0.8c0.1,0.3,0.1,0.5,0.2,0.7l0,0
				h1.7V8.5c-0.2-0.4-0.3-0.9-0.3-1.6l0,0V3.6c0-0.8-0.3-1.4-0.8-1.8c-0.5-0.4-1.2-0.7-2.1-0.7c-0.6,0-1.1,0.1-1.5,0.3
				c-0.5,0.2-0.8,0.5-1.1,0.8c-0.3,0.4-0.4,0.7-0.4,1.1l0,0h1.7c0-0.3,0.1-0.5,0.4-0.7c0.2-0.2,0.6-0.3,0.9-0.3c0.4,0,0.8,0.1,1,0.3
				c0.2,0.2,0.3,0.5,0.3,0.9l0,0v0.5h-1c-1.1,0-1.9,0.2-2.5,0.6c-0.6,0.4-0.9,1-0.9,1.8c0,0.6,0.2,1.1,0.7,1.6
				C97,8.6,97.6,8.8,98.3,8.8z M98.6,7.4c-0.4,0-0.6-0.1-0.9-0.3s-0.3-0.4-0.3-0.8c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.8-0.3,1.4-0.3
				l0,0h0.9v1.4C100,6.8,99.8,7,99.5,7.2C99.2,7.4,98.9,7.4,98.6,7.4z"
          />
        </g>
      </g>
    </g>
  </svg>
);
