export const newTradeLedgerCollection = `
query($order: [NewTradeLedgerOrder], $where: NewTradeLedgerFilter) {
  newTradeLedgerCollection(order: $order, where: $where) {
    items {
      cropContractName,
      tradeType,
      tradePrice,
      cropContractYear,
      cropContractMonth,
      datetime
    }
  }
}
`;

export const faqCollection = `
query($order: [FaqOrder]) {
  faqCollection(order: $order) {
    items {
      id,
      question,
      answer
    }
  }
}
`;

export const videoPlayerCollection = `
query($order: [VideoPlayerOrder], $where: VideoPlayerFilter) {
  videoPlayerCollection(order: $order, where: $where) {
    items {
      src {
        url
      },
      placeholder {
        url
      },
    }
  }
}
`;

export const resultsCollection = `
  query($order: [ResultsOrder]) {
    resultsCollection(order: $order) {
      items {
        year,
        type,
        profitPerBushelGfy,
        highestSellAlert,
        avgPrice,
        cbotAvg,
        numberOfAlerts,
        year
      }
    }
  }
`;

export const landingPageCollection = `
  query {
    landingPageCollection {
      items {
        key,
        value
      }
    }
  }
`;

export const additionalProfitCollection = `
  query {
    additionalProfitCollection {
      items {
        value
      }
    }
  }
`;

export const notificationsCollection = `
  query($where: NotificationsFilter) {
    notificationsCollection(where: $where) {
      items {
        content
      }
    }
  }
`;
