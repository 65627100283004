import { useContext } from "react";
import { Box, Container, Paper, Divider, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import QA from "./qa";
import Team from "./team";
import { GeneralLayout } from "../../layouts";
import { AppContext } from "../../context";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down(600)]: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  header: {
    fontSize: "2rem",
    fontWeight: 500,
    marginBottom: "16px",
  },
  divider: {
    marginTop: "40px",
  },
  questions: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: 500,
    width: "685px",
    marginLeft: "10px",
    [theme.breakpoints.down(600)]: {
      width: "auto",
      marginBottom: "24px",
      lineHeight: "1.8rem",
    },
  },
  chatContainer: {
    width: "300px",
  },
  questionsContainer: {
    marginTop: "24px",
    marginBottom: "24px",
    [theme.breakpoints.down(600)]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  button: {
    color: "#1E4620",
    fontWeight: 500,
    "&.MuiButton-outlined": {
      border: "1px solid #1E4620",
    },
  },
}));

const About = () => {
  const styles = useStyles();
  const { setShowContact } = useContext(AppContext);

  return (
    <GeneralLayout>
      <Container>
        <Paper elevation={4}>
          <Box p={4} mt={6} mb={6} className={styles.container}>
            <Box mt={2} color="#1E4620" className={styles.header}>
              About Us
            </Box>

            <Divider />
            <QA />
            <Divider className={styles.divider} />

            <Box display="flex" flexDirection="column">
              <Team />

              <Divider className={styles.divider} />
              <Box display="flex" justifyContent="center" alignItems="center" className={styles.questionsContainer}>
                <Typography variant="h3" className={styles.questions}>
                  Do you have questions for us? Suggestions? Just feeling lonely? 🤠
                </Typography>
                <Box className={styles.chatContainer}>
                  <Button
                    variant="outlined"
                    fullWidth
                    className={styles.button}
                    classes={{ label: styles.button }}
                    onClick={() => setShowContact(true)}>
                    Request a Free Trial (?)
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </GeneralLayout>
  );
};

export default About;
