import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import SendCode from "./SendCode";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: "42px",
  },
}));

const SignIn = () => {
  const [currentScreen, setCurrentScreen] = useState("login");

  const styles = useStyles();

  return (
    <form className={styles.formContainer}>
      {currentScreen === "login" && <Login setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "send" && <SendCode setCurrentScreen={setCurrentScreen} />}
      {currentScreen === "resetPass" && <ForgotPassword setCurrentScreen={setCurrentScreen} />}
    </form>
  );
};

export default SignIn;
