import React from 'react'
import { 
  Caption as tCaption,
  H1 as tH1, 
  H2 as tH2,
  H3 as tH3, 
  H4 as tH4, 
  Label as label,
  LabelR as labelR,
  Subtitles as subtitles,
  SubtitlesR as subtitlesR,
} from '../Theme/typography';


const setProps = (props) => {
  let className = '';
  let filterdProps = {};
  for (const prop in props) {
    filterdProps.prop = props[prop];
  }
  filterdProps.className = `${className} ${props.className}`;
  return {...props, ...filterdProps};
}


export const H1 = (props) => {
  const { children } = props;
  return <h1 {...setProps(props)} style={tH1}> {children} </h1>
};

export const H2 = (props) => {
  const { children } = props;
  return <h2 {...setProps(props)} style={tH2}> {children} </h2>
};


export const H3 = (props) => {
  const { children } = props;
  return <h3 {...setProps(props)} style={tH3}> {children} </h3>
};


export const H4 = (props) => {
  const { children } = props;
  return <h4 {...setProps(props)} style={tH4}> {children} </h4>
};


export const Caption = (props) => {
  const { children, p } = props;
  if (p) return <p {...setProps(props)} style={tCaption}> {children} </p>
  return <span {...setProps(props)} style={tCaption}> {children} </span>
};


export const Label = (props) => {
  const { children } = props;
  return <p {...setProps(props)} style={label}> {children} </p>
};

export const LabelRegular = (props) => {
  const { children, p } = props;
  if (p) return <p {...setProps(props)} style={labelR}> {children} </p>
  return <span {...setProps(props)} style={labelR}> {children} </span>
};

export const Subtitles = (props) => {
  const { children, p } = props;
  if (p) return <p {...setProps(props)} style={subtitles}> {children} </p>
  return <span {...setProps(props)} style={subtitles}> {children} </span>
};

export const SubtitlesRegular = (props) => {
  const { children, p } = props;
  if (p) return <p {...setProps(props)} style={subtitlesR}> {children} </p>
  return <span {...setProps(props)} style={subtitlesR}> {children} </span>
};
