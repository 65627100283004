import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
// import auth from amplify
import { Auth } from "aws-amplify";
import config from "../../../config";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const HubspotContactForm = (props) => {
  // const { region, portalId, formId } = props;
  const history = useHistory();

  const env = process.env.REACT_APP_NODE_ENV;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    const env = process.env.REACT_APP_NODE_ENV;

    const hubspot_portal_id_key = "HUBSPOT_PORTAL_ID_" + env.toUpperCase();
    const hubspot_portal_id = config[hubspot_portal_id_key];

    const hubspot_form_id_key = "HUBSPOT_FORM_ID_" + env.toUpperCase();
    const hubspot_form_id = config[hubspot_form_id_key];

    script.addEventListener("load", () => {
      let origin = window.location.origin;

      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: hubspot_portal_id,
          formId: hubspot_form_id,
          target: "#hubspotForm",

          onBeforeFormSubmit: async function ($form, submissionValues) {
            const submissionObj = submissionValues.reduce((acc, field) => {
              acc[field.name] = field.value;
              return acc;
            }, {});

            const { email, firstname, lastname, phone } = submissionObj;

            let formattedPhone;
            const phoneNumber = parsePhoneNumberFromString(phone);
            if (phoneNumber && phoneNumber.isValid()) {
              formattedPhone = phoneNumber.formatInternational();
              
            }
            const cognitoUser = {
              username: email, // assuming email as username
              password: "DummyPassword123!", // You should collect password from user
              attributes: {
                email, // default
                phone_number: formattedPhone, // default
                locale: "dummyPassword"
              },
            };

            try {
              // Try signing in to see if user exists
              await Auth.signIn(email, "DummyPassword123!");
              
            } catch (err) {
              // If user does not exist, proceed with signUp
              if (err.code === "UserNotFoundException" || err.code === "NotAuthorizedException") {
                
                try {
                  const cogr = await Auth.signUp(cognitoUser);
                  // add a flag to cognitoUser to indicate that this is a new user
                  cognitoUser.newUser = true;

                  const cogsr = await Auth.signIn(email, "DummyPassword123!");
                  
                } catch (signUpErr) {
                  console.log(signUpErr);
                }
              } else {
                console.log(err);
              }
            }
          },

          onFormSubmitted: function ($form, submission) {
            // console.log(submission); // Log the submission object
            // Do not perform redirect here
            // It is handled by HubSpot automatically
            // console.log("Form successfully submitted");
            setTimeout(() => {

              window.location = `${origin}/stoplight`;
              
            }, 5000);
          },
        });
      }
    });
  }, []);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;
