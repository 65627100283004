import { Box, Container, Paper, Typography } from '@mui/material';
import { param } from 'jquery';
import React from 'react'
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GeneralLayout } from '../../layouts';
import axios from 'axios';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    marginTop: 50,
    marginBottom: 60,
  },
  postContainer: {
    maxWidth: '100%',
    margin: 'auto',
    fontSize: 16,
  },
  cardImage: {
    width: '100%',
    marginTop: 35
  },
  labelCategory: {
    fontSize: 14,
    marginTop: '5%'
  },
  publishedTimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-1.3rem'
  },
  labelPublishedAt: {
    fontSize: 14,
    color: 'gray',
    marginRight: '4%'
  },
  labelReadTime: {
    fontSize: 14,
    color: 'gray'
  },
  h2: {
    color: "#031716",
    fontFamily: "'Coolvetica'",
    fontSize: "60px",
    fontWeight: "400",
    lineHeight: "96%",
    marginInline: "auto",
    marginBottom: 35,
    marginTop: 35,
    [theme.breakpoints.down("lg")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  },
  para: {
    letterSpacing: "-0.03em",
    color: "#858585",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "167%",
    [theme.breakpoints.down("sm")]: {
      marginInline: "auto",
      textAlign: "center",
    },
  },
}))

const Post = () => {

  const params = useParams();
  const [data, setData] = useState(null);
  const styles = useStyles();

  // console.log("Slug information", params.slug);

  useEffect(() => {
    // fetch sinle post information
    fetchPost();
  }, []);

  const fetchPost = async () => {
    await axios.get(`https://api.dropinblog.com/v1/json/post/?b=a236ac05-cded-4f8b-8055-30bd74a717a7&post=${params.slug}`)
      .then((payload) => {
        if (payload.data.data) {
          setData(payload.data.data);
          stringToHTML(payload.data.data?.post?.content)
        }
      })
      .catch((err) => console.log("unable to fetch featured posts", err));
  }

  var stringToHTML = function (str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    document.getElementById('content').innerHTML = doc.body.innerHTML;
  };

  return (
    <GeneralLayout>
      <Container>
        <img src={data?.post?.featuredImage} className={styles.cardImage} />
        {/* <Paper elevation={4} className={styles.container}> */}
        <div className={styles.postContainer}>
          <div>
            {/* <h1>{data?.headTitle ?? ''}</h1> */}
            <Typography variant="h2" className={styles.h2} >{data?.headTitle ?? ''}</Typography>
          </div>
          <div>
            <p className={styles.labelCategory}>{data?.post?.categories[0].title}</p>
            <div className={styles.publishedTimeContainer}>
              <p className={styles.labelPublishedAt}>{data?.post?.publishedAt}</p>
              <p className={styles.labelReadTime}>{data?.post?.readtime}</p>
            </div>
          </div>
          <div id='content' className={styles.para} />
          <Box mb={30}>
            <a href="/blog">{'<< Back to Blog'}</a>
          </Box>
        </div>
        {/* </Paper> */}
      </Container>
    </GeneralLayout>
  );
}

export default Post;