import { Box, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useRef, useState, useContext } from "react";
import Result from "./result";
import { useWindowSize } from "../../hooks/useWindowSize";
import "swiper/swiper-bundle.css";

const useStyles = makeStyles((theme) => ({
  h2: {
    color: "#031716",
    fontFamily: "'Coolvetica'",
    fontSize: "70px",
    fontWeight: "bold",
    lineHeight: "96%",
    [theme.breakpoints.down("lg")]: {
      fontSize: "60px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "40px",
      textAlign: "center",
    },
  },
  resultsContainer: {
    // display: "flex",
    // flexDirection:"column",
    // height: "auto",
    // alignItems:"center",
    // background: "#ffffff",
    // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    // padding: "20px",
    // top: "120%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // position: "absolute",
    // maxWidth: "1500px",
    // zIndex: 1,
    // [theme.breakpoints.down(1765)]: {
    //   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   top: "120%",
    //   padding: "5px",
    //   maxWidth: "1500px",
    // },
    // [theme.breakpoints.down(1300)]: {
    //   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    //   top: "140%",
    //   maxWidth: "1300px",
    // },
    // [theme.breakpoints.down(1180)]: {
    //   boxShadow: "none",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   top: "140%",
    //   padding: "5px",
    //   maxWidth: "1300px",
    // },
    // [theme.breakpoints.down(768)]: {
    //   boxShadow: "none",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   top: "150%",
    //   padding: "5px",
    //   maxWidth: "1000px",
    // },
    // [theme.breakpoints.down(375)]: {
    //   boxShadow: "none",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   top: "160%",
    //   maxWidth: "1500px",
    //   padding: "5px",
    //   maxWidth: "1000px",
    // },
  },
  result: {
    display:"flex", 
    flexDirection:"row", 
    justifyContent: "center",
    width: "73vw",
    [theme.breakpoints.down(1765)]: {
      flexDirection:"row",
    },
    [theme.breakpoints.down(1300)]: {
      flexDirection:"row",
    },
    [theme.breakpoints.down(1180)]: {
      flexDirection:"row",
    },
    [theme.breakpoints.down(768)]: {
      flexDirection:"column",
    },
    [theme.breakpoints.down(375)]: {
      flexDirection:"column",
    },
  },
  resultItem: {
    flexGrow: 1,
    // [theme.breakpoints.up("md")]: {
    //   width: "90%", 
    // },
    // [theme.breakpoints.down("sm")]: {
    //   marginBottom: "20px",
    // },
  },
  heading: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px", 
    position: "relative",
    fontFamily: "'Coolvetica'",
    textAlign:"center",
    fontSize: "50px",
    fontWeight: 500,
    paddingBottom: "8px",
    width: "100%", 
  boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
      fontSize: "30px",
      paddingBottom: "10px",
    },
  },
  label: {
    border: "2px solid #000000",
    fontSize: "16px",
    fontWeight: 700,
    padding: "5px",
  },
  tabs: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5px", 
    position: "relative",
    marginBottom: "20px",
    [theme.breakpoints.down(1508)]: {
      marginTop: "10px",   
    },
}

}));

const Results = ({ items }) => {
  const styles = useStyles();
  const [selectedYear, setSelectedYear] = useState(2023);

  const { width } = useWindowSize();
  const {
    breakpoints: {
    values: { md },
    },
  } = useTheme();

  const handleChange = (e, newValue) => {
    setSelectedYear(newValue)
  }

  return (
    <Box className={styles.resultsContainer} id="results">
      <Box className={styles.headingContainer}>
        <Typography className={styles.heading}>See our results</Typography>
      </Box>
      <Box className={styles.tabs}>
        <Tabs value={selectedYear} onChange={handleChange}>
          <Tab label="2023" value={2023} className={styles.label} style= {{backgroundColor: selectedYear === 2023 ? "#016938" : '#000', color: "#fff"}}/>
          <Tab label="2022" value={2022} className={styles.label} style= {{backgroundColor: selectedYear === 2022 ? "#016938" : '#000', color: "#fff"}}/>
        </Tabs>
      </Box>
      <Box className={styles.result}>
        {items.filter((result) => result.year === selectedYear).map((result, index) => (
          <Box key={index} className={styles.resultItem}>
            <Result key={result.type} data={result} />
          </Box>
        ))}
      </Box>
    </Box>    
  );
};

export default Results;
