import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Theme from "../../Theme/Theme";
import Divider from "../Divider";
import { H3, H4, Label, Caption } from "../Text";
import dayjs from "dayjs";
import { orderBy } from "lodash";
import { useCropSummary, useOpenPositions, usePositionSummaries } from "../../pages/Dashboard";
import { formatCurrency } from "../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: 0,
    marginBottom: 32,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem !important',
      marginBottom: '1.25rem',
      marginTop: '2.2rem',
    },
  },
  boxTwoPara: {
    fontSize: "12px",
    color: "grey",
    fontWeight: "600",
    letterSpacing: "0.5px",
  },
  percentValue: {
    color: "green",
    fontFamily: "Roboto",
    fontSize: "24px",
    letterSpacing: "0.23px",
    lineHeight: "28px",
    fontWeight: "600",
  },
  percentDescription: {
    margin: "auto",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0.15px",
    lineHeight: "20px",
  },
  percentTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginRight: "16px",
  },
  green: {
    color: "green",
  },
  red: {
    color: "red",
  },
  boxTwoContent: {
    border: "1px solid grey",
    borderRadius: "5px",
    marginTop: "10px",
    [theme.breakpoints.down(400)]: {
      padding: "20px",
    },
  },
  boxGridOne: {
    padding: "20px",
    fontSize: "14px",
    textAlign: "center",
    display: "flex",
    [theme.breakpoints.down(1100)]: {
      flexDirection: "column",
    },
  },
  graphContainer: {
    flex: "1",
  },
  perContainer: {
    borderRight: "1px solid grey",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(1100)]: {
      flexDirection: "column",
    },
  },
  container: {
    border: "0.735px solid #D3D3D3",
    padding: 20,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down(1100)]: {
      maxWidth: "100%",
      marginBottom: "18px",
    },
  },
  greenCard: {
    backgroundColor: Theme.colors.greenCardColor,
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    paddingTop: 27,
    paddingBottom: 32,
    paddingRight: '6%',
    paddingLeft: '6%',
    marginRight: '2%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    }
  },
  cardTitle: {
    color: Theme.colors.white,
    marginTop: 0,
    marginBottom: 24,
  },
  cardCenterCircle: {
    width: 113,
    height: 113,
    backgroundColor: Theme.colors.white,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardDescription: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minWidth: 200,
    marginTop: '4%',
  },
  cardLabelDescription: {
    color: Theme.colors.white,
    marginRight: 10
  },
  cardTotal: {
    color: Theme.colors.white,
  },
  details: {
    backgroundColor: '#99C3AF',
    padding: '26px 28px'
  },
  detailInfoText: {
    marginTop: 0,
    marginBottom: 10,
  },
  invoice: {
    paddingTop: 28,
    paddingRight: 26,
    paddingLeft: 26,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 'unset',
      paddingLeft: 'unset',
    }
  },
  labelInvoice: {
    color: '#858585',
    fontWeight: '400 !important'
  },
  invoiceGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  }
}));

const CurrenPositions = ({ listData, momoGraphData, latestMomoRecord, frCropType, productionCycle }) => {
  const styles = useStyles();
  const openPositionsQry = useOpenPositions(frCropType, productionCycle);
  const openPositions = openPositionsQry.data || [];
  const positionSummariesQry = usePositionSummaries(frCropType, productionCycle);
  const positionSummaries = positionSummariesQry.data || [];
  const openPositionSummaries = positionSummaries.filter((x) => x.openLongQuantity !== 0 || x.openShortQuantity !== 0);
  const sortedPositionSummaries = orderBy(openPositionSummaries, (x) => dayjs(x.expirationDate).toDate(), "desc");
  const sortedLedgerSummaries = orderBy(positionSummaries, (x) => dayjs(x.expirationDate).toDate(), "desc");
  const cropSummaryQry = useCropSummary(frCropType, productionCycle);
  const cropSummaries = cropSummaryQry.data || [];
  const cropSummary = cropSummaries.find((cropSummary) => cropSummary.hedgingCropType === frCropType);
  return (
    <div className={styles.graphContainer}>
      <Box display="flex" justifyContent="space-between">
        <H3 className={styles.header}>Current Positions</H3>
      </Box>
      <Box className={styles.container}>
        <Box className={styles.greenCard}>
          <H4 className={styles.cardTitle}>Open Positions</H4>
          <div className={styles.cardCenterCircle}>
            <Label>% Sold</Label>
          </div>
          {positionSummariesQry.isLoading && <p>loading...</p>}
          {
            (!positionSummariesQry.isLoading && sortedPositionSummaries.length) === 0 ? (
              <Typography variant="h6" sx={{ textAlign: "center", padding: "16px" }}>
                No open positions at this time.
              </Typography>
            ) : (
              sortedPositionSummaries.map((summary) => {
                return (
                  <>
                    <Box className={styles.cardDescription}>
                      {summary.openLongQuantity ? <Caption className={styles.cardLabelDescription}>{summary.openLongQuantity ? summary.openLongQuantity : "--"} Long</Caption> : ''}
                      <Caption className={styles.cardLabelDescription}>{formatCurrency(summary.currentPrice)}</Caption>
                      {summary.openShortQuantity ? <Caption className={styles.cardLabelDescription}>{summary.openShortQuantity ? summary.openShortQuantity : "--"} Short</Caption> : ''}
                    </Box>
                    <Divider
                      marginTop={27}
                      marginBottom={23}
                    />
                    <Caption className={styles.cardTotal}>{formatCurrency(summary.totalGainLossValue)} Total P&L</Caption>
                  </>
                )
              })
            )
          }
        </Box>
        <Box flex={1}>
          <Box className={styles.details}>
            {openPositionsQry.isLoading && <p>loading...</p>}
            {
              (!openPositionsQry.isLoading && openPositions.length === 0) ? (
                <Typography variant="h6" sx={{ textAlign: "center", padding: "16px" }}>No positions at this time.</Typography>
              ) : (
                openPositions.map((position) => {
                  return (
                    <Caption p className={styles.detailInfoText}>{position.frSymbol} on {dayjs(position.expirationDate).format("MM/DD/YYYY h:mm A")} {position.openQuantity} @ {position.currentPrice}</Caption>
                  )
                })
              )
            }
          </Box>
          <Box className={styles.invoice}>
            {positionSummariesQry.isLoading && <p>loading...</p>}

            {(!positionSummariesQry.isLoading && sortedLedgerSummaries.length === 0) ? (
              <Typography variant="h6" sx={{ textAlign: "center", padding: "16px" }}>
                No trades at this time.
              </Typography>
            ) : (
              sortedLedgerSummaries.map((summary) =>
                <Box className={styles.invoiceGroup}>
                  <Caption className={styles.labelInvoice}>{summary.frSymbol}</Caption>
                  <Caption>{formatCurrency(summary.totalGainLossValue) || '--'}</Caption>
                </Box>
              )
            )}
            <Divider
              marginTop={12}
              marginBottom={20}
            />
            <Box className={styles.invoiceGroup}>
              <Caption>Total P&L</Caption>
              <Caption>{(cropSummary && formatCurrency(cropSummary.totalGainLossValue)) || '--'}</Caption>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CurrenPositions;
