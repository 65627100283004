import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, TextField, InputAdornment, IconButton, Collapse, Alert } from "@mui/material";
import { ArrowBackIos, Visibility, VisibilityOff, Close } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";
import PasswordHelper from "./PasswordHelper";
import AuthContext from "../../context/AuthContext";
import { Button } from "../.";
import { passwordValidate } from "../../utils/passwordValidation";

const useStyles = makeStyles((theme) => ({
  box: {
    borderTopLeftRadius: 0,
  },
  container: {
    marginBottom: "137px",
    marginTop: "33px",
    height: "400px",
    width: "380px",
  },
  innerContainer: {
    backgroundColor: "#fff",
    padding: "25px 40px",
  },
  formContainer: {
    marginTop: "42px",
  },
  centerBox: {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
  },
  floatingLabelFocusStyle: {
    color: "#000",
  },
  button: {
    fontWeight: 500,
  },
  buttonContainer: {
    marginTop: "39px",
  },
  header: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    marginBottom: "24px",
  },
  input: {
    marginBottom: "22.9px",
  },
  successIcon: {
    color: "#497248",
    height: "45px",
    width: "45px",
    marginBottom: "32px",
    marginTop: "20px",
  },
  successText: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.15px",
    marginBottom: "48px",
  },
  successButtonContainer: {
    width: "300px",
  },
  backButton: {
    color: "#1E4620",
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "22px",
    lineSpacing: "0.37px",
  },
  buttonStyle: {
    color: "#001F00",
  },
}));

const errorOptions = {
  code: false,
  oldPassword: false,
  confirmPassword: false,
  password: false,
  username: false,
};

const ResetPassword = ({ setCurrentScreen }) => {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [showPasswordHelper, setShowPasswordHelper] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [errors, setErrors] = useState(errorOptions);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);

  const styles = useStyles();
  const history = useHistory();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);
  const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

  const passwordInputType = showPassword ? "text" : "password";
  const passwordConfirmInputType = showPasswordConfirm ? "text" : "password";

  const handleReset = async () => {
    setLoading(true);
    if (!code) {
      setErrors((prevState) => ({
        ...prevState,
        code: true,
      }));
      setLoading(false);
      return;
    }
    if (!username) {
      setErrors((prevState) => ({
        ...prevState,
        username: true,
      }));
      setLoading(false);
      return;
    }
    if (!password) {
      setErrors((prevState) => ({
        ...prevState,
        password: true,
      }));
      setLoading(false);
      return;
    }
    if (!confirmPassword) {
      setErrors((prevState) => ({
        ...prevState,
        confirmPassword: true,
      }));
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      setApiError("Please confirm passwords match!");
      setShowApiError(true);
      setLoading(false);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      setErrors(errorOptions);
      const user = await Auth.signIn(username, password);
      setUserProfile(user.attributes);
      setIsLoggedIn(true);
      setCode("");
      setPassword("");
      setConfirmPassword("");
      history.push("/stoplight");
    } catch (e) {
      setApiError(e.message);
      setShowApiError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showPasswordHelper) {
      currentId === "newPassword" ? passwordValidate(password) : passwordValidate(confirmPassword);
    }
  }, [showPasswordHelper]);

  useEffect(() => {
    setTimeout(() => {
      setUsername("");
      setPassword("");
    }, 1000);
  }, []);
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        mb={3}
        onClick={() => setCurrentScreen("send")}
        className={styles.backContainer}>
        <ArrowBackIos /> <Box className={styles.backButton}>BACK</Box>
      </Box>
      <Collapse in={showApiError}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowApiError(false);
              }}>
              <Close fontSize="inherit" />
            </IconButton>
          }>
          {apiError}
        </Alert>
      </Collapse>
      <form>
        <TextField
          id="code"
          label="Confirmation Code"
          type="text"
          fullWidth
          className={styles.input}
          value={code}
          error={errors.code}
          onChange={(e) => setCode(e.target.value)}
          helperText={errors.code ? "Please enter your confirmation code." : ""}
        />

        <TextField
          id="username"
          label="E-mail Address"
          type="text"
          autoComplete="off"
          fullWidth
          className={styles.input}
          value={username}
          error={errors.username}
          onChange={(e) => setUsername(e.target.value)}
          helperText={errors.username ? "Please enter your e-mail address." : ""}
        />

        <TextField
          id="newPassword"
          label="New Password"
          type={passwordInputType}
          autoComplete="off"
          fullWidth
          className={styles.input}
          value={password}
          error={errors.password}
          onFocus={(e) => {
            setShowPasswordHelper(true);
            setCurrentId(e.target.id);
          }}
          onBlur={() => setShowPasswordHelper(false)}
          onChange={(e) => {
            setPassword(e.target.value);
            passwordValidate(e.target.value);
          }}
          helperText={errors.password ? "Please enter a password." : ""}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword()}
                  onMouseDown={() => handleMouseDownPassword()}
                  size="large">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          id="confirmPassword"
          label="Confirm Password"
          type={passwordConfirmInputType}
          fullWidth
          // InputLabelProps={{ color: "#000" }}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            passwordValidate(e.target.value);
          }}
          value={confirmPassword}
          className={styles.form}
          onFocus={(e) => {
            setShowPasswordHelper(true);
            setCurrentId(e.target.id);
          }}
          onBlur={() => setShowPasswordHelper(false)}
          error={errors.confirmPassword}
          helperText={errors.confirmPassword ? "Please enter a password." : ""}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPasswordConfirm()}
                  onMouseDown={() => handleMouseDownPasswordConfirm()}
                  size="large">
                  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <PasswordHelper showPasswordHelper={showPasswordHelper} />

        <Box className={styles.buttonContainer}>
          <Button
            onClick={() => handleReset()}
            fullWidth
            label="Reset Password"
            classes={{ label: styles.button }}
            loading={loading}
            className={styles.buttonStyle}
          />
        </Box>
      </form>
    </Box>
  );
};

export default ResetPassword;
