import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Line, XAxis, YAxis, CartesianGrid, ComposedChart, Area, ResponsiveContainer } from "recharts";
import { formatAxis } from "../../utils";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 500,
    color: "#000",
    [theme.breakpoints.down(600)]: {
      textAlign: "left",
    },
  },
  boxTwoPara: {
    fontSize: "12px",
    color: "grey",
    fontWeight: "600",
    letterSpacing: "0.5px",
  },
  percentValue: {
    color: "green",
    fontFamily: "Roboto",
    fontSize: "24px",
    letterSpacing: "0.23px",
    lineHeight: "28px",
    fontWeight: "600",
  },
  percentDescription: {
    margin: "auto",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "0.15px",
    lineHeight: "20px",
  },
  percentTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginRight: "16px",
  },
  green: {
    color: "green",
  },
  red: {
    color: "red",
  },
  boxTwoContent: {
    border: "1px solid grey",
    borderRadius: "5px",
    marginTop: "10px",
    [theme.breakpoints.down(400)]: {
      padding: "20px",
    },
  },
  boxGridOne: {
    padding: "20px",
    fontSize: "14px",
    textAlign: "center",
    display: "flex",
    [theme.breakpoints.down(1100)]: {
      flexDirection: "column",
    },
  },
  graphContainer: {
    flex: "1",
  },
  perContainer: {
    borderRight: "1px solid grey",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(1100)]: {
      flexDirection: "column",
    },
  },
}));

const MomoResultsGraph = ({ listData, momoGraphData, latestMomoRecord }) => {
  const styles = useStyles();

  return (
    <div className={styles.graphContainer}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3" className={styles.header}>
          GFY Proprietary Indicator
        </Typography>
      </Box>
      <Typography variant="p" className={styles.boxTwoPara}>
        Below you can see our stoplight Indicator and its values for the last 300 trading days.
      </Typography>
      <Box>
        <ResponsiveContainer width="99%" height={230}>
          <ComposedChart data={momoGraphData}>
            <defs>
              <linearGradient id="colorGreen" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#41b141" stopOpacity={1} />
                <stop offset="95%" stopColor="#41b141" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorGreenBot" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#41b141" stopOpacity={1} />
                <stop offset="95%" stopColor="#41b141" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorYellow" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#dfdf5d" stopOpacity={1} />
                <stop offset="95%" stopColor="#dfdf5d" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ef9494" stopOpacity={1} />
                <stop offset="95%" stopColor="#ef9494" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              dy={5}
              tickFormatter={formatAxis}
              tickLine={false}
              angle={-15}
              textAnchor="end"
              style={{ fontSize: "10px" }}
            />
            <YAxis type="number" domain={[-3.5, 3.5]} hide />
            <Line type="monotone" dataKey="momo" stroke="#035c03" dot={{ r: 0 }} />
            <Area type="monotone" dataKey="green_threshold" stroke="#41b141" fill="rgba(76,175,80,0.2)" stackId={1} />
            <Area type="monotone" dataKey="green_bot" stroke="#41b141" fill="rgba(76,175,80,0.2)" />
            <Area type="monotone" dataKey="yellow_threshold" stroke="#dfdf5d" fill="rgba(225,168,1,0.2)" stackId={1} />
            <Area type="monotone" dataKey="red_threshold" stroke="#ef9494" fill="rgba(147,16,16,0.2)" stackId={1} />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Box className={styles.boxTwoContent}>
        <Grid container spacing={0}>
          <Grid item xs={6} md={6} className={styles.boxGridOne}>
            <div className={styles.perContainer}>
              <span className={styles.percentTitle}>Momos Current Percentile:</span>
              <span
                className={`
                  ${styles.percentValue}
                  ${listData?.trafficLightMap === 1 || listData?.trafficLightMap === 2 ? styles.green : styles.red}
                `}>
                {latestMomoRecord?.momo_p?.toFixed(2) || 0}%
              </span>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            className={`
              ${styles.percentDescription}
              ${listData?.trafficLightMap === 1 || listData?.trafficLightMap === 2 ? styles.green : styles.red}
            `}>
            <p>
              The current value of Momo is higher than {latestMomoRecord?.momo_p?.toFixed(2) || 0}% of it’s historical
              values.
            </p>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default MomoResultsGraph;
