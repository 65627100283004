import { getConfig } from "./config";
import axios from "axios";
import { Auth } from "aws-amplify";

const config = getConfig();
const API_BASE_URL = config.apiBaseUrl;

async function getAxiosClient() {
  const session = await Auth.currentSession();
  // FYI, API Gateway Cognito Authorizer wants the ID Token and not the accessToken
  const idToken = await session.getIdToken().getJwtToken();

  const client = axios.create({
    baseURL: API_BASE_URL,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  });

  return client;
}

export async function getApiClient() {
  const client = await getAxiosClient();
  return client;
}
