import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Column } from "../table/column";

const ROW_COLUMNS = [
  new Column({ key: "tradeDate", label: "Trade Date", formatType: "date" }),
  new Column({ key: "longQuantity", label: "Long", formatType: "longOrShort" }),
  new Column({ key: "shortQuantity", label: "Short", formatType: "longOrShort" }),
  new Column({ key: "tradePrice", label: "Trade Price", formatType: "currency" }),
  new Column({ key: "currentPrice", label: "Current Price", formatType: "currency" }),
  new Column({ key: "unrealizedGainLossPrice", label: "Gain/Loss", formatType: "currency" }),
  //new Column({ key: "productionCycle", label: "Crop Year" }),
];

// const SUMMARY_COLUMNS = [
//   new Column({ key: "openLongQuantity", label: "Long", formatType: "longOrShort" }),
//   new Column({ key: "openShortQuantity", label: "Short", formatType: "longOrShort" }),
//   new Column({ key: "averageOpenTradePrice", label: "Trade Price", formatType: "currency" }),
//   new Column({ key: "currentPrice", label: "Current Price", formatType: "currency" }),
//   new Column({ key: "unrealizedGainLossPrice", label: "Gain/Loss", formatType: "currency" }),
//   new Column({ key: "productionCycle", label: "Crop Year" }),
// ];

const OpenPositionsTable = ({ openPositions, positionSummaries }) => {
  openPositions = openPositions || [];
  positionSummaries = positionSummaries || [];

  const tradesGroupedBySymbol = openPositions.reduce((acc, trade) => {
    const symbol = trade.frSymbol;
    if (!acc[symbol]) {
      acc[symbol] = [];
    }
    acc[symbol].push(trade);
    return acc;
  }, {});

  const openPositionGroupings = Object.entries(tradesGroupedBySymbol).map(([key, vals]) => {
    return {
      frSymbol: key,
      openPositions: vals,
    };
  });

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        {openPositions.length > 0 ? (
          <Table stickyHeader aria-label="sticky table" sx={{ borderBottomColor: "transparent" }}>
            <TableHead>
              <TableRow>
                {ROW_COLUMNS.map((column) => (
                  <TableCell {...column} style={{ fontWeight: 900, textTransform: "uppercase" }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {openPositionGroupings.map((opGroup, idx) => {
                return (
                  <>
                    {opGroup.openPositions.map((row, idx2) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={`row-${idx2}`}>
                          {ROW_COLUMNS.map((column) => (
                            <TableCell {...column}>{column.format(row[column.key], row)}</TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Typography mb={4} variant="h1" textAlign="center">
            All Flat - No Open Positions.
          </Typography>
        )}
      </TableContainer>
    </Paper>
  );
};

export default OpenPositionsTable;
