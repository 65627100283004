import React, { useContext } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SubscriptionContext } from "../../../context/subscription-context";
import { Subscription } from "./subscription";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "120px",
    width: "100%",
    borderRadius: "4px",
    boxShadow: "0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)",
  },
  bottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #1E4620",
    cursor: "pointer",
    color: "#1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  activeBottom: {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    border: "1px solid #1E4620",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  top: {
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  topText: {
    color: "rgba(0,0,0,0.87)",
    fontWeight: 500,
    fontSize: "14px",
  },
  amount: {
    color: "rgba(0,0,0,0.6)",
    fontWeight: 500,
    fontSize: "14px",
    marginTop: "4px",
  },
  subscriptionContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
}));

export const SubscriptionList = () => {
  const { subscription } = useContext(SubscriptionContext);
  const styles = useStyles();

  return (
    <Box className={styles.subscriptionContainer}>
      {subscription.map((eachSubscription, index) => (
        <Subscription subscription={eachSubscription} key={index} />
      ))}
    </Box>
  );
};
