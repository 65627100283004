import React from "react";
import { H3 } from "../../../../../components/Text";

export const LoadingSubscription = () => {
  return (
    <>
      <div>
        <H3>Loading Subscription Details...</H3>
      </div>
    </>
  );
};
