import { Box, Container, Paper, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GeneralLayout } from "../layouts";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.down(900)]: {
      paddingLeft: "32px",
      paddingRight: "32px",
    },
    [theme.breakpoints.down(600)]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  header: {
    fontSize: "1.25rem",
    fontWeight: 500,
    marginBottom: "16px",
  },
}));

const Terms = () => {
  const styles = useStyles();
  return (
    <GeneralLayout>
      <Container>
        <Paper elevation={4}>
          <Box p={4} mt={6} mb={6} className={styles.container}>
            <Box mt={2} color="#1E4620" className={styles.header}>
              Terms & Conditions
            </Box>
            <Divider />
            <object
              style={{ width: "100%", height: "100vh" }}
              data="https://d2rm55816u294m.cloudfront.net/GFYTOS_2022_03_01.pdf"
              type="application/pdf"
              width="100%"
              height="100%">
              <p>
                <a href="https://d2rm55816u294m.cloudfront.net/GFYTOS_2022_03_01.pdf">Terms of Service</a>
              </p>
            </object>
          </Box>
        </Paper>
      </Container>
    </GeneralLayout>
  );
};

export default Terms;
