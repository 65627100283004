import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, Chip, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { orderBy } from "lodash";
import dayjs from "dayjs";
import { formatCurrency } from "../../utils/formatting";
import OpenPositionsTable from "./OpenPositionsTable";

function CropOpenPositionsSummary({ openPositions, positionSummaries, trades }) {
  const openPositionSummaries = positionSummaries.filter((x) => x.openLongQuantity !== 0 || x.openShortQuantity !== 0);
  const sortedPositionSummaries = orderBy(openPositionSummaries, (x) => dayjs(x.expirationDate).toDate(), "desc");

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {sortedPositionSummaries.length === 0 ? (
        <Typography variant="h6" sx={{ textAlign: "center", padding: "16px" }}>
          No open positions at this time.
        </Typography>
      ) : (
        sortedPositionSummaries.map((summary) => {
          return (
            <Accordion key={summary.frSymbol}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction="row" spacing={3} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                  <Chip label={summary.frSymbol} color="primary" />
                  <Typography>{summary.instrumentDescription}</Typography>
                  <Paper
                    sx={{
                      padding: "6px",
                      textAlign: "center",
                      minWidth: "120px",
                      maxWidth: "200px",
                    }}>
                    <Typography variant="h6">Long</Typography>
                    <Typography variant="h1">{summary.openLongQuantity ? summary.openLongQuantity : "--"}</Typography>
                  </Paper>
                  <Paper
                    sx={{
                      padding: "6px",
                      textAlign: "center",
                      minWidth: "120px",
                      maxWidth: "200px",
                    }}>
                    <Typography variant="h6">Short</Typography>
                    <Typography variant="h1">{summary.openShortQuantity ? summary.openShortQuantity : "--"}</Typography>
                  </Paper>
                  <Paper
                    elevation={4}
                    sx={{
                      padding: "8px",
                      textAlign: "center",
                      minWidth: "120px",
                      maxWidth: "200px",
                    }}>
                    <Typography variant="h6">Profit & Loss</Typography>
                    <Typography variant="h1">{formatCurrency(summary.totalGainLossValue)}</Typography>
                  </Paper>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <OpenPositionsTable
                  openPositions={openPositions.filter((x) => x.frSymbol === summary.frSymbol)}></OpenPositionsTable>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </Paper>
  );
}

export default CropOpenPositionsSummary;
