import React from "react";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useContext } from "react";
import { AppContext } from "../../context";

const useStyles = makeStyles((theme) => ({
  textcontainer: {
    position: "absolute", 
    top: "70px", 
    left: "280px", 
    padding: theme.spacing(2),
    zIndex: 1, 
  },
  h1: {
    color: "#ffffff",
    fontSize: "80px",
  },
  subtext: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default function App() {
  const styles = useStyles();

  const scrollDownClickHandler = () => {
    window.location.href = "#results";
  };

  const { setShowContact } = useContext(AppContext);

  return (
    <Box className={styles.textcontainer}>
      <Typography variant="h4" className={styles.h4}>GFY 2023 RESULTS YTD</Typography>
      <Box className={styles.subtext}>
        <Typography variant="h1" className={styles.h1}>+$59,143</Typography>
        <Typography variant='h4' className={styles.subtexth4}>Based on <br />a $100,000 <br />portfolio.</Typography>
      </Box>
  </Box>
  );
}
