const PRODUCT_TPYE = { CORN: "Corn", WHEAT: "Kansan Wheat", SOYBEAN: "Beans" };
const CROP_TYPES = ["CORN", "WHEAT", "SOYBEAN"];
const SUMMARY_TYPE = { CORN: "ZC", WHEAT: "KE", SOYBEAN: "ZS" };
const SUGGESTED_ACTION = [
  {
    header: "SIT TIGHT 🍺",
    trafficLightMap: 1,
    text: "Sit back and relax! Have a beer or drive the tractor and check back soon to see if market is ready to sell. Sign up for alerts to you phone or email and never miss the best opportunities to sell!",
    momospercentile: 54,
  },
  {
    header: "BE ALERT! 👀",
    trafficLightMap: 2,
    text: "The market is changing! it may be time to sell soon so check back frequently. Sign up for alerts to your phone or email and never miss the best opportunities to sell!",
    momospercentile: 33,
  },
  {
    header: "CONSIDER SELLING 💸",
    trafficLightMap: 3,
    text: "The market may be overvalued! you should strongly consider selling some of your crop.If you want these alerts as soon as they happen, sign up for alerts to your phone or email and never miss the best opportunities to sell!",
    momospercentile: 86,
  },
];
const FINAL_DESCRIPTION =
  "All sales final. You may cancel your subscription at any time within “My Account” section. Money Back Guarantee! If the average of our sell suggestions are less than the CBOT Average you can get your money back. *CBOT average is calculated using the daily average sale price of the underlying commodity, from 300 days before expiration up to the date of expiration*";

const DISCLAIMER =
"Futures, foreign currency, and options trading in grains, livestock, and other related commodities contain substantial risk and are not for every investor. An investor could potentially lose all or more than the initial investment. Risk capital is money that can be lost without jeopardizing one's financial security or lifestyle. Only risk capital should be used for trading, and only those with sufficient risk capital should consider trading. Past performance is not necessarily indicative of future results.";


export { PRODUCT_TPYE, CROP_TYPES, SUMMARY_TYPE, SUGGESTED_ACTION, FINAL_DESCRIPTION, DISCLAIMER };
