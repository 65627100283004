import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import "./password.scss";

const useStyles = makeStyles((theme) => ({
  passwordHelper: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    marginTop: "14px",
    lineHeight: "20px",
    letterSpacing: "0.4px",
  },
  passwordValidation: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    marginTop: "14px",
    lineHeight: "12px",
  },
}));

const PasswordHelper = ({ showPasswordHelper }) => {
  const styles = useStyles();
  return (
    <>
      {!showPasswordHelper && (
        <Box className={styles.passwordHelper}>
          Password must include: at least 8 characters, lowercase, uppercase, numerals, symbols
        </Box>
      )}

      {showPasswordHelper && (
        <Box className={styles.passwordValidation}>
          <p id="letter" className="invalid">
            A <b>lowercase</b> letter
          </p>
          <p id="capital" className="invalid">
            A <b>capital (uppercase)</b> letter
          </p>
          <p id="number" className="invalid">
            A <b>number</b>
          </p>
          <p id="character" className="invalid">
            A <b>special</b> character
          </p>
          <p id="length" className="invalid">
            Minimum <b>8 characters</b>
          </p>
        </Box>
      )}
    </>
  );
};

export default PasswordHelper;
