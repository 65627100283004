import { Route, Switch } from "react-router-dom";
import {
  Drill,
  Account,
  Login,
  Login2,
  Landing,
  Terms,
  Privacy,
  ResetPassword,
  About,
  FAQs,
  ContentfulVideo,
  NotFound,
  Results,
  Dashboard,
  Blog,
  Post,
  Stoplight
} from "./pages";
import { CustomCheckoutPage } from "./pages/custom-checkout-page";
import { AddtoCart } from "./pages/Cart/AddtoCart";
import { EnterCardDetail } from "./pages/Cart/EnterCardDetail";
import { PlansCheckout } from "./pages/PlansCheckout";
import { YourSubscription } from "./pages/Cart/YourSubscription";

export const Routes = () => (
  <Switch>
    <Route exact path="/">
      <Landing />
    </Route>
    <Route exact path="/login">
      <Login2 />
    </Route>
    <Route exact path="/login-new">
      <Login2 />
    </Route>
    <Route exact path="/reset-password">
      <ResetPassword />
    </Route>
    <Route exact path="/about">
      <About />
    </Route>
    <Route exact path="/terms">
      <Terms />
    </Route>
    <Route exact path="/privacy">
      <Privacy />
    </Route>
    <Route exact path="/faqs">
      <FAQs />
    </Route>
    <Route exact path="/what">
      <CustomCheckoutPage />
    </Route>
    <Route exact path="/stoplight">
      {/* <Drill /> */}
      <Stoplight />
    </Route>
    <Route exact path="/account">
      <Account />
    </Route>
    <Route exact path="/contentful-video">
      <ContentfulVideo />
    </Route>

    {/* Single route for cart and payments */}
    {/* ---------------------------------------------------------------------------------------------------------------> */}
    <Route exact path="/PlansCheckout">
      <PlansCheckout />
    </Route>
    {/* ---------------------------------------------------------------------------------------------------------------> */}

    {/* Multiple routes for cart and payments */}
    {/* ---------------------------------------------------------------------------------------------------------------> */}
    <Route exact path="/subscriptions">
      <AddtoCart />
    </Route>
    <Route exact path="/payment">
      <EnterCardDetail />
    </Route>
    {/* ---------------------------------------------------------------------------------------------------------------> */}
    <Route exact path="/YourSubscriptions">
      <YourSubscription />
    </Route>
    <Route exact path="/results">
      <Results />
    </Route>
    <Route exact path="/dashboard-v2">
      <Dashboard />
    </Route>
    {/* <Route exact path="/payment">
      <CustomCheckoutPage />
    </Route> */}
    <Route exact path="/blog">
      <Blog />
    </Route>
    <Route exact path="/blog/post/:slug">
      <Post />
    </Route>
    <Route path="*">
      <NotFound />
    </Route>
  </Switch>
);
