import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Reviewimage from "./review.png";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

export default function App() {
  const data = [
    {
      review:
        "I'm spending all of my time raising a crop - Ginger is spending all her time and energy watching the markets & selling a crop. She knows what happens before the market responds.",
      name: "Cale Carlson",
      designation: "Nebraska",
    },
    {
      review:
        "I'm spending all of my time raising a crop - Ginger is spending all her time and energy watching the markets & selling a crop. She knows what happens before the market responds.",
      name: "Cale Carlson",
      designation: "Nebraska",
    },
    {
      review:
        "I'm spending all of my time raising a crop - Ginger is spending all her time and energy watching the markets & selling a crop. She knows what happens before the market responds.",
      name: "Cale Carlson",
      designation: "Nebraska",
    },
  ];

  return (
    <div className="testimonial-section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Swiper
            id="main"
            spaceBetween={0}
            slidesPerView={1}
            onInit={(swiper) => console.log("Swiper initialized!", swiper)}
            onSlideChange={(swiper) => {
              console.log("Slide index changed to: ", swiper.activeIndex);
            }}
            onReachEnd={() => console.log("Swiper end reached")}>
            {data.map((review, index) => (
              <SwiperSlide key={index}>
                <div className="review-section">
                  <Box className="hero" mr={2}>
                    <img src={Reviewimage} />
                  </Box>
                  <div className="review-part">
                    <p className="review">{review.review}</p>
                    <p className="name">{review.name}</p>
                    <p className="designation">{review.designation}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </div>
  );
}
