import { createContext } from "react";

const initialParams = [
  {
    key: "drillSlider",
    value: {
      minBound: 500,
      maxBound: 540,
      step: 1,
      defaultVal: 520,
    },
  },
  {
    key: "socialMediaShare",
    value: {
      twitter: "",
      facebook: "",
      linkedin: "",
    },
  },
  {
    key: "cornParams",
    value: {
      lastUpdatedDate: "7/27",
      lastUpdatedTime: "1:30 PM CST",
      lastUpdatedInputVal: "542",
      lastUpdatedPredVal: "546.91",
    },
  },
  {
    key: "drillAvailable",
    value: {
      corn: false,
    },
  },
];

const AppContext = createContext({
  params: initialParams,
  setParams: () => {},
  showDisabled: false,
  setShowDisabled: () => {},
  selectedTab: 0,
  setSelectedTab: () => {},
  cropType: [],
});

export default AppContext;
