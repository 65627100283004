// react components------------------------------->
import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Layout components--------------------------->
import { GeneralLayout } from "../layouts";

// sub components------------------------------>
import { AddtoCart } from "./Cart/AddtoCart";
import { EnterCardDetail } from "./Cart/EnterCardDetail";

// others-------------------------------------->
import { Views } from "./SubAndPayComps/Views";
import { YourSubscription } from "./Cart/YourSubscription";

export const PlansCheckout = React.memo(() => {
  // useState variables--------------------------------->
  const [currentView, setCurrentView] = useState(Views.FIRST);
  const [cardItems, setCardItems] = useState([]);
  const styles = useStyles();

  /* Componenet UseEffect #1----------------------------> */
  useEffect(() => {
    if (cardItems?.length > 0) {
      setCurrentView(Views.SECOND);
    }
  }, [cardItems]);

  /* handleSwitchView Function------------------>
   * the function handles the change of view at route PlansCheckout */
  const handleSwitchView = (view) => {
    setCurrentView(view);
  };

  return (
    <GeneralLayout>
      <>
        {currentView === Views.FIRST && (
          <Container className={styles.container}>
            <AddtoCart withLayout={false}
              onSwitchView={handleSwitchView}
              saveCardItems={(items) => {
                setCardItems(items);
              }}
            />
            <YourSubscription withLayout={false} />
          </Container>
        )}
        {currentView === Views.SECOND && <EnterCardDetail onSwitchView={handleSwitchView} cardItems={cardItems} />}
      </>
    </GeneralLayout>
  );
});

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1320,
    paddingBottom: 81,
    paddingLeft: 0,
    paddingRight: 0,
  }
}));
