import { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { TextField, Box, InputAdornment, IconButton, Checkbox, Collapse, Alert, Divider } from "@mui/material";
import { Visibility, VisibilityOff, Close } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Auth, API } from "aws-amplify";
import PasswordHelper from "./PasswordHelper";
import Button from "../Button";
import AuthContext from "../../context/AuthContext";
import { passwordValidate } from "../../utils/passwordValidation";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import axios from "axios";

import config from "../../config";

import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  agreeText: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.4px",
  },
  agreeContainer: {
    marginTop: "8px",
  },
  link: {
    color: "#2196F3",
  },
  form: {
    marginTop: "14px",
  },
  text: {
    textDecoration: "underline",
    fontSize: "12px",
    color: "#1E4620",
    cursor: "pointer",
  },
  button: {
    fontWeight: 500,
  },
  buttonStyle: {
    color: "#001F00",
  },
}));

const Login = ({ setCurrentScreen }) => {
  const styles = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordHelper, setShowPasswordHelper] = useState(false);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({ email: false, password: false });
  const [apiError, setApiError] = useState("");
  const [showApiError, setShowApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userExists, setUserExists] = useState(-1);

  const [signUpModalOpen, setSignUpModalOpen] = useState(false);

  const [showSignInForm, setShowSignInForm] = useState(true);

  // Functions to handle opening and closing the sign-up modal
  const handleSignUpModalOpen = () => {
    setSignUpModalOpen(true);
    setShowSignInForm(false);
  };

  const handleSignUpModalClose = () => {
    setSignUpModalOpen(false);
    setShowSignInForm(true);
  };

  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    setUserExists(-1);
  }, [email]);

  useEffect(() => {
    if (showPasswordHelper) {
      passwordValidate(password);
    }
  }, [showPasswordHelper]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleChangePassword = (value) => {
    setPassword(value);
    if (userExists === 0) {
      passwordValidate(value);
    }
  };
  const handleFocusPassword = () => {
    if (userExists === 0) {
      setShowPasswordHelper(true);
    }
  };
  const handleBlurPassword = () => {
    if (userExists === 0) {
      setShowPasswordHelper(false);
    }
  };

  const checkUserExists = async (email) => {

    const env = process.env.REACT_APP_NODE_ENV;
    // GET CONTACT DETAILS FROM HUBSPOT
    const queryStringParameters = {
      env: env,
      email: email,
    };

    const GFY_BACKEND_url_key = "GFYBACKEND_URL_" + env.toUpperCase();
    const GFY_BACKEND_url = config[GFY_BACKEND_url_key];

    const response = await axios.get(GFY_BACKEND_url + "/CheckCognitoUser", {
      params: queryStringParameters,
    });

    return response.status;
    
  };

  // const handleCheckUser = async () => {
  //   if (!email) {
  //     setErrors((prevState) => ({
  //       ...prevState,
  //       email: true,
  //     }));
  //     return;
  //   }
  //   setLoading(true);
  //   // check if user exists
  //   const userStatus = await checkUserExists(email);
  //   setUserExists(userStatus);
  //   setLoading(false);

  //   if (userStatus === 1) {
  //     await handleSignIn();
  //   } else {
  //     return;
  //   }
  // };
  const handleSignInFederated = async () => {
    try {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    } catch (e) {
      console.log("error: ", e);
    }
  };

  const handleSignIn = async () => {
    setLoading(true);
    if (!password) {
      setErrors((prevState) => ({
        ...prevState,
        password: true,
      }));
      setLoading(false);
      return;
    }
    try {


      // check cognito user pool to see if user exists
      const userStatus = await checkUserExists(email);
      // if userstatus is 204 then user does not exist
      if (userStatus === 204) {
        setApiError("User does not exist. Please create an New Account.");
        
        setShowApiError(true);
        
        setLoading(false);
        return
      }

      const user = await Auth.signIn(email, password);

      setIsLoggedIn(true);
      setUserProfile(user.attributes);
      setLoading(false);
      history.push("/stoplight");
    } catch (e) {
      //Fix Quota login issue on iOS
      if (e.name === "QuotaExceededError") {
        window.localStorage.clear();
        setApiError("Please try again");
        // handleSignIn();  // TODO: check if need auto-retry
      } else {
        if (e.message === "User does not exist.") {
          setApiError("User does not exist. Please create an New Account.");
          // clear api error message from signUpForm
          setShowApiError(false);
          // focus on email field
          document.getElementById("email").focus();
          // blur
          document.getElementById("email").blur();
          handleSignUpModalOpen();
          setLoading(false);

          return;
        }
        setApiError(e.message);
      }
      setShowApiError(true);
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    setLoading(true);
    if (!password) {
      setErrors((prevState) => ({
        ...prevState,
        password: true,
      }));
      setLoading(false);
      return;
    }
    if (!checked) {
      setApiError("Please agree to terms and conditions before signing up!");
      setShowApiError(true);
      setLoading(false);
      return;
    }
    if (
      !password.match(/[a-z]/g) ||
      !password.length >= 8 ||
      !password.match(/[A-Z]/g) ||
      !password.match(/\d+/g) ||
      !password.match(/[#?!@$%^&*\-_\\\/]/g)
    ) {
      // TODO: merge regex into one if possible
      setApiError("Password is not valid.");
      setShowApiError(true);
      setLoading(false);
      return;
    }

    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email: email,
          "custom:onboardingComplete": "0",
        },
      });

      // alert("Please check your email for a verification link.");
      const user = await Auth.signIn(email, password);
      // get idtoken
      const token = user.signInUserSession.idToken.jwtToken;
      setIsLoggedIn(true);
      setUserProfile(user.attributes);
      setLoading(false);
      try {
        // add user to mailchimp
        const apiName = "restApiProd";
        const path = "/subscribeMailChimp";
        const myInit = {
          body: { email },
        };
        await API.post(apiName, path, myInit);
      } catch (error) {
        console.log("error: ", error);
      }
      // this makes things render twice
      history.push("/stoplight");
    } catch (e) {
      setApiError(e.message);
      setShowApiError(true);
      setLoading(false);
    }
  };

  return (
    <>
      {/* Sign-in form begins */}
      {showSignInForm && (
        <div id="signinform">
          <Box className={styles.loginContainer}>
            <Collapse in={showApiError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowApiError(false);
                    }}>
                    <Close fontSize="inherit" />
                  </IconButton>
                }>
                {apiError}
              </Alert>
            </Collapse>
            <form className={styles.loginForm}>
              <TextField
                id="email"
                label="Email or Phone Number"
                type="email"
                fullWidth
                required
                value={email}
                error={errors.email}
                helperText={errors.email ? "Please enter an email address." : ""}
                className={styles.form}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                id="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                required
                error={errors.password}
                helperText={errors.password ? "Please enter a password." : ""}
                value={password}
                onChange={(e) => handleChangePassword(e.target.value)}
                onFocus={handleFocusPassword}
                onBlur={handleBlurPassword}
                className={styles.form}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword()}
                        onMouseDown={() => handleMouseDownPassword()}
                        size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Box mt={3} position="relative">
                <Button
                  // onClick={userExists === 1 ? handleSignIn : handleCheckUser}
                  onClick={handleSignIn}
                  fullWidth
                  classes={{ label: styles.button }}
                  label={"Log In"}
                  disabled={loading}
                  loading={loading}
                  className={styles.buttonStyle}
                />
              </Box>

              <Box mt={3} position="relative">
                <Button
                  // onClick={userExists === 1 ? handleSignIn : handleCheckUser}
                  onClick={handleSignInFederated}
                  fullWidth
                  classes={{ label: styles.button }}
                  label={"Log in with Google"}
                  className={styles.buttonStyle}
                />
              </Box>
            </form>

            <>
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                className={styles.text}
                onClick={() => setCurrentScreen("send")}>
                forgot password?
              </Box>
            </>
          </Box>

          <Box mt={3} className={styles.dividerContainer}>
            <Divider className={styles.divider} />
            <Divider className={styles.divider} />
          </Box>

          <Box mt={3} className={styles.createAccountContainer}>
            <Button
              fullWidth
              classes={{ label: styles.button }}
              className={styles.buttonStyle}
              onClick={handleSignUpModalOpen}
              label="Create New Account"
            />
          </Box>
          <Box mt={3} position="relative">
                <Button
                  // onClick={userExists === 1 ? handleSignIn : handleCheckUser}
                  onClick={handleSignInFederated}
                  fullWidth
                  classes={{ label: styles.button }}
                  label={"Sign up with Google"}
                  className={styles.buttonStyle}
                />
              </Box>          
        </div>
      )}
      {/* Sign-in form ends */}

      {/* Sign-up modal */}
      <Dialog open={signUpModalOpen} onClose={handleSignUpModalClose}>
        <DialogTitle>Sign Up</DialogTitle>
        <DialogContent>
          <Box className={styles.loginContainer}>
            <Collapse in={showApiError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowApiError(false);
                    }}>
                    <Close fontSize="inherit" />
                  </IconButton>
                }>
                {apiError}
              </Alert>
            </Collapse>
          </Box>
          <form className={styles.signUpForm}>
            <TextField
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              required
              margin="dense"
              value={email}
              error={errors.email}
              helperText={errors.email ? "Please enter an email address." : ""}
              className={styles.form}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              id="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              required
              // add a button to make password visible
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword()}
                      onMouseDown={() => handleMouseDownPassword()}
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              margin="dense"
              value={password}
              onChange={(e) => handleChangePassword(e.target.value)}
              onFocus={handleFocusPassword}
              onBlur={handleBlurPassword}
              error={errors.password}
            />

            <Box display="flex" justifyContent="center" alignItems="center" className={styles.agreeContainer}>
              <Box>
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  inputProps={{ "aria-label": "privacy policy checkbox" }}
                />
              </Box>
              <Box className={styles.agreeText}>
                By creating this account you confirm that you agree with our{" "}
                <Link className={styles.link} to={"/privacy"}>
                  Privacy Policy
                </Link>
                .
              </Box>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            classes={{ label: styles.button }}
            className={styles.buttonStyle}
            label="Cancel"
            onClick={handleSignUpModalClose}
            color="primary"
          />

          <Button
            classes={{ label: styles.button }}
            className={styles.buttonStyle}
            label="Sign Up"
            onClick={handleSignUp}
            disabled={loading}
            loading={loading}
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Login;
