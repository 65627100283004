import { useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Clear, Twitter, LinkedIn, Facebook } from "@mui/icons-material";
import { Auth, API } from "aws-amplify";
import { AppContext } from "../../context";

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: "413px",
    width: "482px",
  },
  titleText: {
    marginLeft: "20px",
  },
  text: {
    fontSize: "1.25rem",
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
    textAlign: "left",
    marginBottom: "40px",
    marginTop: "20px",
  },
  dialogContent: {
    marginLeft: "20px",
    marginRight: "20px",
    textAlign: "center",
  },
  content: {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "40px",
    textAlign: "center",
  },
  icon: {
    marginRight: "10px",
  },
  header: {
    fontSize: "24px",
    color: "rgba(0,0,0,0.87)",
  },
  closeIcon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  socialText: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "16px",
    textAlign: "left",
    letterSpacing: "0.15px",
    marginBottom: "28px",
    width: "300px",
  },
  shareBox: {
    height: "56px",
    width: "300px",
    marginBottom: "8px",
    boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2)",
    cursor: "pointer",
    transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.01, 1.01)",
      transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
      boxShadow: "0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2)",
    },
  },
  socialIcon: {
    marginLeft: "16px",
    marginRight: "16px",
    height: "40px",
  },
  socialShareText: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "16px",
  },
}));

const SocialDialog = (props) => {
  const { showSocialShare, setShowSocialShare } = props;

  const [socialMediaLinks, setSocialMediaLinks] = useState({ twitter: "", facebook: "", linkedIn: "" });

  const styles = useStyles();
  const { params } = useContext(AppContext);

  useEffect(() => {
    if (params.length) {
      const results = params.find((x) => x.key === "socialMediaShare");
      if (results) {
        const data = JSON.parse(results.value);
        setSocialMediaLinks(data);
      }
    }
  }, [params]);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleSocialMediaShare = async (platform) => {
    openInNewTab(socialMediaLinks[platform]);
    const apiName = "restgfyag";
    const path = "/social-share";
    const user = await Auth.currentAuthenticatedUser();
    const myInit = {
      body: { uuid: user.username, socialNetwork: platform },
    };
    await API.post(apiName, path, myInit);
  };

  return (
    <Dialog
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={showSocialShare}
      classes={{ paper: styles.dialog }}>
      <DialogContent className={styles.dialogContent}>
        <Box mt={2}>
          <Box display="flex" justifyContent="space-between">
            <Box className={styles.header}>Brag About Us! 🤠</Box>
            <Box>
              <Clear className={styles.closeIcon} onClick={() => setShowSocialShare(false)} />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className={styles.content}>
            <Box className={styles.socialText}>
              Select a social media platform you’d like to share on and earn 2 Drill Tokens!
            </Box>
            <Box
              className={styles.shareBox}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              onClick={() => handleSocialMediaShare("twitter")}>
              <Twitter className={styles.socialIcon} />
              <Box className={styles.socialShareText}>Share on Twitter</Box>
            </Box>
            <Box
              className={styles.shareBox}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              onClick={() => handleSocialMediaShare("facebook")}>
              <Facebook className={styles.socialIcon} />
              <Box className={styles.socialShareText}>Share on Facebook</Box>
            </Box>
            <Box
              className={styles.shareBox}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              onClick={() => handleSocialMediaShare("linkedIn")}>
              <LinkedIn className={styles.socialIcon} />
              <Box className={styles.socialShareText}>Share on LinkedIn</Box>
            </Box>
          </Box>

          <Box style={{ margin: 25 }}></Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SocialDialog;
