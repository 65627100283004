import { memo, useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { Header, Footer } from "../components";
import { AuthContext } from "../context";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageStyling: {
    marginTop: "70px",
  },
  pageStylingWithout: {
    // marginTop: "70px",
  },
  spacing: {
    height: "100px",
  },
  spacingWithColor: {
    height: "100px",
    background: "black",
  },
}));

const GeneralLayout = (props) => {
  const styles = useStyles();
  const { pathname } = useLocation();
  const { children, headerIsTransparent } = props;
  const [active, setActive] = useState();
  const { checkAuthState } = useContext(AuthContext);

  useEffect(() => {
    checkAuthState();
  }, []);

  return (
    <Box display="flex" minHeight="100vh" flexDirection="column">
      <Header headerIsTransparent={headerIsTransparent} {...{ active, setActive }} />
      <div className={pathname === "/" ? styles.spacingWithColor : styles.spacing}></div>
      <div className="page"> {children} </div>
      <Footer {...{ active, setActive }} />
    </Box>
  );
};

export default memo(GeneralLayout);
