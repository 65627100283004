import React, { useState } from "react";
import { Box, CardActions, Button, Card, Typography, CardContent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const PaymentPlan = React.memo(({ price, subscription, priceId, setPrice, AllPrice }) => {
  const filtered = subscription.filter((eachSubs) => eachSubs.priceId == priceId && eachSubs.paymentStatus == "active");
  const [IsSubscribed] = useState(filtered.length > 0 && filtered[0].priceId == priceId ? true : false);
  const filteredItem = filtered.length > 0 ? filtered[0] : {};
  const styles = useStyles();

  const btnClick = (e) => {
    if (IsSubscribed) {
      e.preventDefault();
      return;
    } else {
      setPrice(
        AllPrice.map((eachprice) => {
          if (eachprice.priceId == priceId) {
            if (eachprice.addToCart == true) {
              eachprice.addToCart = false;
            } else {
              eachprice.addToCart = true;
            }
          }
          return eachprice;
        })
      );
    }
  };
  return (
    <Box>
      <Card
        variant="outlined"
        className={
          IsSubscribed
            ? styles.selectedPriceItem
            : price.addToCart == true
            ? styles.addtoCartPriceItem
            : styles.priceItem
        }>
        <CardContent>
          {styles.icon}
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom className={styles.priceText}>
            {filteredItem.productName || price.text}
          </Typography>
          <Typography variant="body2" className={styles.priceAmount}>
            {price.amount}
          </Typography>
          <Typography variant="body2" className={styles.priceAmount}>
            {filteredItem.paymentStatus || "Not Active"}
          </Typography>
        </CardContent>
        <CardActions className={IsSubscribed ? styles.cursorDisabled : ""}>
          <Button
            size="small"
            onClick={btnClick}
            disabled={IsSubscribed}
            className={[
              IsSubscribed
                ? styles.selectedPriceAction
                : price.addToCart == true
                ? styles.AddToCartPriceAction
                : styles.priceAction,
            ].join(' ')}>
            {IsSubscribed
              ? filteredItem.paymentStatus && filteredItem.paymentStatus == "incomplete"
                ? "Pay invoice"
                : "Already Subscribed"
              : price.addToCart == true
              ? "Added to cart"
              : "Select"}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
});

const useStyles = makeStyles((theme) => ({
  priceItem: {
    border: "solid 2px brown",
    padding: "4px",
    borderRadius: "5px",
  },
  selectedPriceItem: {
    border: "solid 4px black",
    borderRadius: "5px",
    padding: "2px",
  },
  addtoCartPriceItem: {
    border: "solid 4px orange",
    borderRadius: "5px",
    padding: "2px",
  },
  priceText: {
    textAlign: "center",
    fontWeight: "bold",
  },
  priceAmount: {
    textAlign: "center",
  },
  container: {
    padding: "16px",
  },
  priceContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    gap: "8px",
  },
  priceAction: {
    background: "brown",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "orange",
    },
    "&:focus": {
      backgroundColor: "brown",
    },
    "&:active": {
      backgroundColor: "orange",
    },
    "&:disabled": {
      color: "white",
      cursor: "not-allowed !important",
    },
  },
  selectedPriceAction: {
    background: "black",
    color: "white",
    width: "100%",
    "&:hover, &:focus": {
      backgroundColor: "black",
    },
    "&:disabled": {
      color: "white",
      cursor: "not-allowed !important",
    },
  },
  AddToCartPriceAction: {
    background: "orange",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "brown",
    },
    "&:focus": {
      backgroundColor: "orange",
    },
    "&:disabled": {
      color: "black",
      cursor: "not-allowed !important",
    },
  },
  cursorDisabled: {
    cursor: "not-allowed !important",
  },
  cardlist: {
    padding: "16px 0",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
  },
  proceedCheckout: {
    backgroundColor: "brown",
    color: "white",
  },
}));
