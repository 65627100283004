/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://uyzkcgauvbbu7jif4i3s6s7x2q.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-uzcslgxvfnbzznebme3vtlg32a",
    "aws_cloud_logic_custom": [
        {
            "name": "restApiProd",
            "endpoint": "https://oi9cd8dj4d.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:78ef8d21-9857-4423-83da-43bdf023230f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_sDvGFUlzD",
    "aws_user_pools_web_client_id": "6l4iu8fdme0cvl8ltfrv6ulhnp",
    "oauth": {
        "domain": "gfyprod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/stoplight/,https://dev.d3q1zlnk5c0ho3.amplifyapp.com/stoplight/,https://production.d3q1zlnk5c0ho3.amplifyapp.com/stoplight/,https://uat.d3q1zlnk5c0ho3.amplifyapp.com/stoplight/,https://www.gfy.ag/stoplight/",
        "redirectSignOut": "http://localhost:3000/,https://dev.d3q1zlnk5c0ho3.amplifyapp.com/,https://production.d3q1zlnk5c0ho3.amplifyapp.com/,https://uat.d3q1zlnk5c0ho3.amplifyapp.com/,https://www.gfy.ag/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_UPPERCASE",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_content_delivery_bucket": "prod-gfy-ag-production",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1hvb2c0y3414.cloudfront.net",
    "aws_user_files_s3_bucket": "content-storage-prod-gfy-ag160528-production",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "appdata-production",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
